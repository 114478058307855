import styled from 'styled-components';
const colors = {
    white: '#ffffff',
    black: '#000000',
    gray: '#666',
    lightGray: '#999',
    borderGray: '#ddd',
    backgroundGray: '#f9f9f9',
    headerBackground: '#f2f2f2',
    shadow: 'rgba(0, 0, 0, 0.1)'
};
const spacing = {
    small: '10px',
    medium: '15px',
    large: '20px',
    xlarge: '30px',
    xxlarge: '50px',
    xxxlarge: '80px'
};
const fontSizes = {
    small: '10px',
    medium: '18px',
    large: '24px'
};
export const PDFButton = styled.button `
  width: 100%;
  background-color: #1351b4;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const PDFContainer = styled.div `
  width: 100%;
  max-width: 800px;
  background-color: ${colors.white};
  padding: ${spacing.large};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px ${colors.shadow};
`;
export const TitleContainer = styled.div `
  text-align: center;
  margin-bottom: ${spacing.xlarge};
`;
export const Title = styled.h1 `
  font-size: ${fontSizes.large};
  font-weight: bold;
`;
export const Subtitle = styled.p `
  color: ${colors.gray};
`;
export const Section = styled.div `
  margin-bottom: ${spacing.xlarge};
`;
export const SectionTitle = styled.h2 `
  font-size: ${fontSizes.medium};
  border-bottom: 1px solid ${colors.borderGray};
  padding-bottom: ${spacing.small};
`;
export const InfoBlock = styled.div `
  margin: ${spacing.medium} 0;
  p {
    margin: ${spacing.small} 0;
  }
`;
export const SignatureContainer = styled.div `
  margin-top: ${spacing.xxxlarge};
  text-align: center;
`;
export const Signature = styled.div `
  border-top: 1px solid ${colors.black};
  width: 250px;
  margin: ${spacing.xxlarge} auto 0;
  padding-top: ${spacing.small};
`;
export const Footer = styled.div `
  margin-top: ${spacing.xxlarge};
  font-size: ${fontSizes.small};
  color: ${colors.lightGray};
  text-align: center;
`;
export const Table = styled.table `
  width: 100%;
  border-collapse: collapse;
  margin-top: ${spacing.medium};
`;
export const TableRow = styled.tr `
  &:nth-child(even) {
    background-color: ${colors.backgroundGray};
  }
`;
export const TableHeader = styled.th `
  border: 1px solid ${colors.borderGray};
  padding: ${spacing.small};
  text-align: left;
  background-color: ${colors.headerBackground};
`;
export const TableCell = styled.td `
  border: 1px solid ${colors.borderGray};
  padding: ${spacing.small};
  text-align: left;
`;
