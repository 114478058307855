import { camelCase } from 'change-case';
export const isSortFunction = obj => {
    return typeof obj === 'function';
};
export const isOrderRelation = obj => {
    return typeof obj === 'object';
};
export const isOrderBy = key => {
    if (key.startsWith('order') && key.endsWith('By') && key.length > 'orderBy'.length) {
        return camelCase(key.substring('order'.length, key.length - 'By'.length));
    }
    return key;
};
export const sortRelationBy = (obj, order) => {
    Object.entries(order).forEach(_ref => {
        let [key, value] = _ref;
        key = isOrderBy(key);
        const relation = obj[key];
        if (Array.isArray(relation) && isSortFunction(value)) {
            obj[key] = relation.sort(value);
        }
        if (isOrderRelation(value)) {
            [relation].flat().forEach(relation => sortRelationBy(relation, value));
        }
    });
    return obj;
};
