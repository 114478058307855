var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';
export class AreaDeConhecimentoApi extends GenericApi {
    buscarGrandesAreas() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.get(`${this.url}/grandearea`);
            return response.data;
        });
    }
    buscarAreaPorGrandeAreaId(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.get(`${this.url}/area/${id}`);
            return response.data;
        });
    }
    buscarSubAreaPorAreaId(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.get(`${this.url}/subarea/${id}`);
            return response.data;
        });
    }
    buscarEspecialidadePorSubAreaId(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.get(`${this.url}/especialidade/${id}`);
            return response.data;
        });
    }
}
export const makeAreaDeConhecimentoApi = () => {
    return new AreaDeConhecimentoApi('area-de-conhecimento');
};
