const filterUiSchema = handleRemove => ({
    type: 'VerticalLayout',
    elements: [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Filter',
                    scope: '#/properties/filters',
                    options: {
                        handleRemove
                    }
                }]
        }]
});
export default filterUiSchema;
