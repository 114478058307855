var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GenericApi } from './generic-api';
import client from '../config/axios-config';
export class EditalApi extends GenericApi {
    getEditalFileToken() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.get(`${this.url}/file-token`);
                return response.data;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Get error: ', {
                    cause: error
                });
            }
        });
    }
}
export const makeEditalApi = () => {
    return new EditalApi('/edital');
};
