const funcionalProgramaticaUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/codigo',
            label: 'Código',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/descricao',
            label: 'Descrição'
        }]
};
export default funcionalProgramaticaUiSchema;
