var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeApi } from '../../../api/generic-api';
import { parseMathTree } from '../../../helpers/math-tree/parser';
import { TipoRespostaPerguntaAvaliacao } from '../../../models/pergunta-avaliacao';
import { makeAvaliacaoEnquadramento } from './avaliacao-proposta-api';
import { makeAvaliacaoConsultor } from '../avaliacao-consultor/avaliacao-consultor-api';
import { reviewStyle } from '../../../views/avaliacao-proposta-detalhes/avaliacao-proposta-detalhes.view';
export const configurarEnquadramento = (_ref) => __awaiter(void 0, void 0, void 0, function* () {
    let { formularioEnquadramentoId, propostaId, avaliacaoId, setTitlePage, setFormulario, setHasCategories, setCategories, setPerguntasMap, setPerguntas, setReviewControl, avaliacaoConsultor } = _ref;
    setTitlePage('Avaliação de Enquadramento');
    const formularioEnquadramentoApi = makeApi('formulario-enquadramento');
    const avaliacaoEnquadramentoApi = makeAvaliacaoEnquadramento(formularioEnquadramentoId, propostaId);
    try {
        const formulario = yield formularioEnquadramentoApi.get(formularioEnquadramentoId);
        const avaliacao = yield avaliacaoEnquadramentoApi.get(avaliacaoId);
        processarFormularioEAvaliacao({
            formulario,
            avaliacao,
            setFormulario,
            setHasCategories,
            setCategories,
            setPerguntasMap,
            setPerguntas,
            setReviewControl,
            avaliacaoConsultor
        });
    }
    catch (error) {
        console.error('Erro ao buscar dados de enquadramento:', error);
    }
});
export const configurarConsultoria = (_ref2) => __awaiter(void 0, void 0, void 0, function* () {
    let { formularioConsultoriaId, conviteId, avaliacaoId, setTitlePage, setFormulario, setHasCategories, setCategories, setPerguntasMap, setPerguntas, setReviewControl, avaliacaoConsultor } = _ref2;
    setTitlePage('Avaliação de Consultoria');
    const avaliacaoConsultoriaApi = makeAvaliacaoConsultor(conviteId);
    try {
        const [formulario, avaliacao] = yield Promise.all([avaliacaoConsultoriaApi.getFormularioConsultoria(formularioConsultoriaId), avaliacaoConsultoriaApi.get(avaliacaoId)]);
        processarFormularioEAvaliacao({
            formulario,
            avaliacao,
            setFormulario,
            setHasCategories,
            setCategories,
            setPerguntasMap,
            setPerguntas,
            setReviewControl,
            avaliacaoConsultor
        });
    }
    catch (error) {
        console.error('Erro ao buscar dados de consultoria:', error);
    }
});
const processarFormularioEAvaliacao = _ref3 => {
    let { formulario, avaliacao, setFormulario, setHasCategories, setCategories, setPerguntasMap, setPerguntas, setReviewControl } = _ref3;
    setFormulario(formulario);
    const hasCategories = formulario.perguntasCategorizadas;
    const respostasObj = avaliacao.descricaoRespostaObj;
    const revisaoAvaliacaoObj = avaliacao.revisaoAvaliacaoObj;
    setHasCategories(hasCategories);
    if (hasCategories) {
        processarCategorizados({
            formulario,
            respostasObj,
            revisaoAvaliacaoObj,
            setCategories,
            setPerguntasMap,
            setReviewControl
        });
    }
    else {
        processarNaoCategorizados({
            formulario,
            respostasObj,
            revisaoAvaliacaoObj,
            setPerguntas,
            setPerguntasMap,
            setReviewControl
        });
    }
};
const processarCategorizados = _ref4 => {
    let { formulario, respostasObj, revisaoAvaliacaoObj, setCategories, setPerguntasMap, setReviewControl } = _ref4;
    const categoriesData = formulario.formularioEnquadramentoCategorizado || formulario.formularioConsultoriaCategorizado;
    if (categoriesData && categoriesData.length > 0) {
        const categorias = categoriesData.map(categoria => {
            return {
                id: categoria.id,
                tituloCategoria: categoria.tituloCategoria,
                perguntas: (categoria.formularioEnquadramentoCategorizadoPerguntaAvaliacao || categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao || []).map(pergunta => {
                    var _a, _b;
                    const resposta = (_a = respostasObj[`form-${categoria.id}`]) === null || _a === void 0 ? void 0 : _a[`pergunta-${pergunta.id}`];
                    const revisao = revisaoAvaliacaoObj ? (_b = revisaoAvaliacaoObj[`form-${categoria.id}`]) === null || _b === void 0 ? void 0 : _b[`pergunta-${pergunta.id}`] : '';
                    return Object.assign(Object.assign({}, pergunta), { resposta,
                        revisao });
                })
            };
        });
        setCategories(categorias);
        construirPerguntasMap(categorias, setPerguntasMap);
    }
};
const processarNaoCategorizados = _ref5 => {
    let { formulario, respostasObj, revisaoAvaliacaoObj, setPerguntas, setPerguntasMap, setReviewControl } = _ref5;
    const perguntasFormulario = formulario.formularioEnquadramentoPergunta || formulario.formularioConsultoriaPergunta;
    if (perguntasFormulario && perguntasFormulario.length > 0) {
        const perguntasMap = {};
        const perguntas = perguntasFormulario.map(pergunta => {
            var _a, _b;
            const resposta = (_a = respostasObj['form']) === null || _a === void 0 ? void 0 : _a[`pergunta-${pergunta.id}`];
            const revisao = revisaoAvaliacaoObj ? (_b = revisaoAvaliacaoObj['form']) === null || _b === void 0 ? void 0 : _b[`pergunta-${pergunta.id}`] : '';
            return Object.assign(Object.assign({}, pergunta), { resposta,
                revisao });
        });
        const reviewControl = perguntasFormulario.map(pergunta => {
            var _a;
            const revisao = revisaoAvaliacaoObj ? (_a = revisaoAvaliacaoObj['form']) === null || _a === void 0 ? void 0 : _a[`pergunta-${pergunta.id}`] : '';
            const reviewControlObj = {
                id: pergunta.id,
                status: revisao === '' ? reviewStyle.normal : reviewStyle.edit,
                view: false,
                revisao: revisao,
                revisaoPrev: revisao
            };
            return reviewControlObj;
        });
        setPerguntas(perguntas);
        setReviewControl(reviewControl);
        perguntas.forEach((pergunta, index) => {
            const key = `P${index + 1}`;
            perguntasMap[key] = pergunta;
        });
        setPerguntasMap(perguntasMap);
    }
};
const construirPerguntasMap = (categorias, setPerguntasMap) => {
    const perguntasMap = {};
    categorias.forEach((categoria, catIndex) => {
        categoria.perguntas.forEach((pergunta, pergIndex) => {
            const key = `P${catIndex + 1}.${pergIndex + 1}`;
            perguntasMap[key] = pergunta;
        });
    });
    setPerguntasMap(perguntasMap);
};
export const convertToLatex = input => {
    if (!input)
        return '';
    return input
        // Fração com parênteses
        .replace(/\(([^)]+)\) \/ (\d+)/g, '\\frac{$1}{$2}')
        // Multiplicação
        .replace(/\*/g, ' \\times ')
        // Raiz quadrada e cúbica
        .replace(/sqrt\[(\d+)\]\{([^}]+)\}/g, '\\sqrt[$1]{$2}').replace(/sqrt\{([^}]+)\}/g, '\\sqrt{$1}')
        // Divisão simples
        .replace(/(\S+) \/ (\S+)/g, '\\frac{$1}{$2}');
};
export const calcularNota = (notas, formula) => {
    if (!formula || Object.keys(notas).length === 0) {
        return 0;
    }
    try {
        const formulaStr = formula.replace(/\s+/g, '');
        const mathTree = parseMathTree(formulaStr);
        return mathTree.solve(notas);
    }
    catch (e) {
        console.error('Erro ao calcular nota final', e);
        return 0;
    }
};
export const processarNotas = _ref6 => {
    let { perguntasMap, formula, setNotaFinal, setNotaMaximaPossivel } = _ref6;
    const notasFinal = [];
    const notasMaxima = [];
    Object.entries(perguntasMap).filter(_ref7 => {
        let [, value] = _ref7;
        return value.tipoResposta === TipoRespostaPerguntaAvaliacao.nota;
    }).forEach(_ref8 => {
        var _a;
        let [key, value] = _ref8;
        const resposta = value.resposta;
        notasMaxima.push([key, Number((_a = value.nota) === null || _a === void 0 ? void 0 : _a.notaMaxima) || 0]);
        notasFinal.push([key, Number(resposta.nota) || 0]);
    });
    setNotaFinal(calcularNota(Object.fromEntries(notasFinal), formula));
    setNotaMaximaPossivel(calcularNota(Object.fromEntries(notasMaxima), formula));
};
export const getPerguntaDescricao = pergunta => {
    var _a, _b;
    switch (pergunta.tipoResposta) {
        case TipoRespostaPerguntaAvaliacao.descritiva:
            return (_a = pergunta.descritiva) === null || _a === void 0 ? void 0 : _a.descricao;
        case TipoRespostaPerguntaAvaliacao.nota:
            return (_b = pergunta.nota) === null || _b === void 0 ? void 0 : _b.descricao;
        default:
            return '';
    }
};
export const formatarNota = nota => Number.isInteger(nota) ? nota : Number(Math.floor(nota * 100) / 100);
