var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeApi } from '../../../../../api/generic-api';
import { CrudStatesOptions } from '../../../../../jsonforms/uischema';
const getErrorMessage = error => {
    var _a, _b, _c, _d, _e;
    let errorMessage = ((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || ((_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message);
    if (Array.isArray(errorMessage))
        errorMessage = errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage[0];
    return errorMessage;
};
const handleApiResponse = (apiCall, showError) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiCall;
        if (![200, 201].includes(response.status))
            return false;
    }
    catch (error) {
        showError(getErrorMessage(error) || 'Ocorreu um erro.');
        return false;
    }
    return true;
});
export const handlerFormularioConsultoriaFormula = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const { formData, crudStates, showError, updateCrudStates } = ctx;
    const api = makeApi('formulario-consultoria');
    let result = true;
    if (crudStates.add) {
        result = yield handleApiResponse((_a = api === null || api === void 0 ? void 0 : api.post) === null || _a === void 0 ? void 0 : _a.call(api, formData), showError);
    }
    else if (crudStates.edit) {
        result = yield handleApiResponse((_b = api === null || api === void 0 ? void 0 : api.put) === null || _b === void 0 ? void 0 : _b.call(api, formData === null || formData === void 0 ? void 0 : formData.id, formData), showError);
    }
    if (result) {
        updateCrudStates(CrudStatesOptions.LIST);
    }
    return result;
});
