import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
export const documentosPessoaisUiSchema = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        type: 'Category',
        label: 'Documentos Pessoais',
        options: {
            onFinish: _ref => {
                let { ctx, authContext } = _ref;
                const { updateUserName, getLoggedInUser } = authContext;
                getLoggedInUser();
                const { formData } = ctx;
                updateUserName(formData === null || formData === void 0 ? void 0 : formData.nome);
            },
            stepHandler: {
                handler: stepHandlerErrors,
                callback: ctx => {
                    const { showSuccess, updateCrudStates } = ctx;
                    updateCrudStates(CrudStatesOptions.VIEW);
                    showSuccess('Editado com sucesso.');
                    return true;
                }
            }
        },
        elements: [{
                type: 'VerticalLayout',
                elements: [{
                        label: 'Documentos Pessoais',
                        type: 'File',
                        scope: `#${scopePrefix}/properties/usuarioAnexo`,
                        options: {
                            withoutFk: true
                        }
                    }]
            }]
    };
};
