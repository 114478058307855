var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const stepHandlerEditalRubrica = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    const { formData, showError } = ctx;
    const editalRubrica = formData === null || formData === void 0 ? void 0 : formData.editalRubrica;
    let valid = true;
    let message = '';
    if ((editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.length) > 0) {
        editalRubrica.forEach(x => {
            if (!x.tipoEditalRubrica) {
                valid = false;
                message = 'Selecione uma Rubrica.';
            }
            else if (!x.naturezaDespesaId) {
                valid = false;
                message = 'Selecione uma Natureza da Despesa.';
            }
            else if (x.temMoedaEstrangeira) {
                if (!x.moedaEstrangeira || x.moedaEstrangeira.length < 1) {
                    valid = false;
                    message = 'Selecione ao menos uma moeda estrangeira.';
                }
            }
        });
        if (valid)
            return true;
    }
    else {
        return true;
    }
    showError(message);
    return false;
});
