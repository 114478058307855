const AvaliacaoConsultorUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/descricaoRespostaObj'
        }, {
            type: 'Control',
            scope: '#/properties/conviteId',
            rule: {
                effect: 'HIDE',
                condition: {}
            }
        }]
};
export default AvaliacaoConsultorUiSchema;
