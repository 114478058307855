var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import merge from 'lodash/merge';
import { makeErrorsManager } from '../components/crud/errors-manager';
import { customRequired } from '../helpers/custom-errors';
import { getNestedValues } from '../helpers/get-nested-values';
import { scopeToPath } from '../helpers/scope-to-path';
export const stepHandlerErrors = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const { errorsJsonForms, setValidationMode, errorsCustom, setErrorsCustom } = ctx;
    const { paths, instancePaths, requiredScopes } = fieldsConfig;
    const requiredFields = (_a = requiredScopes === null || requiredScopes === void 0 ? void 0 : requiredScopes.map) === null || _a === void 0 ? void 0 : _a.call(requiredScopes, scope => scopeToPath(scope));
    const errorsManager = makeErrorsManager(ctx);
    const { addCustomErrors } = errorsManager;
    const requiredErrors = [];
    for (const requiredField of requiredFields) {
        const value = getNestedValues(data, requiredField);
        if (!value) {
            const requiredError = customRequired(requiredField);
            if (!errorsCustom.find(e => e === requiredError))
                requiredErrors.push(requiredError);
        }
    }
    const newErrorsCustom = merge(errorsCustom, requiredErrors);
    setErrorsCustom(newErrorsCustom);
    const additionalErrorsLocal = addCustomErrors();
    const customError = (_b = additionalErrorsLocal === null || additionalErrorsLocal === void 0 ? void 0 : additionalErrorsLocal.find) === null || _b === void 0 ? void 0 : _b.call(additionalErrorsLocal, error => { var _a; return (_a = paths === null || paths === void 0 ? void 0 : paths.includes) === null || _a === void 0 ? void 0 : _a.call(paths, error === null || error === void 0 ? void 0 : error.propertyName); });
    const jsonFormErrors = errorsJsonForms === null || errorsJsonForms === void 0 ? void 0 : errorsJsonForms.map(error => {
        var _a, _b, _c;
        if ((_a = requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.includes) === null || _a === void 0 ? void 0 : _a.call(requiredFields, (_b = error === null || error === void 0 ? void 0 : error.params) === null || _b === void 0 ? void 0 : _b.missingProperty)) {
            return error;
        }
        if ((_c = instancePaths === null || instancePaths === void 0 ? void 0 : instancePaths.includes) === null || _c === void 0 ? void 0 : _c.call(instancePaths, error === null || error === void 0 ? void 0 : error.instancePath)) {
            return error;
        }
        return undefined;
    }).filter(error => error !== undefined);
    if (jsonFormErrors.length || !!customError) {
        console.error('Errors', jsonFormErrors, customError);
        setValidationMode('ValidateAndShow');
        return false;
    }
    if (callback) {
        const cbValue = !!(yield (callback === null || callback === void 0 ? void 0 : callback(ctx, fieldsConfig)));
        if (cbValue)
            setValidationMode('ValidateAndHide');
        return cbValue;
    }
    setValidationMode('ValidateAndHide');
    return true;
});
