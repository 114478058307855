import { Paperclip, BuildingBankToolbox } from '../../../components/styled-icons';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerBack } from '../../../utils/step-handler-back';
const iconConfig = [{
        category: 'Instrumentos Jurídicos',
        icon: BuildingBankToolbox
    }, {
        category: 'Anexo',
        icon: Paperclip
    }];
const instrumentoJuridicoUiSchema = {
    type: 'Categorization',
    elements: [{
            type: 'Category',
            label: 'Instrumentos Jurídicos',
            options: {
                stepHandler: {
                    handler: stepHandlerErrors,
                    callback: ctx => createResource(ctx, '/instrumento-juridico')
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/nome',
                                    options: {
                                        required: true
                                    }
                                }]
                        }, {
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/statusInstrumentoJuridico',
                                            label: 'Status',
                                            options: {
                                                required: true
                                            }
                                        }]
                                }, {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/tipoInstrumentoJuridico',
                                            label: 'Tipo',
                                            options: {
                                                required: true
                                            }
                                        }]
                                }]
                        }, {
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'LongText',
                                    scope: '#/properties/justificativa'
                                }]
                        }, {
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'Date',
                                    scope: '#/properties/inicioVigencia',
                                    label: 'Início da Vigência',
                                    options: {
                                        required: true
                                    }
                                }, {
                                    type: 'Date',
                                    scope: '#/properties/terminoVigencia',
                                    label: 'Término da Vigência',
                                    options: {
                                        required: true
                                    }
                                }, {
                                    type: 'Date',
                                    scope: '#/properties/dataAssinatura',
                                    label: 'Data da Assinatura'
                                }, {
                                    type: 'Date',
                                    scope: '#/properties/dataPublicacao',
                                    label: 'Data da Publicação'
                                }]
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Anexo',
            options: {
                stepHandler: {
                    handler: stepHandlerBack,
                    url: '/instrumento-juridico'
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'File',
                            scope: '#/properties/instrumentoJuridicoAnexo'
                        }]
                }]
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        icons: iconConfig
    }
};
export default instrumentoJuridicoUiSchema;
