var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RuleEffect } from '@jsonforms/core';
import dayjs from 'dayjs';
import { camelCase } from 'lodash';
import last from 'lodash/last';
import { formatCurrency } from '../../helpers/format-currency';
import { CrudStatesOptions } from '../../jsonforms/uischema';
import { tipoEditalRubricaI18N } from '../../models/edital-rubrica';
import { TipoPergunta } from '../../models/pergunta';
import { createResource } from '../../utils/create-resource';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { stepHandlerEditalRubrica } from './handlers/step-handler-edital-rubrica';
import perguntaUiSchema from './pergunta/pergunta.uischema';
export const formatLabel = item => {
    var _a, _b;
    const value = item.valorAtualBolsa / 100;
    const moedaEstrangeira = (_b = (_a = item === null || item === void 0 ? void 0 : item.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.simbolo) !== null && _b !== void 0 ? _b : 'R$';
    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
    if (item.cargaHoraria !== null)
        return `${item.nivel} (${item.cargaHoraria}H - ${moedaEstrangeira} ${formattedValue})`;
    return `${item.nivel} ${moedaEstrangeira} ${formattedValue})`;
};
const formatDataLabel = item => {
    return dayjs(item).format('DD/MM/YYYY HH:mm:ss');
};
const hideIfTipoEvento = {
    effect: 'HIDE',
    condition: {
        scope: '#/properties/restricaoTipoEvento',
        schema: {
            const: false
        }
    }
};
const hideIfRubricaBolsa = {
    effect: RuleEffect.HIDE,
    condition: {
        scope: '#/properties/tipoEditalRubrica',
        schema: {
            type: 'string',
            const: 'BOLSA'
        }
    }
};
const hideIfFuncaoMembro = {
    effect: 'HIDE',
    condition: {
        scope: '#/properties/restricaoParticipante',
        schema: {
            const: false
        }
    }
};
const hideIfNotMoedaEstrangeira = {
    effect: 'SHOW',
    condition: {
        type: 'AND',
        conditions: [{
                scope: '#/properties/temMoedaEstrangeira',
                schema: {
                    const: true
                }
            }, {
                scope: '#/properties/tipoEditalRubrica',
                schema: {
                    not: {
                        const: 'BOLSA'
                    }
                }
            }]
    }
};
const hideIfNotDefineMonth = {
    effect: 'SHOW',
    condition: {
        type: 'AND',
        conditions: [{
                scope: '#/properties/definirDuracaoProjetoEmMeses',
                schema: {
                    const: true
                }
            }, {}]
    }
};
const hideIfNoCoordinatorMinimumAcademicLevelRequired = {
    effect: 'HIDE',
    condition: {
        scope: '#/properties/possuiNivelAcademicoMinimoCoordenador',
        schema: {
            const: false
        }
    }
};
const showIfHasQuantityOfScholarshipPerProposal = {
    effect: 'SHOW',
    condition: {
        scope: '#/properties/possuiQuantidadeBolsaPorProposta',
        schema: {
            const: true
        }
    }
};
const showIfHasConsultingForm = {
    effect: RuleEffect.SHOW,
    condition: {
        scope: '#/properties/formularioConsultoria',
        schema: {
            type: 'array',
            minItems: 1
        }
    }
};
const editalUiSchema = {
    type: 'CategorizationMenu',
    elements: [{
            type: 'Category',
            label: 'Informações do Edital',
            elements: [{
                    type: 'Category',
                    label: 'Identificação do Edital',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/nome',
                            label: 'Título do Edital',
                            options: {
                                required: true
                            }
                        }]
                }, {
                    type: 'Category',
                    label: 'Restrições',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Control',
                                            scope: '#/properties/definirDuracaoProjetoEmMeses',
                                            label: 'Definir a duração do projeto em meses'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/duracaoProjetoEmMeses',
                                            label: 'Duração do projeto em meses',
                                            options: {
                                                isPositiveNumber: true
                                            },
                                            rule: hideIfNotDefineMonth
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/coordenadorPodeReceberBolsa',
                                            label: 'Coordenador do projeto poderá receber bolsa'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/vinculoInstitucionalObrigatorio',
                                            label: 'Vínculo institucional obrigatório'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/pesquisadorSubmeterVariasPropostas',
                                            label: 'Pesquisador pode submeter mais de uma proposta'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/restricaoConviteParticipantes',
                                            label: 'Será um edital para membros convidados'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/restricaoParticipante',
                                            label: 'Possui restrição de Funções de Membros'
                                        }, {
                                            type: 'Select',
                                            scope: '#/properties/funcaoMembro',
                                            label: 'Funções Membro(s)',
                                            rule: hideIfFuncaoMembro,
                                            options: {
                                                selectMultiple: true,
                                                route: 'funcao-membro'
                                            }
                                        }]
                                }, {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Control',
                                            scope: '#/properties/possuiNivelAcademicoMinimoCoordenador',
                                            label: 'Possui nível acadêmico mínimo do coordenador',
                                            options: {
                                                handleChange: (value, handler, _formData, _ctx, path) => __awaiter(void 0, void 0, void 0, function* () {
                                                    if (!value) {
                                                        handler('nivelAcademicoMinimoCoordenadorId', null);
                                                        handler('nivelAcademicoMinimoCoordenador', null);
                                                        handler('nivelAcademicoMinimoCoordenadorId-codigo', null);
                                                    }
                                                    handler(path, value);
                                                })
                                            }
                                        }, {
                                            type: 'Select',
                                            scope: '#/properties/nivelAcademicoMinimoCoordenadorId',
                                            label: 'Nível acadêmico do coordenador',
                                            rule: hideIfNoCoordinatorMinimumAcademicLevelRequired
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/vinculoEmpregaticioObrigatorio',
                                            label: 'Vínculo empregatício obrigatório'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/coordenadorPodeParticiparOutroProjeto',
                                            label: 'Coordenador pode participar da equipe de outro projeto'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/obrigatorioLinkLattesCoordenador',
                                            label: 'Obrigatório o Coordenador possuir link do Lattes'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/obrigatorioLinkLattesMembros',
                                            label: 'Obrigatório membros possuir link do Lattes'
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/restricaoTipoEvento',
                                            label: 'Possui restrição para Tipos de Eventos'
                                        }, {
                                            type: 'Select',
                                            scope: '#/properties/tipoEvento',
                                            label: 'Tipo(s) de Evento(s)',
                                            rule: hideIfTipoEvento,
                                            options: {
                                                selectMultiple: true,
                                                route: 'tipo-evento'
                                            }
                                        }]
                                }]
                        }]
                }, {
                    type: 'Category',
                    label: 'Termo de Aceite',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'CkEditor',
                                    scope: '#/properties/termoDeAceite',
                                    options: {
                                        ckeditorSize: {
                                            height: 400
                                        }
                                    }
                                }]
                        }]
                }, {
                    type: 'Category',
                    label: 'Texto do Edital',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'CkEditor',
                                    scope: '#/properties/texto',
                                    options: {
                                        ckeditorSize: {
                                            height: 400
                                        }
                                    }
                                }]
                        }]
                }, {
                    type: 'Category',
                    label: 'Abrangência',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'ButtonDataGrid',
                                    scope: '#/properties/estado'
                                }]
                        }]
                }, {
                    type: 'Category',
                    label: 'Informações Complementares',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Select',
                                    scope: '#/properties/perguntaInfoId',
                                    label: 'Pergunta(s) de Informações Complementares',
                                    options: {
                                        route: 'pergunta',
                                        addEmptyOption: true,
                                        handleChange: (value, handler) => {
                                            handler('perguntaInfo', value);
                                            handler('perguntaInfoId', value);
                                        },
                                        filter: {
                                            handler: (data, listData, api, queryFilterValues, path) => {
                                                return api.getAll({
                                                    perguntaBase: true
                                                }).then(response => {
                                                    return response.filter(x => x.tipoPergunta === TipoPergunta.informacoesComplementares);
                                                });
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/informacaoComplementarPergunta',
                                    label: 'Informações Complementares',
                                    options: {
                                        detail: perguntaUiSchema({
                                            withoutTipoPergunta: true
                                        }),
                                        elementLabelProp: 'pergunta',
                                        defaultValue: formData => {
                                            var _a;
                                            return (_a = formData.perguntaInfo) !== null && _a !== void 0 ? _a : {
                                                tipoPergunta: TipoPergunta.informacoesComplementares,
                                                descritiva: {
                                                    minimo: 0,
                                                    maximo: 0
                                                },
                                                perguntaBase: false,
                                                obrigatoria: false
                                            };
                                        },
                                        showSortButtons: true,
                                        handleChange: (value, handler, formData, ctx, path) => __awaiter(void 0, void 0, void 0, function* () {
                                            const formDataPergunta = path.split('.').reduce((acc, part) => acc && acc[part], formData) || [];
                                            const allHavePosition = Array.isArray(formDataPergunta) && formDataPergunta.every(item => item.posicao !== undefined);
                                            if (!allHavePosition) {
                                                const updatedFormDataPergunta = allHavePosition ? formDataPergunta : formDataPergunta.map((item, index) => (Object.assign(Object.assign({}, item), { posicao: index + 1 })));
                                                handler(path, updatedFormDataPergunta);
                                            }
                                        })
                                    }
                                }]
                        }]
                }, {
                    type: 'Category',
                    label: 'Anexos',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'File',
                                    scope: '#/properties/editalAnexo'
                                }]
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Cronograma',
            elements: [{
                    type: 'Category',
                    label: 'Período de Submissão',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'ArrayTableLayout',
                            scope: '#/properties/chamada',
                            label: '',
                            options: {
                                headCells: [{
                                        label: 'Submissão',
                                        field: row => `${formatDataLabel(row.inicio)} até ${formatDataLabel(row.termino)}`
                                    }, {
                                        label: 'Recurso Administrativo Enquadramento',
                                        field: row => row.inicioReconsideracaoEnquadramento ? `${formatDataLabel(row.inicioReconsideracaoEnquadramento)} até ${formatDataLabel(row.terminoReconsideracaoEnquadramento)}` : '-'
                                    }, {
                                        label: 'Recurso Administrativo Aprovação',
                                        field: row => row.inicioReconsideracaoAprovacao ? `${formatDataLabel(row.inicioReconsideracaoAprovacao)} até ${formatDataLabel(row.terminoReconsideracaoAprovacao)}` : '-'
                                    }, {
                                        label: 'Status',
                                        field: row => row.chamadaSuspensa ? 'Suspensa' : 'Ativa'
                                    }],
                                detail: {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'VerticalLayout',
                                            elements: [{
                                                    type: 'HorizontalLayout',
                                                    elements: [{
                                                            type: 'Control',
                                                            scope: '#/properties/inicio',
                                                            label: 'Data e Horário Inicial',
                                                            options: {
                                                                required: true,
                                                                format: 'date-time',
                                                                dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                                                                dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                                                ampm: false
                                                            }
                                                        }, {
                                                            type: 'Control',
                                                            scope: '#/properties/termino',
                                                            label: 'Data e Horário Final',
                                                            options: {
                                                                required: true,
                                                                format: 'date-time',
                                                                dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                                                                dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                                                ampm: false
                                                            }
                                                        }]
                                                }, {
                                                    type: 'Control',
                                                    scope: '#/properties/chamadaSuspensa',
                                                    label: 'Suspender Chamada'
                                                }]
                                        }, {
                                            type: 'Label',
                                            options: {
                                                formatOptionLabel: () => 'Período de Recurso Administrativo de Enquadramento',
                                                componentProps: {
                                                    sx: {
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }
                                                }
                                            }
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/inicioReconsideracaoEnquadramento',
                                                    label: 'Data e Horário Inicial',
                                                    options: {
                                                        format: 'date-time',
                                                        dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                                                        dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                                        ampm: false
                                                    }
                                                }, {
                                                    type: 'Control',
                                                    scope: '#/properties/terminoReconsideracaoEnquadramento',
                                                    label: 'Data e Horário Final',
                                                    options: {
                                                        format: 'date-time',
                                                        dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                                                        dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                                        ampm: false
                                                    }
                                                }]
                                        }, {
                                            type: 'Label',
                                            options: {
                                                formatOptionLabel: () => 'Período de Recurso Administrativo de Aprovação',
                                                componentProps: {
                                                    sx: {
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }
                                                }
                                            }
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/inicioReconsideracaoAprovacao',
                                                    label: 'Data e Horário Inicial',
                                                    options: {
                                                        format: 'date-time',
                                                        dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                                                        dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                                        ampm: false
                                                    }
                                                }, {
                                                    type: 'Control',
                                                    scope: '#/properties/terminoReconsideracaoAprovacao',
                                                    label: 'Data e Horário Final',
                                                    options: {
                                                        format: 'date-time',
                                                        dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
                                                        dateTimeSaveFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                                        ampm: false
                                                    }
                                                }]
                                        }]
                                }
                            }
                        }]
                }, {
                    type: 'Category',
                    label: 'Formulário de Consultoria',
                    rule: showIfHasConsultingForm,
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/formularioConsultoria',
                            label: '',
                            options: {
                                itemLayout: 'SimpleItemLayout',
                                disabled: true,
                                detail: {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Control',
                                            scope: '#/properties/titulo',
                                            label: 'Título'
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Date',
                                                    scope: '#/properties/dataInicio',
                                                    label: 'Início',
                                                    options: {
                                                        dateFormat: 'DD/MM/YYYY'
                                                    }
                                                }, {
                                                    type: 'Date',
                                                    scope: '#/properties/dataTermino',
                                                    label: 'Término',
                                                    options: {
                                                        dateFormat: 'DD/MM/YYYY'
                                                    }
                                                }, {
                                                    type: 'Date',
                                                    scope: '#/properties/dataExpiracaoConvite',
                                                    label: 'Expiração do Convite',
                                                    options: {
                                                        dateFormat: 'DD/MM/YYYY'
                                                    }
                                                }]
                                        }]
                                }
                            }
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Orçamento',
            elements: [{
                    type: 'Category',
                    label: 'Programa',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Select',
                                    scope: '#/properties/programaId',
                                    label: 'Programa',
                                    options: {
                                        required: true
                                    }
                                }, {
                                    type: 'ArrayTableLayout',
                                    scope: '#/properties/naturezaDespesaEdital',
                                    label: 'Natureza da Despesa',
                                    options: {
                                        headCells: [{
                                                label: 'Natureza da Despesa',
                                                field: row => { var _a; return (_a = row.naturezaDespesa) === null || _a === void 0 ? void 0 : _a.nome; }
                                            }, {
                                                label: 'Valor',
                                                field: row => row.valor ? formatCurrency(row.valor) : formatCurrency(0)
                                            }, {
                                                label: 'Edição do Programa',
                                                field: row => { var _a; return (_a = row.edicaoPrograma) === null || _a === void 0 ? void 0 : _a.ano; }
                                            }],
                                        itemLayout: 'SimpleItemLayout',
                                        detail: {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Select',
                                                    scope: '#/properties/naturezaDespesaId',
                                                    label: 'Natureza da Despesa',
                                                    options: {
                                                        handleChange: (item, handler, formData, ctx, path) => {
                                                            var _a;
                                                            const dataPath = (_a = path === null || path === void 0 ? void 0 : path.split) === null || _a === void 0 ? void 0 : _a.call(path, '.').slice(0, -1).join('.');
                                                            handler(`${dataPath}.naturezaDespesa`, item);
                                                        }
                                                    }
                                                }, {
                                                    type: 'MonetaryInput',
                                                    scope: '#/properties/valor',
                                                    label: 'Valor',
                                                    options: {
                                                        isPositiveNumber: true
                                                    }
                                                }, {
                                                    type: 'Select',
                                                    scope: '#/properties/edicaoProgramaId',
                                                    label: 'Edição',
                                                    options: {
                                                        filter: {
                                                            formFields: ['programaId'],
                                                            handler: (formData, listData, api) => {
                                                                if (!formData.programaId)
                                                                    return Promise.resolve([]);
                                                                return api.getAll({
                                                                    programaId: formData === null || formData === void 0 ? void 0 : formData.programaId
                                                                });
                                                            }
                                                        },
                                                        handleChange: (item, handler, formData, ctx, path) => {
                                                            var _a;
                                                            const dataPath = (_a = path === null || path === void 0 ? void 0 : path.split) === null || _a === void 0 ? void 0 : _a.call(path, '.').slice(0, -1).join('.');
                                                            handler(`${dataPath}.edicaoPrograma`, item);
                                                        }
                                                    }
                                                }]
                                        }
                                    }
                                }]
                        }]
                }, {
                    type: 'Category',
                    label: 'Rubricas',
                    options: {
                        stepHandler: {
                            handler: stepHandlerEditalRubrica,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'ArrayTableLayout',
                            scope: '#/properties/editalRubrica',
                            label: '',
                            options: {
                                headCells: [{
                                        label: 'Tipo da Rubrica',
                                        field: row => tipoEditalRubricaI18N[camelCase(row.tipoEditalRubrica)]
                                    }, {
                                        label: 'Natureza da Despesa',
                                        field: row => { var _a; return (_a = row.naturezaDespesa) === null || _a === void 0 ? void 0 : _a.nome; }
                                    }, {
                                        label: 'Moeda Estrangeira',
                                        field: row => { var _a, _b; return (_b = (_a = row.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.map(x => x.simbolo)) === null || _b === void 0 ? void 0 : _b.join(', '); }
                                    }, {
                                        label: 'Justificativa Global',
                                        field: row => row.temJustificativaGlobal ? 'Sim' : 'Não'
                                    }, {
                                        label: 'Justificativa Obrigatória',
                                        field: row => row.temJustificativaObrigatoria ? 'Sim' : 'Não'
                                    }],
                                elementLabelProp: 'tipoEditalRubrica',
                                detail: {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Select',
                                                    scope: '#/properties/tipoEditalRubrica',
                                                    label: 'Rubrica',
                                                    options: {
                                                        required: true,
                                                        handleChange: (values, handler, formData, ctx, path) => __awaiter(void 0, void 0, void 0, function* () {
                                                            handler(`${path}`, values);
                                                            if (values === 'BOLSA') {
                                                                handler('editalRubricaUnsaved.moedaEstrangeira', undefined);
                                                                handler('editalRubricaUnsaved.temMoedaEstrangeira', false);
                                                            }
                                                        }),
                                                        filter: {
                                                            handler: (formData, listData) => {
                                                                if (!Array.isArray(listData)) {
                                                                    return Promise.resolve([]);
                                                                }
                                                                const editalRubrica = (formData === null || formData === void 0 ? void 0 : formData.editalRubrica) || [];
                                                                const filteredOptions = listData.filter(option => !editalRubrica.find(x => x.tipoEditalRubrica === option.value));
                                                                return Promise.resolve(filteredOptions);
                                                            }
                                                        }
                                                    }
                                                }, {
                                                    type: 'Select',
                                                    scope: '#/properties/naturezaDespesaId',
                                                    label: 'Natureza Despesa',
                                                    options: {
                                                        required: true,
                                                        handleChange: (item, handler, formData, ctx, path) => {
                                                            handler(`${path}`.replace('naturezaDespesaId', 'naturezaDespesa'), item);
                                                        }
                                                    }
                                                }]
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/temJustificativaObrigatoria',
                                                    label: 'Justificativa Obrigatória',
                                                    rule: {
                                                        effect: RuleEffect.DISABLE,
                                                        condition: {
                                                            scope: '#/properties/temJustificativaGlobal',
                                                            schema: {
                                                                const: true
                                                            }
                                                        }
                                                    }
                                                }, {
                                                    type: 'Control',
                                                    scope: '#/properties/temJustificativaGlobal',
                                                    label: 'Justificativa Global',
                                                    rule: {
                                                        effect: RuleEffect.DISABLE,
                                                        condition: {
                                                            scope: '#/properties/temJustificativaObrigatoria',
                                                            schema: {
                                                                const: true
                                                            }
                                                        }
                                                    }
                                                }]
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/temMoedaEstrangeira',
                                                    label: 'Moeda estrangeira',
                                                    rule: hideIfRubricaBolsa
                                                }, {
                                                    type: 'Select',
                                                    scope: '#/properties/moedaEstrangeira',
                                                    label: 'Moeda estrangeira',
                                                    rule: hideIfNotMoedaEstrangeira,
                                                    options: {
                                                        selectMultiple: true,
                                                        route: 'moeda-estrangeira'
                                                    }
                                                }]
                                        }]
                                }
                            }
                        }]
                }, {
                    type: 'Category',
                    label: 'Faixas de Financiamento',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'ArrayTableLayout',
                            scope: '#/properties/faixaFinanciamento',
                            label: '',
                            options: {
                                beforeCreate: (formData, path) => {
                                    const unsaved = formData['faixaFinanciamentoUnsaved'];
                                    const { valorMinimo, valorMaximo } = unsaved;
                                    if (valorMaximo < valorMinimo) {
                                        return {
                                            shouldCreate: false,
                                            errorMessage: 'O valor máximo deve ser maior que o valor mínimo.'
                                        };
                                    }
                                    return {
                                        shouldCreate: true
                                    };
                                },
                                defaultValue: data => {
                                    const faixaFinanciamento = last(data.faixaFinanciamento);
                                    if (!faixaFinanciamento || typeof faixaFinanciamento !== 'object' || !('valorMaximo' in faixaFinanciamento) || typeof faixaFinanciamento.valorMaximo !== 'number')
                                        return {};
                                    return {
                                        valorMinimo: faixaFinanciamento.valorMaximo + 1
                                    };
                                },
                                elementLabelProp: 'nome',
                                headCells: [{
                                        label: 'Nome da Faixa',
                                        field: row => {
                                            return row.nome;
                                        }
                                    }, {
                                        label: 'Valor Mínimo',
                                        field: row => {
                                            return row.valorMinimo ? formatCurrency(row.valorMinimo) : formatCurrency(0);
                                        }
                                    }, {
                                        label: 'Valor Máximo',
                                        field: row => {
                                            return row.valorMaximo ? formatCurrency(row.valorMaximo) : formatCurrency(0);
                                        }
                                    }, {
                                        label: 'Observação',
                                        field: row => {
                                            const observacao = row.observacao;
                                            return (observacao === null || observacao === void 0 ? void 0 : observacao.length) > 32 ? `${observacao.substring(0, 32)}...` : observacao;
                                        }
                                    }],
                                detail: {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/nome',
                                                    label: 'Nome da Faixa'
                                                }]
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'MonetaryInput',
                                                    scope: '#/properties/valorMinimo',
                                                    label: 'Valor Mínimo'
                                                }, {
                                                    type: 'MonetaryInput',
                                                    scope: '#/properties/valorMaximo',
                                                    label: 'Valor Máximo'
                                                }]
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/observacao',
                                                    label: 'Observação',
                                                    options: {
                                                        required: false
                                                    }
                                                }]
                                        }]
                                }
                            }
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Documentos',
            elements: [{
                    type: 'Category',
                    label: 'Documentos da Proposta',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/documentoPropostaEdital',
                            label: '',
                            options: {
                                elementLabelProp: 'nome',
                                detail: {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/nome',
                                                    label: 'Nome do Documento da Proposta'
                                                }, {
                                                    type: 'Control',
                                                    scope: '#/properties/descricao',
                                                    label: 'Descrição do Documento da Proposta'
                                                }]
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Select',
                                                    scope: '#/properties/formatoArquivo',
                                                    label: 'Formato do Arquivo'
                                                }, {
                                                    type: 'Number',
                                                    scope: '#/properties/tamanhoArquivo',
                                                    label: 'Tamanho Máximo do Arquivo',
                                                    options: {
                                                        required: true
                                                    }
                                                }]
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/arquivoSubmissaoObrigatoria',
                                                    label: 'Arquivo de submissão obrigatória'
                                                }, {
                                                    type: 'Boolean',
                                                    scope: '#/properties/permiteSubmeterMultiplosArquivos',
                                                    label: 'Permitir upload de mais de um anexo'
                                                }]
                                        }]
                                }
                            }
                        }]
                }, {
                    type: 'Category',
                    label: 'Documentos Pessoais',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/documentoPessoalEdital',
                            label: '',
                            options: {
                                itemLayout: 'SimpleItemLayout',
                                detail: {
                                    type: 'HorizontalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/documentoPessoalId',
                                            label: 'Nome do Documento',
                                            options: {
                                                filter: {
                                                    handler: (_ref, listData, api, queryFilterValues, path) => {
                                                        var _a, _b, _c;
                                                        let { documentoPessoalEdital } = _ref;
                                                        const index = Number((_c = (_b = (_a = path === null || path === void 0 ? void 0 : path.split) === null || _a === void 0 ? void 0 : _a.call(path, '.')) === null || _b === void 0 ? void 0 : _b.at) === null || _c === void 0 ? void 0 : _c.call(_b, -2));
                                                        if (Number.isNaN(index))
                                                            return Promise.resolve([]);
                                                        return api.getAll([]).then(response => {
                                                            return response === null || response === void 0 ? void 0 : response.filter(x => {
                                                                var _a;
                                                                if (!documentoPessoalEdital)
                                                                    return true;
                                                                return (_a = documentoPessoalEdital === null || documentoPessoalEdital === void 0 ? void 0 : documentoPessoalEdital.filter((_, i) => i !== index)) === null || _a === void 0 ? void 0 : _a.every(y => y.documentoPessoalId !== x.id);
                                                            });
                                                        });
                                                    }
                                                }
                                            }
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/obrigatorio',
                                            label: 'Arquivo de submissão obrigatório'
                                        }]
                                }
                            }
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Perguntas',
            elements: [{
                    type: 'Category',
                    label: 'Descrição do Projeto',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Select',
                                    scope: '#/properties/perguntaDescId',
                                    label: 'Pergunta(s) de Descrição do Projeto',
                                    options: {
                                        route: 'pergunta',
                                        addEmptyOption: true,
                                        handleChange: (values, handler) => {
                                            handler('perguntaDesc', values);
                                            handler('perguntaDescId', values);
                                        },
                                        filter: {
                                            handler: (data, listData, api, queryFilterValues, path) => {
                                                return api.getAll({
                                                    perguntaBase: true
                                                }).then(response => {
                                                    return response.filter(x => x.tipoPergunta === TipoPergunta.descricaoProjeto);
                                                });
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/pergunta',
                                    label: 'Descrição do Projeto',
                                    options: {
                                        detail: perguntaUiSchema({
                                            withoutTipoPergunta: true
                                        }),
                                        elementLabelProp: 'pergunta',
                                        defaultValue: formData => {
                                            var _a;
                                            return (_a = formData.perguntaDesc) !== null && _a !== void 0 ? _a : {
                                                tipoPergunta: TipoPergunta.descricaoProjeto,
                                                descritiva: {
                                                    minimo: 0,
                                                    maximo: 0
                                                },
                                                perguntaBase: false,
                                                obrigatoria: false
                                            };
                                        },
                                        showSortButtons: true,
                                        handleChange: (value, handler, formData) => __awaiter(void 0, void 0, void 0, function* () {
                                            var _a, _b;
                                            const allHavePosition = (_a = formData.pergunta) === null || _a === void 0 ? void 0 : _a.every(item => item.posicao !== undefined);
                                            if (!allHavePosition) {
                                                const updatedPergunta = allHavePosition ? formData.pergunta : (_b = formData.pergunta) === null || _b === void 0 ? void 0 : _b.map((item, index) => (Object.assign(Object.assign({}, item), { posicao: index + 1 })));
                                                handler('pergunta', updatedPergunta);
                                            }
                                        })
                                    }
                                }]
                        }]
                }, {
                    type: 'Category',
                    label: 'Indicadores de Produção',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            url: '/edital'
                        }
                    },
                    elements: [{
                            type: 'ArrayTableLayout',
                            scope: '#/properties/indicadorProducao',
                            label: '',
                            options: {
                                headCells: [{
                                        label: 'Indicador de Produção',
                                        field: row => {
                                            return row.nome || '-';
                                        }
                                    }],
                                elementLabelProp: 'indicadorProducao',
                                detail: {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/indicadorProducaoId',
                                            label: 'Indicador de Produção',
                                            options: {
                                                route: 'indicador-producao',
                                                filter: {
                                                    handler(formData, listData, api, queryFilterValues, path) {
                                                        var _a;
                                                        return __awaiter(this, void 0, void 0, function* () {
                                                            const indicadorProducaoSelecionados = ((_a = formData === null || formData === void 0 ? void 0 : formData.indicadorProducao) === null || _a === void 0 ? void 0 : _a.map(item => item.indicadorProducaoId)) || [];
                                                            const response = api.getAll();
                                                            const unsaved = formData['indicadorProducaoUnsaved'];
                                                            const editing = unsaved === null || unsaved === void 0 ? void 0 : unsaved.indicadorProducaoId;
                                                            const filtrados = (yield response).filter(item => {
                                                                return !indicadorProducaoSelecionados.includes(item.id) || item.id === editing;
                                                            });
                                                            return filtrados;
                                                        });
                                                    }
                                                },
                                                handleChange: (item, handler, formData, ctx, path) => {
                                                    var _a;
                                                    const dataPath = (_a = path === null || path === void 0 ? void 0 : path.split) === null || _a === void 0 ? void 0 : _a.call(path, '.').slice(0, -1).join('.');
                                                    handler(`${dataPath}`, item);
                                                }
                                            }
                                        }]
                                }
                            }
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Bolsas do Edital',
            elements: [{
                    type: 'Category',
                    label: 'Bolsas',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: ctx => {
                                const { clearForm, showSuccess, updateCrudStates } = ctx;
                                createResource(ctx, '/edital');
                                clearForm();
                                updateCrudStates(CrudStatesOptions.LIST);
                                showSuccess('Editado com sucesso.');
                                return true;
                            }
                        }
                    },
                    elements: [{
                            type: 'ArrayTableLayout',
                            scope: '#/properties/bolsaEdital',
                            label: '',
                            options: {
                                headCells: [{
                                        label: 'Modalidade da Bolsa',
                                        field: row => {
                                            var _a, _b;
                                            return (_b = (_a = row.nivelBolsa) === null || _a === void 0 ? void 0 : _a.modalidadeBolsa) === null || _b === void 0 ? void 0 : _b.nome;
                                        }
                                    }, {
                                        label: 'Nível da Bolsa',
                                        field: row => { var _a; return (_a = row.nivelBolsa) === null || _a === void 0 ? void 0 : _a.nivel; }
                                    }, {
                                        label: 'Quantidade por Proposta',
                                        field: row => { var _a, _b; return (_b = (_a = row.quantidadeBolsaPorProposta) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : 'Não definido'; }
                                    }],
                                elementLabelProp: 'modalidadeBolsa',
                                detail: {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/modalidadeBolsaId',
                                            label: 'Modalidade da Bolsa'
                                        }, {
                                            type: 'Select',
                                            scope: '#/properties/nivelBolsaId',
                                            label: 'Nível da Bolsa',
                                            options: {
                                                formatOptionLabel: formatLabel,
                                                value: (formData, path) => {
                                                    var _a, _b, _c, _d, _e;
                                                    const unsaved = formData['bolsaEditalUnsaved'];
                                                    if (unsaved && unsaved.editing) {
                                                        return unsaved.nivelBolsaId;
                                                    }
                                                    const rowIndex = Number((_c = (_b = (_a = path === null || path === void 0 ? void 0 : path.split) === null || _a === void 0 ? void 0 : _a.call(path, '.')) === null || _b === void 0 ? void 0 : _b.at) === null || _c === void 0 ? void 0 : _c.call(_b, -2));
                                                    return ((_e = (_d = formData.bolsaEdital) === null || _d === void 0 ? void 0 : _d[rowIndex]) === null || _e === void 0 ? void 0 : _e.nivelBolsaId) || '';
                                                },
                                                filter: {
                                                    formFields: ['modalidadeBolsaId'],
                                                    handler: (formData, listData, api, queryFilterValues, path) => {
                                                        var _a, _b, _c, _d, _e, _f;
                                                        const modalidadeId = (_a = queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0]) === null || _a === void 0 ? void 0 : _a.value;
                                                        if (!modalidadeId)
                                                            return Promise.resolve([]);
                                                        const rowIndex = Number((_d = (_c = (_b = path === null || path === void 0 ? void 0 : path.split) === null || _b === void 0 ? void 0 : _b.call(path, '.')) === null || _c === void 0 ? void 0 : _c.at) === null || _d === void 0 ? void 0 : _d.call(_c, -2));
                                                        const unsaved = formData['bolsaEditalUnsaved'];
                                                        let currentNivelBolsaId = (unsaved === null || unsaved === void 0 ? void 0 : unsaved.nivelBolsaId) || ((_f = (_e = formData.bolsaEdital) === null || _e === void 0 ? void 0 : _e[rowIndex]) === null || _f === void 0 ? void 0 : _f.nivelBolsaId);
                                                        return api.getAll({
                                                            modalidadeBolsaId: modalidadeId
                                                        }).then(response => {
                                                            return response.filter(nivel => {
                                                                var _a;
                                                                if (currentNivelBolsaId && nivel.id === currentNivelBolsaId) {
                                                                    return true;
                                                                }
                                                                const used = (_a = formData.bolsaEdital) === null || _a === void 0 ? void 0 : _a.some((bolsa, idx) => {
                                                                    return idx !== rowIndex && bolsa.nivelBolsaId === nivel.id && bolsa.modalidadeBolsaId === nivel.modalidadeBolsaId;
                                                                });
                                                                return !used;
                                                            });
                                                        });
                                                    }
                                                },
                                                handleChange: (item, handler, formData, ctx, path) => {
                                                    if (item && item.id) {
                                                        handler(`${path}`.replace('nivelBolsaId', 'nivelBolsa'), item);
                                                        handler(`${path}`, item.id);
                                                    }
                                                }
                                            }
                                        }, {
                                            type: 'HorizontalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/possuiQuantidadeBolsaPorProposta',
                                                    label: 'Possui quantidade de bolsa por proposta',
                                                    options: {
                                                        handleChange: (value, handler, formData, ctx, path) => __awaiter(void 0, void 0, void 0, function* () {
                                                            if (!value) {
                                                                handler(`${path}`.replace('possuiQuantidadeBolsaPorProposta', 'quantidadeBolsaPorProposta'), null);
                                                            }
                                                            handler(path, value);
                                                        })
                                                    }
                                                }, {
                                                    type: 'Control',
                                                    scope: '#/properties/quantidadeBolsaPorProposta',
                                                    label: 'Quantidade de bolsa por proposta',
                                                    rule: showIfHasQuantityOfScholarshipPerProposal,
                                                    options: {
                                                        isPositiveNumber: true
                                                    }
                                                }]
                                        }]
                                }
                            }
                        }]
                }]
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        route: '/edital',
        finishButtonTitle: 'Finalizar',
        onFinish: (_ref2) => __awaiter(void 0, void 0, void 0, function* () {
            let { navigate, ctx } = _ref2;
            const { clearForm, showSuccess, updateCrudStates } = ctx;
            const result = yield createResource(ctx, '/edital');
            if (result) {
                clearForm();
                updateCrudStates(CrudStatesOptions.LIST);
                navigate('/edital');
                showSuccess('Editado com sucesso.');
            }
        })
    }
};
export default editalUiSchema;
