var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from '../config/axios-config';
import { sortRelationBy } from '../helpers/sort-relation-by';
export class GenericApi {
    constructor(url, apiOptions) {
        this.url = url;
        this.apiOptions = apiOptions;
    }
    getSchema() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.get(`${this.url}/schema`);
                return response.data;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('GetSchema error: ', {
                    cause: error
                });
            }
        });
    }
    get(id) {
        var _a, _b, _c;
        return __awaiter(this, arguments, void 0, function* () {
            let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            try {
                const response = yield client.get(`${this.url}/${id}`);
                return sortRelationBy(response.data, (_b = (_a = this.apiOptions) === null || _a === void 0 ? void 0 : _a.orderRelationBy) !== null && _b !== void 0 ? _b : {});
            }
            catch (error) {
                if (((_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Get error: ', {
                    cause: error
                });
            }
        });
    }
    getAll() {
        return __awaiter(this, arguments, void 0, function* () {
            let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
                withDeleted: false
            };
            return (yield this.getAllForPagination(options)).data;
        });
    }
    getAllForPagination() {
        var _a, _b, _c, _d;
        return __awaiter(this, arguments, void 0, function* () {
            let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
                withDeleted: false
            };
            try {
                const urlBuild = Object.entries(options).reduce((acc, _ref, i) => {
                    var _a;
                    let [chave, valor] = _ref;
                    return `${acc}${i === 0 ? '?' : '&'}${chave}=${(_a = String(valor)) !== null && _a !== void 0 ? _a : ''}`;
                }, `${this.url}${(_c = (_b = (_a = this.apiOptions) === null || _a === void 0 ? void 0 : _a.customPaths) === null || _b === void 0 ? void 0 : _b['getAll']) !== null && _c !== void 0 ? _c : ''}`);
                const response = yield client.get(urlBuild);
                return response === null || response === void 0 ? void 0 : response.data;
            }
            catch (error) {
                if (((_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('GetAll error: ', {
                    cause: error
                });
            }
        });
    }
    restore(id) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.put(`${this.url}/restore/${id}`);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Restore error: ', {
                    cause: error
                });
            }
        });
    }
    put(id, data) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.put(`${this.url}/${id}${(_c = (_b = (_a = this.apiOptions) === null || _a === void 0 ? void 0 : _a.customPaths) === null || _b === void 0 ? void 0 : _b['put']) !== null && _c !== void 0 ? _c : ''}`, data);
                return response;
            }
            catch (error) {
                if (((_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Put error: ', {
                    cause: error
                });
            }
        });
    }
    post(data) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.post(`${this.url}`, data);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Post error: ', {
                    cause: error
                });
            }
        });
    }
    delete(id) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.delete(`${this.url}/${id}`);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Delete error: ', {
                    cause: error
                });
            }
        });
    }
}
export const makeApi = (url, apiOptions) => {
    return new GenericApi(url, apiOptions);
};
