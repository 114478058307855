import { truncateText } from '../../utils/truncate-text';
export const moduleName = 'INSTITUICAO.INSTITUICAO';
export const displayProperties = ['nome'];
export const moduleLabel = 'Instituição';
export const headCells = [{
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
        textFormat: truncateText(64)
    }, {
        id: 'sigla',
        numeric: false,
        disablePadding: false,
        label: 'Sigla'
    }, {
        id: 'paisId',
        numeric: false,
        disablePadding: false,
        label: 'País',
        resource: {
            name: 'pais',
            target: 'nomePt'
        }
    }];
