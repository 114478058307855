var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeApi } from '../../../../api/generic-api';
export const stepHandlerJustificativa = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    const { formData, showError } = ctx;
    const api = makeApi('/reconsideracao');
    const justificativa = formData === null || formData === void 0 ? void 0 : formData.justificativa;
    if (!justificativa) {
        showError('Informe uma justificativa.');
        return false;
    }
    try {
        const putResponse = yield ((_a = api === null || api === void 0 ? void 0 : api.put) === null || _a === void 0 ? void 0 : _a.call(api, formData === null || formData === void 0 ? void 0 : formData.id, {
            justificativa: justificativa
        }));
        if (putResponse.status === 200) {
            return true;
        }
        else {
            console.error(putResponse);
            return false;
        }
    }
    catch (error) {
        let errorMessage = ((_d = (_c = (_b = error === null || error === void 0 ? void 0 : error.cause) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) || ((_f = (_e = error === null || error === void 0 ? void 0 : error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.message);
        showError(errorMessage || 'Ocorreu um erro.');
        console.error(errorMessage);
        return false;
    }
});
