const historicoAjusteValorBolsaUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'MonetaryInput',
            scope: '#/properties/valorAjuste',
            label: 'Valor',
            options: {
                required: true
            }
        }]
};
export default historicoAjusteValorBolsaUiSchema;
