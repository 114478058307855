import { RuleEffect } from '@jsonforms/core';
const edicaoLinhaDeAcaoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Date',
            scope: '#/properties/ano',
            label: 'Ano',
            options: {
                required: true,
                dateDefault: 'now',
                dateFormat: 'yyyy'
            }
        }, {
            type: 'Control',
            scope: '#/properties/valorTotal',
            rule: {
                effect: RuleEffect.HIDE,
                condition: {}
            }
        }]
};
export default edicaoLinhaDeAcaoUiSchema;
