import { RuleEffect } from '@jsonforms/core';
export const hideIfBrasil = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        effect: RuleEffect.SHOW,
        condition: {
            scope: `#${scopePrefix}/properties/pais/properties/codigo`,
            schema: {
                not: {
                    const: 'BRA'
                }
            }
        }
    };
};
