var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TipoRespostaPerguntaAvaliacao } from '../../../models/pergunta-avaliacao';
import { makeApi } from '../../../api/generic-api';
import { makeAvaliacaoConsultor } from './consultoria-api';
import { makeAvaliacaoEnquadramento } from '../../avaliacao/avaliacao-proposta/avaliacao-proposta-api';
import { parseMathTree } from '../../../helpers/math-tree/parser';
export const configurarEnquadramento = (_ref) => __awaiter(void 0, void 0, void 0, function* () {
    let { formularioEnquadramentoId, propostaId, avaliacaoId, setTitlePage, setFormulario, setHasCategories, setCategories, setPerguntasMap, setPerguntas } = _ref;
    setTitlePage('Avaliação de Enquadramento');
    const formularioEnquadramentoApi = makeApi('formulario-enquadramento');
    const avaliacaoEnquadramentoApi = makeAvaliacaoEnquadramento(formularioEnquadramentoId, propostaId);
    try {
        const formulario = yield formularioEnquadramentoApi.get(formularioEnquadramentoId);
        const avaliacao = yield avaliacaoEnquadramentoApi.get(avaliacaoId);
        processarFormularioEAvaliacao({
            formulario,
            avaliacao,
            setFormulario,
            setHasCategories,
            setCategories,
            setPerguntasMap,
            setPerguntas
        });
    }
    catch (error) {
        console.error('Erro ao buscar dados de enquadramento:', error);
    }
});
export const configurarConsultoria = (_ref2) => __awaiter(void 0, void 0, void 0, function* () {
    let { conviteId, avaliacaoId, setTitlePage, setFormulario, setHasCategories, setCategories, setPerguntasMap, setPerguntas } = _ref2;
    setTitlePage('Avaliação de Consultoria');
    const avaliacaoConsultoriaApi = makeAvaliacaoConsultor(conviteId);
    try {
        const [formulario, avaliacao] = yield Promise.all([avaliacaoConsultoriaApi.getFormularioConsultoria(conviteId), avaliacaoConsultoriaApi.get(avaliacaoId)]);
        processarFormularioEAvaliacao({
            formulario,
            avaliacao,
            setFormulario,
            setHasCategories,
            setCategories,
            setPerguntasMap,
            setPerguntas
        });
    }
    catch (error) {
        console.error('Erro ao buscar dados de consultoria:', error);
    }
});
const processarFormularioEAvaliacao = _ref3 => {
    let { formulario, avaliacao, setFormulario, setHasCategories, setCategories, setPerguntasMap, setPerguntas } = _ref3;
    setFormulario(formulario);
    const hasCategories = formulario.perguntasCategorizadas;
    const respostasObj = avaliacao.descricaoRespostaObj;
    setHasCategories(hasCategories);
    if (hasCategories) {
        processarCategorizados({
            formulario,
            respostasObj,
            setCategories,
            setPerguntasMap
        });
    }
    else {
        processarNaoCategorizados({
            formulario,
            respostasObj,
            setPerguntas,
            setPerguntasMap
        });
    }
};
const processarCategorizados = _ref4 => {
    let { formulario, respostasObj, setCategories, setPerguntasMap } = _ref4;
    const categoriesData = formulario.formularioEnquadramentoCategorizado || formulario.formularioConsultoriaCategorizado;
    if (categoriesData && categoriesData.length > 0) {
        const categorias = categoriesData.map(categoria => {
            return {
                id: categoria.id,
                tituloCategoria: categoria.tituloCategoria,
                perguntas: (categoria.formularioEnquadramentoCategorizadoPerguntaAvaliacao || categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao || []).map(pergunta => {
                    var _a;
                    const resposta = (_a = respostasObj[`form-${categoria.id}`]) === null || _a === void 0 ? void 0 : _a[`pergunta-${pergunta.id}`];
                    return Object.assign(Object.assign({}, pergunta), { resposta });
                })
            };
        });
        setCategories(categorias);
        construirPerguntasMap(categorias, setPerguntasMap);
    }
};
const construirPerguntasMap = (categorias, setPerguntasMap) => {
    const perguntasMap = {};
    categorias.forEach((categoria, catIndex) => {
        categoria.perguntas.forEach((pergunta, pergIndex) => {
            const key = `P${catIndex + 1}.${pergIndex + 1}`;
            perguntasMap[key] = pergunta;
        });
    });
    setPerguntasMap(perguntasMap);
};
const processarNaoCategorizados = _ref5 => {
    let { formulario, respostasObj, setPerguntas, setPerguntasMap } = _ref5;
    const perguntasFormulario = formulario.formularioEnquadramentoPergunta || formulario.formularioConsultoriaPergunta;
    if (perguntasFormulario && perguntasFormulario.length > 0) {
        const perguntasMap = {};
        const perguntas = perguntasFormulario.map(pergunta => {
            var _a;
            const resposta = (_a = respostasObj['form']) === null || _a === void 0 ? void 0 : _a[`pergunta-${pergunta.id}`];
            return Object.assign(Object.assign({}, pergunta), { resposta });
        });
        setPerguntas(perguntas);
        perguntas.forEach((pergunta, index) => {
            const key = `P${index + 1}`;
            perguntasMap[key] = pergunta;
        });
        setPerguntasMap(perguntasMap);
    }
};
export const calcularNotaFinal = (notas, formula) => {
    if (!notas || !formula)
        return 0;
    try {
        const formulaStr = formula.replace(/\s+/g, '');
        const mathTree = parseMathTree(formulaStr);
        return mathTree.solve(notas);
    }
    catch (e) {
        console.error('Erro ao calcular nota final', e);
        return 0;
    }
};
export const convertToLatex = input => {
    if (!input)
        return '';
    return input.replace(/\(([^)]+)\) \/ (\d+)/g, '\\frac{$1}{$2}').replace(/\*/g, ' \\times ').replace(/sqrt\[(\d+)\]\{([^}]+)\}/g, '\\sqrt[$1]{$2}').replace(/sqrt\{([^}]+)\}/g, '\\sqrt{$1}').replace(/(\S+) \/ (\S+)/g, '\\frac{$1}{$2}');
};
export const extrairPerguntasNota = perguntasMap => {
    return Object.fromEntries(Object.entries(perguntasMap).filter(_ref6 => {
        let [_, value] = _ref6;
        return value.tipoResposta === TipoRespostaPerguntaAvaliacao.nota;
    }).map(_ref7 => {
        let [key, value] = _ref7;
        return [key, value.resposta];
    }));
};
