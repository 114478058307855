var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from '../config/axios-config';
import { sortRelationBy } from '../helpers/sort-relation-by';
import { GenericApi } from './generic-api';
export class EditalContratacaoApi extends GenericApi {
    constructor(url, apiOptions) {
        super(url, apiOptions);
        this.url = url;
        this.apiOptions = apiOptions;
    }
    findByEditalId(id) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.get(`${this.url}/edital/${id}`);
                return sortRelationBy(response.data, (_b = (_a = this.apiOptions) === null || _a === void 0 ? void 0 : _a.orderRelationBy) !== null && _b !== void 0 ? _b : {});
            }
            catch (error) {
                if (((_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Get error: ', {
                    cause: error
                });
            }
        });
    }
}
export const makeEditalContratacaoApi = apiOptions => {
    return new EditalContratacaoApi('/edital-contratacao', apiOptions);
};
