import { tipoTermoAditivoI18N } from '../../../models/tipo-termo-aditivo';
export const moduleName = 'ORCAMENTO.INSTRUMENTO_JURIDICO.TERMO_ADITIVO_INSTRUMENTO_JURIDICO.TERMO_ADITIVO_INSTRUMENTO_JURIDICO';
export const moduleLabel = 'Termo Aditivo do Instrumento Jurídico';
export const headCells = [{
        id: 'codigo',
        numeric: false,
        disablePadding: false,
        label: 'Código'
    }, {
        id: 'tipoTermoAditivo',
        numeric: false,
        disablePadding: false,
        label: 'Tipo de Termo Aditivo',
        resource: {
            target: row => {
                return tipoTermoAditivoI18N[row.tipoTermoAditivo];
            }
        }
    }, {
        id: 'dataPublicacao',
        numeric: false,
        disablePadding: false,
        label: 'Data de Publicação',
        dateFormat: 'DD/MM/YYYY'
    }, {
        id: 'dataAssinatura',
        numeric: false,
        disablePadding: false,
        label: 'Data de Assinatura',
        dateFormat: 'DD/MM/YYYY'
    }];
