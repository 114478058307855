import dayjs from 'dayjs';
import { tipoSituacaoI18N } from '../../../models/proposta';
export const moduleName = 'PROPOSTA.AVALIACAO_CONSULTOR.AVALIACAO_CONSULTOR';
export const moduleLabel = 'Resultado Consultoria';
export const headCells = [{
        id: 'formularioConsultoria',
        numeric: false,
        disablePadding: false,
        label: 'Formulário',
        computedRowLabel: row => { var _a; return (_a = row === null || row === void 0 ? void 0 : row.formularioConsultoria) === null || _a === void 0 ? void 0 : _a.titulo; }
    }, {
        id: 'usuario',
        numeric: false,
        disablePadding: false,
        label: 'Consultor',
        computedRowLabel: row => { var _a; return (_a = row === null || row === void 0 ? void 0 : row.usuario) === null || _a === void 0 ? void 0 : _a.nome; }
    }, {
        id: 'situacao',
        numeric: false,
        disablePadding: false,
        label: 'Convite',
        computedRowLabel: row => {
            const situacao = row === null || row === void 0 ? void 0 : row.situacao;
            return tipoSituacaoI18N[situacao];
        }
    }, {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Respondida em',
        computedRowLabel: row => (row === null || row === void 0 ? void 0 : row.avaliacaoConsultor) ? dayjs.utc(row === null || row === void 0 ? void 0 : row.avaliacaoConsultor.createdAt).format('DD/MM/YYYY') : '-'
    }];
