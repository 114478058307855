export const atividadesUiSchema = () => [{
        type: 'VerticalLayout',
        elements: [{
                type: 'Control',
                scope: '#/properties/propostaAtividade',
                label: '',
                options: {
                    itemLayout: 'SimpleItemLayout',
                    detail: {
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Control',
                                scope: '#/properties/titulo',
                                label: 'Título'
                            }, {
                                type: 'HorizontalLayout',
                                elements: [{
                                        type: 'Select',
                                        scope: '#/properties/mesInicio',
                                        label: 'Mês de Início',
                                        options: {
                                            oneOf: formData => {
                                                return Array.from({
                                                    length: formData === null || formData === void 0 ? void 0 : formData.duracao
                                                }, (_, i) => ({
                                                    title: `${i + 1}°`,
                                                    const: i + 1
                                                }));
                                            }
                                        }
                                    }, {
                                        type: 'Select',
                                        scope: '#/properties/duracao',
                                        label: 'Duração (Em meses)',
                                        options: {
                                            formFields: (formData, path) => {
                                                var _a, _b;
                                                return [(_b = (_a = formData === null || formData === void 0 ? void 0 : formData.propostaAtividade) === null || _a === void 0 ? void 0 : _a.at(path.split('.').at(-2))) === null || _b === void 0 ? void 0 : _b.mesInicio];
                                            },
                                            oneOf: (formData, path) => {
                                                var _a, _b;
                                                const { duracao } = formData;
                                                const mesInicio = (_b = (_a = formData === null || formData === void 0 ? void 0 : formData.propostaAtividade) === null || _a === void 0 ? void 0 : _a.at(parseInt(path.split('.').at(-2)))) === null || _b === void 0 ? void 0 : _b.mesInicio;
                                                if (!duracao || !mesInicio)
                                                    return [];
                                                const mesesRestantes = duracao - mesInicio + 1;
                                                return Array.from({
                                                    length: mesesRestantes
                                                }, (_, i) => {
                                                    const quantidadeMeses = i + 1;
                                                    return {
                                                        title: `${quantidadeMeses} ${quantidadeMeses > 1 ? 'meses' : 'mês'}`,
                                                        const: quantidadeMeses
                                                    };
                                                });
                                            }
                                        }
                                    }, {
                                        type: 'Select',
                                        scope: '#/properties/cargaHorariaSemanal',
                                        label: 'Carga Horária Semanal',
                                        options: {
                                            oneOf: () => {
                                                return Array.from({
                                                    length: 60
                                                }, (_, i) => {
                                                    const horas = i + 1;
                                                    return {
                                                        title: `${horas} ${horas > 1 ? 'horas' : 'hora'}`,
                                                        const: horas
                                                    };
                                                });
                                            }
                                        }
                                    }]
                            }, {
                                type: 'CustomSelectMembro',
                                scope: '#/properties/membroResponsavelId',
                                label: 'Responsável'
                            }, {
                                type: 'CustomMultipleSelectMembro',
                                scope: '#/properties/propostaAtividadeMembro'
                            }]
                    }
                }
            }]
    }];
