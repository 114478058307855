export const membrosUiSchema = () => [{
        type: 'VerticalLayout',
        elements: [{
                type: 'VerticalLayout',
                elements: [{
                        type: 'InviteMember',
                        scope: '#/properties/propostaConviteMembro'
                    }]
            }]
    }];
