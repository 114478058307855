const tipoEventoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/nome',
            label: 'Nome do Tipo de Evento',
            options: {
                required: true
            }
        }]
};
export default tipoEventoUiSchema;
