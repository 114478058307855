export const makeCustomRequiredIfBrasil = field => {
    var _a;
    return ({
        field,
        hasError: (value, formData) => (formData === null || formData === void 0 ? void 0 : formData['paisId-codigo']) === 'brasil' ? !value : false,
        error: {
            keyword: 'customRequired',
            message: 'Campo obrigatório',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            schemaPath: '#/required',
            params: {
                missingProperty: field
            }
        }
    });
};
export const makePositiveNumberIfBrasil = field => {
    var _a;
    return ({
        field,
        hasError: (value, formData) => (formData === null || formData === void 0 ? void 0 : formData['paisId-codigo']) === 'brasil' ? Number.isNaN(Number(value)) || Number(value) <= 0 : false,
        error: {
            keyword: 'positiveNumber',
            message: 'O campo deve ser um número positivo.',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            dataPath: `/${field}`,
            params: {}
        }
    });
};
