var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import first from 'lodash/first';
import client from '../../../config/axios-config';
export const atualizarEditarPerfilHandler = (_ref, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    let { paths } = _ref;
    const { showError, formData } = ctx;
    const newPaths = paths.map(path => first(path === null || path === void 0 ? void 0 : path.split('.'))).filter((path, index, arr) => arr.indexOf(path) === index);
    const currentData = Object.entries(formData).reduce((acc, _ref2) => {
        let [key, value] = _ref2;
        if (newPaths.includes(key))
            acc[key] = value;
        return acc;
    }, {});
    try {
        const response = yield client.put('/usuario/editar-perfil', currentData);
        if (response.status === 200) {
            if (callback)
                return callback(ctx);
            return true;
        }
        else {
            showError('Ocorreu um erro');
            return false;
        }
    }
    catch (error) {
        let message = error.response.data.message || 'Ocorreu um erro';
        if (Array.isArray(message))
            message = treatMessageErrorVinculoEmpregaticio(message);
        if (message.startsWith('endereco.'))
            message = message.replace('endereco.', '');
        if (message.startsWith('vinculoInstitucional.'))
            message = message.replace('vinculoInstitucional.', '');
        showError(message);
        return false;
    }
});
const treatMessageErrorVinculoEmpregaticio = messages => {
    if (messages.length === 1)
        return messages[0];
    if (messages.includes('vinculoInstitucional.Tipo de vínculo institucional é obrigatório.')) {
        return 'Tipo de vínculo institucional é obrigatório';
    }
    else if (messages.includes('vinculoInstitucional.Início de serviço é obrigatório')) {
        return 'Início de serviço é obrigatório';
    }
    else if (messages.includes('vinculoInstitucional.Regime de trabalho é obrigatório')) {
        return 'Regime de trabalho é obrigatório';
    }
    else if (messages.includes('vinculoInstitucional.Função é obrigatório')) {
        return 'Função é obrigatório';
    }
    else if (messages.includes('vinculoInstitucional.Início de função é obrigatório')) {
        return 'Início de função é obrigatório';
    }
    else {
        return messages[0];
    }
};
