import { Paperclip } from '../../../components/styled-icons';
import { StyledCircleExclamationIcon } from '../../../jsonforms/renderers/styled/categorization-menu';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { TipoReconsideracao } from '../../../models/reconsideracao';
import { stepHandlerAnexo } from './handlers/step-handler-anexo';
import { stepHandlerJustificativa } from './handlers/step-handler-justificativa';
import { handlerReprovar } from './utils/handler-reprovar';
const iconConfig = [{
        category: 'Recurso Administrativo de Não Enquadramento',
        icon: StyledCircleExclamationIcon
    }, {
        category: 'Recurso Administrativo de Não Aprovação',
        icon: StyledCircleExclamationIcon
    }, {
        category: 'Anexos',
        icon: Paperclip
    }];
export const reconsideracaoAvaliarUiSchema = type => {
    const title = type === TipoReconsideracao.naoEnquadramento ? 'Não Enquadramento' : 'Não Aprovação';
    return {
        type: 'Categorization',
        elements: [{
                type: 'Category',
                label: `Recurso Administrativo de ${title}`,
                options: {
                    stepHandler: {
                        handler: stepHandlerJustificativa
                    }
                },
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Label',
                                options: {
                                    formatOptionLabel: () => `Pedido de Recurso Administrativo de ${title}`
                                }
                            }, {
                                type: 'Control',
                                scope: '#/properties/reconsideracao',
                                label: 'Recurso Administrativo',
                                options: {
                                    disabled: true,
                                    multiline: true,
                                    numberOfRows: 5
                                }
                            }]
                    }, {
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Label',
                                options: {
                                    formatOptionLabel: () => `Avaliar Pedido de Recurso Administrativo de ${title}`
                                }
                            }, {
                                type: 'Control',
                                scope: '#/properties/justificativa',
                                label: 'Justificativa',
                                options: {
                                    multiline: true,
                                    numberOfRows: 5
                                }
                            }]
                    }]
            }, {
                type: 'Category',
                label: 'Anexos',
                options: {
                    stepHandler: {
                        handler: stepHandlerAnexo
                    },
                    nextButtonTitle: 'Aprovar',
                    finishButtonTitle: 'Aprovar',
                    customButtons: [{
                            label: 'Reprovar',
                            handler: handlerReprovar,
                            disabled: ctx => {
                                const { crudStates } = ctx;
                                return crudStates.view;
                            }
                        }],
                    onFinish: _ref => {
                        let { navigate, ctx } = _ref;
                        const { updateCrudStates, setFormData } = ctx;
                        setFormData({});
                        updateCrudStates(CrudStatesOptions.LIST);
                        navigate('/proposta');
                    }
                },
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'File',
                                scope: `#/properties/reconsideracaoAnexo`,
                                options: {
                                    route: '/reconsideracao',
                                    filePath: 'reconsideracao-anexo'
                                }
                            }]
                    }]
            }],
        options: {
            variant: 'stepper',
            showNavButtons: true,
            icons: iconConfig,
            route: '/reconsideracao',
            nextButtonTitle: 'Aprovar',
            finishButtonTitle: 'Aprovar'
        }
    };
};
