import { camelCase } from 'lodash';
import { formatFloat } from '../../../helpers/format-float';
import { TipoEditalRubrica, tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { buildTableLayoutHeader } from '../../../shared/proposta/table-layout-header';
import { contrapartidaHandleChange } from './helpers/contrapartidaHandleChange';
import { createShowRuleForEditalRubrica } from './helpers/createShowRuleForEditalRubrica';
import { getJustificativaStatus } from './helpers/getJustificativaStatus';
import { handleTemMoedaEstrangeiraChange } from './helpers/handleTemMoedaEstrangeiraChange';
import { moedaHandleChange } from './helpers/moedaHandleChange';
import { requiredIfContrapartida } from './helpers/requiredIfContrapartida';
import { requiredIfTemMoedaEstrangeira } from './helpers/requiredIfTemMoedaEstrangeira';
import { showIfContrapartida } from './helpers/showIfContrapartida';
import { showIfJustificativaGlobal } from './helpers/showIfJustificativaGlobal';
import { showIfTemMoedaEstrangeira } from './helpers/showIfTemMoedaEstrangeira';
import { visibleHandlerJustificativaGlobal } from './helpers/visibleHandlerJustificativaGlobal';
import { visibleHandlerJustificativaObrigatoria } from './helpers/visibleHandlerJustificativaObrigatoria';
export const getPessoalAnswerForm = function () {
    let { proposta, readonly = false, customHeader, customFooter } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/revisao',
                    label: '',
                    options: {
                        readonly: true,
                        maxLength: 256,
                        customHeader,
                        customFooter,
                        visibleHandler: ctx => {
                            var _a;
                            return (_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.orcamentoEdicao;
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/funcao',
                    label: 'Função',
                    options: {
                        readonly,
                        required: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/formacaoProfissional',
                    label: 'Formação Profissional',
                    options: {
                        readonly,
                        required: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/perfilDesejado',
                    label: 'Perfil Desejado',
                    options: {
                        readonly,
                        required: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/mesInicio',
                    label: 'Mês de Início',
                    options: {
                        readonly,
                        required: true,
                        oneOf: formData => {
                            const data = (formData === null || formData === void 0 ? void 0 : formData.duracao) ? formData : proposta;
                            return Array.from({
                                length: data.duracao
                            }, (_, i) => ({
                                title: `${i + 1}°`,
                                const: i + 1
                            }));
                        },
                        handleChange: (item, handler, formData, ctx, path) => {
                            handler(`${path}`.replace('mesInicio', 'duracao'), null);
                        }
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/duracao',
                    label: 'Duração (Em meses)',
                    options: {
                        readonly,
                        formFields: (formData, path) => {
                            var _a, _b;
                            const data = ((_a = formData === null || formData === void 0 ? void 0 : formData['rubricaPessoalUnsaved']) === null || _a === void 0 ? void 0 : _a.mesInicio) ? formData : proposta;
                            return [(_b = data === null || data === void 0 ? void 0 : data['rubricaPessoalUnsaved']) === null || _b === void 0 ? void 0 : _b.mesInicio];
                        },
                        oneOf: (formData, path) => {
                            var _a, _b;
                            const data = ((_a = formData === null || formData === void 0 ? void 0 : formData['rubricaPessoalUnsaved']) === null || _a === void 0 ? void 0 : _a.mesInicio) ? formData : proposta;
                            const mesInicio = (_b = data === null || data === void 0 ? void 0 : data['rubricaPessoalUnsaved']) === null || _b === void 0 ? void 0 : _b.mesInicio;
                            if (!(data === null || data === void 0 ? void 0 : data.duracao) || !mesInicio)
                                return [];
                            const mesesRestantes = (data === null || data === void 0 ? void 0 : data.duracao) - mesInicio + 1;
                            return Array.from({
                                length: mesesRestantes
                            }, (_, i) => {
                                const quantidadeMeses = i + 1;
                                return {
                                    title: `${quantidadeMeses} ${quantidadeMeses > 1 ? 'meses' : 'mês'}`,
                                    const: quantidadeMeses
                                };
                            });
                        }
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/cargaHorariaSemanal',
                    label: 'Carga Horária Semanal',
                    options: {
                        readonly,
                        required: true,
                        oneOf: () => {
                            return Array.from({
                                length: 60
                            }, (_, i) => {
                                const horas = i + 1;
                                return {
                                    title: `${horas} ${horas > 1 ? 'horas' : 'hora'}`,
                                    const: horas
                                };
                            });
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    scope: '#/properties/custoHoraCustoMes',
                    label: 'Custo Hora ou Custo Mês',
                    options: {
                        readonly,
                        required: true,
                        isPositiveNumber: true
                    }
                }, {
                    type: 'MonetaryInput',
                    scope: '#/properties/valorTotal',
                    label: 'Valor',
                    options: {
                        readonly,
                        required: true,
                        isPositiveNumber: true
                    }
                }, {
                    type: 'Boolean',
                    scope: '#/properties/contrapartida',
                    options: {
                        readonly,
                        defaultValue: false,
                        handleChange: contrapartidaHandleChange
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Select',
                            scope: '#/properties/tipoPessoa',
                            label: 'Tipo Pessoa',
                            rule: showIfContrapartida,
                            options: {
                                readonly,
                                required: requiredIfContrapartida('rubricaPessoalUnsaved')
                            }
                        }, {
                            type: 'Control',
                            scope: '#/properties/entidade',
                            label: 'Entidade',
                            rule: showIfContrapartida,
                            options: {
                                readonly,
                                required: requiredIfContrapartida('rubricaPessoalUnsaved')
                            }
                        }]
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/temMoedaEstrangeira',
                    label: 'Moeda Estrangeira',
                    options: {
                        readonly,
                        visibleHandler: ctx => {
                            var _a, _b, _c;
                            const { formData } = ctx;
                            const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                            return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => { var _a; return (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === TipoEditalRubrica.pessoal && ((_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.length) > 0; });
                        },
                        handleChange: handleTemMoedaEstrangeiraChange
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/moedaEstrangeiraId',
                    label: 'Moeda Estrangeira',
                    rule: showIfTemMoedaEstrangeira,
                    options: {
                        readonly,
                        required: requiredIfTemMoedaEstrangeira('rubricaPessoalUnsaved'),
                        displayProperties: ['nome'],
                        filter: {
                            handler: (formData, listData, api, queryFilterValues, path) => {
                                var _a, _b, _c;
                                const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                                let editalRubrica = (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.find(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.pessoal));
                                return api.getAll().then(response => response.filter(moedaEstrangeira => { var _a; return (_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.some(me => me.id === moedaEstrangeira.id); }));
                            }
                        },
                        handleChange: moedaHandleChange
                    }
                }]
        }, {
            type: 'LongText',
            scope: '#properties/justificativa',
            label: 'Justificativa',
            options: {
                readonly,
                maxLength: 256,
                label: getJustificativaStatus(TipoEditalRubrica.pessoal),
                visibleHandler: visibleHandlerJustificativaObrigatoria(TipoEditalRubrica.pessoal),
                required: ctx => {
                    var _a, _b, _c;
                    const { formData } = ctx;
                    const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                    return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.pessoal) && (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.temJustificativaObrigatoria));
                }
            }
        }];
};
export const rubricaPessoalUiSchema = function () {
    let { route, customPaths, scopePrefix = '', revisaoOrcamento, customHeader, customFooter, customActions } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
        type: 'Category',
        label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.pessoal)],
        rule: createShowRuleForEditalRubrica(TipoEditalRubrica.pessoal),
        options: {
            stepHandler: {
                handler: stepHandlerErrors
            }
        },
        elements: [{
                type: 'ArrayTableLayout',
                scope: `#${scopePrefix}/properties/rubricaPessoal`,
                label: '',
                options: {
                    additionalData: formData => ({
                        editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                    }),
                    buildHeader: {
                        builder: buildTableLayoutHeader,
                        field: 'valor'
                    },
                    route: route !== null && route !== void 0 ? route : '/proposta',
                    customPaths: customPaths !== null && customPaths !== void 0 ? customPaths : {},
                    hideCreate: revisaoOrcamento,
                    hideDefaultActions: revisaoOrcamento,
                    customActions,
                    headCells: [{
                            label: 'Função',
                            field: 'funcao'
                        }, {
                            label: 'Formação',
                            field: 'formacaoProfissional'
                        }, {
                            label: 'Perfil Desejado',
                            field: 'perfilDesejado'
                        }, {
                            label: 'Mês',
                            field: row => row.mesInicio ? `${row.mesInicio}°` : '-'
                        }, {
                            label: 'Contrapartida',
                            field: row => row.contrapartida ? 'Sim' : 'Não'
                        }, {
                            label: 'Moeda',
                            field: row => row.moedaEstrangeira ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})` : 'Real (R$)'
                        }, {
                            label: 'Valor',
                            field: row => row.valorTotal ? formatFloat(row.valorTotal) : '-'
                        }],
                    label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.pessoal)],
                    detail: {
                        type: 'VerticalLayout',
                        elements: getPessoalAnswerForm({
                            customHeader,
                            customFooter
                        })
                    }
                }
            }, {
                type: 'LongText',
                scope: '#properties/justificativaGlobal',
                label: 'Justificativa Global',
                options: {
                    maxLength: 256,
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.pessoal),
                    arrayProps: {
                        id: 'rubricaPessoal',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.pessoal),
                        arrayScope: 'justificativa'
                    }
                },
                rule: showIfJustificativaGlobal('rubricaPessoal')
            }, {
                type: 'Constant',
                scope: '#properties/justificativaGlobal',
                options: {
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.pessoal),
                    arrayProps: {
                        id: 'rubricaPessoal',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.pessoal),
                        arrayScope: 'tipoEditalRubrica'
                    },
                    value: TipoEditalRubrica.pessoal
                },
                rule: showIfJustificativaGlobal('rubricaPessoal')
            }]
    };
};
