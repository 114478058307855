import { truncateText } from '../../../utils/truncate-text';
export const moduleName = 'INSTITUICAO.UNIDADE.UNIDADE';
export const displayProperties = ['nome'];
export const moduleLabel = 'Unidade';
export const headCells = [{
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Unidade',
        textFormat: truncateText(64)
    }, {
        id: 'instituicaoId',
        numeric: false,
        disablePadding: false,
        label: 'Instituição',
        resource: {
            name: 'instituicao',
            target: 'nome',
            textFormat: truncateText(64)
        }
    }, {
        id: 'sigla',
        numeric: false,
        disablePadding: false,
        label: 'Sigla'
    }];
