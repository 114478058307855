const DepartamentoFundacaoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/nome',
            label: 'Nome do Departamento',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/sigla',
            options: {
                required: true
            }
        }, {
            type: 'LongText',
            scope: '#/properties/descricao',
            label: 'Descrição'
        }]
};
export default DepartamentoFundacaoUiSchema;
