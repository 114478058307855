import { sentenceCase } from 'change-case';
export const translateErrors = error => {
    var _a;
    let errorMessage;
    switch (error === null || error === void 0 ? void 0 : error.keyword) {
        case 'maxLength':
            errorMessage = `Máximo de ${error === null || error === void 0 ? void 0 : error.schema} caracteres`;
            break;
        case 'minLength':
            errorMessage = `Mínimo de ${error === null || error === void 0 ? void 0 : error.schema} caracteres`;
            break;
        case 'positiveNumber':
            errorMessage = `O campo deve ser um número positivo`;
            break;
        case 'type':
            errorMessage = `O valor precisa ser ${translateType((_a = error === null || error === void 0 ? void 0 : error.params) === null || _a === void 0 ? void 0 : _a.type)}`;
            break;
        case 'maximum':
            errorMessage = `O valor deve ser menor ou igual a ${error === null || error === void 0 ? void 0 : error.schema}`;
            break;
        default:
            errorMessage = errorsTranslate === null || errorsTranslate === void 0 ? void 0 : errorsTranslate[error === null || error === void 0 ? void 0 : error.keyword];
    }
    return errorMessage;
};
const translateType = type => {
    switch (type) {
        case 'string':
            return 'texto';
        case 'number':
            return 'número';
        case 'boolean':
            return 'booleano';
        case 'array':
            return 'lista';
        case 'object':
            return 'objeto';
        case 'integer':
            return 'inteiro';
        default:
            return type;
    }
};
export const errorsTranslate = {
    required: 'Campo obrigatório',
    format: 'Formato inválido',
    maxLength: 'Máximo de X caracteres'
};
export const translateAction = action => {
    var _a;
    return (_a = actionsTranslate[action]) !== null && _a !== void 0 ? _a : sentenceCase(action.replace('_', ' ').toLowerCase());
};
const actionsTranslate = {
    // Actions
    BUSCAR_TODOS: 'Buscar todos',
    BUSCAR_POR_ID: 'Visualizar',
    ESTRUTURA_DO_DOCUMENTO: 'Estrutura do documento',
    BUSCAR_CONFIGURACOES_DA_FUNDACAO: 'Buscar configurações da fundação',
    BUSCAR_EXCLUIDOS: 'Buscar excluídos',
    BAIXAR_ARQUIVO: 'Baixar arquivo',
    BUSCAR_ANEXOS: 'Buscar anexos',
    ENVIAR_ARQUIVO: 'Enviar arquivo',
    BUSCAR_TODAS_AS_PERMISSOES: 'Buscar todas as permissões',
    BUSCAR_PERMISSOES_POR_ID_DO_PERFIL: 'Buscar permissões por id do perfil',
    LISTAR_ORCAMENTO: 'Listar orçamento',
    AJUSTE_ORCAMENTARIO: 'Ajuste orçamentário',
    REVISAO_ORCAMENTO: 'Revisão do Orçamento',
    // Modules
    CARGO_PERMISSAO: 'Cargo Permissão',
    PERMISSAO: 'Permissão',
    USUARIO: 'Usuários',
    CONFIGURACOES_FUNDACAO: 'Configurações Fundação',
    CONFIGURACOES_INICIAIS: 'Configurações Iniciais',
    AREA_DE_CONHECIMENTO: 'Áreas de Conhecimento',
    DEPARTAMENTO_FUNDACAO: 'Departamentos',
    DEPARTAMENTO_USUARIO: 'Departamento Usuário',
    DOCUMENTO_PROPOSTA: 'Documento da Proposta',
    ENDERECO: 'Endereço',
    NIVEL_ACADEMICO: 'Níveis Acadêmicos',
    PAIS: 'País',
    RACA_COR: 'Raça/Cor',
    VALOR_DIARIA: 'Diárias',
    INDICADOR_PRODUCAO: 'Indicadores de Produção',
    INSTITUICAO: 'Instituição',
    UNIDADE: 'Unidades',
    BANCO: 'Bancos',
    PERFIL_PERMISSIONAMENTO: 'Permissões do Perfil',
    MODALIDADE_BOLSA: 'Modalidade de Bolsa',
    PERFIL: 'Perfis',
    INSTITUICAO_SUGERIDA: 'Instituição Sugerida',
    INSTRUMENTO_JURIDICO: 'Instrumentos Jurídicos',
    LINHA_DE_ACAO: 'Linhas de Ação',
    EDICAO_LINHA_DE_ACAO: 'Edição Linha de Ação',
    EDICAO_RECURSO_LINHA_DE_ACAO: 'Recursos da Linha de Ação',
    HISTORICO_AJUSTE_EDICAO_RECURSO_LINHA_DE_ACAO: 'Ajuste de Recursos da Linha de Ação',
    PROGRAMA: 'Programas',
    PRODUCAO: 'Produção',
    EDICAO_PROGRAMA: 'Edição Programa',
    EDICAO_RECURSO_PROGRAMA: 'Recursos do Programa',
    INSTRUMENTO_JURIDICO_ANEXO: 'Instrumentos Jurídicos anexo',
    INSTITUICAO_INSTRUMENTO_JURIDICO: 'Instituição do Instrumento Jurídico',
    STATUS_INSTRUMENTO_JURIDICO: 'Status Insturmento Jurídico',
    TIPO_INSTRUMENTO_JURIDICO: 'Tipos de Instrumento Jurídico',
    HISTORICO_AJUSTE_INSTITUICAO_INSTRUMENTO_JURIDICO: 'Histórico ajuste instituição Instrumento Jurídico',
    TIPO_HISTORICO_AJUSTE_INSTITUICAO_INSTRUMENTO_JURIDICO: 'Tipo histórico ajuste instituição Instrumento Jurídico',
    TERMO_ADITIVO_INSTRUMENTO_JURIDICO: 'Termo Aditivo Instrumento Jurídico',
    FONTE_DE_RECURSO: 'Fonte de recurso',
    HISTORICO_AJUSTE_TESOURO: 'Ajuste de Tesouro',
    PESSOA_JURIDICA: 'Pessoa Jurídica',
    ALTERAR_SENHA: 'Alterar Senha',
    CONFIRMAR_EMAIL: 'Confirmar E-mail',
    RECUPERAR_SENHA: 'Recuperar Senha',
    ORCAMENTO: 'Orçamento',
    RECURSO_LINHA_DE_ACAO: 'Recurso Linha de Ação',
    TERMO_POLITICA_PRIVACIDADE: 'Termo de Política e Privacidade',
    USUARIO_ANEXO: 'Usuário Anexo',
    NIVEL_BOLSA: 'Nível de Bolsa',
    HISTORICO_AJUSTE_VALOR_BOLSA: 'Ajuste do Valor',
    PERGUNTA: 'Perguntas Propostas',
    DOCUMENTO_PESSOAL: 'Documentos Pessoais',
    MOEDA_ESTRANGEIRA: 'Moedas Estrangeiras',
    RUBRICA: 'Rubricas',
    NATUREZA_DESPESA: 'Natureza da Despesa',
    FUNCIONAL_PROGRAMATICA: 'Funcionais Programáticas',
    AVISO: 'Avisos',
    CONFIGURACOES_DO_USUARIO: 'Configurações do Usuário',
    TIPO_EVENTO: 'Tipos de Eventos',
    FUNCAO_MEMBRO: 'Funções Membros',
    PROPOSTA: 'Propostas',
    AVALIACAO: 'Avaliação',
    ACOMPANHAR_AVALIACAO: 'Acompanhar Avaliações',
    PERGUNTA_AVALIACAO: 'Perguntas de Avaliação',
    FORMULARIO_ENQUADRAMENTO: 'Formulário de Enquadramento',
    FORMULARIO_CONSULTORIA: 'Formulário de Consultoria',
    AVALIACAO_PROPOSTA: 'Avaliação de Proposta',
    TERMO_CONTRATACAO: 'Termo Contratação',
    CONVITE_CONSULTOR: 'Convite de Consultor',
    AVALIACAO_CONSULTOR: 'Avaliação de Consultor',
    PROPOSTA_CONVITE_CONSULTOR: 'Convite de Consultor',
    EDITAL_CONTRATACAO: 'Edital Contratação',
    RECONSIDERACAO: 'Recurso Administrativo'
};
