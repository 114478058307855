import { RuleEffect } from '@jsonforms/core';
const tesouroUiSchema = {
    type: 'HorizontalLayout',
    elements: [{
            type: 'VerticalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/ano'
                }, {
                    type: 'MonetaryInput',
                    scope: '#/properties/valorPrometido',
                    rule: {
                        effect: RuleEffect.HIDE,
                        condition: {}
                    }
                }]
        }, {
            type: 'VerticalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    scope: '#/properties/valorPrevisto',
                    options: {
                        required: true
                    }
                }, {
                    type: 'MonetaryInput',
                    scope: '#/properties/valorAlocado',
                    rule: {
                        effect: RuleEffect.HIDE,
                        condition: {}
                    }
                }]
        }]
};
export default tesouroUiSchema;
