import { AcademicCap, Attachment, BriefcaseFill, Home, User } from '../../../components/styled-icons';
import { dadosAcademicosUiSchema } from './dados-academicos.uischema';
import { dadosPessoaisUiSchema } from './dados-pessoais.uischema';
import { dadosProfissionaisUiSchema } from './dados-profissionais.uischema';
import { documentosPessoaisUiSchema } from './documentos-pessoais.uischema';
import { enderecoUiSchema } from './endereco.uischema';
const iconConfig = [{
        category: 'Dados Pessoais',
        icon: User
    }, {
        category: 'Endereço',
        icon: Home
    }, {
        category: 'Dados Acadêmicos',
        icon: AcademicCap
    }, {
        category: 'Dados Profissionais',
        icon: BriefcaseFill
    }, {
        category: 'Documentos Pessoais',
        icon: Attachment
    }];
const editarPerfilUiSchema = {
    type: 'Categorization',
    elements: [dadosPessoaisUiSchema(), enderecoUiSchema(), dadosAcademicosUiSchema(), dadosProfissionaisUiSchema(), documentosPessoaisUiSchema()],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        showEditButton: true,
        icons: iconConfig
    }
};
export default editarPerfilUiSchema;
