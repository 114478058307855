import { makePropostaConviteMembroApi } from '../../../api/proposta-convite-membro-api';
export class ConviteMembroApi {
    constructor() {
        this.conviteMembroApi = makePropostaConviteMembroApi();
    }
    static getInstance() {
        if (!ConviteMembroApi.instance) {
            ConviteMembroApi.instance = new ConviteMembroApi();
        }
        return ConviteMembroApi.instance;
    }
    getApi() {
        return this.conviteMembroApi;
    }
}
