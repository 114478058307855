var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { rankWith, uiTypeIs } from '@jsonforms/core';
import { useEffect, useMemo } from 'react';
import { withJsonFormsCustomProps } from '../with-jsonforms-custom-props';
import { useCrudContext } from '../../components/crud/crud-context';
const constantTester = rankWith(7, uiTypeIs('Constant'));
export const ConstantRender = {
    tester: constantTester,
    renderer: withJsonFormsCustomProps(_ref => {
        var _a;
        let { data, visible, path, handleChange } = _ref, props = __rest(_ref, ["data", "visible", "path", "handleChange"]);
        const uischema = props.uischema;
        const ctx = useCrudContext();
        const { crudStates } = ctx;
        const visibleHandler = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.visibleHandler;
        const isVisible = useMemo(() => {
            const visibility = visibleHandler ? visibleHandler === null || visibleHandler === void 0 ? void 0 : visibleHandler(ctx, null, null) : true;
            return visible && visibility;
        }, [visible, crudStates, visibleHandler]);
        useEffect(() => {
            var _a;
            if (isVisible) {
                const value = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.value;
                handleChange(path, value);
            }
        }, [uischema, isVisible]);
        return null;
    })
};
