export const documentosDaPropostaUiSchema = () => [{
        type: 'VerticalLayout',
        elements: [{
                label: 'Documentos Pessoais',
                type: 'File',
                options: {
                    categoriesRoute: 'proposta/documento-pessoal-proposta-anexo/categories',
                    route: 'usuario/usuario-anexo',
                    fileApiOptions: formData => {
                        return {
                            editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                        };
                    },
                    withoutFk: true,
                    filterByCategories: true
                }
            }]
    }];
