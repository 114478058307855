const usuarioUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/nome',
            options: {
                disabled: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/email',
            label: 'E-mail',
            options: {
                mask: 'email',
                disabled: true
            }
        }, {
            type: 'Date',
            scope: '#/properties/dataNascimento',
            label: 'Data de nascimento',
            options: {
                dateFormat: 'DD/MM/YYYY',
                onlyCreate: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/nomeSocial',
            options: {
                disabled: true
            }
        }, {
            type: 'Select',
            scope: '#/properties/departamentoFundacaoId'
        }, {
            type: 'Select',
            scope: '#/properties/perfil',
            options: {
                selectMultiple: true,
                route: 'perfil'
            }
        }]
};
export default usuarioUiSchema;
