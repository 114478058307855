import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);
export function isPeriodoReconsideracaoEnquadramento(chamada) {
    const now = dayjs();
    if (!chamada) {
        return false;
    }
    return chamada === null || chamada === void 0 ? void 0 : chamada.filter(item => !item.deletedAt).some(item => {
        if (!item.inicioReconsideracaoEnquadramento)
            return false;
        const inicio = dayjs(item.inicioReconsideracaoEnquadramento);
        const termino = dayjs(item.terminoReconsideracaoEnquadramento);
        return now.isBetween(inicio, termino, undefined, '[]');
    });
}
export function isPeriodoReconsideracaoAprovacao(chamada) {
    const now = dayjs();
    if (!chamada) {
        return false;
    }
    return chamada === null || chamada === void 0 ? void 0 : chamada.filter(item => !item.deletedAt).some(item => {
        if (!item.inicioReconsideracaoAprovacao)
            return false;
        const inicio = dayjs(item.inicioReconsideracaoAprovacao);
        const termino = dayjs(item.terminoReconsideracaoAprovacao);
        return now.isBetween(inicio, termino, undefined, '[]');
    });
}
