export const moduleName = 'CONFIGURACOES_INICIAIS.BANCO';
export const displayProperties = ['nome'];
export const moduleLabel = 'Banco';
export const headCells = [{
        id: 'codigo',
        numeric: false,
        disablePadding: false,
        label: 'Código'
    }, {
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }];
