import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { displayProperties } from '../../../shared';
export const disableHandler = (uischema, ctx, apiListData, setDisabled) => {
    var _a;
    const disableHandler = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.disableHandler;
    if (!disableHandler)
        return;
    const handlerResult = disableHandler === null || disableHandler === void 0 ? void 0 : disableHandler(ctx, apiListData);
    setDisabled(handlerResult);
};
export const filterHandler = props => {
    var _a, _b, _c, _d, _e;
    const { uischema, path, ctx, apiListData, setApiListData, lastFilterValues, setLastFilterValues, api, apiUrl, setSelectedItem } = props;
    const { formData, showError } = ctx;
    const filter = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.filter;
    if (!filter)
        return;
    const { preventCleaning: preventClear, formFields, handler } = filter;
    if (!formFields)
        return;
    let hasNullFilterValue = false;
    const queryFilterValues = (_c = (_b = formFields === null || formFields === void 0 ? void 0 : formFields.map) === null || _b === void 0 ? void 0 : _b.call(formFields, filter => {
        const filterValue = path === null || path === void 0 ? void 0 : path.split('.').slice(0, -1).concat(filter).reduce((acc, key) => acc && acc[key], formData);
        if (!filterValue)
            hasNullFilterValue = true;
        return {
            filter,
            value: filterValue
        };
    })) !== null && _c !== void 0 ? _c : [];
    if (handler && !isEqual(queryFilterValues, lastFilterValues)) {
        if (formFields) {
            (_e = (_d = handler === null || handler === void 0 ? void 0 : handler(formData, apiListData, api, queryFilterValues, path)) === null || _d === void 0 ? void 0 : _d.then) === null || _e === void 0 ? void 0 : _e.call(_d, dataFromApi => {
                setApiListData(dataFromApi);
            }).catch(error => {
                var _a, _b, _c;
                showError(((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c[0]) || 'Ocorreu um erro.');
            });
        }
        if (hasNullFilterValue) {
            //Limpa a lista quando o filtro observado for null
            setApiListData([]);
        }
        if (!(queryFilterValues.length && !(lastFilterValues === null || lastFilterValues === void 0 ? void 0 : lastFilterValues.length)) && !preventClear) {
            // Alterou os filtros
            // Limpa o select
            setTimeout(() => handleChange(null, uischema, apiUrl, props.customProps, ctx, setSelectedItem), 100);
        }
        setLastFilterValues(queryFilterValues);
    }
};
export const loadSavedItem = (uischema, props, dataFromApi, setSelectedItem, formData, apiUrl) => {
    var _a, _b, _c, _d, _e;
    if (props === null || props === void 0 ? void 0 : props.data) {
        const targetFieldValue = (_b = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.targetFieldValue) !== null && _b !== void 0 ? _b : 'id';
        const comparator = (_c = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _c === void 0 ? void 0 : _c.optionsComparator;
        const item = (_d = dataFromApi === null || dataFromApi === void 0 ? void 0 : dataFromApi.find) === null || _d === void 0 ? void 0 : _d.call(dataFromApi, item => {
            const isMatch = comparator ? comparator === null || comparator === void 0 ? void 0 : comparator(item, props === null || props === void 0 ? void 0 : props.data) : (item === null || item === void 0 ? void 0 : item.id) === Number(props === null || props === void 0 ? void 0 : props.data);
            return isMatch;
        });
        if (item) {
            const option = {
                label: displayPropertiesComputed(uischema, item, apiUrl),
                value: item === null || item === void 0 ? void 0 : item[targetFieldValue],
                codigo: item === null || item === void 0 ? void 0 : item.codigo
            };
            setSelectedItem(option);
            if ((_e = uischema.options) === null || _e === void 0 ? void 0 : _e.handleChange) {
                setTimeout(() => { var _a, _b; return (_b = (_a = uischema.options) === null || _a === void 0 ? void 0 : _a.handleChange) === null || _b === void 0 ? void 0 : _b.call(_a, item, props.handleChange, formData); }, 200);
            }
        }
    }
};
export const displayPropertiesComputed = (uischema, item, apiUrl) => {
    var _a, _b, _c, _d, _e;
    if (isEmpty(item))
        return '';
    const formatOptionLabel = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.formatOptionLabel;
    if (formatOptionLabel && typeof formatOptionLabel === 'function') {
        return formatOptionLabel(item);
    }
    // Formatação padrão
    const properties = displayProperties === null || displayProperties === void 0 ? void 0 : displayProperties.find(option => (option === null || option === void 0 ? void 0 : option.id) === apiUrl);
    const values = [];
    if (typeof (properties === null || properties === void 0 ? void 0 : properties.value) === 'function')
        return properties === null || properties === void 0 ? void 0 : properties.value(item);
    let propValues = (_c = (_b = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _b === void 0 ? void 0 : _b.displayProperties) !== null && _c !== void 0 ? _c : properties === null || properties === void 0 ? void 0 : properties.value;
    (_d = propValues === null || propValues === void 0 ? void 0 : propValues.forEach) === null || _d === void 0 ? void 0 : _d.call(propValues, prop => { var _a; return (_a = values === null || values === void 0 ? void 0 : values.push) === null || _a === void 0 ? void 0 : _a.call(values, prop.split('.').reduce((acc, key) => acc && acc[key], item)); });
    return (_e = values === null || values === void 0 ? void 0 : values.join) === null || _e === void 0 ? void 0 : _e.call(values, ', ');
};
export const handleChange = (content, uischema, apiUrl, props, ctx, setSelectedItem) => {
    var _a, _b, _c;
    const { formData } = ctx;
    const option = {
        label: (_a = displayPropertiesComputed(uischema, content === null || content === void 0 ? void 0 : content.data, apiUrl)) !== null && _a !== void 0 ? _a : '',
        value: content === null || content === void 0 ? void 0 : content.value,
        codigo: content === null || content === void 0 ? void 0 : content.codigo
    };
    if ((_b = uischema.options) === null || _b === void 0 ? void 0 : _b.handleChange) {
        setTimeout(() => { var _a, _b, _c, _d; return (_b = (_a = uischema.options) === null || _a === void 0 ? void 0 : _a.handleChange) === null || _b === void 0 ? void 0 : _b.call(_a, (_d = (_c = content === null || content === void 0 ? void 0 : content.data) !== null && _c !== void 0 ? _c : content === null || content === void 0 ? void 0 : content.value) !== null && _d !== void 0 ? _d : null, props.handleChange, formData, ctx, props.path); }, 200);
    }
    props.handleChange(props.path, (_c = content === null || content === void 0 ? void 0 : content.value) !== null && _c !== void 0 ? _c : null);
    setSelectedItem((content === null || content === void 0 ? void 0 : content.value) ? option : null);
};
export const loadEnumDefault = (listData, props, ctx, schema) => {
    var _a;
    const { formData } = ctx;
    if (!props.data && (formData === null || formData === void 0 ? void 0 : formData[`${props.path}-codigo`])) {
        const foreignEnum = schema === null || schema === void 0 ? void 0 : schema.foreignEnum;
        const enumCode = foreignEnum[formData[`${props.path}-codigo`]];
        const item = (_a = listData === null || listData === void 0 ? void 0 : listData.find) === null || _a === void 0 ? void 0 : _a.call(listData, item => item.codigo === enumCode);
        props.handleChange(props.path, item === null || item === void 0 ? void 0 : item.id);
    }
};
