var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from '../config/axios-config';
import { GenericApi } from './generic-api';
export class PropostaConviteMembroApi extends GenericApi {
    aceitar(id, data) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.put(`${this.url}/${id}/aceitar`, data);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Put error: ', {
                    cause: error
                });
            }
        });
    }
    recusar(id) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.put(`${this.url}/${id}/recusar`);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Put error: ', {
                    cause: error
                });
            }
        });
    }
    remover(id) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.put(`${this.url}/${id}/remover`);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Put error: ', {
                    cause: error
                });
            }
        });
    }
    reenviar(id) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.put(`${this.url}/${id}/reenviar`);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Put error: ', {
                    cause: error
                });
            }
        });
    }
    getAllReceived() {
        var _a;
        return __awaiter(this, arguments, void 0, function* () {
            let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
                withDeleted: false
            };
            try {
                const urlBuild = Object.entries(options).reduce((acc, _ref, i) => {
                    var _a;
                    let [chave, valor] = _ref;
                    return `${acc}${i === 0 ? '?' : '&'}${chave}=${(_a = String(valor)) !== null && _a !== void 0 ? _a : ''}`;
                }, `${this.url}/recebido`);
                const response = yield client.get(urlBuild);
                return response === null || response === void 0 ? void 0 : response.data;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('GetAll error: ', {
                    cause: error
                });
            }
        });
    }
    mudarFuncaoMembro(id, funcaoMembroId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield client.put(`${this.url}/${id}/mudar-funcao`, {
                    funcaoMembroId
                });
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Put error: ', {
                    cause: error
                });
            }
        });
    }
}
export const makePropostaConviteMembroApi = () => {
    return new PropostaConviteMembroApi('/proposta-convite-membro');
};
