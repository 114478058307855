import { camelCase } from 'change-case';
import { formatFloat } from '../../../helpers/format-float';
import { TipoEditalRubrica, tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { buildTableLayoutHeader } from '../../../shared/proposta/table-layout-header';
import { contrapartidaHandleChange } from './helpers/contrapartidaHandleChange';
import { createShowRuleForEditalRubrica } from './helpers/createShowRuleForEditalRubrica';
import { getJustificativaStatus } from './helpers/getJustificativaStatus';
import { handleTemMoedaEstrangeiraChange } from './helpers/handleTemMoedaEstrangeiraChange';
import { moedaHandleChange } from './helpers/moedaHandleChange';
import { requiredIfContrapartida } from './helpers/requiredIfContrapartida';
import { requiredIfTemMoedaEstrangeira } from './helpers/requiredIfTemMoedaEstrangeira';
import { showIfContrapartida } from './helpers/showIfContrapartida';
import { showIfJustificativaGlobal } from './helpers/showIfJustificativaGlobal';
import { showIfTemMoedaEstrangeira } from './helpers/showIfTemMoedaEstrangeira';
import { visibleHandlerJustificativaGlobal } from './helpers/visibleHandlerJustificativaGlobal';
import { visibleHandlerJustificativaObrigatoria } from './helpers/visibleHandlerJustificativaObrigatoria';
export const getMaterialDeConsumoAnswerForm = function () {
    let { proposta, readonly = false, customHeader, customFooter } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/revisao',
                    label: '',
                    options: {
                        readonly: true,
                        maxLength: 256,
                        customHeader,
                        customFooter,
                        visibleHandler: ctx => {
                            var _a;
                            return (_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.orcamentoEdicao;
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/especificacao',
                    label: 'Especificação',
                    options: {
                        readonly,
                        required: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/unidadeMedida',
                    label: 'Unidade de Medida',
                    options: {
                        readonly,
                        required: true
                    }
                }, {
                    type: 'Float',
                    scope: '#/properties/quantidade',
                    label: 'Quantidade',
                    options: {
                        readonly,
                        required: true,
                        isPositiveNumber: true,
                        handleChange: (quantidade, handler, formData, ctx, path) => {
                            var _a;
                            const custoUnitario = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaMaterialConsumoUnsaved']) === null || _a === void 0 ? void 0 : _a.custoUnitario;
                            if (!custoUnitario && !quantidade)
                                return;
                            handler(`${path}`.replace('quantidade', 'valorTotal'), custoUnitario * quantidade / 100);
                        }
                    }
                }, {
                    type: 'MonetaryInput',
                    scope: '#/properties/custoUnitario',
                    label: 'Custo Unitário',
                    options: {
                        readonly,
                        required: true,
                        handleChange: (custoUnitario, handler, formData, ctx, path) => {
                            var _a;
                            const quantidade = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaMaterialConsumoUnsaved']) === null || _a === void 0 ? void 0 : _a.quantidade;
                            if (!custoUnitario && !quantidade)
                                return;
                            handler(`${path}`.replace('custoUnitario', 'valorTotal'), custoUnitario * quantidade / 100);
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    scope: '#/properties/valorTotal',
                    label: 'Valor Total',
                    options: {
                        disabled: true
                    }
                }, {
                    type: 'Boolean',
                    scope: '#/properties/temMoedaEstrangeira',
                    label: 'Moeda Estrangeira',
                    options: {
                        readonly,
                        visibleHandler: ctx => {
                            var _a, _b, _c;
                            const { formData } = ctx;
                            const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                            return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => { var _a; return (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === TipoEditalRubrica.materialDeConsumo && ((_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.length) > 0; });
                        },
                        handleChange: handleTemMoedaEstrangeiraChange
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/moedaEstrangeiraId',
                    label: 'Moeda Estrangeira',
                    rule: showIfTemMoedaEstrangeira,
                    options: {
                        readonly,
                        required: requiredIfTemMoedaEstrangeira('rubricaMaterialConsumoUnsaved'),
                        handleChange: moedaHandleChange,
                        displayProperties: ['nome'],
                        filter: {
                            handler: (formData, listData, api, queryFilterValues, path) => {
                                var _a, _b, _c;
                                const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                                const editalRubrica = (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.find(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.materialDeConsumo));
                                return api.getAll().then(response => response.filter(moedaEstrangeira => { var _a; return (_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.some(me => me.id === moedaEstrangeira.id); }));
                            }
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/mesPrevisto',
                    label: 'Mês Previsto',
                    options: {
                        readonly,
                        required: true,
                        oneOf: formData => {
                            const data = (formData === null || formData === void 0 ? void 0 : formData.duracao) ? formData : proposta;
                            return Array.from({
                                length: data.duracao
                            }, (_, i) => ({
                                title: `${i + 1}°`,
                                const: i + 1
                            }));
                        }
                    }
                }, {
                    type: 'Boolean',
                    scope: '#/properties/contrapartida',
                    options: {
                        readonly,
                        defaultValue: false,
                        handleChange: contrapartidaHandleChange
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/tipoPessoa',
                    label: 'Tipo Pessoa',
                    rule: showIfContrapartida,
                    options: {
                        readonly,
                        required: requiredIfContrapartida('rubricaMaterialConsumoUnsaved')
                    }
                }]
        }, {
            type: 'Control',
            scope: '#/properties/entidade',
            label: 'Entidade',
            rule: showIfContrapartida,
            options: {
                readonly,
                required: requiredIfContrapartida('rubricaMaterialConsumoUnsaved')
            }
        }, {
            type: 'LongText',
            scope: '#properties/justificativa',
            label: 'Justificativa',
            options: {
                readonly,
                maxLength: 256,
                label: getJustificativaStatus(TipoEditalRubrica.materialDeConsumo),
                visibleHandler: visibleHandlerJustificativaObrigatoria(TipoEditalRubrica.materialDeConsumo),
                required: ctx => {
                    var _a, _b, _c;
                    const { formData } = ctx;
                    const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                    return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.materialDeConsumo) && (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.temJustificativaObrigatoria));
                }
            }
        }];
};
export const rubricaMaterialConsumoUiSchema = function () {
    let { route, customPaths, scopePrefix = '', revisaoOrcamento, customHeader, customFooter, customActions } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
        type: 'Category',
        label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.materialDeConsumo)],
        rule: createShowRuleForEditalRubrica(TipoEditalRubrica.materialDeConsumo),
        options: {
            stepHandler: {
                handler: stepHandlerErrors
            }
        },
        elements: [{
                type: 'ArrayTableLayout',
                scope: `#${scopePrefix}/properties/rubricaMaterialConsumo`,
                label: '',
                options: {
                    additionalData: formData => ({
                        editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                    }),
                    buildHeader: {
                        builder: buildTableLayoutHeader
                    },
                    route: route !== null && route !== void 0 ? route : '/proposta',
                    customPaths: customPaths !== null && customPaths !== void 0 ? customPaths : {},
                    hideCreate: revisaoOrcamento,
                    hideDefaultActions: revisaoOrcamento,
                    customActions,
                    headCells: [{
                            label: 'Especificação',
                            field: 'especificacao'
                        }, {
                            label: 'Quantidade',
                            field: row => row.quantidade ? formatFloat(row.quantidade, 'pt-BR', 0, 0) : '-'
                        }, {
                            label: 'Custo Unitário',
                            field: row => row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-'
                        }, {
                            label: 'Mês',
                            field: row => row.mesPrevisto ? `${row.mesPrevisto}°` : '-'
                        }, {
                            label: 'Contrapartida',
                            field: row => row.contrapartida ? 'Sim' : 'Não'
                        }, {
                            label: 'Moeda',
                            field: row => row.moedaEstrangeira ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})` : 'Real (R$)'
                        }, {
                            label: 'Valor Total',
                            field: row => row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-'
                        }],
                    label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.materialDeConsumo)],
                    detail: {
                        type: 'VerticalLayout',
                        elements: getMaterialDeConsumoAnswerForm({
                            customHeader,
                            customFooter
                        })
                    }
                }
            }, {
                type: 'LongText',
                scope: '#properties/justificativaGlobal',
                label: 'Justificativa Global',
                options: {
                    maxLength: 256,
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.materialDeConsumo),
                    arrayProps: {
                        id: 'materialDeConsumo',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.materialDeConsumo),
                        arrayScope: 'justificativa'
                    }
                },
                rule: showIfJustificativaGlobal('rubricaMaterialConsumo')
            }, {
                type: 'Constant',
                scope: '#properties/justificativaGlobal',
                options: {
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.materialDeConsumo),
                    arrayProps: {
                        id: 'materialDeConsumo',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.materialDeConsumo),
                        arrayScope: 'tipoEditalRubrica'
                    },
                    value: TipoEditalRubrica.materialDeConsumo
                },
                rule: showIfJustificativaGlobal('rubricaMaterialConsumo')
            }]
    };
};
