export const displayProperties = ['nomePt'];
export const moduleLabel = 'País';
export const headCells = [{
        id: 'nomePt',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }, {
        id: 'codigo',
        numeric: false,
        disablePadding: false,
        label: 'Código'
    }];
