import { RuleEffect } from '@jsonforms/core';
export const showIfTemMoedaEstrangeira = {
    effect: RuleEffect.SHOW,
    condition: {
        scope: `#/properties/temMoedaEstrangeira`,
        schema: {
            const: true
        }
    }
};
