import { tipoSituacaoI18N } from '../../../models/proposta';
import { FuncaoMembro } from './funcao-membro';
export const displayProperties = ['usuario.nome'];
export const moduleLabel = 'Convite de Membros';
export const moduleName = 'PROPOSTA.PROPOSTA_CONVITE_MEMBRO.PROPOSTA_CONVITE_MEMBRO';
export const headCells = [{
        id: 'usuarioPersistido',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
        resource: {
            target: row => { var _a; return (_a = row === null || row === void 0 ? void 0 : row.usuarioPersistido) === null || _a === void 0 ? void 0 : _a.nome; }
        }
    }, {
        id: 'usuarioPersistido',
        numeric: false,
        disablePadding: false,
        label: 'Instituição',
        resource: {
            target: row => { var _a, _b; return (_b = (_a = row === null || row === void 0 ? void 0 : row.usuarioPersistido) === null || _a === void 0 ? void 0 : _a.instituicao) === null || _b === void 0 ? void 0 : _b.nome; }
        }
    }, {
        id: 'situacao',
        numeric: true,
        disablePadding: false,
        label: 'Convite',
        resource: {
            target: row => {
                return tipoSituacaoI18N[row === null || row === void 0 ? void 0 : row.situacao];
            }
        }
    }, {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Data de Convite',
        dateFormat: 'DD/MM/YYYY'
    }, {
        id: 'dataAceite',
        numeric: false,
        disablePadding: false,
        label: 'Data de Aceite',
        dateFormat: 'DD/MM/YYYY'
    }, {
        id: 'funcaoMembro',
        numeric: false,
        disablePadding: false,
        label: 'Função do membro',
        overrideCellComponent: row => {
            return FuncaoMembro(row);
        }
    }];
