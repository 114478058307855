export const reconsideracaoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'VerticalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/reconsideracao',
                    label: 'Recurso Administrativo',
                    options: {
                        multiline: true,
                        numberOfRows: 5
                    }
                }]
        }]
};
