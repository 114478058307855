var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const stepHandlerAbrangencia = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    const { formData, showError } = ctx;
    const estado = formData === null || formData === void 0 ? void 0 : formData.estado;
    if ((estado === null || estado === void 0 ? void 0 : estado.length) > 0) {
        if (callback)
            yield callback(ctx);
        return true;
    }
    showError('Informe pelo menos um estado.');
    return false;
});
