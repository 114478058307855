var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from '../config/axios-config';
export class LoginApi {
    login(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.post('login', data);
            return response;
        });
    }
    confirmarEmail(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.post(`usuario/confirmar-email?token=${token}`);
            return response;
        });
    }
    reenviarEmailConfirmacao(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.post('usuario/reenviar-email-confirmacao', {
                email
            });
            return response;
        });
    }
    recuperarSenha(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.post('usuario/recuperar-senha', {
                email
            });
            return response;
        });
    }
    alterarSenha(token, senha) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.post('usuario/alterar-senha', {
                token,
                senha
            });
            return response;
        });
    }
    obterConfiguracoesFundacao() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield client.get('configuracoes-fundacao');
        });
    }
}
export const makeLoginApi = () => {
    return new LoginApi();
};
