export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.PROPOSTA_CONVITE_CONSULTOR';
export const displayProperties = ['nome'];
export const moduleLabel = 'Acompanhar Avaliações';
export const headCells = [{
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Edital'
    }, {
        id: 'qtdePropostas',
        numeric: false,
        disablePadding: false,
        label: 'Qtde Propostas'
    }, {
        id: 'qtdeConvitesAceitos',
        numeric: false,
        disablePadding: false,
        label: 'Qtde Aceitos'
    }, {
        id: 'qtdeConviteRecusados',
        numeric: false,
        disablePadding: false,
        label: 'Qtde Recusados'
    }, {
        id: 'qtdeConvitesAvaliados',
        numeric: false,
        disablePadding: false,
        label: 'Qtde Avaliados '
    }, {
        id: 'qtdeConvitesPendentes',
        numeric: false,
        disablePadding: false,
        label: 'Qtde Pendentes'
    }];
