import { tipoSituacaoI18N } from '../../../models/proposta';
export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.PROPOSTA_CONVITE_CONSULTOR';
export const displayProperties = ['tituloDoProjeto'];
export const moduleLabel = 'Acompanhar Convites';
export const headCells = [{
        id: 'propostaId',
        numeric: false,
        disablePadding: false,
        label: 'Proposta',
        resource: {
            name: 'proposta',
            target: 'tituloDoProjeto'
        }
    }, {
        id: 'propostaId',
        numeric: false,
        disablePadding: false,
        label: 'Coordenador',
        computedRowLabel: row => { var _a, _b, _c, _d, _e, _f, _g; return ((_b = (_a = row === null || row === void 0 ? void 0 : row.proposta) === null || _a === void 0 ? void 0 : _a.criadoPor) === null || _b === void 0 ? void 0 : _b.nome) ? (_d = (_c = row === null || row === void 0 ? void 0 : row.proposta) === null || _c === void 0 ? void 0 : _c.criadoPor) === null || _d === void 0 ? void 0 : _d.nome : (_g = (_f = (_e = row === null || row === void 0 ? void 0 : row.proposta) === null || _e === void 0 ? void 0 : _e.criadoPor) === null || _f === void 0 ? void 0 : _f.usuario) === null || _g === void 0 ? void 0 : _g.nome; }
    }, {
        id: 'propostaId',
        numeric: false,
        disablePadding: false,
        label: 'Protocolo',
        computedRowLabel: row => { var _a; return (_a = row === null || row === void 0 ? void 0 : row.proposta) === null || _a === void 0 ? void 0 : _a.protocolo; }
    }, {
        id: 'usuarioId',
        numeric: false,
        disablePadding: false,
        label: 'Consultor',
        resource: {
            name: 'usuario',
            target: 'nome'
        }
    }, {
        id: 'formularioConsultoriaId',
        numeric: false,
        disablePadding: false,
        label: 'Formulário',
        computedRowLabel: row => { var _a; return (_a = row === null || row === void 0 ? void 0 : row.formularioConsultoria) === null || _a === void 0 ? void 0 : _a.titulo; }
    }, {
        id: 'situacao',
        numeric: false,
        disablePadding: false,
        label: 'Convite',
        resource: {
            target: row => {
                return tipoSituacaoI18N[row === null || row === void 0 ? void 0 : row.situacao];
            }
        }
    }];
