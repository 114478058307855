const linhaDeAcaoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'HorizontalLayout',
            elements: [{
                    label: 'Título',
                    type: 'Control',
                    scope: '#/properties/titulo',
                    options: {
                        required: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/objetivo',
                    options: {
                        required: true
                    }
                }, {
                    label: 'Descrição',
                    type: 'Control',
                    scope: '#/properties/descricao'
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    label: 'Informação da Publicação',
                    type: 'Control',
                    scope: '#/properties/informacaoPublicacao'
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    label: 'Data de Início',
                    type: 'Date',
                    scope: '#/properties/dataInicio',
                    options: {
                        required: true
                    }
                }, {
                    label: 'Data de Término',
                    type: 'Date',
                    scope: '#/properties/dataTermino',
                    options: {
                        required: true
                    }
                }]
        }]
};
export default linhaDeAcaoUiSchema;
