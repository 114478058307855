import { getUischemaElements } from './get-uischema-elements';
import { scopeToPath } from './scope-to-path';
export const getUischemaConfigs = (uischema, ctx) => {
    var _a, _b;
    const paths = (_b = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.additionalPaths) !== null && _b !== void 0 ? _b : [];
    const requiredPaths = [];
    const instancePaths = [];
    const requiredScopes = [];
    const elements = getUischemaElements(uischema);
    elements.forEach(el => {
        var _a, _b;
        const path = scopeToPath(el === null || el === void 0 ? void 0 : el.scope);
        const required = (_a = el === null || el === void 0 ? void 0 : el.options) === null || _a === void 0 ? void 0 : _a.required;
        if (typeof required === 'function' && required(ctx) || typeof required === 'boolean' && required) {
            requiredScopes.push(el === null || el === void 0 ? void 0 : el.scope);
            requiredPaths.push(path);
        }
        paths.push(path);
        instancePaths.push(`/${(_b = path === null || path === void 0 ? void 0 : path.replace) === null || _b === void 0 ? void 0 : _b.call(path, '.', '/')}`);
    });
    return {
        paths,
        requiredPaths,
        instancePaths,
        requiredScopes
    };
};
