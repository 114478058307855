const termoPoliticaPrivacidadeUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'CkEditor',
            scope: '#/properties/politicaDePrivacidade',
            options: {
                ckeditorSize: {
                    height: 600
                }
            }
        }]
};
export default termoPoliticaPrivacidadeUiSchema;
