var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const areaDeConhecimentoHandleChange = (item, handler, formData, _ctx, path) => __awaiter(void 0, void 0, void 0, function* () {
    if (!path)
        return;
    const pathToSet = path.split('.').slice(0, -1).join('.');
    const fieldKey = path.split('.').pop();
    if (item === null || item === void 0 ? void 0 : item.id) {
        handler(`${pathToSet}.id`, item.id);
        return;
    }
    const areaConhecimento = path.split('.').slice(0, -1).reduce((acc, curr) => acc === null || acc === void 0 ? void 0 : acc[curr], formData);
    if (!areaConhecimento)
        return;
    const ordem = ['especialidadeId', 'subAreaId', 'areaId', 'grandeAreaId'];
    const fallbackId = ordem.find(key => key !== fieldKey && areaConhecimento[key]);
    if (fallbackId) {
        handler(`${pathToSet}.id`, areaConhecimento[fallbackId]);
    }
});
