const programaUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'HorizontalLayout',
            elements: [{
                    label: 'Nome',
                    type: 'Control',
                    scope: '#/properties/nome',
                    options: {
                        required: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    label: 'Sigla',
                    type: 'Control',
                    scope: '#/properties/sigla',
                    options: {
                        required: true
                    }
                }, {
                    label: 'Objetivo',
                    type: 'Control',
                    scope: '#/properties/objetivo'
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    label: 'Descrição',
                    type: 'Control',
                    scope: '#/properties/descricao'
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    label: 'Data de Início',
                    type: 'Date',
                    scope: '#/properties/dataInicio',
                    options: {
                        required: true
                    }
                }, {
                    label: 'Data de Término',
                    type: 'Date',
                    scope: '#/properties/dataTermino',
                    options: {
                        required: true
                    }
                }]
        }]
};
export default programaUiSchema;
