export const displayProperties = ['codigo', 'descricao'];
export const moduleName = 'CONFIGURACOES_INICIAIS.FUNCIONAL_PROGRAMATICA';
export const moduleLabel = 'Funcionais Programáticas';
export const headCells = [{
        id: 'codigo',
        numeric: false,
        disablePadding: false,
        label: 'Funcional Programática'
    }, {
        id: 'descricao',
        numeric: false,
        disablePadding: false,
        label: 'Descrição'
    }];
