export const displayProperties = ['nome'];
export const moduleLabel = 'Perfil Fundação';
export const moduleName = 'DADOS_FUNDACAO';
export const headCells = [{
        id: 'nomeDiretorPresidente',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }, {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'email'
    }];
