import { Profile, Attachment } from '../../../components/styled-icons';
import { dadosFundacaoStepHandlerSave } from './dados-fundacao-step-handler';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
const iconConfig = [{
        category: 'Dados da fundação',
        icon: Profile
    }, {
        category: 'Anexos',
        icon: Attachment
    }];
const dadosFundacaoUiSchema = {
    type: 'Categorization',
    elements: [{
            type: 'Category',
            label: 'Dados da fundação',
            options: {
                stepHandler: {
                    handler: dadosFundacaoStepHandlerSave,
                    url: '/dados-fundacao'
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/telefone',
                            options: {
                                mask: '(99) [9]9999-9999'
                            }
                        }, {
                            type: 'Control',
                            scope: '#/properties/email'
                        }, {
                            type: 'Control',
                            scope: '#/properties/nomeDiretorPresidente',
                            label: 'Nome do Diretor Presidente'
                        }, {
                            type: 'Control',
                            scope: '#/properties/nomeSecretarioDiretor',
                            label: 'Nome do Secretário Diretor'
                        }, {
                            type: 'Control',
                            scope: '#/properties/nomeSecretarioEstado',
                            label: 'Nome do Secretário de Estado'
                        }, {
                            type: 'Color',
                            scope: '#/properties/corPrimaria',
                            label: 'Cor primária'
                        }, {
                            type: 'Color',
                            scope: '#/properties/corSecundaria',
                            label: 'Cor secundária'
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Anexos',
            options: {
                stepHandler: {
                    handler: (_, ctx) => {
                        ctx.updateCrudStates(CrudStatesOptions.VIEW);
                        return true;
                    }
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'File',
                            scope: '#/properties/logoCabecalho'
                        }]
                }]
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        showEditButton: true,
        icons: iconConfig
    }
};
export default dadosFundacaoUiSchema;
