export class VariableNode {
    constructor(name) {
        this.name = name;
    }
    solve(vars) {
        if (this.name in vars)
            return vars[this.name];
        throw new Error(`Variable ${this.name} not found`);
    }
    listVars() {
        return [this.name];
    }
}
