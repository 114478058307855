export const getOutorgado = _ref => {
    let { pesquisador: { nome, documento: cpf, endereco: { logradouro, numero, bairro, cep, municipio, estado } } } = _ref;
    return `${nome || ''}, doravante denominado(a) OUTORGADO, pesquisador(a), portador(a) do CPF n.º ${cpf || ''}, residente e domiciliado a ${logradouro || ''}, ${numero || ''} - ${bairro || ''}, CEP ${cep || ''}, ${municipio || ''} - ${estado || ''}.`;
};
export const getOutorgante = _ref2 => {
    let { fundacao: { instituicao: { nome, sigla, cnpj, endereco: { logradouro, numero, bairro } } }, fundacao: { nomeDiretorPresidente: nomePresidente } } = _ref2;
    return `${nome}, ${sigla || ''}, pessoa jurídica de direito público, inscrita no CNPJ sob nº ${cnpj || ''}, estabelecida à ${logradouro || ''}, ${numero || ''} - ${bairro || ''}, nesta Capital, denominada CONCEDENTE representada por seu Diretor-Presidente ${nomePresidente || ''}.`;
};
export const getInterveniente = _ref3 => {
    let { proposta: { instituicaoExecutora: { nome, sigla, cnpj, dirigente, endereco: { logradouro, numero, bairro, cep, municipio, estado } }, unidadeExecutora: { nome: unidade } } } = _ref3;
    return `${nome || ''}- ${sigla || ''}-${unidade}, sediada a ${logradouro || ''}, ${numero || ''} - ${bairro || ''}, CEP ${cep || ''},  ${municipio || ''} - ${estado || ''}, inscrita no CNPJ sob nº ${cnpj || ''}, representada por seu/sua Reitor ${dirigente || ''}.`;
};
