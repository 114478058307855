import { useAuthContext } from '../shared/login/auth/auth-context';
import { authStringsFor } from './auth-strings-for';
export const SecureObject = (obj, _ref) => {
    let { hasPermission, moduleName, ignorePermission } = _ref;
    const { permissions } = useAuthContext();
    let permissionGranted = true;
    if (ignorePermission)
        return obj;
    if (typeof hasPermission === 'function')
        permissionGranted = hasPermission === null || hasPermission === void 0 ? void 0 : hasPermission(moduleName, permissions);
    if (typeof hasPermission === 'string')
        permissionGranted = authStringsFor(`${moduleName}.${hasPermission}`).some(x => permissions.includes(x));
    if (Array.isArray(hasPermission))
        permissionGranted = hasPermission.reduce((acc, value) => acc && authStringsFor(`${moduleName}.${value}`).some(x => permissions.includes(x)), true);
    return permissionGranted ? obj : null;
};
