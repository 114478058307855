var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeIbgeApi } from '../../../api/ibge.api';
const visibleIfHasFilter = (_ctx, _apiListData, queryFilterValues) => {
    var _a;
    const filterValue = (_a = queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0]) === null || _a === void 0 ? void 0 : _a.value;
    if (filterValue !== null && filterValue !== undefined)
        return true;
    return false;
};
const apiInstanceIbge = makeIbgeApi();
export const abrangenciaUiSchema = () => [{
        type: 'Control',
        scope: '#/properties/abrangencia',
        label: '',
        options: {
            itemLayout: 'SimpleItemLayout',
            detail: {
                type: 'VerticalLayout',
                elements: [{
                        type: 'HorizontalLayout',
                        elements: [{
                                type: 'Select',
                                scope: '#/properties/estadoId',
                                label: 'Estado',
                                options: {
                                    displayProperties: ['nome'],
                                    filter: {
                                        handler(formData, _, api, __, path) {
                                            var _a, _b, _c;
                                            const editalId = formData === null || formData === void 0 ? void 0 : formData.editalId;
                                            if (!editalId)
                                                return Promise.resolve([]);
                                            const index = Number((_c = (_b = (_a = path === null || path === void 0 ? void 0 : path.split) === null || _a === void 0 ? void 0 : _a.call(path, '.')) === null || _b === void 0 ? void 0 : _b.at) === null || _c === void 0 ? void 0 : _c.call(_b, -2));
                                            if (Number.isNaN(index))
                                                return Promise.resolve([]);
                                            const { abrangencia } = formData;
                                            return api.getAll({
                                                editalId: editalId
                                            }).then(response => {
                                                return response === null || response === void 0 ? void 0 : response.filter(x => {
                                                    var _a;
                                                    if (!abrangencia)
                                                        return true;
                                                    return (_a = abrangencia === null || abrangencia === void 0 ? void 0 : abrangencia.filter((_, i) => i !== index)) === null || _a === void 0 ? void 0 : _a.every(y => y.estadoId !== x.id);
                                                });
                                            });
                                        }
                                    }
                                }
                            }, {
                                type: 'Select',
                                scope: '#/properties/abrangenciaMunicipio',
                                options: {
                                    selectMultiple: true,
                                    displayProperties: ['nome'],
                                    filter: {
                                        selfContainedApi: true,
                                        formFields: ['estadoId'],
                                        handler: (_formData, _listData, _api, queryFilterValues) => {
                                            if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                                return Promise.resolve([]);
                                            return apiInstanceIbge.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                                        }
                                    },
                                    handleChange: (values, handler, _formData, _ctx, path) => __awaiter(void 0, void 0, void 0, function* () {
                                        const mappedValues = values === null || values === void 0 ? void 0 : values.map(value => {
                                            var _a;
                                            return {
                                                municipio: (_a = value === null || value === void 0 ? void 0 : value.data) === null || _a === void 0 ? void 0 : _a.nome
                                            };
                                        });
                                        handler(`${path}`, mappedValues);
                                    }),
                                    optionsComparator: (option, item) => { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.data) === null || _a === void 0 ? void 0 : _a.nome) === (item === null || item === void 0 ? void 0 : item.municipio); },
                                    visibleHandler: visibleIfHasFilter
                                }
                            }]
                    }]
            }
        }
    }];
