import { RuleEffect } from '@jsonforms/core';
export const showIfJustificativaGlobal = fieldName => {
    return {
        effect: RuleEffect.HIDE,
        condition: {
            type: 'OR',
            conditions: [{
                    scope: `#/properties/${fieldName}Unsaved/editing`,
                    schema: {},
                    failWhenUndefined: true
                }, {
                    scope: `#/properties/${fieldName}`,
                    schema: {
                        type: 'array',
                        maxItems: 0
                    }
                }, {
                    scope: `#/properties/${fieldName}`,
                    schema: {
                        isUndefined: true
                    }
                }]
        }
    };
};
