export const moduleName = 'AVALIACAO.FORMULARIO_ENQUADRAMENTO.FORMULARIO_ENQUADRAMENTO';
export const moduleLabel = 'Avaliação de Enquadramento';
export const headCells = [{
        id: 'tituloDoProjeto',
        numeric: false,
        disablePadding: false,
        label: 'Título da Proposta'
    }, {
        id: 'criadoPorId',
        numeric: false,
        disablePadding: false,
        label: 'Nome do Coordenador',
        computedRowLabel: row => { var _a; return (_a = row.criadoPor) === null || _a === void 0 ? void 0 : _a.nome; }
    }, {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Data de Criação',
        dateFormat: 'DD/MM/YYYY'
    }];
