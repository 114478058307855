export const getUischemaElements = root => {
    let result = [];
    if (!Array.isArray(root)) {
        if (root === null || root === void 0 ? void 0 : root.elements) {
            if (root.type !== 'Category') {
                result.push(root);
            }
            result = result.concat(getUischemaElements(root.elements));
            return result;
        }
        return [];
    }
    for (const element of root) {
        if ((element === null || element === void 0 ? void 0 : element.type) !== 'Category') {
            result.push(element);
        }
        if (element === null || element === void 0 ? void 0 : element.elements) {
            result = result.concat(getUischemaElements(element.elements));
        }
    }
    return result.filter(x => Boolean(x.scope));
};
