var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RuleEffect } from '@jsonforms/core';
import { Checklist, Eye } from '../../../components/styled-icons';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { TipoResposta, TipoRestricao } from '../../../models/pergunta';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerFormPergunta, stepHandlerViewPergunta } from './pergunta-step-handler';
const iconConfig = [{
        category: 'Pergunta',
        icon: Checklist
    }, {
        category: 'Visualizar Pergunta',
        icon: Eye
    }];
const defaultField = {
    type: 'Control',
    scope: '#/properties/field'
};
const requiredIf = tipoResposta => ctx => {
    var _a;
    return ((_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.tipoResposta) === tipoResposta;
};
const perguntaUiSchema = function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        field: defaultField
    };
    return {
        type: 'Categorization',
        options: {
            variant: 'stepper',
            showNavButtons: true,
            icons: iconConfig
        },
        elements: [{
                type: 'Category',
                label: 'Pergunta',
                options: {
                    stepHandler: {
                        handler: stepHandlerFormPergunta
                    }
                },
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'VerticalLayout',
                                elements: [{
                                        type: 'LongText',
                                        scope: '#/properties/pergunta',
                                        label: 'Texto da Pergunta',
                                        options: {
                                            required: true
                                        }
                                    }, {
                                        type: 'Select',
                                        scope: '#/properties/tipoPergunta',
                                        label: 'Tipo da Pergunta',
                                        options: {
                                            required: true
                                        },
                                        rule: {
                                            effect: RuleEffect.HIDE,
                                            condition: {
                                                schema: {
                                                    const: (options === null || options === void 0 ? void 0 : options.withoutTipoPergunta) ? undefined : ''
                                                }
                                            }
                                        }
                                    }, {
                                        type: 'Select',
                                        scope: '#/properties/tipoResposta',
                                        label: 'Tipo da Resposta',
                                        options: {
                                            required: true
                                        }
                                    }, {
                                        type: 'Control',
                                        scope: '#/properties/obrigatoria',
                                        label: 'Será pergunta obrigatória',
                                        options: {
                                            defaultValue: false
                                        }
                                    }, {
                                        type: 'Select',
                                        scope: '#/properties/descritiva/properties/tipoRestricao',
                                        label: 'Restrição de Limitação',
                                        options: {
                                            required: requiredIf(TipoResposta.descritiva),
                                            handleChange: (item, handler, formData, _ctx, path) => {
                                                var _a, _b;
                                                handler(path, item);
                                                if (item === TipoRestricao.semLimitacao) {
                                                    handler('descritiva.minimo', 0);
                                                    handler('descritiva.maximo', 0);
                                                }
                                                else {
                                                    if (((_a = formData.descritiva) === null || _a === void 0 ? void 0 : _a.minimo) < 1) {
                                                        handler('descritiva.minimo', 1);
                                                    }
                                                    if (((_b = formData.descritiva) === null || _b === void 0 ? void 0 : _b.maximo) < 1) {
                                                        handler('descritiva.maximo', 1);
                                                    }
                                                }
                                            }
                                        },
                                        rule: {
                                            effect: RuleEffect.SHOW,
                                            condition: {
                                                type: 'AND',
                                                conditions: [{
                                                        scope: '#/properties/tipoResposta',
                                                        schema: {
                                                            enum: ['DESCRITIVA']
                                                        }
                                                    }, {}]
                                            }
                                        }
                                    }]
                            }, {
                                type: 'HorizontalLayout',
                                rule: {
                                    condition: {
                                        conditions: [{
                                                type: 'Date',
                                                scope: '#/properties/tipoResposta',
                                                schema: {
                                                    enum: ['DATA', null]
                                                },
                                                options: {
                                                    required: true
                                                }
                                            }]
                                    }
                                }
                            }, {
                                type: 'VerticalLayout',
                                elements: [{
                                        type: 'Control',
                                        scope: '#/properties/multiplaEscolha',
                                        label: 'Adicionar Alternativa',
                                        options: {
                                            itemLayout: 'SimpleItemLayout',
                                            required: ctx => {
                                                var _a, _b;
                                                return ((_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.tipoResposta) === TipoResposta.multiplaEscolha || ((_b = ctx.formData) === null || _b === void 0 ? void 0 : _b.tipoResposta) === TipoResposta.multiplaSelecao;
                                            },
                                            defaultValue: formData => {
                                                var _a, _b;
                                                const nextPosition = ((_b = (_a = formData.multiplaEscolha) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) + 1;
                                                return {
                                                    posicao: nextPosition
                                                };
                                            },
                                            detail: {
                                                type: 'HorizontalLayout',
                                                elements: [{
                                                        type: 'Control',
                                                        scope: '#/properties/alternativa',
                                                        label: 'Texto Alternativa',
                                                        options: {
                                                            handleChange: (value, handler, formData, ctx, path) => __awaiter(this, void 0, void 0, function* () {
                                                                const crrPath = path.split('.').slice(0, -2).join('.');
                                                                const multiplaEscolha = crrPath.split('.').reduce((acc, part) => acc === null || acc === void 0 ? void 0 : acc[part], formData) || formData[path] || [];
                                                                const allHavePosition = Array.isArray(multiplaEscolha) && multiplaEscolha.every(item => item.posicao !== null);
                                                                if (!allHavePosition) {
                                                                    const updatedMultiplaEscolha = multiplaEscolha.map((item, index) => (Object.assign(Object.assign({}, item), { posicao: index + 1 })));
                                                                    handler(crrPath, updatedMultiplaEscolha);
                                                                }
                                                            })
                                                        }
                                                    }]
                                            },
                                            showSortButtons: true
                                        }
                                    }],
                                rule: {
                                    effect: RuleEffect.SHOW,
                                    condition: {
                                        type: 'AND',
                                        conditions: [{
                                                scope: '#/properties/tipoResposta',
                                                schema: {
                                                    enum: ['MULTIPLA_ESCOLHA', 'MULTIPLA_SELECAO', null]
                                                }
                                            }, {}]
                                    }
                                }
                            }, {
                                type: 'VerticalLayout',
                                elements: [{
                                        type: 'Control',
                                        scope: '#/properties/tabela/properties/linha',
                                        label: 'Adicionar linha',
                                        options: {
                                            itemLayout: 'SimpleItemLayout',
                                            required: requiredIf(TipoResposta.tabela),
                                            defaultValue: formData => {
                                                var _a, _b, _c;
                                                const nextPosition = ((_c = (_b = (_a = formData.tabela) === null || _a === void 0 ? void 0 : _a.linha) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) + 1;
                                                return {
                                                    posicao: nextPosition
                                                };
                                            },
                                            detail: {
                                                type: 'HorizontalLayout',
                                                elements: [{
                                                        type: 'Control',
                                                        scope: '#/properties/descricao',
                                                        label: 'Entrada'
                                                    }]
                                            },
                                            showSortButtons: true
                                        }
                                    }],
                                rule: {
                                    effect: RuleEffect.SHOW,
                                    condition: {
                                        type: 'AND',
                                        conditions: [{
                                                scope: '#/properties/tipoResposta',
                                                schema: {
                                                    enum: ['TABELA', null]
                                                }
                                            }, {}]
                                    }
                                }
                            }, {
                                type: 'VerticalLayout',
                                elements: [{
                                        type: 'Control',
                                        scope: '#/properties/tabela/properties/coluna',
                                        label: 'Adicionar coluna (máximo de 6 colunas)',
                                        options: {
                                            required: requiredIf(TipoResposta.tabela),
                                            itemLayout: 'SimpleItemLayout',
                                            defaultValue: formData => {
                                                var _a, _b, _c;
                                                const nextPosition = ((_c = (_b = (_a = formData.tabela) === null || _a === void 0 ? void 0 : _a.coluna) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) + 1;
                                                return {
                                                    posicao: nextPosition
                                                };
                                            },
                                            beforeCreate: formData => {
                                                var _a, _b, _c;
                                                const isValid = ((_c = (_b = (_a = formData.tabela) === null || _a === void 0 ? void 0 : _a.coluna) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) < 6;
                                                return {
                                                    shouldCreate: isValid,
                                                    errorMessage: isValid ? '' : 'Máximo de 6 colunas'
                                                };
                                            },
                                            detail: {
                                                type: 'HorizontalLayout',
                                                elements: [{
                                                        type: 'Control',
                                                        scope: '#/properties/descricao',
                                                        label: 'Entrada'
                                                    }]
                                            },
                                            showSortButtons: true
                                        }
                                    }],
                                rule: {
                                    effect: RuleEffect.SHOW,
                                    condition: {
                                        type: 'AND',
                                        conditions: [{
                                                scope: '#/properties/tipoResposta',
                                                schema: {
                                                    enum: ['TABELA', null]
                                                }
                                            }, {}]
                                    }
                                }
                            }, {
                                type: 'HorizontalLayout',
                                elements: [{
                                        type: 'Control',
                                        scope: '#/properties/descritiva/properties/minimo',
                                        label: 'Mínimo'
                                    }, {
                                        type: 'Control',
                                        scope: '#/properties/descritiva/properties/maximo',
                                        label: 'Máximo'
                                    }],
                                rule: {
                                    effect: RuleEffect.SHOW,
                                    condition: {
                                        type: 'AND',
                                        conditions: [{
                                                scope: '#/properties/descritiva/properties/tipoRestricao',
                                                schema: {
                                                    enum: ['LIMITAR_CARACTERES', 'LIMITAR_PALAVRAS']
                                                }
                                            }, {
                                                scope: '#/properties/tipoResposta',
                                                schema: {
                                                    enum: ['DESCRITIVA']
                                                }
                                            }, {}]
                                    }
                                }
                            }]
                    }]
            }, {
                type: 'Category',
                label: 'Pré-visualização da Pergunta',
                options: {
                    stepHandler: {
                        handler: stepHandlerViewPergunta,
                        callback: ctx => {
                            const { showSuccess } = ctx;
                            const result = createResource(ctx, '/pergunta');
                            if (result)
                                showSuccess('Pergunta salva com sucesso!');
                            return true;
                        }
                    },
                    onFinish: _ref => {
                        let { ctx, navigate } = _ref;
                        const { updateCrudStates, setFormData } = ctx;
                        setFormData({});
                        updateCrudStates(CrudStatesOptions.LIST);
                        navigate('/pergunta');
                    }
                },
                elements: [options.field]
            }]
    };
};
export default perguntaUiSchema;
