var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeUsuarioApi } from '../../editar-perfil/usuario-api';
export const stepHandlerDadosCoordenador = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    const { crudStates } = ctx;
    const usuarioResult = crudStates.edit || crudStates.add ? yield atualizarUsuario(fieldsConfig, ctx) : true;
    if (callback && usuarioResult) {
        return callback(ctx, fieldsConfig);
    }
    return usuarioResult;
});
const atualizarUsuario = (fieldsConfig, ctx) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    const { formData, showError, showSuccess } = ctx;
    const usuarioApi = makeUsuarioApi();
    let valid = false;
    try {
        const usuario = formData.criadoPor;
        const toUpdate = fieldsConfig ? Object.fromEntries(Object.entries(usuario).filter(_ref => {
            let [path] = _ref;
            const field = path.split('.').at(0);
            return fieldsConfig.paths.some(configPath => configPath === null || configPath === void 0 ? void 0 : configPath.endsWith(field));
        })) : usuario;
        const updatedUsuario = yield usuarioApi.atualizarPerfil(toUpdate);
        formData.criadoPor = updatedUsuario.data;
        showSuccess('Dados do usuário atualizados com sucesso.');
        valid = true;
    }
    catch (error) {
        showError(((_d = (_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d[0]) || 'Erro ao atualizar dados do usuário.');
        console.error(error);
    }
    return valid;
});
