export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.FORMULARIO_CONSULTORIA';
export const displayProperties = ['titulo'];
export const moduleLabel = 'Formulário de Consultoria - Proposta';
export const headCells = [{
        id: 'tituloDoProjeto',
        numeric: false,
        disablePadding: false,
        label: 'Título da Proposta'
    }, {
        id: 'criadoPorId',
        numeric: true,
        disablePadding: false,
        label: 'Nome do Coordenador',
        resource: {
            name: 'criadoPor',
            target: 'nome'
        }
    }, {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Data de Criação',
        dateFormat: 'DD/MM/YYYY'
    }];
