var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RuleEffect } from '@jsonforms/core';
import { makeApi } from '../../../api/generic-api';
import { makeIbgeApi } from '../../../api/ibge.api';
import { hideIfBrasil, showIfBrasil } from '../../../jsonforms/uischema';
const getCodigoPaisInstituicao = (instituicao, updater) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const instSchema = yield makeApi('instituicao').getSchema();
    const paisIdSchema = (_a = instSchema === null || instSchema === void 0 ? void 0 : instSchema.properties) === null || _a === void 0 ? void 0 : _a.paisId;
    const country = (_b = instituicao === null || instituicao === void 0 ? void 0 : instituicao.pais) !== null && _b !== void 0 ? _b : {};
    let foreignEnum;
    if ('codigo' in country && (foreignEnum = paisIdSchema === null || paisIdSchema === void 0 ? void 0 : paisIdSchema.foreignEnum)) {
        const [codeKey] = (_c = Object.entries(foreignEnum).find(_ref => {
            let [, value] = _ref;
            return value === country.codigo;
        })) !== null && _c !== void 0 ? _c : [];
        setTimeout(() => updater('paisId-codigo', codeKey), 200);
    }
});
const apiInstanceIbge = makeIbgeApi();
const requiredIfBrasil = ctx => {
    const { formData } = ctx;
    return formData['paisId-codigo'] === 'brasil';
};
const requiredIfNotBrasil = ctx => {
    const { formData } = ctx;
    return formData['paisId-codigo'] !== 'brasil';
};
const requiredIfNotVinculo = ctx => {
    const { formData } = ctx;
    return formData['vincularExistente'] ? !formData['vincularExistente'] : true;
};
const apiInstanceUnidade = makeApi('/unidade');
const showIfVincularExistente = {
    effect: RuleEffect.SHOW,
    condition: {
        scope: '#/properties/vincularExistente',
        schema: {
            const: true
        }
    }
};
const hideIfVincularExistente = {
    effect: RuleEffect.HIDE,
    condition: {
        scope: '#/properties/vincularExistente',
        schema: {
            const: true
        }
    }
};
const hideIfAcknowledgedAt = {
    effect: RuleEffect.HIDE,
    condition: {
        scope: '#/properties/acknowledgedAt',
        schema: {
            not: {
                const: null
            }
        }
    }
};
const unidadeUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'HorizontalLayout',
            rule: hideIfAcknowledgedAt,
            elements: [{
                    type: 'Boolean',
                    scope: '#/properties/vincularExistente',
                    label: 'Vincular à uma unidade já reconhecida',
                    options: {
                        handleChange: (value, handler) => {
                            if (!value) {
                                handler('unidadeId', null);
                                handler('endereco', null);
                            }
                            else {
                                handler('endereco', {
                                    cep: '00000-000',
                                    estado: 'estado',
                                    municipio: 'município',
                                    logradouro: 'logradouro'
                                });
                            }
                            handler('vincularExistente', value);
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/unidadeId',
                    label: 'Unidade para substituição',
                    options: {
                        apiInstance: apiInstanceUnidade,
                        displayProperties: ['nome'],
                        filter: {
                            formFields: ['nome'],
                            handler: (formData, listData, api) => {
                                return api.getAll({
                                    withAcknowledgedAt: true,
                                    instituicaoId: formData.instituicaoId
                                });
                            }
                        },
                        required: !requiredIfNotVinculo
                    }
                }],
            rule: showIfVincularExistente
        }, {
            type: 'VerticalLayout',
            rule: hideIfVincularExistente,
            elements: [{
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Select',
                            scope: '#/properties/instituicaoId',
                            label: 'Instituição',
                            options: {
                                handleChange: getCodigoPaisInstituicao,
                                multiline: true,
                                filter: {
                                    formFields: ['nome'],
                                    handler: (formData, listData, api) => {
                                        return api.getAll({
                                            withAcknowledgedAt: true
                                        });
                                    }
                                }
                            }
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/nome',
                                    label: 'Nome',
                                    options: {
                                        required: requiredIfNotVinculo,
                                        multiline: true
                                    }
                                }]
                        }, {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/sigla',
                                    label: 'Sigla',
                                    options: {
                                        required: requiredIfNotVinculo
                                    }
                                }]
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/cnpj',
                            label: 'CNPJ',
                            rule: showIfBrasil(),
                            options: {
                                mask: '99.999.999/9999-99',
                                required: requiredIfBrasil && requiredIfNotVinculo
                            }
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Cep',
                                    scope: '#/properties/endereco/properties/cep',
                                    label: 'CEP',
                                    rule: showIfBrasil(),
                                    options: {
                                        mask: '99999-999',
                                        required: requiredIfBrasil && requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Text',
                                    scope: '#/properties/endereco/properties/cep',
                                    label: 'ZipCode',
                                    rule: hideIfBrasil(),
                                    options: {
                                        maxLength: 9,
                                        required: requiredIfNotBrasil && requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Select',
                                    scope: '#/properties/endereco/properties/municipio',
                                    label: 'Município',
                                    options: {
                                        required: requiredIfNotVinculo,
                                        targetFieldValue: 'nome',
                                        optionsComparator: (option, item) => {
                                            return (option === null || option === void 0 ? void 0 : option.nome) === item;
                                        },
                                        displayProperties: ['nome'],
                                        filter: {
                                            acceptUnmatched: true,
                                            preventCleaning: true,
                                            selfContainedApi: true,
                                            formFields: ['estado', 'cep'],
                                            handler: (_formData, _listData, _api, queryFilterValues) => {
                                                if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                                    return Promise.resolve([]);
                                                return apiInstanceIbge.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/bairro',
                                    label: 'Bairro',
                                    rule: hideIfBrasil(),
                                    options: {
                                        required: requiredIfNotBrasil && requiredIfNotVinculo
                                    }
                                }]
                        }, {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Select',
                                    scope: '#/properties/endereco/properties/estado',
                                    label: 'Estado/Região',
                                    options: {
                                        handleChange: (item, handler, formData, ctx, path) => {
                                            handler(path, item);
                                            handler(path.replace('estado', 'municipio'), undefined);
                                        },
                                        required: requiredIfNotVinculo,
                                        targetFieldValue: 'sigla',
                                        optionsComparator: (option, item) => {
                                            return (option === null || option === void 0 ? void 0 : option.sigla) === item;
                                        },
                                        displayProperties: ['nome'],
                                        filter: {
                                            acceptUnmatched: true,
                                            preventCleaning: true,
                                            selfContainedApi: true,
                                            formFields: ['pais', 'cep'],
                                            handler: () => {
                                                return apiInstanceIbge.buscarEstados();
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/logradouro',
                                    options: {
                                        required: requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/complemento',
                                    rule: hideIfBrasil()
                                }]
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/bairro',
                                    label: 'Bairro',
                                    rule: showIfBrasil(),
                                    options: {
                                        required: requiredIfNotVinculo
                                    }
                                }]
                        }, {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'HorizontalLayout',
                                    elements: [{
                                            type: 'VerticalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/endereco/properties/numero',
                                                    label: 'Número',
                                                    rule: showIfBrasil(),
                                                    options: {
                                                        required: requiredIfBrasil && requiredIfNotVinculo
                                                    }
                                                }]
                                        }, {
                                            type: 'VerticalLayout',
                                            elements: [{
                                                    type: 'Control',
                                                    scope: '#/properties/endereco/properties/complemento',
                                                    rule: showIfBrasil()
                                                }]
                                        }]
                                }]
                        }]
                }]
        }]
};
export default unidadeUiSchema;
