import cloneDeep from 'lodash/cloneDeep';
class ErrorsManager {
    constructor(ctx) {
        // Atualiza o additionalErrors com os errorsCustom que estão com erro
        this.addCustomErrors = () => {
            var _a, _b, _c, _d, _e;
            const { errorsCustom, formData } = this.ctx;
            if (!errorsCustom || errorsCustom.length <= 0) {
                return errorsCustom;
            }
            const errorsToAdd = [];
            for (const customError of errorsCustom) {
                let valueForValidate = formData === null || formData === void 0 ? void 0 : formData[customError === null || customError === void 0 ? void 0 : customError.field];
                if ((_b = (_a = customError === null || customError === void 0 ? void 0 : customError.field) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, '.')) {
                    const tokens = (_d = (_c = customError === null || customError === void 0 ? void 0 : customError.field) === null || _c === void 0 ? void 0 : _c.split) === null || _d === void 0 ? void 0 : _d.call(_c, '.');
                    let valueNested = formData;
                    for (const token of tokens) {
                        valueNested = valueNested === null || valueNested === void 0 ? void 0 : valueNested[token];
                    }
                    valueForValidate = valueNested;
                }
                const hasError = (_e = customError === null || customError === void 0 ? void 0 : customError.hasError) === null || _e === void 0 ? void 0 : _e.call(customError, valueForValidate, formData);
                if (hasError) {
                    errorsToAdd.push(customError === null || customError === void 0 ? void 0 : customError.error);
                }
            }
            if (errorsToAdd.length) {
                this.insertAdditionalErrors(errorsToAdd);
            }
            return errorsToAdd;
        };
        this.insertAdditionalErrors = errorsToAdd => {
            const { additionalErrors, setAdditionalErrors } = this.ctx;
            const errorsToInsert = cloneDeep(additionalErrors);
            for (const errorToAdd of errorsToAdd) {
                if (!this.hasInsertedError(additionalErrors, errorToAdd)) {
                    errorsToInsert.push(errorToAdd);
                }
            }
            setAdditionalErrors(errorsToInsert);
        };
        this.removeAdditionalErrors = errorsToRemove => {
            const { additionalErrors, setAdditionalErrors } = this.ctx;
            let additionalErrorsCopy = cloneDeep(additionalErrors);
            for (const errorToRemove of errorsToRemove) {
                additionalErrorsCopy = additionalErrorsCopy.filter(error => (error === null || error === void 0 ? void 0 : error.keyword) !== (errorToRemove === null || errorToRemove === void 0 ? void 0 : errorToRemove.keyword) && (error === null || error === void 0 ? void 0 : error.propertyName) !== (errorToRemove === null || errorToRemove === void 0 ? void 0 : errorToRemove.propertyName));
            }
            setAdditionalErrors(additionalErrorsCopy);
        };
        this.hasInsertedError = (errors, errorToInsert) => {
            var _a;
            return (_a = errors === null || errors === void 0 ? void 0 : errors.find) === null || _a === void 0 ? void 0 : _a.call(errors, error => { var _a, _b, _c; return error.keyword === (errorToInsert === null || errorToInsert === void 0 ? void 0 : errorToInsert.keyword) && ((error === null || error === void 0 ? void 0 : error.field) === (errorToInsert === null || errorToInsert === void 0 ? void 0 : errorToInsert.field) || (error === null || error === void 0 ? void 0 : error.propertyName) && (error === null || error === void 0 ? void 0 : error.propertyName) === (errorToInsert === null || errorToInsert === void 0 ? void 0 : errorToInsert.propertyName) || ((_a = error === null || error === void 0 ? void 0 : error.params) === null || _a === void 0 ? void 0 : _a.missingProperty) && ((_b = error === null || error === void 0 ? void 0 : error.params) === null || _b === void 0 ? void 0 : _b.missingProperty) === ((_c = errorToInsert === null || errorToInsert === void 0 ? void 0 : errorToInsert.params) === null || _c === void 0 ? void 0 : _c.missingProperty) || (error === null || error === void 0 ? void 0 : error.instancePath) && (error === null || error === void 0 ? void 0 : error.instancePath) === (errorToInsert === null || errorToInsert === void 0 ? void 0 : errorToInsert.instancePath)); });
        };
        this.removeCustomErrors = (() => {
            var _this = this;
            return function () {
                var _a, _b, _c, _d, _e;
                let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
                const { formData, errorsCustom } = _this.ctx;
                const dataToValidate = data || formData;
                // Remove os custom errors
                if (errorsCustom && errorsCustom.length > 0) {
                    const errorsToRemove = [];
                    for (const customError of errorsCustom) {
                        let valueForValidate = dataToValidate === null || dataToValidate === void 0 ? void 0 : dataToValidate[customError === null || customError === void 0 ? void 0 : customError.field];
                        if ((_b = (_a = customError === null || customError === void 0 ? void 0 : customError.field) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, '.')) {
                            const tokens = (_d = (_c = customError === null || customError === void 0 ? void 0 : customError.field) === null || _c === void 0 ? void 0 : _c.split) === null || _d === void 0 ? void 0 : _d.call(_c, '.');
                            let valueNested = dataToValidate;
                            for (const token of tokens) {
                                valueNested = valueNested === null || valueNested === void 0 ? void 0 : valueNested[token];
                            }
                            valueForValidate = valueNested;
                        }
                        const hasError = (_e = customError === null || customError === void 0 ? void 0 : customError.hasError) === null || _e === void 0 ? void 0 : _e.call(customError, valueForValidate, dataToValidate);
                        if (!hasError) {
                            errorsToRemove.push(customError === null || customError === void 0 ? void 0 : customError.error);
                        }
                    }
                    if (errorsToRemove.length)
                        _this.removeAdditionalErrors(errorsToRemove);
                }
            };
        })();
        this.clearErrors = () => {
            const { setErrorsJsonForm, setAdditionalErrors, setValidationMode } = this.ctx;
            setErrorsJsonForm([]);
            setAdditionalErrors([]);
            setValidationMode('ValidateAndHide');
        };
        this.ctx = ctx;
    }
}
export const makeErrorsManager = ctx => {
    return new ErrorsManager(ctx);
};
