import { ConstNode } from './const-node';
import { makeOperator } from './operator-node';
import { VariableNode } from './variable-node';
const lexical = /(?<num>\d+)|(?<var>[a-zA-Z][\w.]*)|(?<op>[+\-*/])|(?<open>\()|(?<close>\))|(?<trash>[\W_])/gis;
const parseGroup = current => Object.entries(current.value.groups).find(_ref => {
    let [, value] = _ref;
    return Boolean(value);
});
const parseOperand = function (parser) {
    let allowSign = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    const current = parser.next();
    if (current.done)
        throw new Error('Unexpected end of expression, operand expected');
    const [group, value] = parseGroup(current);
    switch (group) {
        case 'num':
            return new ConstNode(Number(value));
        case 'var':
            return new VariableNode(value);
        case 'open':
            return parseTree(parser, false);
        case 'op':
            {
                if (value !== '-' || !allowSign)
                    throw new Error(`Unexpected operator ${value}, expected + or -`);
                return makeOperator('*', new ConstNode(-1), parseOperand(parser, false));
            }
        default:
            throw new Error(`Unexpected character ${value}`);
    }
};
const operatorByPriority = function (parser, leftChild, operation) {
    let closed = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    switch (operation) {
        case '+':
            return makeOperator(operation, leftChild, parseTree(parser, closed));
        case '-':
            const newOperator = makeOperator('*', new ConstNode(-1), parseOperand(parser));
            return makeOperator('+', leftChild, newOperator);
        case '*':
        case '/':
            return parseOperator(parser, makeOperator(operation, leftChild, parseOperand(parser)), closed);
        default:
            throw new Error(`Unexpected character ${operation}`);
    }
};
const parseOperator = function (parser, leftChild) {
    let closed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    const current = parser.next();
    if (current.done) {
        if (closed)
            return leftChild;
        throw new Error('Unexpected end of expression, expected )');
    }
    const [group, value] = Object.entries(current.value.groups).find(_ref2 => {
        let [, value] = _ref2;
        return Boolean(value);
    });
    switch (group) {
        case 'op':
            return operatorByPriority(parser, leftChild, value, closed);
        case 'num':
        case 'var':
        case 'open':
            throw new Error(`Unexpected value ${value}, expected operator(+, -, *, /)`);
        case 'close':
            return leftChild;
        default:
            throw new Error(`Unexpected character ${value}`);
    }
};
const parseTree = function (parser) {
    let closed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    const current = parser.next();
    const [group, value] = parseGroup(current);
    let node;
    switch (group) {
        case 'op':
            if (value !== '-')
                throw new Error(`Unexpected operator ${value}, expected + or -`);
            const nextValue = parseOperand(parser);
            node = parseOperator(parser, makeOperator(value, new ConstNode(0), nextValue), closed);
            break;
        case 'num':
            node = parseOperator(parser, new ConstNode(Number(value)), closed);
            break;
        case 'var':
            node = parseOperator(parser, new VariableNode(value), closed);
            break;
        case 'open':
            node = parseOperator(parser, parseTree(parser, false), closed);
            break;
        default:
            throw new Error(`Unexpected character ${value}`);
    }
    return node;
};
export const parseMathTree = expression => {
    const parser = expression.matchAll(lexical);
    return parseTree(parser);
};
