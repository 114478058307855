const updateNovoValor = (tipo, valorAtual, valorAjuste, handler) => {
    let novoValor = 0;
    if (tipo === 'CORTE') {
        novoValor = valorAtual - (valorAjuste !== null && valorAjuste !== void 0 ? valorAjuste : 0);
    }
    else if (tipo === 'ADITIVO') {
        novoValor = valorAtual + (valorAjuste !== null && valorAjuste !== void 0 ? valorAjuste : 0);
    }
    else {
        novoValor = valorAtual;
    }
    handler('novoValor', novoValor);
};
const historicoAjusteTesouroUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    scope: '#/properties/tesouro/properties/valorAtual',
                    label: 'Valor Atual',
                    options: {
                        disabled: true
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/tipo',
                    label: 'Tipo',
                    options: {
                        required: true,
                        handleChange: (tipo, handler, formData) => {
                            updateNovoValor(tipo, formData.tesouro.valorAtual, formData.valorAjuste, handler);
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    scope: '#/properties/valorAjuste',
                    label: 'Valor do Ajuste',
                    options: {
                        required: true,
                        handleChange: (valueAjuste, handler, formData) => {
                            if (!valueAjuste)
                                return;
                            updateNovoValor(formData.tipo, formData.tesouro.valorAtual, valueAjuste, handler);
                        }
                    }
                }, {
                    type: 'MonetaryInput',
                    scope: '#/properties/novoValor',
                    label: 'Novo Valor',
                    options: {
                        disabled: true
                    }
                }]
        }, {
            type: 'LongText',
            scope: '#/properties/justificativa',
            label: 'Justificativa'
        }]
};
export default historicoAjusteTesouroUiSchema;
