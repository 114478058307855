var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { scopeToPath } from '../../../helpers/scope-to-path';
import { makeDadosFundacaoApi } from './dados-fundacao-api';
export const dadosFundacaoStepHandlerSave = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const { errorsJsonForms, additionalErrors, setValidationMode, crudStates } = ctx;
    const { paths, instancePaths, requiredScopes } = fieldsConfig;
    const requiredFields = (_a = requiredScopes === null || requiredScopes === void 0 ? void 0 : requiredScopes.map) === null || _a === void 0 ? void 0 : _a.call(requiredScopes, scope => scopeToPath(scope));
    const jsonFormErrors = errorsJsonForms === null || errorsJsonForms === void 0 ? void 0 : errorsJsonForms.map(error => {
        var _a, _b, _c;
        if ((_a = requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.includes) === null || _a === void 0 ? void 0 : _a.call(requiredFields, (_b = error === null || error === void 0 ? void 0 : error.params) === null || _b === void 0 ? void 0 : _b.missingProperty)) {
            return error;
        }
        if ((_c = instancePaths === null || instancePaths === void 0 ? void 0 : instancePaths.includes) === null || _c === void 0 ? void 0 : _c.call(instancePaths, error === null || error === void 0 ? void 0 : error.instancePath)) {
            return error;
        }
        return undefined;
    }).filter(error => error !== undefined);
    const hasCustomError = !!((_b = additionalErrors === null || additionalErrors === void 0 ? void 0 : additionalErrors.find) === null || _b === void 0 ? void 0 : _b.call(additionalErrors, error => { var _a; return (_a = paths === null || paths === void 0 ? void 0 : paths.includes) === null || _a === void 0 ? void 0 : _a.call(paths, error === null || error === void 0 ? void 0 : error.propertyName); }));
    if (jsonFormErrors.length || hasCustomError) {
        setValidationMode('ValidateAndShow');
        return false;
    }
    try {
        if (crudStates.edit) {
            const api = makeDadosFundacaoApi(url);
            return (_c = api === null || api === void 0 ? void 0 : api.put) === null || _c === void 0 ? void 0 : _c.call(api, 0, data).then(res => {
                if (res.status === 200) {
                    return true;
                }
                else {
                    console.error(res);
                    return false;
                }
            });
        }
    }
    catch (error) {
        console.error(error);
    }
    if (callback) {
        return !!(yield (callback === null || callback === void 0 ? void 0 : callback(ctx)));
    }
    return true;
});
