import { RuleEffect } from '@jsonforms/core';
import { formatCurrency } from '../../helpers/format-currency';
import { createResource } from '../../utils/create-resource';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { getOrcamentoConsolidadoTable } from './util/calcular-rubricas';
import { getRubricasTrimestralTable } from './util/calcular-rubricas-trimestral';
import { rubricaDiariaUiSchema } from '../../jsonforms/uischema/rubrica/rubricaDiaria.uischema';
import { rubricaMaterialPermanenteUischema } from '../../jsonforms/uischema/rubrica/rubricaMaterialPermanente.uischema';
import { rubricaPassagemUischema } from '../../jsonforms/uischema/rubrica/rubricaPassagem.uischema';
import { rubricaMaterialConsumoUiSchema } from '../../jsonforms/uischema/rubrica/rubricaMaterialConsumo.uischema';
import { rubricaHospedagemAlimentacaoUiSchema } from '../../jsonforms/uischema/rubrica/rubricaHospedagemAlimentacao.uischema';
import { rubricaServicoTerceiroUiSchema } from '../../jsonforms/uischema/rubrica/rubricaServicoTerceiro.uischema';
import { rubricaPessoalUiSchema } from '../../jsonforms/uischema/rubrica/rubricaPessoal.uischema';
import { rubricaEncargoUischema } from '../../jsonforms/uischema/rubrica/rubricaEncargo.uischema';
import { rubricaBolsaUischema } from '../../jsonforms/uischema/rubrica/rubricaBolsa.uischema';
const formatLabelFaixaFinanciamento = item => {
    const formattedValorMinimo = formatCurrency(item.valorMinimo);
    const formattedValorMaximo = formatCurrency(item.valorMaximo);
    return `${item.nome} (${formattedValorMinimo} - ${formattedValorMaximo})`;
};
const rubricasSchemas = [rubricaDiariaUiSchema, rubricaMaterialConsumoUiSchema, rubricaMaterialPermanenteUischema, rubricaPassagemUischema, rubricaHospedagemAlimentacaoUiSchema, rubricaServicoTerceiroUiSchema, rubricaPessoalUiSchema, rubricaEncargoUischema, rubricaBolsaUischema];
export const getOrcamentoPropostaUiSchema = function () {
    let { route, customPaths, scopePrefix = '', formPrefix = '', revisaoOrcamento, customHeader, customFooter, customActions } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
        type: 'Category',
        label: 'Orçamento',
        elements: [{
                type: 'Category',
                label: 'Faixa de Financiamento',
                options: {
                    stepHandler: {
                        handler: stepHandlerErrors,
                        callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                    }
                },
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Select',
                                scope: '#/properties/faixaFinanciamentoId',
                                label: '',
                                options: {
                                    required: true,
                                    displayProperties: ['nome', 'valorMinimo', 'valorMaximo'],
                                    filter: {
                                        handler: (formData, listData, api) => {
                                            if (!formData.editalId)
                                                return Promise.resolve(listData);
                                            return api.getAll({
                                                editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                                            });
                                        }
                                    },
                                    formatOptionLabel: formatLabelFaixaFinanciamento
                                }
                            }]
                    }],
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/edital/properties/faixaFinanciamento',
                        schema: {
                            type: 'array',
                            minItems: 1
                        }
                    }
                }
            }, ...rubricasSchemas.map(schemaFn => schemaFn({
                route,
                customPaths,
                scopePrefix,
                revisaoOrcamento,
                customHeader,
                customFooter,
                customActions
            })), {
                type: 'Category',
                label: 'Consolidação',
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/edital',
                        schema: {
                            properties: {
                                editalRubrica: {
                                    type: 'array',
                                    minItems: 1
                                }
                            }
                        }
                    }
                },
                options: {
                    stepHandler: {
                        handler: stepHandlerErrors
                    }
                },
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'MoneyExchangeRate',
                                scope: '#/properties/cotacaoMoedaEstrangeira',
                                label: 'Cotação Moeda Estrangeira',
                                options: {
                                    isPositiveNumber: true
                                }
                            }]
                    }, {
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'CustomTableView',
                                scope: '#/properties/orcamentoConsolidado',
                                options: {
                                    tableOf: formData => {
                                        return getOrcamentoConsolidadoTable(formData, formPrefix);
                                    }
                                }
                            }]
                    }]
            }, {
                type: 'Category',
                label: 'Solicitado à Fundação',
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/edital',
                        schema: {
                            properties: {
                                editalRubrica: {
                                    type: 'array',
                                    minItems: 1
                                }
                            }
                        }
                    }
                },
                options: {
                    stepHandler: {
                        handler: stepHandlerErrors
                    }
                },
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'CustomMultiTableView',
                                scope: '#/properties/solicitadoFundacao',
                                options: {
                                    tablesOf: formData => {
                                        return getRubricasTrimestralTable(formData, formPrefix);
                                    }
                                }
                            }]
                    }]
            }]
    };
};
