import { TipoRecursoLinhaDeAcao, tipoRecursoLinhaDeAcaoI18N } from '../../../models/tipo-recurso-linha-de-acao';
export const moduleName = 'ORCAMENTO.LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO.EDICAO_RECURSO_LINHA_DE_ACAO.EDICAO_RECURSO_LINHA_DE_ACAO';
export const headCells = [{
        id: 'edicaoLinhaDeAcaoId',
        numeric: false,
        disablePadding: false,
        label: 'Edição da Linha de Ação',
        resource: {
            name: 'edicaoLinhaDeAcao',
            target: 'ano'
        }
    }, {
        id: 'tipoRecursoLinhaDeAcao',
        numeric: false,
        disablePadding: false,
        label: 'Tipo de Recurso',
        resource: {
            target: row => {
                if (row === null || row === void 0 ? void 0 : row.instrumentoJuridicoId) {
                    return tipoRecursoLinhaDeAcaoI18N[TipoRecursoLinhaDeAcao.instrumentoJuridico];
                }
                else if (row === null || row === void 0 ? void 0 : row.tesouroId) {
                    return tipoRecursoLinhaDeAcaoI18N[TipoRecursoLinhaDeAcao.tesouro];
                }
            }
        }
    }, {
        id: 'fonte',
        numeric: false,
        disablePadding: false,
        label: 'Fonte',
        resource: {
            target: data => { var _a, _b, _c; return (_b = (_a = data.tesouro) === null || _a === void 0 ? void 0 : _a.ano) !== null && _b !== void 0 ? _b : (_c = data.instrumentoJuridico) === null || _c === void 0 ? void 0 : _c.nome; }
        }
    }, {
        id: 'valor',
        numeric: false,
        disablePadding: false,
        label: 'Valor',
        mask: 'money'
    }];
