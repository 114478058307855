const showIfForeignCurrency = {
    effect: 'SHOW',
    condition: {
        scope: '#/properties/temMoedaEstrangeira',
        schema: {
            const: true
        }
    }
};
const diariasUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'LongText',
            scope: '#/properties/descricao',
            label: 'Descrição',
            options: {
                required: true
            }
        }, {
            type: 'Select',
            scope: '#/properties/nivelAcademicoId'
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    scope: '#/properties/valorDiaria',
                    label: 'Valor Diária',
                    options: {
                        required: true,
                        isPositiveNumber: true
                    }
                }, {
                    type: 'Boolean',
                    label: 'Moeda estrangeira',
                    scope: '#/properties/temMoedaEstrangeira',
                    options: {
                        handleChange: (temMoedaEstrangeira, handler, formData, ctx, path) => {
                            handler('moedaEstrangeiraId', null);
                        }
                    }
                }, {
                    type: 'Select',
                    label: 'Moeda estrangeira',
                    scope: '#/properties/moedaEstrangeiraId',
                    rule: showIfForeignCurrency,
                    options: {
                        required: ctx => { var _a; return !!((_a = ctx === null || ctx === void 0 ? void 0 : ctx.formData) === null || _a === void 0 ? void 0 : _a.temMoedaEstrangeira); }
                    }
                }]
        }]
};
export default diariasUiSchema;
