import { BarChart } from '@mui/icons-material';
import { ViewIcon } from '../../../components/crud/style/actions-table-cell.styled';
import { stepHandlerBack } from '../../../utils/step-handler-back';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
const iconConfig = [{
        category: 'Indicadores de Produção',
        icon: BarChart
    }, {
        category: 'Pré-Visualização',
        icon: ViewIcon
    }];
const indicadorProducaoUiSchema = {
    type: 'Categorization',
    elements: [{
            type: 'Category',
            label: 'Indicadores de Produção',
            options: {
                stepHandler: {
                    handler: stepHandlerErrors
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'Control',
                            label: 'Nome',
                            scope: '#/properties/nome',
                            options: {
                                required: true
                            }
                        }, {
                            type: 'Control',
                            label: 'Nacional e Internacional',
                            scope: '#/properties/nacionalInternacional'
                        }, {
                            type: 'Control',
                            scope: '#/properties/producao',
                            label: 'Produção',
                            options: {
                                itemLayout: 'SimpleItemLayout',
                                required: false,
                                showSortButtons: true,
                                detail: {
                                    type: 'HorizontalLayout',
                                    elements: [{
                                            type: 'Control',
                                            scope: '#/properties/texto',
                                            label: 'Texto da Produção'
                                        }]
                                }
                            }
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Pré-Visualização',
            options: {
                stepHandler: {
                    handler: stepHandlerBack
                }
            },
            elements: [{
                    type: 'IndicadorProducaoProposta',
                    scope: '#/properties/indicadorProducao',
                    options: {
                        indicadorProducaoSelector: formData => [formData]
                    },
                    label: 'Indicadores de Produção'
                }]
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        icons: iconConfig
    }
};
export default indicadorProducaoUiSchema;
