var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { kebabCase } from 'change-case';
import fileClient from '../config/axios-file-config';
export class FileApi {
    addFile(file, setFileProgress, baseUrl, path, formDataId, category, options, additionalFileApiData) {
        return __awaiter(this, void 0, void 0, function* () {
            const localFormData = new FormData();
            localFormData.append('file', file);
            if (additionalFileApiData) {
                Object.entries(additionalFileApiData).forEach(_ref => {
                    let [key, value] = _ref;
                    localFormData.append(key, value);
                });
            }
            const urlParams = [baseUrl, formDataId, kebabCase(path), category];
            const url = urlParams.filter(x => x).join('/');
            const urlBuild = Object.entries(options).reduce((acc, _ref2, i) => {
                var _a;
                let [chave, valor] = _ref2;
                return `${acc}${i === 0 ? '?' : '&'}${chave}=${(_a = String(valor)) !== null && _a !== void 0 ? _a : ''}`;
            }, url);
            const response = yield fileClient.post(urlBuild, localFormData, {
                onUploadProgress: ProgressEvent => {
                    const percentCompleted = Math.round(ProgressEvent.loaded * 100 / ProgressEvent.total);
                    setFileProgress(prevProgress => (Object.assign(Object.assign({}, prevProgress), { [file.name]: percentCompleted })));
                }
            });
            return response.data;
        });
    }
    removeFile(baseUrl, path, fileId, formDataId) {
        return __awaiter(this, void 0, void 0, function* () {
            const urlParams = [baseUrl, formDataId, kebabCase(path), fileId];
            const url = urlParams.filter(x => x).join('/');
            yield fileClient.delete(url);
        });
    }
    getFile(baseUrl, path, formDataId) {
        return __awaiter(this, void 0, void 0, function* () {
            const urlParams = [baseUrl, formDataId, kebabCase(path)];
            const url = urlParams.filter(x => x).join('/');
            const response = yield fileClient.get(url);
            return response;
        });
    }
    downloadFile(baseUrl, path, anexoId, categoria, formDataId) {
        return __awaiter(this, void 0, void 0, function* () {
            const urlParams = [baseUrl, formDataId, kebabCase(path), categoria ? `${categoria}?anexoId=${anexoId}` : anexoId];
            const url = urlParams.filter(x => x).join('/');
            const response = yield fileClient.get(url, {
                responseType: 'blob'
            });
            return response;
        });
    }
    viewFile(baseUrl, path, anexoId, categoria, formDataId) {
        return __awaiter(this, void 0, void 0, function* () {
            const urlParams = [baseUrl, formDataId, kebabCase(path), `${categoria}?anexoId=${anexoId}&preview=true`];
            const url = urlParams.filter(x => x).join('/');
            const response = yield fileClient.get(url, {
                responseType: 'blob'
            });
            return response.data;
        });
    }
    getCategories(baseUrl) {
        return __awaiter(this, arguments, void 0, function* () {
            let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            const urlBuild = Object.entries(options).reduce((acc, _ref3, i) => {
                var _a;
                let [chave, valor] = _ref3;
                return `${acc}${i === 0 ? '?' : '&'}${chave}=${(_a = String(valor)) !== null && _a !== void 0 ? _a : ''}`;
            }, baseUrl);
            const response = yield fileClient.get(urlBuild);
            return response.data;
        });
    }
}
