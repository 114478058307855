const documentoPessoalUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/nome',
            label: 'Nome',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/descricao',
            label: 'Descrição'
        }, {
            type: 'Select',
            scope: '#/properties/formatoArquivo',
            label: 'Formato do arquivo',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/tamanhoArquivo',
            label: 'Tamanho do arquivo (MB)',
            options: {
                required: true,
                isPositiveNumber: true
            }
        }]
};
export default documentoPessoalUiSchema;
