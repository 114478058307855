import dayjs from 'dayjs';
import { TipoSituacao, tipoSituacaoI18N } from '../../../../models/proposta';
export const getStatusProps = (situacao, dataTermino) => {
    var _a;
    let status = 'danger';
    let label = (_a = tipoSituacaoI18N[situacao]) !== null && _a !== void 0 ? _a : '';
    const data = dayjs.utc(dataTermino).endOf('day');
    const hoje = dayjs.utc();
    if (data.isBefore(hoje)) {
        label = 'Expirado';
        status = 'danger';
    }
    else if (situacao === TipoSituacao.aceito || situacao === TipoSituacao.avaliado) {
        status = 'success';
    }
    return {
        label,
        status
    };
};
