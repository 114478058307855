import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { camelCase } from 'lodash';
import { Calendar, Profile } from '../../../components/styled-icons';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerEditalContratacao, stepHandlerPrestacaoConta } from './step-handler-edital-contratacao';
import { contaLabelStyle } from './style/edital-contratacao';
import { TipoPrestacaoConta, tipoPrestacaoDeContaI18N } from '../../../models/termo-contratacao';
const iconConfig = [{
        category: 'Informações da Contratação',
        icon: Profile
    }, {
        category: 'Prestação de Contas',
        icon: Calendar
    }, {
        category: 'Texto do Termo',
        icon: Calendar
    }];
const editalContratacaoUiSchema = {
    type: 'Categorization',
    options: {
        variant: 'stepper',
        showNavButtons: true,
        icons: iconConfig
    },
    elements: [{
            type: 'Category',
            label: 'Informações da Contratação',
            options: {
                stepHandler: {
                    handler: stepHandlerEditalContratacao
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'Text',
                            scope: '#/properties/nomeTermo',
                            label: 'Nome do Termo',
                            options: {
                                required: false,
                                defaultValue: 'Termo de Outorga'
                            }
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Date',
                            scope: '#/properties/inicioVigencia',
                            label: 'Inicio da Vigência',
                            options: {
                                required: false
                            }
                        }, {
                            type: 'Date',
                            scope: '#/properties/terminoVigencia',
                            label: 'Término da Vigência',
                            options: {
                                required: false
                            }
                        }, {
                            type: 'Boolean',
                            scope: '#/properties/cartaoBBPesquisa',
                            label: 'Cartão BB Pesquisa',
                            options: {
                                defaultValue: false
                            }
                        }]
                }, {
                    type: 'Label',
                    scope: '#',
                    label: 'Conta para Desembolso',
                    options: {
                        formatOptionLabel: () => 'Conta para Desembolso',
                        componentProps: contaLabelStyle
                    }
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Select',
                            scope: '#/properties/bancoDesembolsoId',
                            label: 'Banco/Desembolso',
                            options: {
                                displayProperties: ['nome']
                            }
                        }, {
                            type: 'Control',
                            scope: '#/properties/agenciaDesembolso',
                            label: 'Agência/Desembolso'
                        }, {
                            type: 'Control',
                            scope: '#/properties/contaDesembolso',
                            label: 'Conta/Desembolso'
                        }]
                }, {
                    type: 'Label',
                    scope: '#',
                    label: 'Conta para Recolhimento de Saldo',
                    options: {
                        formatOptionLabel: () => 'Conta para Recolhimento de Saldo',
                        componentProps: contaLabelStyle
                    }
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Select',
                            scope: '#/properties/bancoRecolhimentoId',
                            label: 'Banco/Recolhimento',
                            options: {
                                displayProperties: ['nome']
                            }
                        }, {
                            type: 'Control',
                            scope: '#/properties/agenciaRecolhimento',
                            label: 'Agência/Recolhimento'
                        }, {
                            type: 'Control',
                            scope: '#/properties/contaRecolhimento',
                            label: 'Conta/Recolhimento'
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Prestação de Contas',
            options: {
                stepHandler: {
                    handler: stepHandlerPrestacaoConta
                }
            },
            elements: [{
                    type: 'ArrayTableLayout',
                    scope: '#/properties/editalPrestacaoConta',
                    label: 'Edital Prestação de Conta',
                    options: {
                        headCells: [{
                                label: 'Tipo Prestação de Conta',
                                field: row => tipoPrestacaoDeContaI18N[camelCase(row === null || row === void 0 ? void 0 : row.tipoPrestacaoConta)]
                            }, {
                                label: 'Data',
                                field: row => {
                                    dayjs.extend(utc);
                                    if (!(row === null || row === void 0 ? void 0 : row.data))
                                        return '';
                                    return dayjs.utc(row.data).format('DD/MM/YYYY');
                                }
                            }],
                        elementLabelProp: 'editalPrestacaoConta',
                        detail: {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'HorizontalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/tipoPrestacaoConta',
                                            label: 'Tipo',
                                            options: {
                                                filter: {
                                                    handler: (formData, listData, api) => {
                                                        var _a;
                                                        const prestacoesExistentes = (_a = formData === null || formData === void 0 ? void 0 : formData.editalPrestacaoConta) !== null && _a !== void 0 ? _a : [];
                                                        const filteredListData = listData.filter(listItem => {
                                                            const isFinalType = listItem.value === TipoPrestacaoConta.finalTecnica || listItem.value === TipoPrestacaoConta.finalFinanceira;
                                                            const added = prestacoesExistentes.some(item => item.tipoPrestacaoConta === listItem.value && isFinalType);
                                                            return !added;
                                                        });
                                                        return Promise.resolve([...filteredListData]);
                                                    }
                                                }
                                            }
                                        }, {
                                            type: 'Date',
                                            scope: '#/properties/data',
                                            label: 'Data'
                                        }]
                                }]
                        }
                    }
                }]
        }, {
            type: 'Category',
            label: 'Texto do Termo',
            options: {
                stepHandler: {
                    handler: stepHandlerEditalContratacao,
                    callback: ctx => {
                        const { showSuccess } = ctx;
                        const result = createResource(ctx, '/edital-contratacao');
                        if (result)
                            showSuccess('Contratação salva com sucesso!');
                        return true;
                    }
                },
                onFinish: _ref => {
                    let { navigate, ctx } = _ref;
                    const { updateCrudStates, setFormData } = ctx;
                    setFormData({});
                    updateCrudStates(CrudStatesOptions.LIST);
                    navigate('/edital-contratacao');
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'CkEditor',
                                    scope: '#/properties/termo',
                                    options: {
                                        ckeditorSize: {
                                            height: 400
                                        }
                                    }
                                }]
                        }, {
                            type: 'Label',
                            scope: '#',
                            label: 'Assinaturas:',
                            options: {
                                formatOptionLabel: () => 'Assinaturas:',
                                componentProps: contaLabelStyle
                            }
                        }, {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Boolean',
                                    scope: '#/properties/temOutorgante',
                                    label: 'Outorgante'
                                }, {
                                    type: 'Boolean',
                                    scope: '#/properties/temOutorgado',
                                    label: 'Outorgado'
                                }, {
                                    type: 'Boolean',
                                    scope: '#/properties/temInterveniente',
                                    label: 'Interveniente'
                                }]
                        }]
                }]
        }]
};
export default editalContratacaoUiSchema;
