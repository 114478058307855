const moedaEstrangeiraUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/nome',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/simbolo',
            label: 'Símbolo',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/sigla',
            options: {
                required: true
            }
        }]
};
export default moedaEstrangeiraUiSchema;
