var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setValidationMode } from '@jsonforms/core';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
const handleFinish = (_ref) => __awaiter(void 0, void 0, void 0, function* () {
    let { ctx, handleStep, categories } = _ref;
    const id = yield createResource(ctx, '/proposta');
    if (!id) {
        setValidationMode('ValidateAndShow');
        return false;
    }
    const stepPendencyIndex = categories.findIndex(category => category === null || category === void 0 ? void 0 : category.options.hiddenCategory);
    handleStep(stepPendencyIndex);
});
export const termoDeAceiteUiSchema = () => [{
        type: 'Category',
        label: 'Termo de Aceite',
        options: {
            stepHandler: {
                handler: stepHandlerErrors,
                callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
            },
            nextButtonTitle: 'Verificar Pendências',
            nextButtonDisabled: data => {
                return !(data === null || data === void 0 ? void 0 : data.termoDeAceiteAceito);
            },
            onFinish: _ref2 => {
                let { ctx, handleStep, categories } = _ref2;
                return handleFinish({
                    ctx,
                    handleStep,
                    categories
                });
            }
        },
        elements: [{
                type: 'VerticalLayout',
                elements: [{
                        type: 'CkEditor',
                        scope: '#/properties/edital/properties/termoDeAceite',
                        options: {
                            ckeditorSize: {
                                height: 400
                            },
                            toolbar: [],
                            disabled: true
                        }
                    }]
            }, {
                type: 'Control',
                scope: '#/properties/termoDeAceiteAceito',
                label: 'Li e estou de acordo com o Termo de Aceite'
            }]
    }];
