import styled from 'styled-components';
export const BooleanContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const BooleanLabel = styled.label `
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
