import { DocumentAttach, Info } from '../../../components/styled-icons';
import { TipoRespostaPerguntaAvaliacao } from '../../../models/pergunta-avaliacao';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import perguntaAvaliacaoUiSchema from '../pergunta-avaliacao/pergunta-avaliacao.uischema';
import { handlerFormularioEnquadramento } from './handler/handler-formulario-enquadramento';
import { handlerFormularioEnquadramentoFormula } from './handler/handler-formulario-enquadramento-formula';
const iconsConfig = [{
        category: 'Dados do Formulário',
        icon: Info
    }, {
        category: 'Perguntas do Formulário',
        icon: DocumentAttach
    }];
const showRuleForPerguntasNotas = () => {
    return {
        effect: 'SHOW',
        condition: {
            type: 'OR',
            conditions: [{
                    scope: '#/properties/formularioEnquadramentoPergunta',
                    schema: {
                        type: 'array',
                        contains: {
                            properties: {
                                tipoResposta: {
                                    const: TipoRespostaPerguntaAvaliacao.nota
                                }
                            }
                        }
                    }
                }, {
                    scope: '#/properties/formularioEnquadramentoCategorizado',
                    schema: {
                        type: 'array',
                        contains: {
                            properties: {
                                formularioEnquadramentoCategorizadoPerguntaAvaliacao: {
                                    type: 'array',
                                    contains: {
                                        properties: {
                                            tipoResposta: {
                                                const: TipoRespostaPerguntaAvaliacao.nota
                                            }
                                        },
                                        required: ['tipoResposta']
                                    }
                                }
                            },
                            required: ['formularioEnquadramentoCategorizadoPerguntaAvaliacao']
                        }
                    }
                }]
        }
    };
};
const hideIfNotCategorizada = {
    effect: 'HIDE',
    condition: {
        scope: '#/properties/perguntasCategorizadas',
        schema: {
            const: false
        }
    }
};
const hideIfCategorizada = {
    effect: 'HIDE',
    condition: {
        scope: '#/properties/perguntasCategorizadas',
        schema: {
            const: true
        }
    }
};
const gerenciarFormularioUiSchema = {
    type: 'Categorization',
    elements: [{
            type: 'Category',
            label: 'Dados do Formulário',
            options: {
                stepHandler: {
                    handler: () => stepHandlerErrors
                }
            },
            elements: [{
                    type: 'Control',
                    scope: '#/properties/titulo',
                    label: 'Título'
                }, {
                    type: 'Control',
                    scope: '#/properties/perguntasCategorizadas',
                    label: 'Será um formulário com perguntas categorizadas',
                    options: {
                        onlyCreate: true,
                        handleChange: (item, handler, formData, ctx, path) => {
                            var _a, _b;
                            const { showError } = ctx;
                            if (item === true && ((_a = formData.formularioEnquadramentoPergunta) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                showError('Remova as perguntas antes de ativar a categorização');
                                return;
                            }
                            else if (item === false && ((_b = formData.formularioEnquadramentoCategorizado) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                                showError('Remova as categorias antes de desativar a categorização');
                                return;
                            }
                            handler(path, item);
                        }
                    }
                }]
        }, {
            type: 'Category',
            label: 'Perguntas do Formulário',
            options: {
                stepHandler: {
                    handler: handlerFormularioEnquadramento
                }
            },
            elements: [{
                    type: 'Control',
                    scope: '#/properties/formularioEnquadramentoCategorizado',
                    label: 'Perguntas Categorizadas',
                    options: {
                        elementLabelProp: 'tituloCategoria',
                        detail: {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/tituloCategoria',
                                    label: 'Título da Categoria'
                                }, {
                                    type: 'Select',
                                    scope: '#/properties/perguntaAvaliacaoId',
                                    label: 'Pergunta de Avaliação',
                                    options: {
                                        addEmptyOption: true,
                                        handleChange: (values, handler) => {
                                            handler('perguntaInfo', values);
                                        },
                                        filter: {
                                            handler: (data, listData, api, queryFilterValues, path) => {
                                                return api.getAll({
                                                    perguntaBase: true
                                                });
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/formularioEnquadramentoCategorizadoPerguntaAvaliacao',
                                    label: 'Perguntas',
                                    options: {
                                        detail: perguntaAvaliacaoUiSchema(),
                                        elementLabelProp: 'pergunta',
                                        defaultValue: formData => {
                                            var _a;
                                            return (_a = formData.perguntaInfo) !== null && _a !== void 0 ? _a : {
                                                perguntaBase: false,
                                                nota: {
                                                    notaMinima: 0,
                                                    notaMaxima: 0
                                                },
                                                tipoResposta: true
                                            };
                                        },
                                        showSortButtons: true
                                    }
                                }]
                        },
                        showSortButtons: true
                    },
                    rule: hideIfNotCategorizada
                }, {
                    type: 'Select',
                    scope: '#/properties/perguntaAvaliacaoId',
                    label: 'Pergunta de Avaliação',
                    options: {
                        addEmptyOption: true,
                        handleChange: (values, handler) => {
                            handler('perguntaInfo', values);
                        },
                        filter: {
                            handler: (data, listData, api, queryFilterValues, path) => {
                                return api.getAll({
                                    perguntaBase: true
                                });
                            }
                        }
                    },
                    rule: hideIfCategorizada
                }, {
                    type: 'Control',
                    scope: '#/properties/formularioEnquadramentoPergunta',
                    label: 'Perguntas',
                    options: {
                        detail: perguntaAvaliacaoUiSchema(),
                        elementLabelProp: 'pergunta',
                        defaultValue: formData => {
                            var _a;
                            return (_a = formData.perguntaInfo) !== null && _a !== void 0 ? _a : {
                                perguntaBase: false,
                                nota: {
                                    notaMinima: 0,
                                    notaMaxima: 0
                                },
                                tipoResposta: true
                            };
                        },
                        showSortButtons: true
                    },
                    rule: hideIfCategorizada
                }]
        }, {
            type: 'Category',
            label: 'Configurações',
            options: {
                stepHandler: {
                    handler: handlerFormularioEnquadramentoFormula
                }
            },
            elements: [{
                    type: 'FormularioNota'
                }, {
                    type: 'Label',
                    options: {
                        formatOptionLabel: formData => {
                            var _a, _b;
                            if (((_a = formData.formularioEnquadramentoPergunta) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                const perguntas = formData.formularioEnquadramentoPergunta.map((pergunta, index) => {
                                    if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                                        return `P${index + 1}`;
                                    }
                                    else
                                        return null;
                                }).filter(titulo => titulo).join(', ');
                                return `Perguntas: (${perguntas})`;
                            }
                            else if (((_b = formData.formularioEnquadramentoCategorizado) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                                const perguntas = [];
                                formData.formularioEnquadramentoCategorizado.forEach((categoria, categoriaIndex) => {
                                    const categoriaPerguntas = categoria.formularioEnquadramentoCategorizadoPerguntaAvaliacao;
                                    if (!categoriaPerguntas)
                                        return;
                                    categoriaPerguntas.forEach((pergunta, perguntaIndex) => {
                                        if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                                            perguntas.push(`P${categoriaIndex + 1}.${perguntaIndex + 1}`);
                                        }
                                    });
                                });
                                return `Perguntas: (${perguntas.join(', ')})`;
                            }
                        }
                    }
                }, {
                    type: 'Control',
                    scope: '#/properties/possuiFormulaPersonalizada',
                    label: 'Utilizar fórmula personalizada'
                }, {
                    type: 'Control',
                    scope: '#/properties/formula',
                    rule: {
                        effect: 'DISABLE',
                        condition: {
                            scope: '#/properties/possuiFormulaPersonalizada',
                            schema: {
                                const: false
                            }
                        }
                    }
                }, {
                    type: 'Label',
                    options: {
                        formatOptionLabel: _ref => {
                            var _a;
                            let { totalFormula } = _ref;
                            return `[${(_a = totalFormula === null || totalFormula === void 0 ? void 0 : totalFormula.toFixed(2)) !== null && _a !== void 0 ? _a : Number('0.00')}]`;
                        }
                    }
                }],
            rule: showRuleForPerguntasNotas()
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        icons: iconsConfig
    }
};
export default gerenciarFormularioUiSchema;
