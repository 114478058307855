export const moduleName = 'ORCAMENTO.LINHA_DE_ACAO.RECURSO_LINHA_DE_ACAO.TIPO_RECURSO_LINHA_DE_ACAO';
export const displayProperties = ['codigo'];
export const headCells = [{
        id: 'titulo',
        numeric: false,
        disablePadding: false,
        label: 'Título'
    }, {
        id: 'codigo',
        numeric: false,
        disablePadding: false,
        label: 'Código'
    }];
