import { showIfTemMoedaEstrangeira } from '../../../jsonforms/uischema/rubrica/helpers/showIfTemMoedaEstrangeira';
const handleTemMoedaEstrangeiraChange = (temMoedaEstrangeira, handler, _formData, _ctx, path) => {
    if (!temMoedaEstrangeira) {
        handler(`${path}`.replace('temMoedaEstrangeira', 'moedaEstrangeiraId'), null);
        handler(`${path}`.replace('temMoedaEstrangeira', 'moedaEstrangeira'), null);
    }
    handler(path, temMoedaEstrangeira);
};
const nivelBolsaUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/codigo',
            label: 'Código'
        }, {
            type: 'Control',
            scope: '#/properties/nivel',
            label: 'Nível',
            options: {
                required: true
            }
        }, {
            type: 'Select',
            scope: '#/properties/nivelAcademicoId',
            label: 'Nível Acadêmico',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/cargaHoraria',
            label: 'Carga Horária'
        }, {
            type: 'MonetaryInput',
            scope: '#/properties/valorAtualBolsa',
            label: 'Valor',
            options: {
                required: true
            }
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Boolean',
                            scope: '#/properties/temMoedaEstrangeira',
                            label: 'Moeda Estrangeira',
                            options: {
                                handleChange: handleTemMoedaEstrangeiraChange
                            }
                        }, {
                            type: 'Select',
                            scope: '#/properties/moedaEstrangeiraId',
                            label: 'Moeda Estrangeira',
                            rule: showIfTemMoedaEstrangeira,
                            options: {
                                displayProperties: ['nome']
                            }
                        }]
                }]
        }]
};
export default nivelBolsaUiSchema;
