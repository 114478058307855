export const moduleName = 'PESSOA.PERFIL.PERFIL';
export const displayProperties = ['nome'];
export const moduleLabel = 'Perfil';
export const headCells = [{
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }, {
        id: 'descricao',
        numeric: false,
        disablePadding: false,
        label: 'Descrição'
    }];
