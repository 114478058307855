export const moduleName = 'ORCAMENTO.LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO.EDICAO_LINHA_DE_ACAO';
export const displayProperties = ['ano'];
export const headCells = [{
        id: 'ano',
        numeric: false,
        disablePadding: false,
        label: 'Ano'
    }, {
        id: 'valorTotal',
        numeric: false,
        disablePadding: false,
        label: 'Soma de Recursos',
        mask: 'money'
    }, {
        id: 'valorAlocado',
        numeric: false,
        disablePadding: false,
        label: 'Valor Alocado',
        mask: 'money'
    }];
