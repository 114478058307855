import { RuleEffect } from '@jsonforms/core';
import { makeIbgeApi } from '../../../api/ibge.api';
import { hideIfBrasil, showIfBrasil } from '../../../jsonforms/uischema';
import { atualizarEditarPerfilHandler } from '../handlers/atualizar-editar-perfil-handler';
export const apiInstanceIbge = makeIbgeApi();
export const enderecoUiSchema = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        type: 'Category',
        label: 'Endereço',
        options: {
            additionalPaths: ['paisId', 'pais'],
            stepHandler: {
                handler: atualizarEditarPerfilHandler,
                callback: ctx => {
                    const { formData, showError } = ctx;
                    if (!(formData === null || formData === void 0 ? void 0 : formData.endereco)) {
                        showError('Preencha o endereço antes de prosseguir.');
                        return false;
                    }
                    return true;
                }
            }
        },
        elements: [{
                type: 'HorizontalLayout',
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Constant',
                                scope: `#${scopePrefix}/properties/enderecoId`,
                                rule: {
                                    effect: RuleEffect.HIDE,
                                    condition: {}
                                }
                            }, {
                                type: 'Cep',
                                scope: `#${scopePrefix}/properties/endereco/properties/cep`,
                                label: 'CEP',
                                rule: showIfBrasil(scopePrefix),
                                options: {
                                    mask: '99999-999',
                                    required: true
                                }
                            }, {
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/endereco/properties/cep`,
                                label: 'ZipCode',
                                rule: hideIfBrasil(scopePrefix),
                                options: {
                                    mask: '999999999',
                                    required: true
                                }
                            }, {
                                type: 'Text',
                                scope: `#${scopePrefix}/properties/endereco/properties/logradouro`,
                                options: {
                                    required: true
                                }
                            }, {
                                type: 'Text',
                                scope: `#${scopePrefix}/properties/endereco/properties/numero`,
                                label: 'Número',
                                rule: showIfBrasil(scopePrefix),
                                options: {
                                    required: true
                                }
                            }, {
                                type: 'Text',
                                scope: `#${scopePrefix}/properties/endereco/properties/complemento`
                            }]
                    }, {
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/endereco/properties/bairro`,
                                label: 'Bairro',
                                rule: showIfBrasil(scopePrefix),
                                options: {
                                    required: true
                                }
                            }, {
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/endereco/properties/estado`,
                                label: 'Estado',
                                rule: showIfBrasil(scopePrefix),
                                options: {
                                    handleChange: (item, handler, formData, ctx, path) => {
                                        handler(path, item);
                                        handler(path.replace('estado', 'municipio'), undefined);
                                    },
                                    required: true,
                                    targetFieldValue: 'sigla',
                                    optionsComparator: (option, item) => {
                                        return (option === null || option === void 0 ? void 0 : option.sigla) === item;
                                    },
                                    displayProperties: ['nome'],
                                    filter: {
                                        acceptUnmatched: true,
                                        preventCleaning: true,
                                        selfContainedApi: true,
                                        formFields: ['pais', 'cep'],
                                        handler: () => {
                                            return apiInstanceIbge.buscarEstados();
                                        }
                                    }
                                }
                            }, {
                                type: 'Text',
                                scope: `#${scopePrefix}/properties/endereco/properties/estado`,
                                label: 'Estado/Região',
                                rule: hideIfBrasil(scopePrefix),
                                options: {
                                    required: true
                                }
                            }, {
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/endereco/properties/municipio`,
                                label: 'Município',
                                rule: showIfBrasil(scopePrefix),
                                options: {
                                    required: true,
                                    targetFieldValue: 'nome',
                                    optionsComparator: (option, item) => {
                                        return (option === null || option === void 0 ? void 0 : option.nome) === item;
                                    },
                                    displayProperties: ['nome'],
                                    filter: {
                                        acceptUnmatched: true,
                                        preventCleaning: true,
                                        selfContainedApi: true,
                                        formFields: ['estado', 'cep'],
                                        handler: (_formData, _listData, _api, queryFilterValues) => {
                                            if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                                return Promise.resolve([]);
                                            return apiInstanceIbge.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                                        }
                                    }
                                }
                            }, {
                                type: 'Text',
                                scope: `#${scopePrefix}/properties/endereco/properties/municipio`,
                                label: 'Município',
                                rule: hideIfBrasil(scopePrefix),
                                options: {
                                    required: true
                                }
                            }]
                    }]
            }]
    };
};
