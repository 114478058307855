const instituicaoInstrumentoJuridicoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/instituicaoId',
                    options: {
                        required: true,
                        filter: {
                            formFields: ['instrumentoJuridicoId'],
                            handler: (formData, listData, api) => {
                                var _a;
                                if (!formData.instrumentoJuridicoId)
                                    return Promise.resolve(listData);
                                let filters = {
                                    withDeleted: false
                                };
                                if (!formData.id) {
                                    filters.instrumentoJuridicoId = formData.instrumentoJuridicoId;
                                }
                                return (_a = api === null || api === void 0 ? void 0 : api.getAll) === null || _a === void 0 ? void 0 : _a.call(api, filters);
                            }
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/objetivoInstrumentoJuridico',
                    label: 'Objetivo do Instrumento Jurídico'
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'Select',
                            scope: '#/properties/tipoParceria',
                            label: 'Parceria',
                            options: {
                                required: true
                            }
                        }, {
                            type: 'Control',
                            scope: '#/properties/porcentagemOutrosFins',
                            label: '% para outros fins'
                        }]
                }, {
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'MonetaryInput',
                            scope: '#/properties/valorFirmado',
                            label: 'Valor Firmado'
                        }]
                }]
        }]
};
export default instituicaoInstrumentoJuridicoUiSchema;
