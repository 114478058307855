import { circleFlagBuilder } from '../../../components/circle-flag';
import { showIfBrasil } from '../../../jsonforms/uischema';
import { atualizarEditarPerfilHandler } from '../handlers/atualizar-editar-perfil-handler';
export const dadosPessoaisUiSchema = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        type: 'Category',
        label: 'Dados Pessoais',
        options: {
            stepHandler: {
                handler: atualizarEditarPerfilHandler
            }
        },
        elements: [{
                type: 'HorizontalLayout',
                elements: [{
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/nome`,
                                label: 'Nome',
                                options: {
                                    required: true
                                }
                            }, {
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/nomeSocial`,
                                label: 'Nome social'
                            }, {
                                type: 'Date',
                                scope: `#${scopePrefix}/properties/dataNascimento`,
                                label: 'Data de nascimento',
                                options: {
                                    dateFormat: 'DD/MM/YYYY',
                                    required: true
                                }
                            }, {
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/email`,
                                label: 'E-mail',
                                options: {
                                    mask: 'email',
                                    disabled: true
                                }
                            }]
                    }, {
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/racaCorId`,
                                label: 'Raça/Cor'
                            }, {
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/paisId`,
                                label: 'País',
                                options: {
                                    required: true,
                                    handleChange: (item, handler, formData, _ctx, path) => {
                                        handler(path === null || path === void 0 ? void 0 : path.replace('paisId', 'pais'), item);
                                        handler(path, item.id);
                                    },
                                    iconBuilder: circleFlagBuilder
                                }
                            }, {
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/documento`,
                                label: 'CPF',
                                rule: showIfBrasil(scopePrefix),
                                options: {
                                    mask: '999.999.999-99'
                                }
                            }]
                    }]
            }]
    };
};
