var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getPerguntaSchema } from '../../../utils/get-pergunta-schema.util';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import perguntaUiSchema from './pergunta.uischema';
export const stepHandlerFormPergunta = (fieldsConfig, ctx, data, url, _callback) => __awaiter(void 0, void 0, void 0, function* () {
    const { formData, setSchema, setCurrentUiSchema } = ctx;
    const errorsResult = yield stepHandlerErrors(fieldsConfig, ctx, data, url);
    const { schema: perguntaSchema, uischema } = getPerguntaSchema(formData);
    if (errorsResult) {
        setSchema(prev => (Object.assign(Object.assign({}, prev), { properties: Object.assign(Object.assign({}, prev.properties), perguntaSchema.properties) })));
        const newUiSchema = perguntaUiSchema({
            field: uischema
        });
        setCurrentUiSchema(newUiSchema);
        return true;
    }
    return false;
});
export const stepHandlerViewPergunta = (_fieldsConfig, ctx, _data, _url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    if (callback)
        yield callback(ctx);
    return true;
});
