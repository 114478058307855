export class ConstNode {
    constructor(value) {
        this.value = value;
    }
    solve() {
        return this.value;
    }
    listVars() {
        return [];
    }
}
