import { RuleEffect } from '@jsonforms/core';
const perguntaAvaliacaoUiSchema = options => ({
    type: 'VerticalLayout',
    elements: [{
            type: 'VerticalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/pergunta',
                    label: 'Texto da Pergunta',
                    options: {
                        required: true
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/tipoResposta',
                    label: 'Tipo da Resposta',
                    options: {
                        required: true
                    }
                }]
        }, {
            type: 'VerticalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/descritiva/properties/descricao',
                    label: 'Descrição da Pergunta'
                }],
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    type: 'AND',
                    conditions: [{
                            scope: '#/properties/tipoResposta',
                            schema: {
                                enum: ['DESCRITIVA', null]
                            }
                        }, {}]
                }
            }
        }, {
            type: 'VerticalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/multiplaEscolha',
                    label: 'Adicionar Alternativa',
                    options: {
                        defaultValue: formData => {
                            var _a, _b;
                            const nextPosition = ((_b = (_a = formData.multiplaEscolha) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) + 1;
                            return {
                                posicao: nextPosition
                            };
                        },
                        itemLayout: 'SimpleItemLayout',
                        detail: {
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/alternativa',
                                    label: 'Texto Alternativa'
                                }]
                        },
                        showSortButtons: true
                    }
                }],
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    type: 'AND',
                    conditions: [{
                            scope: '#/properties/tipoResposta',
                            schema: {
                                enum: ['MULTIPLA_ESCOLHA', null]
                            }
                        }, {}]
                }
            }
        }, {
            type: 'VerticalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/nota/properties/descricao',
                    label: 'Descrição da Pergunta'
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/nota/properties/notaMinima',
                            label: 'Nota Mínima',
                            options: {
                                isPositiveNumber: true
                            }
                        }, {
                            type: 'Control',
                            scope: '#/properties/nota/properties/notaMaxima',
                            label: 'Nota Máxima',
                            options: {
                                isPositiveNumber: true
                            }
                        }]
                }, {
                    type: 'Boolean',
                    scope: '#/properties/nota/properties/deveTerJustificativa',
                    label: 'Deve ter justificativa'
                }, {
                    type: 'LongText',
                    scope: '#/properties/nota/properties/justificativa',
                    label: 'Justificativa',
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'AND',
                            conditions: [{
                                    scope: '#/properties/nota/properties/deveTerJustificativa',
                                    schema: {
                                        const: true
                                    }
                                }, {
                                    scope: '#/properties/nota/properties/justificativa',
                                    schema: {
                                        type: 'string',
                                        minLength: 1
                                    }
                                }]
                        }
                    }
                }],
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    type: 'AND',
                    conditions: [{
                            scope: '#/properties/tipoResposta',
                            schema: {
                                enum: ['NOTA', null]
                            }
                        }, {}]
                }
            }
        }]
});
export default perguntaAvaliacaoUiSchema;
