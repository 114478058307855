import { RuleEffect } from '@jsonforms/core';
import { TipoRecursoLinhaDeAcao, tipoRecursoLinhaDeAcaoI18N } from '../../../models/tipo-recurso-linha-de-acao';
const hideIfInstrumentoJuridico = {
    effect: RuleEffect.HIDE,
    condition: {
        scope: '#/properties/tipoRecursoLinhaDeAcao',
        schema: {
            not: {
                const: 'TESOURO'
            }
        }
    }
};
const hideIfTesouro = {
    effect: RuleEffect.HIDE,
    condition: {
        scope: '#/properties/tipoRecursoLinhaDeAcao',
        schema: {
            not: {
                const: 'INSTRUMENTO_JURIDICO'
            }
        }
    }
};
const edicaoRecursoLinhaDeAcaoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Select',
            scope: '#/properties/tipoRecursoLinhaDeAcao',
            label: 'Tipo de Recurso',
            options: {
                onlyCreate: true,
                oneOf: formData => {
                    return Array.from(Object.values(TipoRecursoLinhaDeAcao), (value, _) => ({
                        title: tipoRecursoLinhaDeAcaoI18N[value],
                        const: value
                    }));
                },
                handleChange: (tipoRecursoLinhaDeAcao, handler, formData, ctx, path) => {
                    handler('instrumentoJuridicoId', null);
                    handler('tesouroId', null);
                }
            }
        }, {
            type: 'Select',
            scope: '#/properties/instrumentoJuridicoId',
            rule: hideIfTesouro
        }, {
            type: 'Select',
            scope: '#/properties/tesouroId',
            rule: hideIfInstrumentoJuridico,
            options: {
                filter: {
                    handler: (formData, listData, api) => {
                        return api.getAll({
                            withDeleted: 'false',
                            orderBy: 'ano',
                            orderDirection: 'DESC'
                        });
                    }
                }
            }
        }, {
            type: 'MonetaryInput',
            scope: '#/properties/valor'
        }]
};
export default edicaoRecursoLinhaDeAcaoUiSchema;
