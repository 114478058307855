var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { camelCase } from 'change-case';
import { formatFloat } from '../../../helpers/format-float';
import { TipoEditalRubrica, tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { tipoPassagemI18N, TrechoPassagem, trechoPassagemI18N } from '../../../models/proposta';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { apiInstanceIbge } from '../../../shared/editar-perfil/uischemas/endereco.uischema';
import { buildTableLayoutHeader } from '../../../shared/proposta/table-layout-header';
import { createShowRuleForEditalRubrica } from './helpers/createShowRuleForEditalRubrica';
import { getJustificativaStatus } from './helpers/getJustificativaStatus';
import { handleTemMoedaEstrangeiraChange } from './helpers/handleTemMoedaEstrangeiraChange';
import { moedaHandleChange } from './helpers/moedaHandleChange';
import { requiredIfContrapartida } from './helpers/requiredIfContrapartida';
import { requiredIfTemMoedaEstrangeira } from './helpers/requiredIfTemMoedaEstrangeira';
import { requiredIfTrechoInternacional } from './helpers/requiredIfTrechoInternacional';
import { requiredIfTrechoNacional } from './helpers/requiredIfTrechoNacional';
import { showIfContrapartida } from './helpers/showIfContrapartida';
import { showIfInternationalTicket } from './helpers/showIfInternationalTicket';
import { showIfJustificativaGlobal } from './helpers/showIfJustificativaGlobal';
import { showIfNationalTicket } from './helpers/showIfNationalTicket';
import { showIfTemMoedaEstrangeira } from './helpers/showIfTemMoedaEstrangeira';
import { visibleHandlerJustificativaGlobal } from './helpers/visibleHandlerJustificativaGlobal';
import { visibleHandlerJustificativaObrigatoria } from './helpers/visibleHandlerJustificativaObrigatoria';
export const getPassagemAnswerForm = function () {
    let { proposta, readonly = false, customHeader, customFooter } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/revisao',
                    label: '',
                    options: {
                        readonly: true,
                        maxLength: 256,
                        customHeader,
                        customFooter,
                        visibleHandler: ctx => {
                            var _a;
                            return (_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.orcamentoEdicao;
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    label: 'Trecho',
                    scope: '#/properties/trecho',
                    options: {
                        readonly,
                        required: true,
                        handleChange: (trecho, handler, formData, ctx, path) => {
                            if (trecho === TrechoPassagem.nacional) {
                                handler(`${path}`.replace('trecho', 'paisOrigemId'), null);
                                handler(`${path}`.replace('trecho', 'estadoRegiaoOrigem'), null);
                                handler(`${path}`.replace('trecho', 'paisDestinoId'), null);
                                handler(`${path}`.replace('trecho', 'estadoRegiaoDestino'), null);
                            }
                            else if (trecho === TrechoPassagem.internacional) {
                                handler(`${path}`.replace('trecho', 'estadoOrigemId'), null);
                                handler(`${path}`.replace('trecho', 'estadoOrigem'), null);
                                handler(`${path}`.replace('trecho', 'municipioOrigem'), null);
                                handler(`${path}`.replace('trecho', 'estadoDestinoId'), null);
                                handler(`${path}`.replace('trecho', 'estadoDestino'), null);
                                handler(`${path}`.replace('trecho', 'municipioDestino'), null);
                            }
                        }
                    }
                }, {
                    type: 'Control',
                    label: 'Ida e Volta',
                    scope: '#/properties/idaVolta'
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    label: 'Estado de Origem',
                    scope: '#/properties/estadoOrigemId',
                    rule: showIfNationalTicket,
                    options: {
                        readonly,
                        displayProperties: ['nome'],
                        required: requiredIfTrechoNacional,
                        handleChange: (item, handler, formData, ctx, path) => {
                            //Adiciona o objeto inteiro no formulário provisório
                            handler(`${path}`.replace('estadoOrigemId', 'estadoOrigem'), item);
                        }
                    }
                }, {
                    type: 'Select',
                    label: 'Município de Origem',
                    scope: '#/properties/municipioOrigem',
                    rule: showIfNationalTicket,
                    options: {
                        readonly,
                        targetFieldValue: 'nome',
                        optionsComparator: (option, item) => (option === null || option === void 0 ? void 0 : option.nome) === item,
                        defaultValue: 'municipioOrigem',
                        required: requiredIfTrechoNacional,
                        displayProperties: ['nome'],
                        filter: {
                            selfContainedApi: true,
                            formFields: ['estadoOrigemId'],
                            handler: (_formData, _listData, _api, queryFilterValues) => {
                                if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                    return Promise.resolve([]);
                                return apiInstanceIbge.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                            }
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    label: 'País de Origem',
                    scope: '#/properties/paisOrigemId',
                    rule: showIfInternationalTicket,
                    options: {
                        readonly,
                        displayProperties: ['nomePt'],
                        required: requiredIfTrechoInternacional,
                        handleChange: (item, handler, formData, ctx, path) => {
                            //Adiciona o objeto inteiro no formulário provisório
                            handler(`${path}`.replace('paisOrigemId', 'paisOrigem'), item);
                        }
                    }
                }, {
                    type: 'Control',
                    label: 'Estado/Região de Origem',
                    rule: showIfInternationalTicket,
                    scope: '#/properties/estadoRegiaoOrigem',
                    options: {
                        readonly,
                        required: requiredIfTrechoInternacional
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    label: 'Estado de Destino',
                    scope: '#/properties/estadoDestinoId',
                    rule: showIfNationalTicket,
                    options: {
                        readonly,
                        displayProperties: ['nome'],
                        required: requiredIfTrechoNacional,
                        handleChange: (item, handler, formData, ctx, path) => {
                            //Adiciona o objeto inteiro no formulário provisório
                            handler(`${path}`.replace('estadoDestinoId', 'estadoDestino'), item);
                        }
                    }
                }, {
                    type: 'Select',
                    label: 'Município de Destino',
                    scope: '#/properties/municipioDestino',
                    rule: showIfNationalTicket,
                    options: {
                        readonly,
                        targetFieldValue: 'nome',
                        optionsComparator: (option, item) => (option === null || option === void 0 ? void 0 : option.nome) === item,
                        defaultValue: 'municipioOrigem',
                        displayProperties: ['nome'],
                        required: requiredIfTrechoNacional,
                        filter: {
                            selfContainedApi: true,
                            formFields: ['estadoDestinoId'],
                            handler: (_formData, _listData, _api, queryFilterValues) => {
                                if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                    return Promise.resolve([]);
                                return apiInstanceIbge.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                            }
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    label: 'País de Destino',
                    scope: '#/properties/paisDestinoId',
                    rule: showIfInternationalTicket,
                    options: {
                        readonly,
                        displayProperties: ['nomePt'],
                        required: requiredIfTrechoInternacional,
                        handleChange: (item, handler, formData, ctx, path) => {
                            //Adiciona o objeto inteiro no formulário provisório
                            handler(`${path}`.replace('paisDestinoId', 'paisDestino'), item);
                        }
                    }
                }, {
                    type: 'Control',
                    label: 'Estado/Região de Destino',
                    rule: showIfInternationalTicket,
                    scope: '#/properties/estadoRegiaoDestino',
                    options: {
                        readonly,
                        required: requiredIfTrechoInternacional
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    label: 'Tipo',
                    scope: '#/properties/tipo',
                    options: {
                        readonly,
                        required: true
                    }
                }, {
                    type: 'MonetaryInput',
                    label: 'Custo Unitário',
                    scope: '#/properties/custoUnitario',
                    options: {
                        readonly,
                        required: true,
                        handleChange: (custoUnitario, handler, formData, ctx, path) => {
                            var _a;
                            const quantidade = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaPassagemUnsaved']) === null || _a === void 0 ? void 0 : _a.quantidade;
                            if (!custoUnitario && !quantidade)
                                return;
                            handler(`${path}`.replace('custoUnitario', 'valorTotal'), custoUnitario * quantidade);
                        }
                    }
                }, {
                    type: 'Number',
                    label: 'Quantidade',
                    scope: '#/properties/quantidade',
                    options: {
                        readonly,
                        numberStep: 1,
                        required: true,
                        isPositiveNumber: true,
                        handleChange: (quantidade, handler, formData, ctx, path) => {
                            var _a;
                            const custoUnitario = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaPassagemUnsaved']) === null || _a === void 0 ? void 0 : _a.custoUnitario;
                            if (!custoUnitario && !quantidade)
                                return;
                            handler(`${path}`.replace('quantidade', 'valorTotal'), custoUnitario * quantidade);
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    label: 'Valor Total',
                    scope: '#/properties/valorTotal',
                    options: {
                        disabled: true
                    }
                }, {
                    type: 'Select',
                    label: 'Mês Previsto',
                    scope: '#/properties/mesPrevisto',
                    options: {
                        readonly,
                        required: true,
                        oneOf: formData => {
                            const data = (formData === null || formData === void 0 ? void 0 : formData.duracao) ? formData : proposta;
                            return Array.from({
                                length: data.duracao
                            }, (_, i) => ({
                                title: `${i + 1}°`,
                                const: i + 1
                            }));
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Boolean',
                    scope: '#/properties/temMoedaEstrangeira',
                    label: 'Moeda Estrangeira',
                    options: {
                        readonly,
                        visibleHandler: ctx => {
                            var _a, _b, _c;
                            const { formData } = ctx;
                            const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                            return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => { var _a; return (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === TipoEditalRubrica.passagens && ((_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.length) > 0; });
                        },
                        handleChange: handleTemMoedaEstrangeiraChange
                    }
                }, {
                    type: 'Select',
                    label: 'Moeda Estrangeira',
                    scope: '#/properties/moedaEstrangeiraId',
                    rule: showIfTemMoedaEstrangeira,
                    options: {
                        readonly,
                        displayProperties: ['nome'],
                        required: requiredIfTemMoedaEstrangeira('rubricaPassagemUnsaved'),
                        filter: {
                            handler: (formData, listData, api, queryFilterValues, path) => {
                                var _a, _b, _c;
                                const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                                let editalRubrica = (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.find(editalRubrica => editalRubrica.tipoEditalRubrica === TipoEditalRubrica.passagens);
                                return api.getAll().then(response => response.filter(moedaEstrangeira => { var _a; return (_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.some(me => me.id === moedaEstrangeira.id); }));
                            }
                        },
                        handleChange: moedaHandleChange
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Boolean',
                    label: 'Contrapartida',
                    scope: '#/properties/contrapartida',
                    options: {
                        readonly,
                        handleChange: (value, handler, formData, ctx, path) => __awaiter(this, void 0, void 0, function* () {
                            if (!value) {
                                handler(`${path}`.replace('contrapartida', 'tipoEntidade'), null);
                                handler(`${path}`.replace('contrapartida', 'entidade'), null);
                            }
                        })
                    }
                }, {
                    type: 'Select',
                    label: 'Tipo de Entidade',
                    scope: '#/properties/tipoEntidade',
                    rule: showIfContrapartida,
                    options: {
                        readonly,
                        required: requiredIfContrapartida('rubricaPassagemUnsaved')
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    label: 'Entidade',
                    rule: showIfContrapartida,
                    scope: '#/properties/entidade',
                    options: {
                        readonly,
                        required: requiredIfContrapartida('rubricaPassagemUnsaved')
                    }
                }]
        }, {
            type: 'LongText',
            scope: '#properties/justificativa',
            label: 'Justificativa',
            options: {
                readonly,
                maxLength: 256,
                label: getJustificativaStatus(TipoEditalRubrica.passagens),
                visibleHandler: visibleHandlerJustificativaObrigatoria(TipoEditalRubrica.passagens),
                required: ctx => {
                    var _a, _b, _c;
                    const { formData } = ctx;
                    const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                    return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.passagens) && (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.temJustificativaObrigatoria));
                }
            }
        }];
};
export const rubricaPassagemUischema = function () {
    let { route, customPaths, scopePrefix = '', revisaoOrcamento, customHeader, customFooter, customActions } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
        type: 'Category',
        label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.passagens)],
        rule: createShowRuleForEditalRubrica(TipoEditalRubrica.passagens),
        options: {
            stepHandler: {
                handler: stepHandlerErrors
            }
        },
        elements: [{
                type: 'ArrayTableLayout',
                scope: `#${scopePrefix}/properties/rubricaPassagem`,
                label: '',
                options: {
                    additionalData: formData => ({
                        editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                    }),
                    defaultValue: formData => ({
                        paisId: 1
                    }),
                    buildHeader: {
                        builder: buildTableLayoutHeader
                    },
                    route: route !== null && route !== void 0 ? route : '/proposta',
                    customPaths: customPaths !== null && customPaths !== void 0 ? customPaths : {},
                    hideCreate: revisaoOrcamento,
                    hideDefaultActions: revisaoOrcamento,
                    customActions,
                    headCells: [{
                            label: 'Trecho',
                            field: row => { var _a; return trechoPassagemI18N[(_a = row.trecho) === null || _a === void 0 ? void 0 : _a.toUpperCase()]; }
                        }, {
                            label: 'Ida e Volta',
                            field: row => row.idaVolta ? 'Sim' : 'Não'
                        }, {
                            label: 'Origem',
                            field: row => { var _a, _b; return row.trecho === TrechoPassagem.nacional ? `Brasil - ${(_a = row.estadoOrigem) === null || _a === void 0 ? void 0 : _a.nome}-${row.municipioOrigem}` : `${(_b = row.paisOrigem) === null || _b === void 0 ? void 0 : _b.nomePt}-${row.estadoRegiaoOrigem}`; }
                        }, {
                            label: 'Destino',
                            field: row => { var _a, _b; return row.trecho === TrechoPassagem.nacional ? `Brasil-${(_a = row.estadoDestino) === null || _a === void 0 ? void 0 : _a.nome}-${row.municipioDestino}` : `${(_b = row.paisDestino) === null || _b === void 0 ? void 0 : _b.nomePt}-${row.estadoRegiaoDestino}`; }
                        }, {
                            label: 'Tipo',
                            field: row => { var _a; return tipoPassagemI18N[(_a = row.tipo) === null || _a === void 0 ? void 0 : _a.toUpperCase()]; }
                        }, {
                            label: 'Custo Unitário',
                            field: row => row.quantidade ? `${formatFloat(row.custoUnitario)}` : '-'
                        }, {
                            label: 'Quantidade',
                            field: 'quantidade'
                        }, {
                            label: 'Mês',
                            field: row => row.mesPrevisto ? `${row.mesPrevisto}°` : '-'
                        }, {
                            label: 'Contrapartida',
                            field: row => row.contrapartida ? 'Sim' : 'Não'
                        }, {
                            label: 'Moeda',
                            field: row => row.moedaEstrangeira ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})` : 'Real (R$)'
                        }, {
                            label: 'Valor Total',
                            field: row => row.valorTotal ? `${formatFloat(row.valorTotal)}` : '-'
                        }],
                    label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.passagens)],
                    detail: {
                        type: 'VerticalLayout',
                        elements: getPassagemAnswerForm({
                            customHeader,
                            customFooter
                        })
                    }
                }
            }, {
                type: 'LongText',
                scope: '#properties/justificativaGlobal',
                label: 'Justificativa Global',
                options: {
                    maxLength: 256,
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.passagens),
                    arrayProps: {
                        id: 'passagens',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.passagens),
                        arrayScope: 'justificativa'
                    }
                },
                rule: showIfJustificativaGlobal('rubricaPassagem')
            }, {
                type: 'Constant',
                scope: '#properties/justificativaGlobal',
                options: {
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.passagens),
                    arrayProps: {
                        id: 'passagens',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.passagens),
                        arrayScope: 'tipoEditalRubrica'
                    },
                    value: TipoEditalRubrica.passagens
                },
                rule: showIfJustificativaGlobal('rubricaPassagem')
            }]
    };
};
