export const displayProperties = ['tipo'];
export const moduleLabel = 'Níveis Acadêmicos';
export const moduleName = 'CONFIGURACOES_INICIAIS.NIVEL_ACADEMICO';
export const rowsLength = 12;
export const headCells = [{
        id: 'tipo',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }, {
        id: 'prioridade',
        numeric: true,
        disablePadding: false,
        label: 'Prioridade'
    }];
