import { RuleEffect } from '@jsonforms/core';
import { atualizarEditarPerfilHandler } from '../handlers/atualizar-editar-perfil-handler';
import { makeAreaDeConhecimentoApi } from '../../fundacao/area-de-conhecimento/area-de-conhecimento-api';
import { areaDeConhecimentoHandleChange } from '../handlers/area-de-conhecimento.handler';
import { get } from 'lodash';
const disableIfNoOptions = (_ctx, apiListData) => {
    if (apiListData.length === 0)
        return true;
    return false;
};
const visibleIfHasFilter = (_ctx, _apiListData, queryFilterValues) => {
    var _a;
    const filterValue = (_a = queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0]) === null || _a === void 0 ? void 0 : _a.value;
    if (filterValue !== null && filterValue !== undefined)
        return true;
    return false;
};
const sugerirInstituicaoHandler = (value, handler, _formData, _ctx, path) => {
    let pathPrefix = path.split('.').slice(0, -1).join('.');
    if (pathPrefix && pathPrefix.length > 0)
        pathPrefix = `${pathPrefix}.`;
    handler(`${pathPrefix}instituicaoId`, null);
    handler(`${pathPrefix}instituicaoNome`, null);
    handler(`${pathPrefix}instituicaoSigla`, null);
    handler(`${pathPrefix}unidadeNome`, null);
    handler(`${pathPrefix}unidadeSigla`, null);
    handler(`${pathPrefix}sugerirInstituicao`, value);
};
const sugerirUnidadeHandler = (value, handler, _formData, _ctx, path) => {
    let pathPrefix = path.split('.').slice(0, -1).join('.');
    if (pathPrefix && pathPrefix.length > 0)
        pathPrefix = `${pathPrefix}.`;
    handler(`${pathPrefix}unidadeId`, null);
    handler(`${pathPrefix}unidadeNome`, null);
    handler(`${pathPrefix}unidadeSigla`, null);
    handler(`${pathPrefix}sugerirUnidade`, value);
};
const showIfSugerirInstituicao = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        effect: RuleEffect.SHOW,
        condition: {
            scope: `#${scopePrefix}/properties/sugerirInstituicao`,
            schema: {
                const: true
            }
        }
    };
};
const hideIfSugerirInstituicao = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        effect: RuleEffect.HIDE,
        condition: {
            scope: `#${scopePrefix}/properties/sugerirInstituicao`,
            schema: {
                const: true
            }
        }
    };
};
const showIfSugerirUnidade = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        effect: RuleEffect.SHOW,
        condition: {
            type: 'OR',
            conditions: [{
                    scope: `#${scopePrefix}/properties/sugerirInstituicao`,
                    schema: {
                        const: true
                    }
                }, {
                    scope: `#${scopePrefix}/properties/sugerirUnidade`,
                    schema: {
                        const: true
                    }
                }]
        }
    };
};
const hideIfSugerirUnidade = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        effect: RuleEffect.HIDE,
        condition: {
            type: 'OR',
            conditions: [{
                    scope: `#${scopePrefix}/properties/sugerirInstituicao`,
                    schema: {
                        const: true
                    }
                }, {
                    scope: `#${scopePrefix}/properties/sugerirUnidade`,
                    schema: {
                        const: true
                    }
                }]
        }
    };
};
const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();
export const dadosAcademicosUiSchema = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    const scopePath = scopePrefix.replace('/properties/', '');
    const scopePathFor = property => scopePath ? `${scopePath}.${property}` : property;
    return {
        type: 'Category',
        label: 'Dados Acadêmicos',
        options: {
            stepHandler: {
                handler: atualizarEditarPerfilHandler
            }
        },
        elements: [{
                type: 'VerticalLayout',
                elements: [{
                        type: 'HorizontalLayout',
                        elements: [{
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/instituicaoId`,
                                label: 'Instituição',
                                options: {
                                    formatOptionLabel: option => `${option === null || option === void 0 ? void 0 : option.sigla}/${option === null || option === void 0 ? void 0 : option.nome}`
                                }
                            }, {
                                type: 'Boolean',
                                scope: `#${scopePrefix}/properties/sugerirInstituicao`,
                                label: 'Sugerir Instituição',
                                options: {
                                    handleChange: sugerirInstituicaoHandler
                                }
                            }],
                        rule: hideIfSugerirInstituicao(scopePrefix)
                    }, {
                        type: 'HorizontalLayout',
                        elements: [{
                                type: 'Text',
                                scope: `#${scopePrefix}/properties/instituicaoNome`,
                                label: 'Instituição Nome',
                                options: {
                                    format: 'string',
                                    maxLength: 32
                                }
                            }, {
                                type: 'HorizontalLayout',
                                elements: [{
                                        type: 'Text',
                                        scope: `#${scopePrefix}/properties/instituicaoSigla`,
                                        label: 'Instituição Sigla',
                                        options: {
                                            format: 'string',
                                            maxLength: 8
                                        }
                                    }, {
                                        type: 'Boolean',
                                        scope: `#${scopePrefix}/properties/sugerirInstituicao`,
                                        label: 'Sugerir Instituição',
                                        options: {
                                            handleChange: sugerirInstituicaoHandler
                                        }
                                    }]
                            }],
                        rule: showIfSugerirInstituicao(scopePrefix)
                    }, {
                        type: 'HorizontalLayout',
                        elements: [{
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/unidadeId`,
                                label: 'Unidade',
                                options: {
                                    formatOptionLabel: option => `${option === null || option === void 0 ? void 0 : option.sigla}/${option === null || option === void 0 ? void 0 : option.nome}`,
                                    filter: {
                                        formFields: [scopePathFor('instituicaoId')],
                                        handler: (formData, listData, api) => {
                                            const instituicaoIdPath = scopePathFor('instituicaoId');
                                            const instituicaoId = get(formData, instituicaoIdPath);
                                            if (!instituicaoId)
                                                return Promise.resolve([]);
                                            return api.getAll({
                                                instituicaoId
                                            }).then(resposta => {
                                                return resposta;
                                            });
                                        }
                                    }
                                }
                            }, {
                                type: 'Boolean',
                                scope: `#${scopePrefix}/properties/sugerirUnidade`,
                                label: 'Sugerir Unidade',
                                options: {
                                    handleChange: sugerirUnidadeHandler
                                }
                            }],
                        rule: hideIfSugerirUnidade(scopePrefix)
                    }, {
                        type: 'HorizontalLayout',
                        elements: [{
                                type: 'Text',
                                scope: `#${scopePrefix}/properties/unidadeNome`,
                                label: 'Unidade Nome',
                                options: {
                                    format: 'string',
                                    maxLength: 32
                                }
                            }, {
                                type: 'HorizontalLayout',
                                elements: [{
                                        type: 'Text',
                                        scope: `#${scopePrefix}/properties/unidadeSigla`,
                                        label: 'Unidade Sigla',
                                        options: {
                                            format: 'string',
                                            maxLength: 8
                                        }
                                    }, {
                                        type: 'Boolean',
                                        scope: `#${scopePrefix}/properties/sugerirUnidade`,
                                        label: 'Sugerir Unidade',
                                        options: {
                                            handleChange: sugerirUnidadeHandler
                                        },
                                        rule: hideIfSugerirInstituicao(scopePrefix)
                                    }]
                            }],
                        rule: showIfSugerirUnidade(scopePrefix)
                    }, {
                        type: 'HorizontalLayout',
                        elements: [{
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/nivelAcademicoId`,
                                label: 'Nível Acadêmico'
                            }, {
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/interesseConsultor`,
                                label: 'Interesse em ser consultor'
                            }]
                    }]
            }, {
                type: 'HorizontalLayout',
                elements: [{
                        type: 'Control',
                        scope: `#${scopePrefix}/properties/lattes`,
                        label: 'Currículo Lattes'
                    }, {
                        type: 'Control',
                        scope: `#${scopePrefix}/properties/linkedin`,
                        label: 'Linkedin'
                    }]
            }, {
                type: 'Control',
                scope: `#${scopePrefix}/properties/areaDeConhecimento`,
                label: 'Suas Áreas de Conhecimento',
                options: {
                    label: 'Área de Conhecimento',
                    detail: {
                        type: 'VerticalLayout',
                        elements: [{
                                type: 'Select',
                                scope: `#/properties/grandeAreaId`,
                                label: 'Grande área',
                                options: {
                                    apiInstance: apiInstanceAreaDeConhecimento,
                                    handleChange: areaDeConhecimentoHandleChange,
                                    displayProperties: ['grandeAreaNome'],
                                    filter: {
                                        handler: (formData, listData, api) => {
                                            return api.buscarGrandesAreas();
                                        }
                                    },
                                    disableHandler: disableIfNoOptions
                                }
                            }, {
                                type: 'Select',
                                scope: `#/properties/areaId`,
                                label: 'Área',
                                options: {
                                    apiInstance: apiInstanceAreaDeConhecimento,
                                    handleChange: areaDeConhecimentoHandleChange,
                                    displayProperties: ['areaNome'],
                                    filter: {
                                        formFields: ['grandeAreaId'],
                                        handler: (_formData, _listData, api, queryFilterValues) => {
                                            if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                                return Promise.resolve([]);
                                            return api.buscarAreaPorGrandeAreaId(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value);
                                        }
                                    },
                                    disableHandler: disableIfNoOptions,
                                    visibleHandler: visibleIfHasFilter
                                }
                            }, {
                                type: 'Select',
                                scope: `#/properties/subAreaId`,
                                label: 'Sub-área',
                                options: {
                                    apiInstance: apiInstanceAreaDeConhecimento,
                                    displayProperties: ['subAreaNome'],
                                    handleChange: areaDeConhecimentoHandleChange,
                                    filter: {
                                        formFields: ['areaId'],
                                        handler: (_formData, _listData, api, queryFilterValues) => {
                                            if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                                return Promise.resolve([]);
                                            return api.buscarSubAreaPorAreaId(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value);
                                        }
                                    },
                                    disableHandler: disableIfNoOptions,
                                    visibleHandler: visibleIfHasFilter
                                }
                            }, {
                                type: 'Select',
                                scope: `#/properties/especialidadeId`,
                                label: 'Especialidade',
                                options: {
                                    apiInstance: apiInstanceAreaDeConhecimento,
                                    displayProperties: ['especialidadeNome'],
                                    handleChange: areaDeConhecimentoHandleChange,
                                    filter: {
                                        formFields: ['subAreaId'],
                                        handler: (_formData, _listData, api, queryFilterValues) => {
                                            if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                                return Promise.resolve([]);
                                            return api.buscarEspecialidadePorSubAreaId(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value);
                                        }
                                    },
                                    disableHandler: disableIfNoOptions,
                                    visibleHandler: visibleIfHasFilter
                                }
                            }]
                    }
                }
            }]
    };
};
