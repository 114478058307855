export const formatCurrency = function (value) {
    let currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'BRL';
    let locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'pt-BR';
    const cents = typeof value === 'string' ? parseInt(value, 10) : value;
    const reais = cents / 100;
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency
    }).format(reais);
};
