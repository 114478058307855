import { pascalCase } from 'change-case';
export const onlyNumbers = (field, message) => {
    var _a;
    return ({
        field: field,
        hasError: value => !String(value).match(/^[0-9]*$/),
        error: {
            keyword: 'onlyNumbers',
            message: message !== null && message !== void 0 ? message : 'Informe apenas números positivos',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            dataPath: `/${field}`,
            params: {}
        }
    });
};
export const customRequired = (field, message) => {
    var _a;
    return ({
        field: field,
        hasError: value => !value,
        error: {
            keyword: 'customRequired',
            message: 'Campo obrigatório',
            propertyName: field,
            instancePath: `/${((_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')) || field}`,
            schemaPath: '#/required',
            params: {}
        }
    });
};
export const customLength = (field, length, message) => {
    var _a;
    return ({
        field: field,
        hasError: value => String(value).length !== length,
        error: {
            keyword: 'customLength',
            message: message !== null && message !== void 0 ? message : `O tamanho do campo deve ser de ${length} caracteres.`,
            propertyName: field,
            instancePath: `/${((_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')) || field}`,
            schemaPath: '#/minLength',
            params: {
                length
            }
        }
    });
};
export const currency = (field, message) => {
    var _a;
    return ({
        field: field,
        hasError: value => !String(value).match(/^[0-9]+(.\d{2})?$/),
        error: {
            keyword: 'currency',
            message: message !== null && message !== void 0 ? message : 'Informe um valor com duas casas decimais ex.: 10,00',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            dataPath: `/${field}`,
            params: {}
        }
    });
};
export const positiveNumber = (field, message) => {
    var _a;
    return ({
        field: field,
        hasError: value => Number.isNaN(Number(value)) || Number(value) <= 0,
        error: {
            keyword: 'positiveNumber',
            message: message !== null && message !== void 0 ? message : 'O campo deve ser um número positivo.',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            dataPath: `/${field}`,
            params: {}
        }
    });
};
export const positiveNumberOrEmpty = (field, message) => {
    var _a;
    return ({
        field: field,
        hasError: value => value && (Number.isNaN(Number(value)) || Number(value) <= 0),
        error: {
            keyword: 'positiveNumberOrEmpty',
            message: message !== null && message !== void 0 ? message : 'O campo deve ser vazio ou um número positivo.',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            dataPath: `/${field}`,
            params: {}
        }
    });
};
export const whiteSpaces = (field, message) => {
    var _a;
    return ({
        field: field,
        hasError: value => String(value).length > 0 && String(value).replace(/\s/g, '').length === 0,
        error: {
            keyword: 'whiteSpaces',
            message: message !== null && message !== void 0 ? message : 'Campo obrigatório',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            dataPath: `/${field}`,
            params: {}
        }
    });
};
export const customError = (field, messages, data) => {
    var _a;
    const instancePath = `/${((_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')) || field}`;
    const fieldFormatted = field.replace(/\//g, '.');
    const currentValueData = fieldFormatted.split('.').reduce((acc, key) => acc === null || acc === void 0 ? void 0 : acc[key], data);
    return {
        field: fieldFormatted,
        hasError: value => value === currentValueData,
        error: {
            keyword: `customError${pascalCase(field)}`,
            message: messages.join(', '),
            propertyName: fieldFormatted,
            instancePath: instancePath,
            schemaPath: '#/required',
            params: {}
        }
    };
};
