var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DocumentAttach, Info, PersonXFill } from '../../../../components/styled-icons';
import { TipoRespostaPerguntaAvaliacao } from '../../../../models/pergunta-avaliacao';
import { stepHandlerErrors } from '../../../../utils/step-handler-errors';
import perguntaAvaliacaoUiSchema from '../../pergunta-avaliacao/pergunta-avaliacao.uischema';
import { handlerFormularioConsultoria } from './handler/handler-formulario-consultoria';
import { handlerFormularioConsultoriaFormula } from './handler/handler-formulario-consultoria-formula';
const iconsConfig = [{
        category: 'Dados do Formulário',
        icon: Info
    }, {
        category: 'Perguntas do Formulário',
        icon: DocumentAttach
    }, {
        category: 'Restrições',
        icon: PersonXFill
    }];
export const formatLabel = item => {
    return item.label;
};
const showRuleForPerguntasNotas = () => {
    return {
        effect: 'SHOW',
        condition: {
            type: 'OR',
            conditions: [{
                    scope: '#/properties/formularioConsultoriaPergunta',
                    schema: {
                        type: 'array',
                        minItems: 1,
                        contains: {
                            properties: {
                                tipoResposta: {
                                    const: TipoRespostaPerguntaAvaliacao.nota
                                }
                            }
                        }
                    }
                }, {
                    scope: '#/properties/formularioConsultoriaCategorizado',
                    schema: {
                        type: 'array',
                        contains: {
                            properties: {
                                formularioConsultoriaCategorizadoPerguntaAvaliacao: {
                                    type: 'array',
                                    minItems: 1,
                                    contains: {
                                        properties: {
                                            tipoResposta: {
                                                const: TipoRespostaPerguntaAvaliacao.nota
                                            }
                                        },
                                        required: ['tipoResposta']
                                    }
                                }
                            },
                            required: ['formularioConsultoriaCategorizadoPerguntaAvaliacao']
                        }
                    }
                }]
        }
    };
};
const hideIfNotCategorizada = {
    effect: 'HIDE',
    condition: {
        scope: '#/properties/perguntasCategorizadas',
        schema: {
            const: false
        }
    }
};
const hideIfCategorizada = {
    effect: 'HIDE',
    condition: {
        scope: '#/properties/perguntasCategorizadas',
        schema: {
            const: true
        }
    }
};
const gerenciarFormularioConsultoriaUiSchema = {
    type: 'Categorization',
    elements: [{
            type: 'Category',
            label: 'Dados do Formulário',
            options: {
                stepHandler: {
                    handler: () => stepHandlerErrors
                }
            },
            elements: [{
                    type: 'Control',
                    scope: '#/properties/titulo',
                    label: 'Título',
                    options: {
                        required: true
                    }
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Date',
                            scope: '#/properties/dataInicio',
                            label: 'Início',
                            options: {
                                dateFormat: 'DD/MM/YYYY',
                                required: true
                            }
                        }, {
                            type: 'Date',
                            scope: '#/properties/dataTermino',
                            label: 'Término',
                            options: {
                                dateFormat: 'DD/MM/YYYY',
                                required: true
                            }
                        }, {
                            type: 'Date',
                            scope: '#/properties/dataExpiracaoConvite',
                            label: 'Expiração do Convite',
                            options: {
                                dateFormat: 'DD/MM/YYYY',
                                required: true
                            }
                        }, {
                            type: 'Number',
                            scope: '#/properties/notaMinimaAprovacao',
                            label: 'Nota Mínima de Aprovação',
                            options: {
                                isPositiveNumber: true
                            }
                        }]
                }, {
                    type: 'Control',
                    scope: '#/properties/perguntasCategorizadas',
                    label: 'Será um formulário com perguntas categorizadas',
                    options: {
                        onlyCreate: true
                    }
                }]
        }, {
            type: 'Category',
            label: 'Restrições',
            options: {
                stepHandler: {
                    handler: () => stepHandlerErrors
                }
            },
            elements: [{
                    type: 'Select',
                    scope: '#/properties/documentoPropostaEdital',
                    label: 'Documentos Restritos',
                    options: {
                        formatOptionLabel: formatLabel,
                        selectMultiple: true,
                        displayProperties: ['nome'],
                        filter: {
                            selfContainedApi: true,
                            handler: (formData, _listData, _api, _queryFilterValues) => {
                                var _a, _b, _c;
                                if (!((_b = (_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.documentoPropostaEdital) === null || _b === void 0 ? void 0 : _b.length)) {
                                    return Promise.resolve([]);
                                }
                                return Promise.resolve((_c = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _c === void 0 ? void 0 : _c.documentoPropostaEdital.filter(x => !Boolean(x.deletedAt)).map(doc => ({
                                    label: doc.nome,
                                    id: doc.id,
                                    data: doc
                                })));
                            }
                        },
                        handleChange: (values, handler, formData, ctx, path) => __awaiter(void 0, void 0, void 0, function* () {
                            const mappedValues = values === null || values === void 0 ? void 0 : values.map(value => value === null || value === void 0 ? void 0 : value.data.data);
                            handler(`${path}`, mappedValues);
                        })
                    }
                }, {
                    type: 'Control',
                    scope: '#/properties/consultorPoderaVerDadosProponente',
                    label: 'Consultor poderá ver dados do proponente'
                }, {
                    type: 'Control',
                    scope: '#/properties/consultorPoderaVerDadosMembros',
                    label: 'Consultor poderá ver dados dos membros da equipe'
                }, {
                    type: 'Control',
                    scope: '#/properties/consultorPoderaVerDocsPessoaisProponente',
                    label: 'Consultor poderá ver documentos pessoais do proponente'
                }, {
                    type: 'Control',
                    scope: '#/properties/quantidadeMaximaConsultoresAtivo',
                    label: 'Limitar quantidade máxima de consultores',
                    options: {
                        handleChange: (value, handler, _formData, _ctx, path) => {
                            if (!value) {
                                handler('quantidadeMaximaConsultores', null);
                            }
                            handler(path, value);
                        }
                    }
                }, {
                    type: 'Control',
                    scope: '#/properties/quantidadeMaximaConsultores',
                    label: 'Quantidade máxima de consultores',
                    options: {
                        isPositiveNumber: true
                    },
                    rule: {
                        effect: 'SHOW',
                        condition: {
                            scope: '#/properties/quantidadeMaximaConsultoresAtivo',
                            expectedValue: false,
                            schema: {
                                const: true
                            }
                        }
                    }
                }]
        }, {
            type: 'Category',
            label: 'Perguntas do Formulário',
            options: {
                stepHandler: {
                    handler: handlerFormularioConsultoria
                }
            },
            elements: [{
                    type: 'Control',
                    scope: '#/properties/formularioConsultoriaCategorizado',
                    label: 'Perguntas Categorizadas',
                    options: {
                        elementLabelProp: 'tituloCategoria',
                        detail: {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/tituloCategoria',
                                    label: 'Título da Categoria'
                                }, {
                                    type: 'Select',
                                    scope: '#/properties/perguntaAvaliacaoId',
                                    label: 'Pergunta de Avaliação',
                                    options: {
                                        addEmptyOption: true,
                                        handleChange: (value, handler) => {
                                            handler('perguntaInfo', value);
                                        },
                                        filter: {
                                            handler: (data, listData, api, queryFilterValues, path) => {
                                                return api.getAll({
                                                    perguntaBase: true
                                                });
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/formularioConsultoriaCategorizadoPerguntaAvaliacao',
                                    label: 'Perguntas',
                                    options: {
                                        detail: perguntaAvaliacaoUiSchema(),
                                        elementLabelProp: 'pergunta',
                                        defaultValue: formData => {
                                            var _a;
                                            return (_a = formData.perguntaInfo) !== null && _a !== void 0 ? _a : {
                                                perguntaBase: false,
                                                nota: {
                                                    notaMinima: 0,
                                                    notaMaxima: 0
                                                },
                                                tipoResposta: true
                                            };
                                        },
                                        showSortButtons: true
                                    }
                                }]
                        },
                        showSortButtons: true
                    },
                    rule: hideIfNotCategorizada
                }, {
                    type: 'Select',
                    scope: '#/properties/perguntaAvaliacaoId',
                    label: 'Pergunta de Avaliação',
                    options: {
                        addEmptyOption: true,
                        handleChange: (value, handler) => {
                            handler('perguntaInfo', value);
                        },
                        filter: {
                            handler: (data, listData, api, queryFilterValues, path) => {
                                return api.getAll({
                                    perguntaBase: true
                                });
                            }
                        }
                    },
                    rule: hideIfCategorizada
                }, {
                    type: 'Control',
                    scope: '#/properties/formularioConsultoriaPergunta',
                    label: 'Perguntas',
                    options: {
                        detail: perguntaAvaliacaoUiSchema(),
                        elementLabelProp: 'pergunta',
                        defaultValue: formData => {
                            var _a;
                            return (_a = formData.perguntaInfo) !== null && _a !== void 0 ? _a : {
                                perguntaBase: false,
                                nota: {
                                    notaMinima: 0,
                                    notaMaxima: 0
                                },
                                tipoResposta: true
                            };
                        },
                        showSortButtons: true
                    },
                    rule: hideIfCategorizada
                }]
        }, {
            type: 'Category',
            label: 'Configurações',
            options: {
                stepHandler: {
                    handler: handlerFormularioConsultoriaFormula
                }
            },
            elements: [{
                    type: 'FormularioNota'
                }, {
                    type: 'Label',
                    options: {
                        formatOptionLabel: formData => {
                            var _a, _b;
                            if (((_a = formData.formularioConsultoriaPergunta) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                const perguntas = formData.formularioConsultoriaPergunta.map((pergunta, index) => {
                                    if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                                        return `P${index + 1}`;
                                    }
                                    else
                                        return null;
                                }).filter(titulo => titulo).join(', ');
                                return `Perguntas: (${perguntas})`;
                            }
                            else if (((_b = formData.formularioConsultoriaCategorizado) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                                const perguntas = [];
                                formData.formularioConsultoriaCategorizado.forEach((categoria, categoriaIndex) => {
                                    const categoriaPerguntas = categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao;
                                    if (!categoriaPerguntas)
                                        return;
                                    categoriaPerguntas.forEach((pergunta, perguntaIndex) => {
                                        if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                                            perguntas.push(`P${categoriaIndex + 1}.${perguntaIndex + 1}`);
                                        }
                                    });
                                });
                                return `Perguntas: (${perguntas.join(', ')})`;
                            }
                        }
                    }
                }, {
                    type: 'Control',
                    scope: '#/properties/possuiFormulaPersonalizada',
                    label: 'Utilizar fórmula personalizada'
                }, {
                    type: 'Control',
                    scope: '#/properties/formula',
                    rule: {
                        effect: 'DISABLE',
                        condition: {
                            scope: '#/properties/possuiFormulaPersonalizada',
                            schema: {
                                const: false
                            }
                        }
                    }
                }, {
                    type: 'Label',
                    options: {
                        formatOptionLabel: _ref => {
                            var _a;
                            let { totalFormula } = _ref;
                            return `[${(_a = totalFormula === null || totalFormula === void 0 ? void 0 : totalFormula.toFixed(2)) !== null && _a !== void 0 ? _a : Number('0.00')}]`;
                        }
                    }
                }],
            rule: showRuleForPerguntasNotas()
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        icons: iconsConfig
    }
};
export default gerenciarFormularioConsultoriaUiSchema;
