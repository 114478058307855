export const moduleName = 'BOLSA.MODALIDADE_BOLSA.NIVEL_BOLSA.NIVEL_BOLSA';
export const moduleLabel = 'Nível de Bolsa';
export const headCells = [{
        id: 'codigo',
        numeric: false,
        disablePadding: false,
        label: 'Código'
    }, {
        id: 'nivel',
        numeric: false,
        disablePadding: false,
        label: 'Nível'
    }, {
        id: 'moedaEstrangeiraId',
        numeric: false,
        disablePadding: false,
        label: 'Moeda',
        computedRowLabel: row => { var _a; return (row === null || row === void 0 ? void 0 : row.temMoedaEstrangeira) ? (_a = row === null || row === void 0 ? void 0 : row.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.simbolo : 'R$'; }
    }, {
        id: 'valorAtualBolsa',
        numeric: true,
        disablePadding: false,
        label: 'Valor',
        mask: 'coin'
    }, {
        id: 'cargaHoraria',
        numeric: true,
        disablePadding: false,
        label: 'Carga Horária'
    }];
