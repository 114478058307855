var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GenericApi } from '../../../api/generic-api';
export class AvaliacaoEnquadramentoApi extends GenericApi {
    constructor(editalId) {
        super('/proposta');
        this.editalId = editalId;
    }
    getAllForPagination(options) {
        const _super = Object.create(null, {
            getAllForPagination: { get: () => super.getAllForPagination }
        });
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield _super.getAllForPagination.call(this, Object.assign(Object.assign({}, options), { editalId: this.editalId }));
            }
            catch (e) {
                return {
                    data: [],
                    count: 0
                };
            }
        });
    }
}
export const makeAvaliacaoEnquadramento = editalId => {
    return new AvaliacaoEnquadramentoApi(editalId);
};
