const nivelAcademicoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/tipo'
        }, {
            type: 'Control',
            scope: '#/properties/codigo',
            label: 'Código'
        }]
};
export default nivelAcademicoUiSchema;
