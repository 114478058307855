export const getFromPath = (obj, path) => {
    var _a;
    const keys = path.split('.');
    let currentObj = obj;
    for (const key of keys) {
        if ((currentObj === null || currentObj === void 0 ? void 0 : currentObj[key]) && !('properties' in currentObj[key])) {
            return currentObj[key];
        }
        currentObj = (_a = currentObj === null || currentObj === void 0 ? void 0 : currentObj[key]) === null || _a === void 0 ? void 0 : _a.properties;
    }
    return {};
};
