var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import axios from 'axios';
const client = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_ADDR}${(_a = process.env.REACT_APP_API_PATH_PREFIX) !== null && _a !== void 0 ? _a : ''}/`,
    headers: {
        'Content-Type': 'application/json'
    }
});
const REFRESH_REQUEST_EXPIRATION_TIME = parseInt(process.env.REACT_APP_REFRESH_REQUEST_EXPIRATION_TIME);
let semaforo = null;
client.interceptors.request.use((config) => __awaiter(void 0, void 0, void 0, function* () {
    let token = localStorage.getItem('token');
    const expiresIn = localStorage.getItem('expiresIn');
    if (expiresIn) {
        const expiresInDate = new Date(parseInt(expiresIn));
        if (expiresInDate < new Date()) {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                if (!semaforo) {
                    semaforo = axios.post('/login', {
                        refreshToken
                    }, {
                        baseURL: client.defaults.baseURL
                    });
                }
                setTimeout(() => {
                    semaforo = null;
                }, REFRESH_REQUEST_EXPIRATION_TIME);
                try {
                    const { data: authData } = yield semaforo;
                    const currentDate = new Date();
                    localStorage.setItem('token', authData.token);
                    localStorage.setItem('permissions', JSON.stringify(authData.permissions));
                    localStorage.setItem('refreshToken', authData.refreshToken);
                    localStorage.setItem('expiresIn', currentDate.setSeconds(currentDate.getSeconds() + authData.expiresIn).toString());
                    token = authData.token;
                }
                catch (error) {
                    console.error('Refresh Token Incorreto', error);
                }
            }
        }
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}), error => Promise.reject(error));
export default client;
