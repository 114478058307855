export const moduleName = 'ORCAMENTO.PROGRAMA.PROGRAMA';
export const moduleLabel = 'Programas';
export const displayProperties = ['nome'];
export const headCells = [{
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }, {
        id: 'dataInicio',
        numeric: false,
        disablePadding: false,
        label: 'Data de Início',
        dateFormat: 'DD/MM/YYYY'
    }, {
        id: 'dataTermino',
        numeric: false,
        disablePadding: false,
        label: 'Data de Término',
        dateFormat: 'DD/MM/YYYY'
    }];
