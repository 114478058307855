import { formatCurrency } from '../../../helpers/format-currency';
import { insertDecimals } from '../../../helpers/insert-decimals';
import { TipoEditalRubrica, tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { TipoPessoa } from '../../../models/proposta';
import { get } from 'lodash';
const calcularTotais = (items, getTotal) => {
    return items.reduce((acc, item) => {
        const { total, contrapartida, temMoedaEstrangeira, moedaId } = getTotal(item);
        if (contrapartida) {
            if (temMoedaEstrangeira && moedaId !== undefined) {
                acc.contrapartidaEstrangeira[moedaId] = (acc.contrapartidaEstrangeira[moedaId] || 0) + total;
            }
            else {
                acc.contrapartida += total;
            }
        }
        else if (temMoedaEstrangeira && moedaId !== undefined) {
            acc.moedaEstrangeira[moedaId] = (acc.moedaEstrangeira[moedaId] || 0) + total;
        }
        else {
            acc.reais += total;
        }
        return acc;
    }, {
        reais: 0,
        moedaEstrangeira: {},
        contrapartida: 0,
        contrapartidaEstrangeira: {}
    });
};
const calcularDiariaTotal = rubricaDiaria => {
    var _a, _b;
    const total = insertDecimals(((_a = rubricaDiaria.numeroDiaria) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaDiaria.custoUnitario) !== null && _b !== void 0 ? _b : 0));
    return {
        total,
        contrapartida: rubricaDiaria.contrapartida,
        temMoedaEstrangeira: rubricaDiaria.temMoedaEstrangeira,
        moedaId: rubricaDiaria.temMoedaEstrangeira ? rubricaDiaria.moedaEstrangeiraId : undefined
    };
};
const calcularMaterialConsumoTotal = rubricaMaterialConsumo => {
    var _a, _b;
    const total = insertDecimals(((_a = rubricaMaterialConsumo.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaMaterialConsumo.custoUnitario) !== null && _b !== void 0 ? _b : 0));
    return {
        total,
        contrapartida: rubricaMaterialConsumo.contrapartida,
        temMoedaEstrangeira: rubricaMaterialConsumo.temMoedaEstrangeira,
        moedaId: rubricaMaterialConsumo.temMoedaEstrangeira ? rubricaMaterialConsumo.moedaEstrangeiraId : undefined
    };
};
const calcularMaterialPermanenteTotal = rubricaMaterialPermanente => {
    var _a, _b;
    const total = ((_a = rubricaMaterialPermanente.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaMaterialPermanente.custoUnitario) !== null && _b !== void 0 ? _b : 0);
    return {
        total,
        contrapartida: rubricaMaterialPermanente.contrapartida,
        temMoedaEstrangeira: rubricaMaterialPermanente.temMoedaEstrangeira,
        moedaId: rubricaMaterialPermanente.temMoedaEstrangeira ? rubricaMaterialPermanente.moedaEstrangeiraId : undefined
    };
};
const calcularPassagemTotal = rubricaPassagem => {
    var _a, _b;
    const total = ((_a = rubricaPassagem.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaPassagem.custoUnitario) !== null && _b !== void 0 ? _b : 0);
    return {
        total,
        contrapartida: rubricaPassagem.contrapartida,
        temMoedaEstrangeira: rubricaPassagem.temMoedaEstrangeira,
        moedaId: rubricaPassagem.temMoedaEstrangeira ? rubricaPassagem.moedaEstrangeiraId : undefined
    };
};
const calcularHospedagemTotal = rubricaHospedagemAlimentacao => {
    var _a, _b;
    const total = ((_a = rubricaHospedagemAlimentacao.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaHospedagemAlimentacao.custoUnitario) !== null && _b !== void 0 ? _b : 0);
    return {
        total,
        contrapartida: rubricaHospedagemAlimentacao.contrapartida,
        temMoedaEstrangeira: rubricaHospedagemAlimentacao.temMoedaEstrangeira,
        moedaId: rubricaHospedagemAlimentacao.temMoedaEstrangeira ? rubricaHospedagemAlimentacao.moedaEstrangeiraId : undefined
    };
};
const calcularServicoTerceiroTotal = rubricaServicoTerceiro => {
    var _a;
    return {
        total: (_a = rubricaServicoTerceiro.valorTotal) !== null && _a !== void 0 ? _a : 0,
        contrapartida: rubricaServicoTerceiro.contrapartida,
        temMoedaEstrangeira: rubricaServicoTerceiro.temMoedaEstrangeira,
        moedaId: rubricaServicoTerceiro.temMoedaEstrangeira ? rubricaServicoTerceiro.moedaEstrangeiraId : undefined
    };
};
const calcularEncargoTotal = rubricaEncargo => {
    var _a;
    return {
        total: (_a = rubricaEncargo.valorTotal) !== null && _a !== void 0 ? _a : 0,
        contrapartida: rubricaEncargo.contrapartida,
        temMoedaEstrangeira: rubricaEncargo.temMoedaEstrangeira,
        moedaId: rubricaEncargo.temMoedaEstrangeira ? rubricaEncargo.moedaEstrangeiraId : undefined
    };
};
const calcularPessoal = rubricaPessoal => {
    var _a;
    return {
        total: (_a = rubricaPessoal.valorTotal) !== null && _a !== void 0 ? _a : 0,
        contrapartida: rubricaPessoal.contrapartida,
        temMoedaEstrangeira: rubricaPessoal.temMoedaEstrangeira,
        moedaId: rubricaPessoal.temMoedaEstrangeira ? rubricaPessoal.moedaEstrangeiraId : undefined
    };
};
const calcularBolsas = bolsa => {
    var _a;
    return {
        total: (_a = bolsa.valorTotal) !== null && _a !== void 0 ? _a : 0,
        contrapartida: bolsa.contrapartida,
        temMoedaEstrangeira: bolsa.nivelBolsa.temMoedaEstrangeira,
        moedaId: bolsa.nivelBolsa.temMoedaEstrangeira ? bolsa.nivelBolsa.moedaEstrangeiraId : undefined
    };
};
export const calcularRubricas = (proposta, customPrefix) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    let columns = [];
    if ((_b = (_a = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) === null || _b === void 0 ? void 0 : _b.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.diarias))) {
        const rubricaDiaria = customPrefix ? get(proposta, customPrefix).rubricaDiaria : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaDiaria;
        columns.push({
            rubrica: tipoEditalRubricaI18N.diarias,
            calculo: (rubricaDiaria === null || rubricaDiaria === void 0 ? void 0 : rubricaDiaria.length) > 0 ? calcularTotais(rubricaDiaria, calcularDiariaTotal) : null
        });
    }
    if ((_d = (_c = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _c === void 0 ? void 0 : _c.editalRubrica) === null || _d === void 0 ? void 0 : _d.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.materialDeConsumo))) {
        const rubricaMaterialConsumo = customPrefix ? get(proposta, customPrefix).rubricaMaterialConsumo : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaMaterialConsumo;
        columns.push({
            rubrica: tipoEditalRubricaI18N.materialDeConsumo,
            calculo: (rubricaMaterialConsumo === null || rubricaMaterialConsumo === void 0 ? void 0 : rubricaMaterialConsumo.length) > 0 ? calcularTotais(rubricaMaterialConsumo, calcularMaterialConsumoTotal) : null
        });
    }
    if ((_f = (_e = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _e === void 0 ? void 0 : _e.editalRubrica) === null || _f === void 0 ? void 0 : _f.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.materialPermanente))) {
        const rubricaMaterialPermanente = customPrefix ? get(proposta, customPrefix).rubricaMaterialPermanente : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaMaterialPermanente;
        columns.push({
            rubrica: tipoEditalRubricaI18N.materialPermanente,
            calculo: (rubricaMaterialPermanente === null || rubricaMaterialPermanente === void 0 ? void 0 : rubricaMaterialPermanente.length) > 0 ? calcularTotais(rubricaMaterialPermanente, calcularMaterialPermanenteTotal) : null
        });
    }
    if ((_h = (_g = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _g === void 0 ? void 0 : _g.editalRubrica) === null || _h === void 0 ? void 0 : _h.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.passagens))) {
        const rubricaPassagem = customPrefix ? get(proposta, customPrefix).rubricaPassagem : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaPassagem;
        columns.push({
            rubrica: tipoEditalRubricaI18N.passagens,
            calculo: (rubricaPassagem === null || rubricaPassagem === void 0 ? void 0 : rubricaPassagem.length) > 0 ? calcularTotais(rubricaPassagem, calcularPassagemTotal) : null
        });
    }
    if ((_k = (_j = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _j === void 0 ? void 0 : _j.editalRubrica) === null || _k === void 0 ? void 0 : _k.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.hospedagemAlimentacao))) {
        const rubricaHospedagemAlimentacao = customPrefix ? get(proposta, customPrefix).rubricaHospedagemAlimentacao : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaHospedagemAlimentacao;
        columns.push({
            rubrica: tipoEditalRubricaI18N.hospedagemAlimentacao,
            calculo: (rubricaHospedagemAlimentacao === null || rubricaHospedagemAlimentacao === void 0 ? void 0 : rubricaHospedagemAlimentacao.length) > 0 ? calcularTotais(rubricaHospedagemAlimentacao, calcularHospedagemTotal) : null
        });
    }
    if ((_m = (_l = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _l === void 0 ? void 0 : _l.editalRubrica) === null || _m === void 0 ? void 0 : _m.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.encargos))) {
        const rubricaEncargo = customPrefix ? get(proposta, customPrefix).rubricaEncargo : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaEncargo;
        columns.push({
            rubrica: tipoEditalRubricaI18N.encargos,
            calculo: (rubricaEncargo === null || rubricaEncargo === void 0 ? void 0 : rubricaEncargo.length) > 0 ? calcularTotais(rubricaEncargo, calcularEncargoTotal) : null
        });
    }
    if ((_p = (_o = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _o === void 0 ? void 0 : _o.editalRubrica) === null || _p === void 0 ? void 0 : _p.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.pessoal))) {
        const rubricaPessoal = customPrefix ? get(proposta, customPrefix).rubricaPessoal : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaPessoal;
        columns.push({
            rubrica: tipoEditalRubricaI18N.pessoal,
            calculo: (rubricaPessoal === null || rubricaPessoal === void 0 ? void 0 : rubricaPessoal.length) > 0 ? calcularTotais(rubricaPessoal, calcularPessoal) : null
        });
    }
    if ((_r = (_q = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _q === void 0 ? void 0 : _q.editalRubrica) === null || _r === void 0 ? void 0 : _r.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.bolsa))) {
        const rubricaBolsa = customPrefix ? get(proposta, customPrefix).rubricaBolsa : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaBolsa;
        columns.push({
            rubrica: tipoEditalRubricaI18N.bolsa,
            calculo: (rubricaBolsa === null || rubricaBolsa === void 0 ? void 0 : rubricaBolsa.length) > 0 ? calcularTotais(rubricaBolsa, calcularBolsas) : null
        });
    }
    if ((_t = (_s = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _s === void 0 ? void 0 : _s.editalRubrica) === null || _t === void 0 ? void 0 : _t.some(rubrica => rubrica.tipoEditalRubrica === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.servicosDeTerceiros))) {
        const rubricaServicoTerceiro = customPrefix ? get(proposta, customPrefix).rubricaServicoTerceiro : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaServicoTerceiro;
        columns.push({
            rubrica: tipoEditalRubricaI18N.servicosDeTerceiros,
            calculo: (rubricaServicoTerceiro === null || rubricaServicoTerceiro === void 0 ? void 0 : rubricaServicoTerceiro.length) > 0 ? calcularTotais(rubricaServicoTerceiro, calcularServicoTerceiroTotal) : null
        });
        columns.push({
            rubrica: '- Pessoa Física',
            calculo: (rubricaServicoTerceiro === null || rubricaServicoTerceiro === void 0 ? void 0 : rubricaServicoTerceiro.length) > 0 ? calcularTotais(rubricaServicoTerceiro.filter(servico => servico.tipo === TipoPessoa.pessoaFisica), calcularServicoTerceiroTotal) : null
        });
        columns.push({
            rubrica: '- Pessoa Jurídica',
            calculo: (rubricaServicoTerceiro === null || rubricaServicoTerceiro === void 0 ? void 0 : rubricaServicoTerceiro.length) > 0 ? calcularTotais(rubricaServicoTerceiro.filter(servico => servico.tipo === TipoPessoa.pessoaJuridica), calcularServicoTerceiroTotal) : null
        });
    }
    return columns;
};
export const getOrcamentoConsolidadoTable = (formData, customPrefix) => {
    var _a, _b, _c, _d, _e;
    if ((_a = formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) {
        const headers = [{
                value: 'Nome',
                texto: 'Nome',
                colspan: 1
            }, {
                value: 'R$',
                texto: 'R$',
                colspan: 1,
                customSx: {
                    textAlign: 'center'
                }
            }];
        const rubricaResult = calcularRubricas(formData, customPrefix);
        let uniqueMoedaIds = new Set();
        let contrapartidaMoedaIds = new Set();
        let hasContrapartida = false;
        let contrapartidaColspan = 1;
        let rubricaColspan = 2;
        let totalReais = 0;
        let totalContrapartidaReais = 0;
        const totalMoedaEstrangeira = {};
        const totalContrapartidaMoedaEstrangeira = {};
        const cotacoes = getCotacoes(formData);
        rubricaResult.forEach(_ref => {
            let { rubrica, calculo } = _ref;
            if (calculo) {
                Object.keys(calculo.moedaEstrangeira).forEach(moedaId => {
                    uniqueMoedaIds.add(Number(moedaId));
                });
                if (calculo.contrapartida > 0 || Object.keys(calculo.contrapartidaEstrangeira).length > 0) {
                    hasContrapartida = true;
                }
                Object.keys(calculo.contrapartidaEstrangeira).forEach(moedaId => {
                    contrapartidaMoedaIds.add(Number(moedaId));
                });
            }
        });
        const sortedUniqueMoedaIds = Array.from(uniqueMoedaIds).sort((a, b) => a - b);
        const sortedContrapartidaMoedaIds = Array.from(contrapartidaMoedaIds).sort((a, b) => a - b);
        const moedaEstrangeiraRubrica = Array.from((_b = formData.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica.reduce((map, editalRubrica) => {
            editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira.forEach(moeda => {
                if (sortedUniqueMoedaIds.includes(moeda.id) && !map.has(moeda.id)) {
                    map.set(moeda.id, moeda);
                }
            });
            return map;
        }, new Map()).values());
        moedaEstrangeiraRubrica.forEach(moeda => {
            headers.push({
                value: moeda.simbolo,
                texto: moeda.simbolo,
                colspan: 1,
                customSx: {
                    textAlign: 'center'
                }
            });
            totalMoedaEstrangeira[moeda.id] = 0;
        });
        if (hasContrapartida) {
            headers.push({
                value: 'Contrapartida R$',
                texto: 'R$',
                colspan: 1,
                customSx: {
                    textAlign: 'center'
                }
            });
        }
        const moedaEstrangeiraContrapartida = Array.from((_c = formData.edital) === null || _c === void 0 ? void 0 : _c.editalRubrica.reduce((map, editalRubrica) => {
            editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira.forEach(moeda => {
                if (sortedContrapartidaMoedaIds.includes(moeda.id) && !map.has(moeda.id)) {
                    map.set(moeda.id, moeda);
                }
            });
            return map;
        }, new Map()).values());
        moedaEstrangeiraContrapartida.forEach(moeda => {
            headers.push({
                value: `Contrapartida Moeda ${moeda.simbolo}`,
                texto: moeda.simbolo,
                colspan: 1,
                customSx: {
                    textAlign: 'center'
                }
            });
            totalContrapartidaMoedaEstrangeira[moeda.id] = 0;
        });
        const columns = rubricaResult.map(_ref2 => {
            var _a, _b;
            let { rubrica, calculo } = _ref2;
            const row = {
                value: [{
                        header: 'Nome',
                        textValue: rubrica,
                        customSx: {
                            fontWeight: 700
                        }
                    }, {
                        header: 'R$',
                        textValue: formatCurrency((_a = calculo === null || calculo === void 0 ? void 0 : calculo.reais) !== null && _a !== void 0 ? _a : 0),
                        customSx: {
                            textAlign: 'center'
                        }
                    }]
            };
            if (rubrica !== '- Pessoa Jurídica' && rubrica !== '- Pessoa Física') {
                totalReais += (calculo === null || calculo === void 0 ? void 0 : calculo.reais) || 0;
            }
            moedaEstrangeiraRubrica.forEach(moeda => {
                const valorMoeda = (calculo === null || calculo === void 0 ? void 0 : calculo.moedaEstrangeira[moeda.id]) || 0;
                row.value.push({
                    header: moeda.simbolo,
                    textValue: formatCurrency(valorMoeda, moeda.sigla),
                    customSx: {
                        textAlign: 'center'
                    }
                });
                totalMoedaEstrangeira[moeda.id] += valorMoeda;
            });
            if (hasContrapartida) {
                row.value.push({
                    header: 'Contrapartida R$',
                    textValue: formatCurrency((_b = calculo === null || calculo === void 0 ? void 0 : calculo.contrapartida) !== null && _b !== void 0 ? _b : 0),
                    customSx: {
                        textAlign: 'center'
                    }
                });
                totalContrapartidaReais += (calculo === null || calculo === void 0 ? void 0 : calculo.contrapartida) || 0;
            }
            moedaEstrangeiraContrapartida.forEach(moeda => {
                const valorContrapartidaMoeda = (calculo === null || calculo === void 0 ? void 0 : calculo.contrapartidaEstrangeira[moeda.id]) || 0;
                row.value.push({
                    header: `Contrapartida Moeda ${moeda.simbolo}`,
                    textValue: formatCurrency(valorContrapartidaMoeda, moeda.sigla),
                    customSx: {
                        textAlign: 'center'
                    }
                });
                totalContrapartidaMoedaEstrangeira[moeda.id] += valorContrapartidaMoeda;
            });
            const total = (calculo === null || calculo === void 0 ? void 0 : calculo.reais) + ((calculo === null || calculo === void 0 ? void 0 : calculo.contrapartida) || 0) + moedaEstrangeiraRubrica.reduce((sum, moeda) => {
                var _a;
                const valorMoeda = (calculo === null || calculo === void 0 ? void 0 : calculo.moedaEstrangeira[moeda.id]) || 0;
                const cotacaoMoeda = insertDecimals((_a = cotacoes[moeda.id]) !== null && _a !== void 0 ? _a : '');
                const valorComCotacao = cotacaoMoeda ? valorMoeda * cotacaoMoeda : valorMoeda;
                return sum + valorComCotacao;
            }, 0) + moedaEstrangeiraContrapartida.reduce((sum, moeda) => {
                var _a;
                const valorContrapartidaMoeda = (calculo === null || calculo === void 0 ? void 0 : calculo.contrapartidaEstrangeira[moeda.id]) || 0;
                const cotacaoContrapartidaMoeda = insertDecimals((_a = cotacoes[moeda.id]) !== null && _a !== void 0 ? _a : '');
                const valorContrapartidaComCotacao = cotacaoContrapartidaMoeda ? valorContrapartidaMoeda * cotacaoContrapartidaMoeda : valorContrapartidaMoeda;
                return sum + valorContrapartidaComCotacao;
            }, 0);
            row.value.push({
                header: 'Total R$',
                textValue: formatCurrency(isNaN(total) ? 0 : total),
                customSx: {
                    textAlign: 'center',
                    fontWeight: 700
                }
            });
            return row;
        });
        const totalRow = {
            value: [{
                    header: 'Nome',
                    textValue: 'Valor Total',
                    customSx: {
                        fontWeight: 700
                    }
                }, {
                    header: 'R$',
                    textValue: formatCurrency(totalReais),
                    customSx: {
                        textAlign: 'center',
                        fontWeight: 700
                    }
                }]
        };
        moedaEstrangeiraRubrica.forEach(moeda => {
            totalRow.value.push({
                header: moeda.simbolo,
                textValue: formatCurrency(totalMoedaEstrangeira[moeda.id], moeda.sigla),
                customSx: {
                    textAlign: 'center',
                    fontWeight: 700
                }
            });
            rubricaColspan++;
        });
        totalRow.value.push({
            header: 'Contrapartida R$',
            textValue: formatCurrency(totalContrapartidaReais),
            customSx: {
                textAlign: 'center',
                fontWeight: 700
            }
        });
        moedaEstrangeiraContrapartida.forEach(moeda => {
            totalRow.value.push({
                header: `Contrapartida Moeda ${moeda.simbolo}`,
                textValue: formatCurrency(totalContrapartidaMoedaEstrangeira[moeda.id], moeda.sigla),
                customSx: {
                    textAlign: 'center',
                    fontWeight: 700
                }
            });
            contrapartidaColspan++;
        });
        if (((_e = (_d = formData.edital) === null || _d === void 0 ? void 0 : _d.editalRubrica) === null || _e === void 0 ? void 0 : _e.length) > 1) {
            const calculoTotal = totalReais + Object.keys(totalMoedaEstrangeira).reduce((acc, moedaId) => {
                var _a;
                const valorMoeda = totalMoedaEstrangeira[moedaId] || 0;
                const cotacaoMoeda = insertDecimals((_a = cotacoes[moedaId]) !== null && _a !== void 0 ? _a : '');
                const valorComCotacao = cotacaoMoeda ? valorMoeda * cotacaoMoeda : valorMoeda;
                return acc + valorComCotacao;
            }, 0) + Object.keys(totalContrapartidaMoedaEstrangeira).reduce((acc, moedaId) => {
                var _a;
                const valorContrapartidaMoeda = totalContrapartidaMoedaEstrangeira[moedaId] || 0;
                const cotacaoContrapartidaMoeda = insertDecimals((_a = cotacoes[moedaId]) !== null && _a !== void 0 ? _a : '');
                const valorContrapartidaComCotacao = cotacaoContrapartidaMoeda ? valorContrapartidaMoeda * cotacaoContrapartidaMoeda : valorContrapartidaMoeda;
                return acc + valorContrapartidaComCotacao;
            }, 0) + totalContrapartidaReais;
            totalRow.value.push({
                header: 'Total R$',
                textValue: formatCurrency(isNaN(calculoTotal) ? 0 : calculoTotal),
                customSx: {
                    textAlign: 'center',
                    fontWeight: 700
                }
            });
            columns.push(totalRow);
        }
        let subtitles = [{
                texto: 'Rubrica',
                colspan: rubricaColspan,
                customSx: {
                    textAlign: 'center'
                }
            }];
        if (hasContrapartida) {
            subtitles.push({
                texto: 'Contrapartida',
                colspan: contrapartidaColspan,
                customSx: {
                    textAlign: 'center'
                }
            });
        }
        if (hasContrapartida || moedaEstrangeiraRubrica.length > 0 || moedaEstrangeiraContrapartida.length > 0) {
            headers.push({
                value: 'Total R$',
                texto: 'R$',
                colspan: 1,
                customSx: {
                    textAlign: 'center'
                }
            });
            subtitles.push({
                texto: 'Total',
                colspan: 1,
                customSx: {
                    textAlign: 'center'
                }
            });
        }
        return {
            headers: headers,
            columns: columns,
            subtitle: subtitles
        };
    }
    else {
        return {
            headers: [],
            columns: [],
            subtitle: []
        };
    }
};
export const getMoedasEstrangeirasUnificadas = formData => {
    var _a, _b;
    if ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) {
        const rubricaResult = calcularRubricas(formData);
        const uniqueMoedaIds = new Set();
        const contrapartidaMoedaIds = new Set();
        rubricaResult.forEach(_ref3 => {
            let { rubrica, calculo } = _ref3;
            if (calculo) {
                Object.keys(calculo.moedaEstrangeira).forEach(moedaId => {
                    uniqueMoedaIds.add(Number(moedaId));
                });
                Object.keys(calculo.contrapartidaEstrangeira).forEach(moedaId => {
                    contrapartidaMoedaIds.add(Number(moedaId));
                });
            }
        });
        const combinedMoedaIds = new Set([...uniqueMoedaIds, ...contrapartidaMoedaIds]);
        return Array.from((_b = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica.reduce((map, editalRubrica) => {
            editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira.forEach(moeda => {
                if (combinedMoedaIds.has(moeda.id) && !map.has(moeda.id)) {
                    map.set(moeda.id, moeda);
                }
            });
            return map;
        }, new Map()).values());
    }
    else {
        return null;
    }
};
export const getCotacoes = formData => {
    if (formData.cotacaoMoedaEstrangeira) {
        return formData === null || formData === void 0 ? void 0 : formData.cotacaoMoedaEstrangeira.reduce((acc, _ref4) => {
            let { moedaEstrangeiraId, valor } = _ref4;
            acc[moedaEstrangeiraId] = valor;
            return acc;
        }, {});
    }
    else {
        return [];
    }
};
