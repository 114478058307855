const AreaDeConhecimentoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Control',
            scope: '#/properties/grandeAreaNome',
            label: 'Grande área',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/areaNome',
            label: 'Área'
        }, {
            type: 'Control',
            scope: '#/properties/subAreaNome',
            label: 'Sub-área'
        }, {
            type: 'Control',
            scope: '#/properties/especialidadeNome',
            label: 'Especialidade'
        }]
};
export default AreaDeConhecimentoUiSchema;
