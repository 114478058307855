const perfilUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            label: 'Nome',
            type: 'Control',
            scope: '#/properties/nome',
            options: {
                required: true
            }
        }, {
            label: 'Descrição',
            type: 'Control',
            scope: '#/properties/descricao'
        }]
};
export default perfilUiSchema;
