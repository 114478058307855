import { camelCase } from 'change-case';
import { formatFloat } from '../../../helpers/format-float';
import { TipoEditalRubrica, tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { formatLabel } from '../../../shared/edital/edital.uischema';
import { buildTableLayoutHeader } from '../../../shared/proposta/table-layout-header';
import { contrapartidaHandleChange } from './helpers/contrapartidaHandleChange';
import { createShowRuleForEditalRubrica } from './helpers/createShowRuleForEditalRubrica';
import { getJustificativaStatus } from './helpers/getJustificativaStatus';
import { requiredIfContrapartida } from './helpers/requiredIfContrapartida';
import { showIfContrapartida } from './helpers/showIfContrapartida';
import { showIfJustificativaGlobal } from './helpers/showIfJustificativaGlobal';
import { visibleHandlerJustificativaGlobal } from './helpers/visibleHandlerJustificativaGlobal';
import { visibleHandlerJustificativaObrigatoria } from './helpers/visibleHandlerJustificativaObrigatoria';
export const getBolsaAnswerForm = function () {
    let { proposta, readonly = false, customHeader, customFooter } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/revisao',
                    label: '',
                    options: {
                        readonly: true,
                        maxLength: 256,
                        customHeader,
                        customFooter,
                        visibleHandler: ctx => {
                            var _a;
                            return (_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.orcamentoEdicao;
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/modalidadeBolsaId',
                    label: 'Modalidade da Bolsa',
                    options: {
                        readonly,
                        required: true,
                        filter: {
                            handler: (formData, listData, api, queryFilterValues, path) => {
                                var _a, _b;
                                const data = (formData === null || formData === void 0 ? void 0 : formData.edital) ? formData : proposta;
                                const bolsaEdital = (_b = (_a = data === null || data === void 0 ? void 0 : data.edital) === null || _a === void 0 ? void 0 : _a.bolsaEdital) === null || _b === void 0 ? void 0 : _b.map(bolsaEdital => bolsaEdital === null || bolsaEdital === void 0 ? void 0 : bolsaEdital.modalidadeBolsaId);
                                //Filtra as modalidades de bolsa que estão no edital
                                return api.getAll().then(response => response.filter(modalidadeBolsa => bolsaEdital === null || bolsaEdital === void 0 ? void 0 : bolsaEdital.find(modalidadeBolsaEdital => modalidadeBolsaEdital === (modalidadeBolsa === null || modalidadeBolsa === void 0 ? void 0 : modalidadeBolsa.id))));
                            }
                        },
                        handleChange: (item, handler, formData, ctx, path) => {
                            //Adiciona o objeto inteiro no formulário provisório
                            handler(`${path}`.replace('modalidadeBolsaId', 'modalidadeBolsa'), item);
                        }
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/nivelBolsaId',
                    label: 'Nível da Bolsa',
                    options: {
                        readonly,
                        required: true,
                        formatOptionLabel: formatLabel,
                        handleChange: (item, handler, formData, ctx, path) => {
                            handler(`${path}`.replace('nivelBolsaId', 'nivelBolsa'), item);
                        },
                        filter: {
                            formFields: ['modalidadeBolsaId'],
                            handler: (formData, listData, api, queryFilterValues, path) => {
                                var _a, _b, _c, _d, _e;
                                const rubricaBolsaArray = (_a = proposta === null || proposta === void 0 ? void 0 : proposta.rubricaBolsa) !== null && _a !== void 0 ? _a : formData.rubricaBolsa;
                                const nivelBolsaEditalArray = (_c = (_b = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _b === void 0 ? void 0 : _b.bolsaEdital) !== null && _c !== void 0 ? _c : (_d = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _d === void 0 ? void 0 : _d.bolsaEdital;
                                const rubricaBolsaUnsaved = formData['rubricaBolsaUnsaved'];
                                if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                    return Promise.resolve([]);
                                return api.getAll({
                                    modalidadeBolsaId: (_e = queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0]) === null || _e === void 0 ? void 0 : _e.value
                                }).then(response => response.filter(nivelBolsa => {
                                    const isInProposta = rubricaBolsaArray === null || rubricaBolsaArray === void 0 ? void 0 : rubricaBolsaArray.some(rubricaBolsa => rubricaBolsa.nivelBolsaId === nivelBolsa.id && rubricaBolsa !== rubricaBolsaUnsaved);
                                    const isInEdital = nivelBolsaEditalArray === null || nivelBolsaEditalArray === void 0 ? void 0 : nivelBolsaEditalArray.some(nivelBolsaEdital => nivelBolsaEdital.nivelBolsaId === nivelBolsa.id);
                                    const isUnsaved = (rubricaBolsaUnsaved === null || rubricaBolsaUnsaved === void 0 ? void 0 : rubricaBolsaUnsaved.nivelBolsaId) === nivelBolsa.id;
                                    return proposta ? true : (!isInProposta || isUnsaved) && isInEdital;
                                }));
                            }
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Number',
                    scope: '#/properties/quantidade',
                    label: 'Quantidade',
                    options: {
                        readonly,
                        numberStep: 1,
                        required: true,
                        isPositiveNumber: true,
                        handleChange: (quantidade, handler, formData, ctx, path) => {
                            var _a, _b, _c, _d, _e;
                            const duracao = (_b = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaBolsaUnsaved']) === null || _a === void 0 ? void 0 : _a.duracao) !== null && _b !== void 0 ? _b : 0;
                            const valorAtualBolsa = (_e = (_d = (_c = formData === null || formData === void 0 ? void 0 : formData['rubricaBolsaUnsaved']) === null || _c === void 0 ? void 0 : _c.nivelBolsa) === null || _d === void 0 ? void 0 : _d.valorAtualBolsa) !== null && _e !== void 0 ? _e : 0;
                            const valorTotal = duracao * quantidade * valorAtualBolsa;
                            handler(`quantidade`, quantidade);
                            handler(`${path}`.replace('quantidade', 'valorTotal'), isNaN(valorTotal) ? 0 : valorTotal);
                        }
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/duracao',
                    label: 'Duração (Em meses)',
                    options: {
                        readonly,
                        required: true,
                        oneOf: formData => {
                            const data = (formData === null || formData === void 0 ? void 0 : formData.duracao) ? formData : proposta;
                            return Array.from({
                                length: data === null || data === void 0 ? void 0 : data.duracao
                            }, (_, i) => ({
                                title: `${i + 1}`,
                                const: i + 1
                            }));
                        },
                        handleChange: (duracao, handler, formData, ctx, path) => {
                            var _a, _b, _c, _d, _e;
                            const quantidade = (_b = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaBolsaUnsaved']) === null || _a === void 0 ? void 0 : _a.quantidade) !== null && _b !== void 0 ? _b : 0;
                            const valorAtualBolsa = (_e = (_d = (_c = formData === null || formData === void 0 ? void 0 : formData['rubricaBolsaUnsaved']) === null || _c === void 0 ? void 0 : _c.nivelBolsa) === null || _d === void 0 ? void 0 : _d.valorAtualBolsa) !== null && _e !== void 0 ? _e : 0;
                            const valorTotal = duracao * quantidade * valorAtualBolsa;
                            handler(path.replace('duracao', 'valorTotal'), isNaN(valorTotal) ? 0 : valorTotal);
                        }
                    }
                }, {
                    type: 'MonetaryInput',
                    scope: '#/properties/valorTotal',
                    label: 'Valor Total',
                    options: {
                        required: true,
                        disabled: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Boolean',
                    scope: '#/properties/contrapartida',
                    options: {
                        readonly,
                        defaultValue: false,
                        handleChange: contrapartidaHandleChange
                    }
                }, {
                    type: 'Control',
                    scope: '#/properties/entidade',
                    label: 'Entidade',
                    rule: showIfContrapartida,
                    options: {
                        readonly,
                        required: requiredIfContrapartida('rubricaBolsaUnsaved')
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/tipoPessoa',
                    label: 'Tipo Pessoa',
                    rule: showIfContrapartida,
                    options: {
                        readonly,
                        required: requiredIfContrapartida('rubricaBolsaUnsaved')
                    }
                }]
        }, {
            type: 'LongText',
            scope: '#properties/justificativa',
            label: 'Justificativa',
            options: {
                readonly,
                maxLength: 256,
                label: getJustificativaStatus(TipoEditalRubrica.bolsa),
                visibleHandler: visibleHandlerJustificativaObrigatoria(TipoEditalRubrica.bolsa),
                required: ctx => {
                    var _a, _b;
                    const { formData } = ctx;
                    return (_b = (_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) === null || _b === void 0 ? void 0 : _b.some(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.bolsa) && (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.temJustificativaObrigatoria));
                }
            }
        }];
};
export const rubricaBolsaUischema = function () {
    let { route, customPaths, scopePrefix = '', revisaoOrcamento, customHeader, customFooter, customActions } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
        type: 'Category',
        rule: createShowRuleForEditalRubrica(TipoEditalRubrica.bolsa),
        label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.bolsa)],
        options: {
            stepHandler: {
                handler: stepHandlerErrors
            }
        },
        elements: [{
                type: 'ArrayTableLayout',
                scope: `#${scopePrefix}/properties/rubricaBolsa`,
                label: 'Bolsas',
                options: {
                    route: route !== null && route !== void 0 ? route : '/proposta',
                    customPaths: customPaths !== null && customPaths !== void 0 ? customPaths : {},
                    hideCreate: revisaoOrcamento,
                    hideDefaultActions: revisaoOrcamento,
                    additionalData: formData => ({
                        editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                    }),
                    buildHeader: {
                        field: 'valorTotal',
                        builder: buildTableLayoutHeader
                    },
                    customActions,
                    headCells: [{
                            label: 'Modalidade e Nível',
                            field: row => { var _a, _b; return `${(_a = row.modalidadeBolsa) === null || _a === void 0 ? void 0 : _a.nome} - ${(_b = row.nivelBolsa) === null || _b === void 0 ? void 0 : _b.nivel}`; }
                        }, {
                            label: 'Quantidade',
                            field: 'quantidade'
                        }, {
                            label: 'Duração',
                            field: 'duracao'
                        }, {
                            label: 'Valor Bolsa',
                            field: row => { var _a, _b; return ((_a = row.nivelBolsa) === null || _a === void 0 ? void 0 : _a.valorAtualBolsa) ? formatFloat((_b = row.nivelBolsa) === null || _b === void 0 ? void 0 : _b.valorAtualBolsa) : '-'; }
                        }, {
                            label: 'Contrapartida',
                            field: row => row.contrapartida ? 'Sim' : 'Não'
                        }, {
                            label: 'Valor Total',
                            field: row => row.valorTotal ? formatFloat(row.valorTotal) : '-'
                        }],
                    label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.bolsa)],
                    detail: {
                        type: 'VerticalLayout',
                        elements: getBolsaAnswerForm({
                            customHeader,
                            customFooter
                        })
                    }
                }
            }, {
                type: 'LongText',
                scope: '#properties/justificativaGlobal',
                label: 'Justificativa Global',
                options: {
                    maxLength: 256,
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.bolsa),
                    arrayProps: {
                        id: 'bolsa',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.bolsa),
                        arrayScope: 'justificativa'
                    }
                },
                rule: showIfJustificativaGlobal('rubricaBolsa')
            }, {
                type: 'Constant',
                scope: '#properties/justificativaGlobal',
                options: {
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.bolsa),
                    arrayProps: {
                        id: 'bolsa',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.bolsa),
                        arrayScope: 'tipoEditalRubrica'
                    },
                    value: TipoEditalRubrica.bolsa
                },
                rule: showIfJustificativaGlobal('rubricaBolsa')
            }]
    };
};
