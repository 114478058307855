var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Api } from './api';
import client from '../config/axios-config';
export class IbgeApi extends Api {
    buscarMunicipioPorEstadoId(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.get(`${this.url}estados/${id}/municipios`);
            return response.data;
        });
    }
    buscarEstados() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.get(`${this.url}estados?orderBy=nome`);
            return response.data;
        });
    }
}
export const makeIbgeApi = () => {
    return new IbgeApi(`${process.env.REACT_APP_IBGE_API_BASE_ADDR}`);
};
