var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeApi } from '../../../api/generic-api';
const edicaoRecursoProgramaUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'Select',
            scope: '#/properties/edicaoLinhaDeAcaoId',
            label: 'Linha de Ação',
            options: {
                required: true,
                apiInstance: makeApi('/edicao-linha-de-acao'),
                filter: {
                    handler: (formData, listData, api) => {
                        if (!formData.ano)
                            return Promise.resolve([formData.edicaoLinhaDeAcao]);
                        const resultFn = () => __awaiter(void 0, void 0, void 0, function* () {
                            return (yield api.getAll({
                                ano: formData === null || formData === void 0 ? void 0 : formData.ano
                            })).filter(x => { var _a; return formData.id || !((_a = formData.edicoesRecurso) === null || _a === void 0 ? void 0 : _a.some(y => y.edicaoLinhaDeAcao.id === x.id)); });
                        });
                        return resultFn();
                    }
                },
                displayProperties: ['linhaDeAcao.titulo', 'ano'],
                route: 'edicao-linha-de-acao',
                formatOptionLabel: option => {
                    return `${option.linhaDeAcao.titulo} - ${option.ano}`;
                }
            }
        }, {
            type: 'Select',
            scope: '#/properties/funcionalProgramaticaId',
            options: {
                required: true
            }
        }, {
            type: 'Control',
            scope: '#/properties/descricao',
            label: 'Observação'
        }, {
            type: 'Control',
            scope: '#/properties/edicaoRecursoProgramaNaturezaDespesa',
            label: 'Natureza da Despesa',
            options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Select',
                            scope: '#/properties/naturezaDespesaId',
                            label: 'Natureza da Despesa',
                            options: {
                                filter: {
                                    handler: (formData, listData, api, onLoad, path) => {
                                        var _a;
                                        const filter = ((_a = formData.edicaoRecursoProgramaNaturezaDespesa) === null || _a === void 0 ? void 0 : _a.map(value => value.naturezaDespesaId)) || [];
                                        return api.getAll().then(data => {
                                            if (!formData.ano) {
                                                return data;
                                            }
                                            return data.filter(x => !filter.includes(x.id));
                                        });
                                    }
                                }
                            }
                        }, {
                            type: 'MonetaryInput',
                            scope: '#/properties/valor',
                            label: 'Valor'
                        }]
                }
            }
        }]
};
export default edicaoRecursoProgramaUiSchema;
