export const displayProperties = ['nome'];
export const moduleLabel = 'Publicar Edital';
export const moduleName = 'EDITAL.EDITAL';
export const headCells = [{
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }];
export const filterSchema = {
    type: 'object',
    properties: {
        filters: {
            type: 'object',
            properties: {
                titulo: {
                    type: 'string',
                    label: 'Título',
                    pinned: true
                },
                protocolo: {
                    type: 'string',
                    label: 'Protocolo'
                },
                edital: {
                    type: 'string',
                    foreignRoute: 'edital',
                    label: 'Edital'
                },
                programa: {
                    type: 'string',
                    foreignRoute: 'programa',
                    label: 'Programa'
                },
                coordenador: {
                    type: 'string',
                    label: 'Coordenador',
                    pinned: true
                },
                sexoCoordenador: {
                    type: 'string',
                    oneOf: [{
                            const: 'M',
                            title: 'Masculino'
                        }, {
                            const: 'F',
                            title: 'Feminino'
                        }, {
                            const: 'X',
                            title: 'Outro'
                        }],
                    label: 'Sexo Coordenador'
                },
                membroEquipe: {
                    type: 'string',
                    label: 'Membro Equipe'
                },
                instituicao: {
                    type: 'string',
                    foreignRoute: 'instituicao',
                    label: 'Instituição'
                },
                unidade: {
                    type: 'string',
                    foreignRoute: 'unidade',
                    label: 'Unidade'
                },
                estado: {
                    type: 'string',
                    oneOf: [{
                            const: 'SP',
                            title: 'São Paulo'
                        }, {
                            const: 'RJ',
                            title: 'Rio de Janeiro'
                        }, {
                            const: 'MG',
                            title: 'Minas Gerais'
                        }, {
                            const: 'ES',
                            title: 'Espírito Santo'
                        }, {
                            const: 'PR',
                            title: 'Paraná'
                        }, {
                            const: 'SC',
                            title: 'Santa Catarina'
                        }, {
                            const: 'RS',
                            title: 'Rio Grande do Sul'
                        }, {
                            const: 'MS',
                            title: 'Mato Grosso do Sul'
                        }, {
                            const: 'MT',
                            title: 'Mato Grosso'
                        }, {
                            const: 'GO',
                            title: 'Goiás'
                        }, {
                            const: 'DF',
                            title: 'Distrito Federal'
                        }, {
                            const: 'TO',
                            title: 'Tocantins'
                        }, {
                            const: 'PA',
                            title: 'Pará'
                        }, {
                            const: 'AP',
                            title: 'Amapá'
                        }, {
                            const: 'AM',
                            title: 'Amazonas'
                        }, {
                            const: 'AC',
                            title: 'Acre'
                        }, {
                            const: 'RO',
                            title: 'Rondônia'
                        }, {
                            const: 'RR',
                            title: 'Roraima'
                        }, {
                            const: 'BA',
                            title: 'Bahia'
                        }, {
                            const: 'SE',
                            title: 'Sergipe'
                        }, {
                            const: 'AL',
                            title: 'Alagoas'
                        }, {
                            const: 'PE',
                            title: 'Pernambuco'
                        }, {
                            const: 'PB',
                            title: 'Paraíba'
                        }, {
                            const: 'RN',
                            title: 'Rio Grande do Norte'
                        }, {
                            const: 'CE',
                            title: 'Ceará'
                        }, {
                            const: 'PI',
                            title: 'Piauí'
                        }, {
                            const: 'MA',
                            title: 'Maranhão'
                        }],
                    label: 'Estado'
                },
                municipio: {
                    type: 'string',
                    oneOf: [{
                            const: 'SP001',
                            title: 'São Paulo - Capital'
                        }, {
                            const: 'SP002',
                            title: 'Campinas'
                        }, {
                            const: 'SP003',
                            title: 'Santos'
                        }, {
                            const: 'RJ001',
                            title: 'Rio de Janeiro - Capital'
                        }, {
                            const: 'RJ002',
                            title: 'Niterói'
                        }, {
                            const: 'MG001',
                            title: 'Belo Horizonte'
                        }, {
                            const: 'MG002',
                            title: 'Uberlândia'
                        }, {
                            const: 'ES001',
                            title: 'Vitória'
                        }, {
                            const: 'PR001',
                            title: 'Curitiba'
                        }, {
                            const: 'SC001',
                            title: 'Florianópolis'
                        }, {
                            const: 'RS001',
                            title: 'Porto Alegre'
                        }, {
                            const: 'DF001',
                            title: 'Brasília'
                        }],
                    label: 'Município'
                },
                areaConhecimento: {
                    type: 'string',
                    oneOf: [{
                            const: '1',
                            title: 'Ciências Exatas e da Terra'
                        }, {
                            const: '2',
                            title: 'Ciências Biológicas'
                        }, {
                            const: '3',
                            title: 'Engenharias'
                        }, {
                            const: '4',
                            title: 'Ciências da Saúde'
                        }, {
                            const: '5',
                            title: 'Ciências Agrárias'
                        }, {
                            const: '6',
                            title: 'Ciências Sociais Aplicadas'
                        }, {
                            const: '7',
                            title: 'Ciências Humanas'
                        }, {
                            const: '8',
                            title: 'Linguística, Letras e Artes'
                        }, {
                            const: '9',
                            title: 'Multidisciplinar'
                        }],
                    label: 'Área Conhecimento'
                },
                data: {
                    type: 'string',
                    format: 'date',
                    label: 'Data'
                },
                situacao: {
                    type: 'object',
                    label: 'Situação',
                    format: 'situacao',
                    locked: true,
                    properties: {
                        propostas: {
                            type: 'array',
                            label: 'Propostas:',
                            enum: ['Em edição pelo pesquisador', 'Sob enquadramento', 'Sob avaliação dos consultores AD-HOC', 'Sob avaliação final', 'Proposta de projetos não aprovados', 'Proposta de projetos não enquadrada', 'Proposta de projetos enquadrada', 'Sob adequação orçamentária proponente', 'Projeto cancelado - não contratado', 'Proposta de projeto em fase de recurso', 'Sob avaliação setorial']
                        },
                        aprovado: {
                            type: 'array',
                            label: 'Projeto Aprovado:',
                            enum: ['Projeto em fase de contratação', 'Projeto não contratado', 'Proposta não contratada mas aprovada no mérito', 'Projeto aprovado em lista de espera']
                        },
                        contratado: {
                            type: 'array',
                            label: 'Projeto Contratado:',
                            enum: ['Projeto em andamento', 'Projeto concluído', 'Projeto cancelado', 'Projeto substituído', 'Projeto não concluído', 'Projeto concluído em fase de prestação de contas', 'Projeto concluído e homologado', 'Projeto concluído, homologado e em processo de doação de bens', 'Projeto concluído e não homologado', 'Processo encerrado', 'Projeto em tomada de contas especial', 'Projeto concluído e com pendências', 'Em recuperação de crédito - POE', 'TCE Concluída, processo em deliberação no conselho']
                        }
                    }
                }
            }
        }
    }
};
