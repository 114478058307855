const requireComponent = require.context('./', true);
requireComponent.keys().forEach(requireComponent);
/**
 * importa automaticamente todas as views do diretorio src/views/*.tsx
 */
export const views = requireComponent.keys().filter(fileName => fileName.includes('.view.tsx')).flatMap(fileName => {
    try {
        return Object.values(requireComponent(fileName));
    }
    catch (e) {
        console.error(e);
    }
    return null;
});
/**
 * importa automaticamente todos os "Head Cells" do diretorio src/views/*
 */
export const headCells = requireComponent.keys().filter(fileName => fileName.includes('.headcell.ts')).map(fileName => {
    const module = requireComponent(fileName);
    const pathSplited = fileName.replace(/\.\//, '').replace(/\.headcell\.ts$/, '').split('/');
    const id = pathSplited[pathSplited.length - 1];
    return {
        id,
        value: module.headCells
    };
}).filter(entry => Boolean(entry.value));
export const displayProperties = requireComponent.keys().filter(fileName => fileName.includes('.headcell.ts')).map(fileName => {
    const module = requireComponent(fileName);
    const pathSplited = fileName.replace(/\.\//, '').replace(/\.headcell\.ts$/, '').split('/');
    const id = pathSplited[pathSplited.length - 1];
    return {
        id,
        value: module.displayProperties
    };
}).filter(entry => Boolean(entry.value));
export const moduleLabels = requireComponent.keys().filter(fileName => fileName.includes('.headcell.ts')).map(fileName => {
    const module = requireComponent(fileName);
    const pathSplited = fileName.replace(/\.\//, '').replace(/\.headcell\.ts$/, '').split('/');
    const id = pathSplited[pathSplited.length - 1];
    return {
        id,
        value: module.moduleLabel
    };
}).filter(entry => Boolean(entry.value));
export const rowsLength = requireComponent.keys().filter(fileName => fileName.includes('.headcell.ts')).map(fileName => {
    const module = requireComponent(fileName);
    const pathSplited = fileName.replace(/\.\//, '').replace(/\.headcell\.ts$/, '').split('/');
    const id = pathSplited[pathSplited.length - 1];
    return {
        id,
        value: module.rowsLength
    };
}).filter(entry => Boolean(entry.value));
export const moduleNames = requireComponent.keys().filter(fileName => fileName.includes('.headcell.ts')).map(fileName => {
    const module = requireComponent(fileName);
    const pathSplited = fileName.replace(/\.\//, '').replace(/\.headcell\.ts$/, '').split('/');
    const id = pathSplited[pathSplited.length - 1];
    return {
        id,
        value: module.moduleName
    };
}).filter(entry => Boolean(entry.value));
export const filterSchemas = requireComponent.keys().filter(fileName => fileName.includes('.headcell.ts')).map(fileName => {
    const module = requireComponent(fileName);
    const pathSplited = fileName.replace(/\.\//, '').replace(/\.headcell\.ts$/, '').split('/');
    const id = pathSplited[pathSplited.length - 1];
    return {
        id,
        schema: module.filterSchema
    };
}).filter(entry => Boolean(entry.schema));
