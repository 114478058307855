import dayjs from '../dayjs/sig-dayjs';
export const normalize = date => dayjs.utc(date).local();
export const ptBrformatDate = date => date.format('DD/MM/YYYY HH:mm:ss');
export const changeTimeFromDate = (date, time) => {
    let newDate = date.add(dayjs.duration({
        hours: time
    }));
    if (time < 0) {
        newDate = date.subtract(dayjs.duration({
            hours: time * -1
        }));
    }
    return newDate;
};
export const compareDates = (firstDate, secondDate, operator) => {
    switch (operator) {
        case '>':
            return firstDate.isAfter(secondDate);
        case '<':
            return firstDate.isBefore(secondDate);
        case '=':
            return firstDate.isSame(secondDate);
        case '>=':
            return firstDate.isSameOrAfter(secondDate);
        case '<=':
            return firstDate.isSameOrBefore(secondDate);
    }
};
