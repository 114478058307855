import { formatCurrency } from '../../../helpers/format-currency';
import { insertDecimals } from '../../../helpers/insert-decimals';
import { TipoEditalRubrica, tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { TipoPessoa } from '../../../models/proposta';
import { getCotacoes } from './calcular-rubricas';
import { get } from 'lodash';
const calcularTotais = (items, getTotal, cotacoes, duracaoProjetoEmMeses) => {
    return items.reduce((acc, item) => {
        var _a;
        const { total, temMoedaEstrangeira, mesPrevisto, moedaId } = getTotal(item);
        const cotacao = temMoedaEstrangeira && moedaId ? insertDecimals((_a = cotacoes[moedaId]) !== null && _a !== void 0 ? _a : 0) || 1 : 1;
        const valorEmReais = total * cotacao;
        const ano = Math.floor((mesPrevisto - 1) / 12) + 1;
        let trimestre;
        if (mesPrevisto <= duracaoProjetoEmMeses) {
            trimestre = Math.floor((mesPrevisto - 1) % 12 / 3) + 1;
        }
        else {
            trimestre = 1;
        }
        if (!acc.totaisPorAno[ano]) {
            acc.totaisPorAno[ano] = {
                1: 0,
                2: 0,
                3: 0,
                4: 0
            };
            acc.totaisPorAnoTotal[ano] = 0;
        }
        acc.totaisPorAno[ano][trimestre] += valorEmReais;
        acc.totaisPorAnoTotal[ano] += valorEmReais;
        return acc;
    }, {
        totaisPorAno: {},
        totaisPorAnoTotal: {}
    });
};
const calcularDiariaTotal = rubricaDiaria => {
    var _a, _b;
    const total = rubricaDiaria.contrapartida ? 0 : insertDecimals(((_a = rubricaDiaria.numeroDiaria) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaDiaria.custoUnitario) !== null && _b !== void 0 ? _b : 0));
    return {
        total,
        temMoedaEstrangeira: rubricaDiaria.temMoedaEstrangeira || false,
        mesPrevisto: rubricaDiaria.mesPrevisto,
        moedaId: rubricaDiaria.temMoedaEstrangeira ? rubricaDiaria.moedaEstrangeiraId : undefined
    };
};
const calcularMaterialConsumoTotal = rubricaMaterialConsumo => {
    var _a, _b;
    const total = rubricaMaterialConsumo.contrapartida ? 0 : insertDecimals(((_a = rubricaMaterialConsumo.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaMaterialConsumo.custoUnitario) !== null && _b !== void 0 ? _b : 0));
    return {
        total,
        temMoedaEstrangeira: rubricaMaterialConsumo.temMoedaEstrangeira,
        mesPrevisto: rubricaMaterialConsumo.mesPrevisto,
        moedaId: rubricaMaterialConsumo.temMoedaEstrangeira ? rubricaMaterialConsumo.moedaEstrangeiraId : undefined
    };
};
const calcularMaterialPermanenteTotal = rubricaMaterialPermanente => {
    var _a, _b;
    const total = rubricaMaterialPermanente.contrapartida ? 0 : ((_a = rubricaMaterialPermanente.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaMaterialPermanente.custoUnitario) !== null && _b !== void 0 ? _b : 0);
    return {
        total,
        temMoedaEstrangeira: rubricaMaterialPermanente.temMoedaEstrangeira,
        mesPrevisto: rubricaMaterialPermanente.mesPrevisto,
        moedaId: rubricaMaterialPermanente.temMoedaEstrangeira ? rubricaMaterialPermanente.moedaEstrangeiraId : undefined
    };
};
const calcularPassagemTotal = rubricaPassagem => {
    var _a, _b;
    const total = rubricaPassagem.contrapartida ? 0 : ((_a = rubricaPassagem.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaPassagem.custoUnitario) !== null && _b !== void 0 ? _b : 0);
    return {
        total,
        temMoedaEstrangeira: rubricaPassagem.temMoedaEstrangeira,
        mesPrevisto: rubricaPassagem.mesPrevisto,
        moedaId: rubricaPassagem.temMoedaEstrangeira ? rubricaPassagem.moedaEstrangeiraId : undefined
    };
};
const calcularHospedagemTotal = rubricaHospedagemAlimentacao => {
    var _a, _b;
    const total = rubricaHospedagemAlimentacao.contrapartida ? 0 : ((_a = rubricaHospedagemAlimentacao.quantidade) !== null && _a !== void 0 ? _a : 0) * ((_b = rubricaHospedagemAlimentacao.custoUnitario) !== null && _b !== void 0 ? _b : 0);
    return {
        total,
        temMoedaEstrangeira: rubricaHospedagemAlimentacao.temMoedaEstrangeira,
        mesPrevisto: rubricaHospedagemAlimentacao.mesPrevisto,
        moedaId: rubricaHospedagemAlimentacao.temMoedaEstrangeira ? rubricaHospedagemAlimentacao.moedaEstrangeiraId : undefined
    };
};
const calcularServicoTerceiroTotal = rubricaServicoTerceiro => {
    var _a;
    const total = rubricaServicoTerceiro.contrapartida ? 0 : (_a = rubricaServicoTerceiro.valorTotal) !== null && _a !== void 0 ? _a : 0;
    return {
        total,
        temMoedaEstrangeira: rubricaServicoTerceiro.temMoedaEstrangeira,
        mesPrevisto: rubricaServicoTerceiro.mesPrevisto,
        moedaId: rubricaServicoTerceiro.temMoedaEstrangeira ? rubricaServicoTerceiro.moedaEstrangeiraId : undefined
    };
};
const calcularEncargoTotal = rubricaEncargo => {
    var _a;
    const total = rubricaEncargo.contrapartida ? 0 : (_a = rubricaEncargo.valorTotal) !== null && _a !== void 0 ? _a : 0;
    return {
        total,
        temMoedaEstrangeira: rubricaEncargo.temMoedaEstrangeira,
        mesPrevisto: rubricaEncargo.mesPrevisto,
        moedaId: rubricaEncargo.temMoedaEstrangeira ? rubricaEncargo.moedaEstrangeiraId : undefined
    };
};
const calcularPessoal = rubricaPessoal => {
    var _a;
    const total = rubricaPessoal.contrapartida ? 0 : (_a = rubricaPessoal.valorTotal) !== null && _a !== void 0 ? _a : 0;
    return {
        total,
        temMoedaEstrangeira: rubricaPessoal.temMoedaEstrangeira,
        mesPrevisto: rubricaPessoal.mesInicio,
        moedaId: rubricaPessoal.temMoedaEstrangeira ? rubricaPessoal.moedaEstrangeiraId : undefined
    };
};
const calcularBolsas = rubricaBolsa => {
    var _a;
    const total = rubricaBolsa.contrapartida ? 0 : (_a = rubricaBolsa.valorTotal) !== null && _a !== void 0 ? _a : 0;
    return {
        total,
        temMoedaEstrangeira: rubricaBolsa.nivelBolsa.temMoedaEstrangeira,
        mesPrevisto: 1,
        moedaId: rubricaBolsa.nivelBolsa.temMoedaEstrangeira ? rubricaBolsa.nivelBolsa.moedaEstrangeiraId : undefined
    };
};
const calcularServicoTerceiroPorTipoPessoa = (servicos, tipoPessoa, cotacoes, duracaoProjetoEmMeses) => {
    if (servicos) {
        const servicosFiltrados = servicos.filter(servico => servico.tipo === tipoPessoa);
        const resultado = calcularTotais(servicosFiltrados, calcularServicoTerceiroTotal, cotacoes, duracaoProjetoEmMeses);
        return resultado;
    }
};
const calcularRubricasTrimestral = (proposta, customPrefix) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    let columns = [];
    const cotacoes = getCotacoes(proposta);
    const calcularSeExisteRubrica = (tipoRubrica, rubricaLabel, lista, calcularTotalFn, cotacoes) => {
        var _a, _b;
        if ((_b = (_a = proposta === null || proposta === void 0 ? void 0 : proposta.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) === null || _b === void 0 ? void 0 : _b.some(rubrica => rubrica.tipoEditalRubrica === tipoRubrica)) {
            const calculo = (lista === null || lista === void 0 ? void 0 : lista.length) > 0 ? calcularTotais(lista, calcularTotalFn, cotacoes, proposta.duracao) : {
                totaisPorAno: {},
                totaisPorAnoTotal: {}
            };
            for (let ano = 1; ano <= Math.ceil(proposta.duracao / 12); ano++) {
                if (!calculo.totaisPorAno[ano]) {
                    calculo.totaisPorAno[ano] = {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0
                    };
                    calculo.totaisPorAnoTotal[ano] = 0;
                }
            }
            columns.push({
                rubrica: rubricaLabel,
                calculo
            });
            if (tipoRubrica === TipoEditalRubrica.servicosDeTerceiros) {
                const totalPessoaJuridica = calcularServicoTerceiroPorTipoPessoa(proposta.rubricaServicoTerceiro, TipoPessoa.pessoaJuridica, cotacoes, proposta.duracao);
                const totalPessoaFisica = calcularServicoTerceiroPorTipoPessoa(proposta.rubricaServicoTerceiro, TipoPessoa.pessoaFisica, cotacoes, proposta.duracao);
                columns.push({
                    rubrica: `- Pessoa Física`,
                    calculo: totalPessoaFisica || {
                        totaisPorAno: {},
                        totaisPorAnoTotal: {}
                    }
                });
                columns.push({
                    rubrica: `- Pessoa Jurídica`,
                    calculo: totalPessoaJuridica || {
                        totaisPorAno: {},
                        totaisPorAnoTotal: {}
                    }
                });
            }
        }
    };
    calcularSeExisteRubrica(TipoEditalRubrica.diarias, tipoEditalRubricaI18N.diarias, customPrefix ? (_a = get(proposta, customPrefix)) === null || _a === void 0 ? void 0 : _a.rubricaDiaria : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaDiaria, calcularDiariaTotal, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.materialDeConsumo, tipoEditalRubricaI18N.materialDeConsumo, customPrefix ? (_b = get(proposta, customPrefix)) === null || _b === void 0 ? void 0 : _b.rubricaMaterialConsumo : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaMaterialConsumo, calcularMaterialConsumoTotal, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.materialPermanente, tipoEditalRubricaI18N.materialPermanente, customPrefix ? (_c = get(proposta, customPrefix)) === null || _c === void 0 ? void 0 : _c.rubricaMaterialPermanente : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaMaterialPermanente, calcularMaterialPermanenteTotal, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.passagens, tipoEditalRubricaI18N.passagens, customPrefix ? (_d = get(proposta, customPrefix)) === null || _d === void 0 ? void 0 : _d.rubricaPassagem : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaPassagem, calcularPassagemTotal, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.hospedagemAlimentacao, tipoEditalRubricaI18N.hospedagemAlimentacao, customPrefix ? (_e = get(proposta, customPrefix)) === null || _e === void 0 ? void 0 : _e.rubricaHospedagemAlimentacao : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaHospedagemAlimentacao, calcularHospedagemTotal, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.encargos, tipoEditalRubricaI18N.encargos, customPrefix ? (_f = get(proposta, customPrefix)) === null || _f === void 0 ? void 0 : _f.rubricaEncargo : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaEncargo, calcularEncargoTotal, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.pessoal, tipoEditalRubricaI18N.pessoal, customPrefix ? (_g = get(proposta, customPrefix)) === null || _g === void 0 ? void 0 : _g.rubricaPessoal : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaPessoal, calcularPessoal, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.bolsa, tipoEditalRubricaI18N.bolsa, customPrefix ? (_h = get(proposta, customPrefix)) === null || _h === void 0 ? void 0 : _h.rubricaBolsa : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaBolsa, calcularBolsas, cotacoes);
    calcularSeExisteRubrica(TipoEditalRubrica.servicosDeTerceiros, tipoEditalRubricaI18N.servicosDeTerceiros, customPrefix ? (_j = get(proposta, customPrefix)) === null || _j === void 0 ? void 0 : _j.rubricaServicoTerceiro : proposta === null || proposta === void 0 ? void 0 : proposta.rubricaServicoTerceiro, calcularServicoTerceiroTotal, cotacoes);
    return columns;
};
export const getRubricasTrimestralTable = (proposta, customPrefix) => {
    const rubricasTrimestrais = calcularRubricasTrimestral(proposta, customPrefix);
    const tables = [];
    const trimestresPorAno = calcularQuantidadeDeTrimestresPorAno(proposta.duracao);
    Object.keys(trimestresPorAno).forEach(anoStr => {
        const ano = Number(anoStr);
        const quantidadeDeTrimestres = trimestresPorAno[ano];
        const rows = [];
        let totalPorAno = {};
        rubricasTrimestrais.forEach(rubricaData => {
            var _a;
            const totaisPorAnoRubrica = (_a = rubricaData.calculo) === null || _a === void 0 ? void 0 : _a.totaisPorAno[ano];
            if (rubricaData.rubrica !== '- Pessoa Jurídica' && rubricaData.rubrica !== '- Pessoa Física') {
                Object.entries(totaisPorAnoRubrica !== null && totaisPorAnoRubrica !== void 0 ? totaisPorAnoRubrica : {}).forEach(_ref => {
                    let [trimestreStr, valor] = _ref;
                    const trimestre = Number(trimestreStr);
                    if (!totalPorAno[ano]) {
                        totalPorAno[ano] = {};
                    }
                    totalPorAno[ano][trimestre] = (totalPorAno[ano][trimestre] || 0) + (valor || 0);
                });
            }
            const row = [{
                    header: 'nome',
                    textValue: rubricaData.rubrica,
                    customSx: {
                        fontWeight: 700
                    }
                }, ...[1, 2, 3, 4].slice(0, quantidadeDeTrimestres).map(trimestre => {
                    var _a;
                    return {
                        header: `trimestre${trimestre}`,
                        textValue: formatCurrency(totaisPorAnoRubrica ? (_a = totaisPorAnoRubrica[trimestre]) !== null && _a !== void 0 ? _a : 0 : 0),
                        customSx: {
                            textAlign: 'center'
                        }
                    };
                }), {
                    header: 'total',
                    textValue: formatCurrency(Object.values(totaisPorAnoRubrica !== null && totaisPorAnoRubrica !== void 0 ? totaisPorAnoRubrica : {}).reduce((a, b) => a + b, 0) || 0),
                    customSx: {
                        textAlign: 'center',
                        fontWeight: 700
                    }
                }];
            rows.push({
                value: row
            });
        });
        const totalPorTrimestre = {
            1: 0,
            2: 0,
            3: 0,
            4: 0
        };
        let totalGeralAno = 0;
        Object.entries(totalPorAno[ano] || {}).forEach(_ref2 => {
            let [trimestreStr, valor] = _ref2;
            const trimestre = Number(trimestreStr);
            totalPorTrimestre[trimestre] += valor || 0;
            totalGeralAno += valor || 0;
        });
        const totalRow = [{
                header: 'nome',
                textValue: 'Valor Total',
                customSx: {
                    textAlign: 'center',
                    fontWeight: 700
                }
            }, ...[1, 2, 3, 4].slice(0, quantidadeDeTrimestres).map(trimestre => {
                return {
                    header: `trimestre${trimestre}`,
                    textValue: formatCurrency(totalPorTrimestre[trimestre] || 0),
                    customSx: {
                        textAlign: 'center',
                        fontWeight: 700
                    }
                };
            }), {
                header: 'total',
                textValue: formatCurrency(totalGeralAno || 0),
                customSx: {
                    textAlign: 'center',
                    fontWeight: 700
                }
            }];
        rows.push({
            value: totalRow
        });
        tables.push({
            title: `Ano ${ano}`,
            subtitle: [{
                    texto: 'Rubrica',
                    colspan: 1,
                    customSx: {
                        textAlign: 'center'
                    }
                }, {
                    texto: quantidadeDeTrimestres > 1 ? 'Trimestres' : 'Trimestre',
                    colspan: quantidadeDeTrimestres,
                    customSx: {
                        textAlign: 'center'
                    }
                }, {
                    texto: 'Total',
                    colspan: 1,
                    customSx: {
                        textAlign: 'center'
                    }
                }],
            headers: [{
                    value: 'nome',
                    texto: 'Nome',
                    colspan: 1,
                    customSx: {
                        textAlign: 'center'
                    }
                }, ...[1, 2, 3, 4].slice(0, quantidadeDeTrimestres).map(trimestre => ({
                    value: `trimestre${trimestre}`,
                    texto: trimestre,
                    colspan: 1,
                    customSx: {
                        textAlign: 'center'
                    }
                })), {
                    value: 'total',
                    texto: 'R$',
                    colspan: 1,
                    customSx: {
                        textAlign: 'center'
                    }
                }],
            rows
        });
    });
    return tables;
};
const calcularQuantidadeDeTrimestresPorAno = duracaoEmMeses => {
    if (duracaoEmMeses <= 0) {
        return {};
    }
    const trimestresPorAno = {};
    let mesesRestantes = duracaoEmMeses;
    let anoAtual = 1;
    while (mesesRestantes > 0) {
        const mesesNoAno = Math.min(12, mesesRestantes);
        const trimestres = Math.ceil(mesesNoAno / 3);
        trimestresPorAno[anoAtual] = trimestres;
        mesesRestantes -= 12;
        anoAtual++;
    }
    return trimestresPorAno;
};
