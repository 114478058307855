var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from '../config/axios-config';
import { GenericApi } from './generic-api';
export class RubricaApi extends GenericApi {
    getRubricaSchema(tipoEditalRubrica) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.get(`${this.url}/schema?tipoEditalRubrica=${tipoEditalRubrica}`);
                return response.data;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('GetSchema error: ', {
                    cause: error
                });
            }
        });
    }
    revisaoOrcamento(id, data) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield client.put(`${this.url}/${id}/revisao-orcamento`, data);
                return response;
            }
            catch (error) {
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                throw new Error('Put error: ', {
                    cause: error
                });
            }
        });
    }
}
export const makeRubricaApi = apiOptions => {
    return new RubricaApi('/rubrica', apiOptions);
};
