var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pascalCase } from 'change-case';
import { makeApi } from '../../../api/generic-api';
export const stepHandlerDocumentoPropostaEdital = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { formData, setFormData, crudStates, parameterFilters, setParameterFilters, showError } = ctx;
    const api = makeApi(url);
    const urlVar = `step${pascalCase((_a = url === null || url === void 0 ? void 0 : url.replace) === null || _a === void 0 ? void 0 : _a.call(url, '/', ''))}Id`;
    const isCreated = !!(formData.id && (parameterFilters === null || parameterFilters === void 0 ? void 0 : parameterFilters[urlVar]) && Number(parameterFilters === null || parameterFilters === void 0 ? void 0 : parameterFilters[urlVar]) === (formData === null || formData === void 0 ? void 0 : formData.id));
    if (crudStates.add && !isCreated) {
        const postResponse = yield ((_b = api === null || api === void 0 ? void 0 : api.post) === null || _b === void 0 ? void 0 : _b.call(api, formData));
        if (postResponse.status === 201) {
            setParameterFilters(Object.assign(Object.assign({}, parameterFilters), { [urlVar]: (_c = postResponse === null || postResponse === void 0 ? void 0 : postResponse.data) === null || _c === void 0 ? void 0 : _c.id }));
            setFormData(Object.assign(Object.assign({}, formData), postResponse.data));
            return true;
        }
        else {
            console.error(postResponse);
            return false;
        }
    }
    if (crudStates.edit || crudStates.add && isCreated) {
        let putResponse;
        try {
            putResponse = yield ((_d = api === null || api === void 0 ? void 0 : api.put) === null || _d === void 0 ? void 0 : _d.call(api, formData === null || formData === void 0 ? void 0 : formData.id, formData));
            if (putResponse.status === 200) {
                return true;
            }
        }
        catch (error) {
            console.error(putResponse);
            let errorMessage = ((_g = (_f = (_e = error === null || error === void 0 ? void 0 : error.cause) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.message) || ((_j = (_h = error === null || error === void 0 ? void 0 : error.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.message);
            if (Array.isArray(errorMessage))
                errorMessage = errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage[0];
            errorMessage = (_m = (_k = errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.replace) === null || _k === void 0 ? void 0 : (_l = _k.call(errorMessage, 'documentoPropostaEdital.', 'Documento da Proposta ')).replace) === null || _m === void 0 ? void 0 : _m.call(_l, /(\d+)\.(\s*)/, (_, p1, p2) => {
                let incrementedNumber = parseInt(p1, 10) + 1;
                return incrementedNumber + '. ' + p2;
            });
            showError(errorMessage || 'Ocorreu um erro.');
            return false;
        }
    }
    return true;
});
