import { MoneyCalculator, Calendar, Business, Attachment, User } from '../../../components/styled-icons';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerBack } from '../../../utils/step-handler-back';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { RuleEffect } from '@jsonforms/core';
import { TipoTermoAditivo } from '../../../models/tipo-termo-aditivo';
const iconConfig = [{
        category: 'Termo Aditivo',
        icon: User
    }, {
        category: 'Aditivo de Prazo',
        icon: Calendar
    }, {
        category: 'Aditivo de Participantes',
        icon: Business
    }, {
        category: 'Aditivo de Recurso',
        icon: MoneyCalculator
    }, {
        category: 'Anexos',
        icon: Attachment
    }];
const termoAditivoInstrumentoJuridicoUiSchema = {
    type: 'Categorization',
    elements: [{
            type: 'Category',
            label: 'Termo Aditivo',
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'Select',
                                    scope: '#/properties/instrumentoJuridicoId',
                                    label: 'Instrumento Jurídico',
                                    options: {
                                        disabled: true
                                    }
                                }]
                        }, {
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Control',
                                            scope: '#/properties/codigo',
                                            label: 'Código',
                                            options: {
                                                required: true
                                            }
                                        }, {
                                            type: 'Date',
                                            scope: '#/properties/dataPublicacao',
                                            label: 'Data de Publicação',
                                            options: {
                                                dateFormat: 'DD/MM/YYYY',
                                                required: true
                                            }
                                        }]
                                }, {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/tipoTermoAditivo',
                                            label: 'Tipo de Termo Aditivo',
                                            options: {
                                                required: true
                                            }
                                        }, {
                                            type: 'Date',
                                            scope: '#/properties/dataAssinatura',
                                            label: 'Data de Assinatura',
                                            options: {
                                                dateFormat: 'DD/MM/YYYY',
                                                required: true
                                            }
                                        }]
                                }]
                        }, {
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'LongText',
                                    scope: '#/properties/objetivoDoTermo',
                                    label: 'Objetivo do Termo'
                                }]
                        }]
                }],
            options: {
                stepHandler: {
                    handler: stepHandlerErrors
                }
            }
        }, {
            type: 'Category',
            label: 'Aditivo de Prazo',
            options: {
                stepHandler: {
                    handler: stepHandlerErrors,
                    callback: ctx => createResource(ctx, '/termo-aditivo-instrumento-juridico')
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Date',
                                            scope: '#/properties/novoInicioVigente',
                                            label: 'Novo Início da Vigência',
                                            options: {
                                                dateFormat: 'DD/MM/YYYY',
                                                required: true
                                            }
                                        }]
                                }, {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Date',
                                            scope: '#/properties/novoTerminoVigente',
                                            label: 'Novo Término da Vigência',
                                            options: {
                                                dateFormat: 'DD/MM/YYYY',
                                                required: true
                                            }
                                        }]
                                }]
                        }]
                }],
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/tipoTermoAditivo',
                    schema: {
                        const: TipoTermoAditivo.aditivoPrazo
                    }
                }
            }
        }, {
            type: 'Category',
            label: 'Aditivo de Participantes',
            options: {
                stepHandler: {
                    handler: stepHandlerErrors,
                    callback: ctx => createResource(ctx, '/termo-aditivo-instrumento-juridico')
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/instituicaoId',
                                            label: 'Instituição',
                                            options: {
                                                filter: {
                                                    formFields: ['instrumentoJuridicoId'],
                                                    handler: (formData, listData, api) => {
                                                        if (!formData.instrumentoJuridicoId)
                                                            return Promise.resolve(listData);
                                                        return api.getAll({
                                                            chave: 'instrumentoJuridicoId',
                                                            valor: formData === null || formData === void 0 ? void 0 : formData.instrumentoJuridicoId,
                                                            instrumentoJuridicoId: formData === null || formData === void 0 ? void 0 : formData.instrumentoJuridicoId
                                                        });
                                                    }
                                                }
                                            }
                                        }, {
                                            type: 'Control',
                                            scope: '#/properties/porcentagemOutrosFins',
                                            label: '% para outros fins'
                                        }]
                                }, {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'MonetaryInput',
                                            scope: '#/properties/valorFirmado',
                                            label: 'Valor firmado',
                                            options: {
                                                required: true
                                            }
                                        }, {
                                            type: 'Select',
                                            scope: '#/properties/tipoParceria',
                                            label: 'Parceria'
                                        }]
                                }]
                        }]
                }],
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/tipoTermoAditivo',
                    schema: {
                        const: TipoTermoAditivo.aditivoParticipantes
                    }
                }
            }
        }, {
            type: 'Category',
            label: 'Aditivo de Recurso',
            options: {
                stepHandler: {
                    handler: stepHandlerErrors,
                    callback: ctx => createResource(ctx, '/termo-aditivo-instrumento-juridico')
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'HorizontalLayout',
                            elements: [{
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/instituicaoInstrumentoJuridicoId',
                                            options: {
                                                filter: {
                                                    formFields: ['instrumentoJuridicoId'],
                                                    handler: (formData, listData, api) => {
                                                        if (!formData.instrumentoJuridicoId)
                                                            return Promise.resolve(listData);
                                                        return api.getAll({
                                                            chave: 'instrumentoJuridicoId',
                                                            valor: formData === null || formData === void 0 ? void 0 : formData.instrumentoJuridicoId,
                                                            instrumentoJuridicoId: formData === null || formData === void 0 ? void 0 : formData.instrumentoJuridicoId
                                                        });
                                                    }
                                                }
                                            }
                                        }, {
                                            type: 'MonetaryInput',
                                            scope: '#/properties/instituicaoInstrumentoJuridico/properties/valorFinal',
                                            label: 'Valor atual',
                                            options: {
                                                disabled: true
                                            }
                                        }]
                                }, {
                                    type: 'VerticalLayout',
                                    elements: [{
                                            type: 'Select',
                                            scope: '#/properties/tipoTermoDeRecurso',
                                            label: 'Tipo'
                                        }, {
                                            type: 'MonetaryInput',
                                            scope: '#/properties/valorAjuste',
                                            label: 'Valor ajuste'
                                        }]
                                }]
                        }]
                }],
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/tipoTermoAditivo',
                    schema: {
                        const: TipoTermoAditivo.aditivoRecurso
                    }
                }
            }
        }, {
            type: 'Category',
            label: 'Anexos',
            options: {
                stepHandler: {
                    handler: stepHandlerBack
                }
            },
            elements: [{
                    type: 'VerticalLayout',
                    elements: [{
                            type: 'File',
                            scope: '#/properties/termoAditivoInstrumentoJuridicoAnexo'
                        }]
                }]
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        icons: iconConfig
    }
};
export default termoAditivoInstrumentoJuridicoUiSchema;
