import isEmpty from 'lodash/isEmpty';
import { translateErrors } from '../../components/crud/i18n';
import { displayProperties, moduleLabels } from '../../shared';
export const getError = (errorsJsonForms, errorsCustom, path) => {
    var _a, _b;
    const requiredError = (_a = errorsJsonForms === null || errorsJsonForms === void 0 ? void 0 : errorsJsonForms.find) === null || _a === void 0 ? void 0 : _a.call(errorsJsonForms, error => { var _a; return ((_a = error === null || error === void 0 ? void 0 : error.params) === null || _a === void 0 ? void 0 : _a.missingProperty) === path; });
    const customRequiredError = (_b = errorsCustom === null || errorsCustom === void 0 ? void 0 : errorsCustom.find) === null || _b === void 0 ? void 0 : _b.call(errorsCustom, error => { var _a; return ((_a = error === null || error === void 0 ? void 0 : error.params) === null || _a === void 0 ? void 0 : _a.missingProperty) === path; });
    return requiredError !== null && requiredError !== void 0 ? requiredError : customRequiredError;
};
export const getLabel = (uischema, schema, apiUrl) => {
    var _a, _b;
    const schemaLabel = schema === null || schema === void 0 ? void 0 : schema.label;
    const uiSchemaLabel = uischema.label;
    const moduleLabel = (_a = moduleLabels === null || moduleLabels === void 0 ? void 0 : moduleLabels.find(headCell => (headCell === null || headCell === void 0 ? void 0 : headCell.id) === apiUrl)) === null || _a === void 0 ? void 0 : _a.value;
    return (_b = uiSchemaLabel !== null && uiSchemaLabel !== void 0 ? uiSchemaLabel : schemaLabel) !== null && _b !== void 0 ? _b : moduleLabel;
};
export const displayPropertiesComputed = (uischema, item, apiUrl) => {
    var _a, _b, _c, _d, _e;
    if (isEmpty(item))
        return '';
    const formatOptionLabel = (_a = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _a === void 0 ? void 0 : _a.formatOptionLabel;
    if (formatOptionLabel && typeof formatOptionLabel === 'function') {
        return formatOptionLabel(item);
    }
    // Formatação padrão
    const properties = displayProperties === null || displayProperties === void 0 ? void 0 : displayProperties.find(option => (option === null || option === void 0 ? void 0 : option.id) === apiUrl);
    const values = [];
    if (typeof (properties === null || properties === void 0 ? void 0 : properties.value) === 'function')
        return properties === null || properties === void 0 ? void 0 : properties.value(item);
    let propValues = (_c = (_b = uischema === null || uischema === void 0 ? void 0 : uischema.options) === null || _b === void 0 ? void 0 : _b.displayProperties) !== null && _c !== void 0 ? _c : properties === null || properties === void 0 ? void 0 : properties.value;
    (_d = propValues === null || propValues === void 0 ? void 0 : propValues.forEach) === null || _d === void 0 ? void 0 : _d.call(propValues, prop => { var _a; return (_a = values === null || values === void 0 ? void 0 : values.push) === null || _a === void 0 ? void 0 : _a.call(values, prop.split('.').reduce((acc, key) => acc && acc[key], item)); });
    return (_e = values === null || values === void 0 ? void 0 : values.join) === null || _e === void 0 ? void 0 : _e.call(values, ', ');
};
export const getErrorMessage = (errorsJsonForms, errorsCustom, path) => {
    var _a, _b;
    const error = getError(errorsJsonForms, errorsCustom, path);
    return (_b = (_a = translateErrors === null || translateErrors === void 0 ? void 0 : translateErrors(error)) !== null && _a !== void 0 ? _a : error === null || error === void 0 ? void 0 : error.message) !== null && _b !== void 0 ? _b : '';
};
