import _Typography from "@mui/material/Typography";
import _Box from "@mui/material/Box";
import { styled } from 'styled-components';
const baseFont = {
    fontFamily: 'Raleway',
    fontVariantNumeric: 'lining-nums',
    fontSize: '0.875rem'
};
export const TableContainer = styled(_Box)({
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
});
export const StyledTitle = styled(_Typography)(Object.assign(Object.assign({}, baseFont), { fontWeight: '600', fontSize: '0.875rem', color: '#1351B4' }));
export const StyledTable = styled('table')(Object.assign(Object.assign({ maxWidth: '100%', border: '1px solid #C5C6D0', borderRadius: '4px', borderSpacing: '0', overflow: 'hidden', width: '100%' }, baseFont), { '& tbody': {
        backgroundColor: '#FFF'
    }, '& th': {
        backgroundColor: '#1351B4',
        color: '#FFF',
        padding: '0.25rem 0.5rem',
        textAlign: 'left',
        fontWeight: '600'
    }, '& thead tr td:first-child': {
        width: '1%'
    }, '& tbody tr td:first-child': {
        padding: '0.25rem 0.5rem',
        whiteSpace: 'nowrap'
    }, '& tbody tr td:not(:first-child)': {
        padding: '0.25rem'
    }, '& tbody tr:not(:last-child) td': {
        borderBottom: '1px solid #C5C6D0'
    }, "& input[type='text']": Object.assign({ width: '100%', padding: '0.5rem 0.75rem', border: '1px solid #757780', backgroundColor: '#FFF !important', borderRadius: '4px' }, baseFont) }));
