// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAutocomplete-popper * span {
  background-color: transparent !important;
  color: var(--color-foreground) !important;
}`, "",{"version":3,"sources":["webpack://./src/jsonforms/renderers/select/multiple.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,yCAAyC;AAC3C","sourcesContent":[".MuiAutocomplete-popper * span {\n  background-color: transparent !important;\n  color: var(--color-foreground) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
