export const formatFloat = function (value) {
    let locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'pt-BR';
    let minimumFractionDigits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    let maximumFractionDigits = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;
    const intNumber = typeof value === 'string' ? parseInt(value, 10) : value;
    const float = intNumber / 100;
    return new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits
    }).format(float);
};
