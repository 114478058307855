import { RuleEffect } from '@jsonforms/core';
import { atualizarEditarPerfilHandler } from '../handlers/atualizar-editar-perfil-handler';
import { setLabelWhenChangeDateFuncao, setLabelWhenChangeDateServico } from '../handlers/set-label-when-change-date';
import { tempoServicoLabelStyle } from '../style/editar-perfil';
const showIfPossuiVinculoInstitucional = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        effect: RuleEffect.SHOW,
        condition: {
            scope: `#${scopePrefix}/properties/possuiVinculoInstitucional`,
            schema: {
                const: true
            }
        }
    };
};
export const dadosProfissionaisUiSchema = function () {
    let scopePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return {
        type: 'Category',
        label: 'Dados Profissionais',
        options: {
            stepHandler: {
                handler: atualizarEditarPerfilHandler
            }
        },
        elements: [{
                type: 'VerticalLayout',
                elements: [{
                        type: 'Control',
                        scope: `#${scopePrefix}/properties/possuiVinculoInstitucional`,
                        label: 'Possuo vínculo institucional'
                    }, {
                        type: 'VerticalLayout',
                        rule: showIfPossuiVinculoInstitucional(scopePrefix),
                        elements: [{
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/vinculoInstitucional/properties/tipoVinculoInstitucionalId`,
                                label: 'Tipo de vínculo',
                                options: {
                                    required: ctx => {
                                        const { formData } = ctx;
                                        return formData.possuiVinculoInstitucional;
                                    }
                                }
                            }, {
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/possuiVinculoEmpregaticio`,
                                label: 'Possuo vínculo empregatício'
                            }, {
                                type: 'HorizontalLayout',
                                elements: [{
                                        type: 'Date',
                                        scope: `#${scopePrefix}/properties/vinculoInstitucional/properties/inicioServico`,
                                        label: 'Início de Serviço',
                                        options: {
                                            dateFormat: 'DD/MM/YYYY',
                                            required: ctx => {
                                                const { formData } = ctx;
                                                return formData.possuiVinculoEmpregaticio;
                                            }
                                        }
                                    }, {
                                        type: 'Label',
                                        scope: `#${scopePrefix}/properties/vinculoInstitucional/properties/tempoServico`,
                                        label: 'Tempo de Serviço',
                                        options: {
                                            formatOptionLabel: setLabelWhenChangeDateServico,
                                            componentProps: tempoServicoLabelStyle
                                        }
                                    }]
                            }, {
                                type: 'Select',
                                scope: `#${scopePrefix}/properties/vinculoInstitucional/properties/regimeTrabalhoId`,
                                label: 'Regime de Trabalho',
                                options: {
                                    required: ctx => {
                                        const { formData } = ctx;
                                        return formData.possuiVinculoEmpregaticio;
                                    }
                                }
                            }, {
                                type: 'Control',
                                scope: `#${scopePrefix}/properties/vinculoInstitucional/properties/funcao`,
                                label: 'Função/Cargo',
                                options: {
                                    required: cxt => {
                                        const { formData } = cxt;
                                        return formData.possuiVinculoEmpregaticio;
                                    }
                                }
                            }, {
                                type: 'HorizontalLayout',
                                elements: [{
                                        type: 'Date',
                                        scope: `#${scopePrefix}/properties/vinculoInstitucional/properties/inicioFuncao`,
                                        label: 'Início de Função/Cargo',
                                        options: {
                                            dateFormat: 'DD/MM/YYYY',
                                            required: cxt => {
                                                const { formData } = cxt;
                                                return formData.possuiVinculoEmpregaticio;
                                            }
                                        }
                                    }, {
                                        type: 'Label',
                                        scope: `#${scopePrefix}/properties/vinculoInstitucional/properties/tempoFuncao`,
                                        label: 'Tempo de Função/Cargo',
                                        options: {
                                            formatOptionLabel: setLabelWhenChangeDateFuncao,
                                            componentProps: {
                                                variant: 'h5',
                                                align: 'left',
                                                sx: {
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }
                                            }
                                        }
                                    }]
                            }]
                    }]
            }]
    };
};
