import { RuleEffect } from '@jsonforms/core';
import { circleFlagBuilder } from '../../components/circle-flag';
import { hideIfBrasil } from '../../jsonforms/uischema/hide-if-brasil';
import { showIfBrasil } from '../../jsonforms/uischema/show-if-brasil';
import { makeIbgeApi } from '../../api/ibge.api';
import { makeApi } from '../../api/generic-api';
export const requiredIfBrasil = ctx => {
    const { formData } = ctx;
    return formData['paisId-codigo'] === 'brasil';
};
export const requiredIfNotBrasil = ctx => {
    const { formData } = ctx;
    return formData['paisId-codigo'] !== 'brasil';
};
const apiInstanceIbge = makeIbgeApi();
const requiredIfNotVinculo = ctx => {
    const { formData } = ctx;
    return formData['vincularExistente'] ? !formData['vincularExistente'] : true;
};
const apiInstanceInstituicao = makeApi('/instituicao');
const showIfVincularExistente = {
    effect: RuleEffect.SHOW,
    condition: {
        scope: '#/properties/vincularExistente',
        schema: {
            const: true
        }
    }
};
const hideIfVincularExistente = {
    effect: RuleEffect.HIDE,
    condition: {
        scope: '#/properties/vincularExistente',
        schema: {
            const: true
        }
    }
};
const hideIfAcknowledgedAt = {
    effect: RuleEffect.HIDE,
    condition: {
        scope: '#/properties/acknowledgedAt',
        schema: {
            not: {
                const: null
            }
        }
    }
};
const instituicaoUiSchema = {
    type: 'VerticalLayout',
    elements: [{
            type: 'HorizontalLayout',
            rule: hideIfAcknowledgedAt,
            elements: [{
                    type: 'Boolean',
                    scope: '#/properties/vincularExistente',
                    label: 'Vincular à uma instituição já reconhecida',
                    options: {
                        handleChange: (value, handler) => {
                            if (!value) {
                                handler('instituicaoId', null);
                                handler('endereco', null);
                                handler('paisId', null);
                            }
                            else {
                                handler('endereco', {
                                    cep: '00000-000',
                                    estado: 'estado',
                                    municipio: 'município',
                                    logradouro: 'logradouro'
                                });
                                handler('paisId', 1);
                            }
                            handler('vincularExistente', value);
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/instituicaoId',
                    label: 'Instituição para substituição',
                    options: {
                        apiInstance: apiInstanceInstituicao,
                        displayProperties: ['nome'],
                        filter: {
                            formFields: ['nome'],
                            handler: (formData, listData, api) => {
                                return api.getAll({
                                    withAcknowledgedAt: true
                                });
                            }
                        },
                        required: !requiredIfNotVinculo
                    }
                }],
            rule: showIfVincularExistente
        }, {
            type: 'VerticalLayout',
            rule: hideIfVincularExistente,
            elements: [{
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'Control',
                            scope: '#/properties/nome',
                            label: 'Nome',
                            options: {
                                required: requiredIfNotVinculo,
                                multiline: true
                            }
                        }, {
                            type: 'Control',
                            scope: '#/properties/sigla',
                            label: 'Sigla',
                            options: {
                                required: requiredIfNotVinculo
                            }
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Text',
                                    scope: '#/properties/dirigente',
                                    label: 'Dirigente'
                                }, {
                                    type: 'Text',
                                    scope: '#/properties/endereco/properties/cep',
                                    label: 'Zipcode',
                                    rule: hideIfBrasil(),
                                    options: {
                                        maxLength: 9,
                                        required: requiredIfNotBrasil && requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/cnpj',
                                    label: 'CNPJ',
                                    rule: showIfBrasil(),
                                    options: {
                                        mask: '99.999.999/9999-99',
                                        required: requiredIfBrasil && requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Select',
                                    scope: '#/properties/endereco/properties/estado',
                                    rule: showIfBrasil(),
                                    options: {
                                        required: requiredIfBrasil && requiredIfNotVinculo,
                                        handleChange: (item, handler, formData, ctx, path) => {
                                            handler(path, item);
                                            handler(path.replace('estado', 'municipio'), undefined);
                                        },
                                        targetFieldValue: 'sigla',
                                        optionsComparator: (option, item) => {
                                            return (option === null || option === void 0 ? void 0 : option.sigla) === item;
                                        },
                                        displayProperties: ['nome'],
                                        filter: {
                                            acceptUnmatched: true,
                                            preventCleaning: true,
                                            selfContainedApi: true,
                                            formFields: ['pais', 'cep'],
                                            handler: () => {
                                                return apiInstanceIbge.buscarEstados();
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/logradouro',
                                    label: 'Logradouro',
                                    rule: showIfBrasil(),
                                    options: {
                                        required: requiredIfBrasil && requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/numero',
                                    label: 'Número',
                                    rule: showIfBrasil(),
                                    options: {
                                        required: requiredIfBrasil && requiredIfNotVinculo,
                                        isPositiveNumber: true
                                    }
                                }]
                        }, {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Select',
                                    scope: '#/properties/paisId',
                                    label: 'País',
                                    options: {
                                        required: requiredIfNotVinculo,
                                        iconBuilder: circleFlagBuilder
                                    }
                                }, {
                                    type: 'Cep',
                                    scope: '#/properties/endereco/properties/cep',
                                    label: 'CEP',
                                    rule: showIfBrasil(),
                                    options: {
                                        mask: '99999-999',
                                        required: requiredIfBrasil && requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Select',
                                    scope: '#/properties/endereco/properties/municipio',
                                    label: 'Município',
                                    rule: showIfBrasil(),
                                    options: {
                                        required: requiredIfBrasil && requiredIfNotVinculo,
                                        targetFieldValue: 'nome',
                                        optionsComparator: (option, item) => {
                                            return (option === null || option === void 0 ? void 0 : option.nome) === item;
                                        },
                                        displayProperties: ['nome'],
                                        filter: {
                                            acceptUnmatched: true,
                                            preventCleaning: true,
                                            selfContainedApi: true,
                                            formFields: ['estado', 'cep'],
                                            handler: (_formData, _listData, _api, queryFilterValues) => {
                                                if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                                    return Promise.resolve([]);
                                                return apiInstanceIbge.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                                            }
                                        }
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/bairro',
                                    label: 'Bairro',
                                    rule: showIfBrasil(),
                                    options: {
                                        required: requiredIfBrasil && requiredIfNotVinculo
                                    }
                                }, {
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/complemento',
                                    label: 'Complemento',
                                    rule: showIfBrasil()
                                }]
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Text',
                                    scope: '#/properties/endereco/properties/estado',
                                    label: 'Estado/Região',
                                    rule: hideIfBrasil(),
                                    options: {
                                        required: requiredIfNotBrasil && requiredIfNotVinculo
                                    }
                                }]
                        }, {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Text',
                                    scope: '#/properties/endereco/properties/municipio',
                                    label: 'Município',
                                    rule: hideIfBrasil(),
                                    options: {
                                        required: requiredIfNotBrasil && requiredIfNotVinculo
                                    }
                                }]
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/logradouro',
                                    label: 'Logradouro',
                                    rule: hideIfBrasil(),
                                    options: {
                                        required: requiredIfNotBrasil && requiredIfNotVinculo
                                    }
                                }]
                        }]
                }, {
                    type: 'HorizontalLayout',
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/bairro',
                                    label: 'Bairro',
                                    rule: hideIfBrasil(),
                                    options: {
                                        required: requiredIfNotBrasil && requiredIfNotVinculo
                                    }
                                }]
                        }, {
                            type: 'VerticalLayout',
                            elements: [{
                                    type: 'Control',
                                    scope: '#/properties/endereco/properties/complemento',
                                    label: 'Complemento',
                                    rule: hideIfBrasil()
                                }]
                        }]
                }]
        }]
};
export default instituicaoUiSchema;
