export const contaLabelStyle = {
    variant: 'h7',
    align: 'left',
    sx: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        marginTop: '9px',
        marginBottom: '19px'
    }
};
