import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { Search } from '../../../components/styled-icons';
export const BoxContainer = styled(Box)({
    fontFamily: 'Raleway',
    '& input, & button, & p': {
        fontFamily: 'inherit',
        fontSize: '1em'
    },
    width: '100%',
    '@media (max-width: 1600px)': {
        fontSize: '0.8em'
    },
    '@media (max-width: 1100px)': {
        fontSize: '0.6em'
    }
});
export const SearchIcon = styled(Search)({
    margin: '0 1rem',
    color: '#434653'
});
export const DescriptionText = styled(Typography)({
    margin: '1rem 0 1.5rem 0',
    fontWeight: '500'
});
export const SearchBox = styled('div')({
    display: 'flex',
    backgroundColor: '#E7E7F0',
    marginBottom: '1rem',
    borderRadius: '30px',
    alignItems: 'center'
});
export const SearchInput = styled('input')({
    background: 'none !important',
    border: 'none',
    borderRadius: '30px',
    padding: '1rem 1.5rem',
    '&:focus': {
        outline: 'none'
    }
});
export const ItemGridButton = styled(Button)(_ref => {
    let { theme } = _ref;
    return {
        width: '100%',
        boxSizing: 'border-box',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        // Permite a quebra de linha
        boxShadow: 'none',
        color: '#FFFFFF',
        fontWeight: '600',
        borderRadius: '20px',
        padding: '0.35rem',
        textTransform: 'none',
        transition: 'none',
        height: '41px',
        lineHeight: '1.2',
        // Estilos específicos para variant="contained"
        '&.MuiButton-contained': {
            backgroundColor: '#FFFFFF',
            color: '#003D92',
            border: '1px solid #003D92',
            '&:hover': {
                boxShadow: 'none'
            }
        },
        // Estilos específicos para variant="outlined"
        '&.MuiButton-outlined': {
            backgroundColor: '#C3C6D5',
            border: '1px solid #C3C6D5'
        }
    };
});
