// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.array-layout {
  margin-bottom: 2rem;
  
  .array-layout-container {
    .no-data-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .no-data-label {
        font-family: 'Raleway';
        font-weight: 600;
        color: #9d9d9d !important;
        text-align: center;
        margin-bottom: 2rem;
      }

      .add-button {
        font-family: 'Raleway';
        background-color: #003d92;
        font-size: 0.75rem;

        &:hover {
          background-color: #002b66;
        }
      }
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/jsonforms/renderers/layout/array-layout-render.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;;EAEnB;IACE;MACE,aAAa;MACb,sBAAsB;MACtB,mBAAmB;;MAEnB;QACE,sBAAsB;QACtB,gBAAgB;QAChB,yBAAyB;QACzB,kBAAkB;QAClB,mBAAmB;MACrB;;MAEA;QACE,sBAAsB;QACtB,yBAAyB;QACzB,kBAAkB;;QAElB;UACE,yBAAyB;QAC3B;MACF;IACF;EACF;AACF","sourcesContent":[".array-layout {\n  margin-bottom: 2rem;\n  \n  .array-layout-container {\n    .no-data-container {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n\n      .no-data-label {\n        font-family: 'Raleway';\n        font-weight: 600;\n        color: #9d9d9d !important;\n        text-align: center;\n        margin-bottom: 2rem;\n      }\n\n      .add-button {\n        font-family: 'Raleway';\n        background-color: #003d92;\n        font-size: 0.75rem;\n\n        &:hover {\n          background-color: #002b66;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
