import dayjs from 'dayjs';
export const setLabelWhenChangeDateFuncao = formData => {
    var _a;
    const today = dayjs();
    if ((_a = formData.vinculoInstitucional) === null || _a === void 0 ? void 0 : _a.inicioFuncao) {
        const monthDiff = today.diff(formData.vinculoInstitucional.inicioFuncao, 'month');
        const years = Math.floor(monthDiff / 12) > 0 ? Math.floor(monthDiff / 12) + ' ano(s)' : '';
        const month = monthDiff % 12 > 0 ? monthDiff % 12 + ' mês(es)' : '';
        return `${years} ${month}`;
    }
    return '';
};
export const setLabelWhenChangeDateServico = formData => {
    var _a;
    const today = dayjs();
    if ((_a = formData.vinculoInstitucional) === null || _a === void 0 ? void 0 : _a.inicioServico) {
        const monthDiff = today.diff(formData.vinculoInstitucional.inicioServico, 'month');
        const years = Math.floor(monthDiff / 12) > 0 ? Math.floor(monthDiff / 12) + ' ano(s)' : '';
        const month = monthDiff % 12 > 0 ? monthDiff % 12 + ' mês(es)' : '';
        return `${years} ${month}`;
    }
    return '';
};
