import { camelCase } from 'change-case';
import { circleFlagBuilder } from '../../../components/circle-flag';
import { formatFloat } from '../../../helpers/format-float';
import { TipoEditalRubrica, tipoEditalRubricaI18N } from '../../../models/edital-rubrica';
import { CodigoPais } from '../../../models/pais';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { apiInstanceIbge } from '../../../shared/editar-perfil/uischemas/endereco.uischema';
import { buildTableLayoutHeader } from '../../../shared/proposta/table-layout-header';
import { hideIfBrasil } from '../hide-if-brasil';
import { showIfBrasil } from '../show-if-brasil';
import { contrapartidaHandleChange } from './helpers/contrapartidaHandleChange';
import { createShowRuleForEditalRubrica } from './helpers/createShowRuleForEditalRubrica';
import { getJustificativaStatus } from './helpers/getJustificativaStatus';
import { handleTemMoedaEstrangeiraChange } from './helpers/handleTemMoedaEstrangeiraChange';
import { moedaHandleChange } from './helpers/moedaHandleChange';
import { requiredIfBrasil } from './helpers/requiredIfBrasil';
import { requiredIfNotBrasil } from './helpers/requiredIfNotBrasil';
import { requiredIfTemMoedaEstrangeira } from './helpers/requiredIfTemMoedaEstrangeira';
import { showIfContrapartida } from './helpers/showIfContrapartida';
import { showIfJustificativaGlobal } from './helpers/showIfJustificativaGlobal';
import { showIfTemMoedaEstrangeira } from './helpers/showIfTemMoedaEstrangeira';
import { visibleHandlerJustificativaGlobal } from './helpers/visibleHandlerJustificativaGlobal';
import { visibleHandlerJustificativaObrigatoria } from './helpers/visibleHandlerJustificativaObrigatoria';
export const getHospedagemAlimentacaoAnswerForm = function () {
    let { proposta, readonly = false, customHeader, customFooter } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return [{
            type: 'HorizontalLayout',
            elements: [{
                    type: 'LongText',
                    scope: '#/properties/revisao',
                    label: '',
                    options: {
                        readonly: true,
                        maxLength: 256,
                        customHeader,
                        customFooter,
                        visibleHandler: ctx => {
                            var _a;
                            return (_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.orcamentoEdicao;
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/paisId',
                    label: 'País',
                    options: {
                        readonly,
                        required: true,
                        iconBuilder: circleFlagBuilder,
                        handleChange: (item, handler, formData, ctx, path) => {
                            if ((item === null || item === void 0 ? void 0 : item.codigo) === CodigoPais.brasil) {
                                handler('rubricaHospedagemAlimentacaoUnsaved.estadoRegiao', null);
                            }
                            //Adiciona o objeto inteiro no formulário provisório
                            handler('rubricaHospedagemAlimentacaoUnsaved.pais', item);
                        }
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/estadoId',
                    label: 'Estado',
                    options: {
                        readonly,
                        displayProperties: ['nome'],
                        required: requiredIfBrasil('rubricaHospedagemAlimentacaoUnsaved'),
                        handleChange: (item, handler, formData, ctx, path) => {
                            //Adiciona o objeto inteiro no formulário provisório
                            handler(`${path}`.replace('estadoId', 'estado'), item);
                        }
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/municipio',
                    label: 'Município',
                    options: {
                        readonly,
                        required: requiredIfBrasil('rubricaHospedagemAlimentacaoUnsaved'),
                        targetFieldValue: 'nome',
                        optionsComparator: (option, item) => (option === null || option === void 0 ? void 0 : option.nome) === item,
                        displayProperties: ['nome'],
                        filter: {
                            selfContainedApi: true,
                            formFields: ['estadoId'],
                            handler: (_formData, _listData, _api, queryFilterValues) => {
                                if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                    return Promise.resolve([]);
                                return apiInstanceIbge.buscarMunicipioPorEstadoId(queryFilterValues[0].value);
                            }
                        }
                    }
                }],
            rule: showIfBrasil()
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Select',
                    scope: '#/properties/paisId',
                    label: 'País',
                    options: {
                        readonly,
                        required: true,
                        iconBuilder: circleFlagBuilder,
                        handleChange: (item, handler, formData, ctx, path) => {
                            if ((item === null || item === void 0 ? void 0 : item.codigo) !== CodigoPais.brasil) {
                                handler('rubricaHospedagemAlimentacaoUnsaved.estadoId', null);
                                handler('rubricaHospedagemAlimentacaoUnsaved.municipio', null);
                            }
                            //Adiciona o objeto inteiro no formulário provisório
                            handler('rubricaHospedagemAlimentacaoUnsaved.pais', item);
                        }
                    }
                }, {
                    type: 'Control',
                    scope: '#/properties/estadoRegiao',
                    label: 'Estado/Região',
                    options: {
                        readonly,
                        required: requiredIfNotBrasil('rubricaHospedagemAlimentacaoUnsaved')
                    }
                }],
            rule: hideIfBrasil()
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/especificacao',
                    label: 'Especificação',
                    options: {
                        readonly,
                        required: true
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Number',
                    scope: '#/properties/quantidade',
                    label: 'Quantidade',
                    options: {
                        readonly,
                        required: true,
                        numberStep: 1,
                        isPositiveNumber: true,
                        handleChange: (quantidade, handler, formData, ctx, path) => {
                            var _a;
                            const custoUnitario = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaHospedagemAlimentacaoUnsaved']) === null || _a === void 0 ? void 0 : _a.custoUnitario;
                            if (!quantidade && !custoUnitario)
                                return;
                            handler(`${path}`.replace('quantidade', 'valorTotal'), custoUnitario * quantidade);
                        }
                    }
                }, {
                    type: 'MonetaryInput',
                    scope: '#/properties/custoUnitario',
                    label: 'Custo Unitário',
                    options: {
                        readonly,
                        required: true,
                        isPositiveNumber: true,
                        handleChange: (custoUnitario, handler, formData, ctx, path) => {
                            var _a;
                            const quantidade = (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaHospedagemAlimentacaoUnsaved']) === null || _a === void 0 ? void 0 : _a.quantidade;
                            if (!custoUnitario && !quantidade)
                                return;
                            handler(`${path}`.replace('custoUnitario', 'valorTotal'), custoUnitario * quantidade);
                        }
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/mesPrevisto',
                    label: 'Mês Previsto',
                    options: {
                        readonly,
                        required: true,
                        oneOf: formData => {
                            const data = (formData === null || formData === void 0 ? void 0 : formData.duracao) ? formData : proposta;
                            return Array.from({
                                length: data.duracao
                            }, (_, i) => ({
                                title: `${i + 1}°`,
                                const: i + 1
                            }));
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'MonetaryInput',
                    scope: '#/properties/valorTotal',
                    label: 'Valor Total',
                    options: {
                        disabled: true
                    }
                }, {
                    type: 'Boolean',
                    scope: '#/properties/temMoedaEstrangeira',
                    label: 'Moeda Estrangeira',
                    options: {
                        readonly,
                        visibleHandler: ctx => {
                            var _a, _b, _c;
                            const { formData } = ctx;
                            const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                            return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => { var _a; return (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === TipoEditalRubrica.hospedagemAlimentacao && ((_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.length) > 0; });
                        },
                        handleChange: handleTemMoedaEstrangeiraChange
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/moedaEstrangeiraId',
                    label: 'Moeda Estrangeira',
                    rule: showIfTemMoedaEstrangeira,
                    options: {
                        readonly,
                        required: requiredIfTemMoedaEstrangeira('rubricaHospedagemAlimentacaoUnsaved'),
                        displayProperties: ['nome'],
                        filter: {
                            handler: (formData, listData, api, queryFilterValues, path) => {
                                var _a, _b, _c;
                                const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                                let editalRubrica = (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.find(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.hospedagemAlimentacao));
                                return api.getAll().then(response => response.filter(moedaEstrangeira => { var _a; return (_a = editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.moedaEstrangeira) === null || _a === void 0 ? void 0 : _a.some(me => me.id === moedaEstrangeira.id); }));
                            }
                        },
                        handleChange: moedaHandleChange
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Boolean',
                    scope: '#/properties/contrapartida',
                    options: {
                        readonly,
                        defaultValue: false,
                        handleChange: contrapartidaHandleChange
                    }
                }, {
                    type: 'Select',
                    scope: '#/properties/tipoPessoa',
                    label: 'Tipo Pessoa',
                    rule: showIfContrapartida,
                    options: {
                        readonly,
                        required: ctx => {
                            var _a;
                            const { formData } = ctx;
                            return (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaHospedagemAlimentacaoUnsaved']) === null || _a === void 0 ? void 0 : _a.contrapartida;
                        }
                    }
                }]
        }, {
            type: 'HorizontalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/entidade',
                    label: 'Entidade',
                    rule: showIfContrapartida,
                    options: {
                        readonly,
                        required: ctx => {
                            var _a;
                            const { formData } = ctx;
                            return (_a = formData === null || formData === void 0 ? void 0 : formData['rubricaHospedagemAlimentacaoUnsaved']) === null || _a === void 0 ? void 0 : _a.contrapartida;
                        }
                    }
                }]
        }, {
            type: 'LongText',
            scope: '#properties/justificativa',
            label: 'Justificativa',
            options: {
                readonly,
                maxLength: 256,
                label: getJustificativaStatus(TipoEditalRubrica.hospedagemAlimentacao),
                visibleHandler: visibleHandlerJustificativaObrigatoria(TipoEditalRubrica.hospedagemAlimentacao),
                required: ctx => {
                    var _a, _b, _c;
                    const { formData } = ctx;
                    const data = ((_a = formData === null || formData === void 0 ? void 0 : formData.edital) === null || _a === void 0 ? void 0 : _a.editalRubrica) ? formData : proposta;
                    return (_c = (_b = data === null || data === void 0 ? void 0 : data.edital) === null || _b === void 0 ? void 0 : _b.editalRubrica) === null || _c === void 0 ? void 0 : _c.some(editalRubrica => (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.hospedagemAlimentacao) && (editalRubrica === null || editalRubrica === void 0 ? void 0 : editalRubrica.temJustificativaObrigatoria));
                }
            }
        }];
};
export const rubricaHospedagemAlimentacaoUiSchema = function () {
    let { route, customPaths, scopePrefix = '', revisaoOrcamento, customHeader, customFooter, customActions } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
        type: 'Category',
        label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.hospedagemAlimentacao)],
        rule: createShowRuleForEditalRubrica(TipoEditalRubrica.hospedagemAlimentacao),
        options: {
            stepHandler: {
                handler: stepHandlerErrors
            }
        },
        elements: [{
                type: 'ArrayTableLayout',
                scope: `#${scopePrefix}/properties/rubricaHospedagemAlimentacao`,
                label: '',
                options: {
                    additionalData: formData => ({
                        editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                    }),
                    buildHeader: {
                        builder: buildTableLayoutHeader
                    },
                    route: route !== null && route !== void 0 ? route : '/proposta',
                    customPaths: customPaths !== null && customPaths !== void 0 ? customPaths : {},
                    hideCreate: revisaoOrcamento,
                    hideDefaultActions: revisaoOrcamento,
                    customActions,
                    headCells: [{
                            label: 'Localidade',
                            field: row => { var _a, _b, _c, _d; return ((_a = row.pais) === null || _a === void 0 ? void 0 : _a.codigo) === CodigoPais.brasil ? `${(_b = row.pais) === null || _b === void 0 ? void 0 : _b.nomePt}-${(_c = row.estado) === null || _c === void 0 ? void 0 : _c.nome}-${row.municipio}` : `${(_d = row.pais) === null || _d === void 0 ? void 0 : _d.nomePt}-${row.estadoRegiao}`; }
                        }, {
                            label: 'Especificação',
                            field: 'especificacao'
                        }, {
                            label: 'Custo Unitário',
                            field: row => row.custoUnitario ? formatFloat(row.custoUnitario) : '-'
                        }, {
                            label: 'Quantidade',
                            field: 'quantidade'
                        }, {
                            label: 'Mês',
                            field: row => row.mesPrevisto ? `${row.mesPrevisto}°` : '-'
                        }, {
                            label: 'Contrapartida',
                            field: row => row.contrapartida ? 'Sim' : 'Não'
                        }, {
                            label: 'Moeda',
                            field: row => row.moedaEstrangeira ? `${row.moedaEstrangeira.nome} (${row.moedaEstrangeira.simbolo})` : 'Real (R$)'
                        }, {
                            label: 'Valor Total',
                            field: row => row.valorTotal ? formatFloat(row.valorTotal) : '-'
                        }],
                    label: tipoEditalRubricaI18N[camelCase(TipoEditalRubrica.hospedagemAlimentacao)],
                    defaultValue: formData => ({
                        paisId: 1
                    }),
                    detail: {
                        type: 'VerticalLayout',
                        elements: getHospedagemAlimentacaoAnswerForm({
                            customHeader,
                            customFooter
                        })
                    }
                }
            }, {
                type: 'LongText',
                scope: '#properties/justificativaGlobal',
                label: 'Justificativa Global',
                options: {
                    maxLength: 256,
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.hospedagemAlimentacao),
                    arrayProps: {
                        id: 'hospedagemAlimentacao',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.hospedagemAlimentacao),
                        arrayScope: 'justificativa'
                    }
                },
                rule: showIfJustificativaGlobal('rubricaHospedagemAlimentacao')
            }, {
                type: 'Constant',
                scope: '#properties/justificativaGlobal',
                options: {
                    visibleHandler: visibleHandlerJustificativaGlobal(TipoEditalRubrica.hospedagemAlimentacao),
                    arrayProps: {
                        id: 'hospedagemAlimentacao',
                        arrayIndex: data => (data === null || data === void 0 ? void 0 : data.tipoEditalRubrica) === (TipoEditalRubrica === null || TipoEditalRubrica === void 0 ? void 0 : TipoEditalRubrica.hospedagemAlimentacao),
                        arrayScope: 'tipoEditalRubrica'
                    },
                    value: TipoEditalRubrica.hospedagemAlimentacao
                },
                rule: showIfJustificativaGlobal('rubricaHospedagemAlimentacao')
            }]
    };
};
