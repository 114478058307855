export const moduleName = 'BOLSA.MODALIDADE_BOLSA.MODALIDADE_BOLSA';
export const moduleLabel = ' Modalidade de Bolsa';
export const displayProperties = ['nome'];
export const headCells = [{
        id: 'nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }, {
        id: 'sigla',
        numeric: false,
        disablePadding: false,
        label: 'Sigla'
    }];
