import { TipoRespostaPerguntaAvaliacao } from '../models/pergunta-avaliacao';
export const getProperties = pergunta => {
    var _a, _b, _c, _d, _e;
    let _type = 'string';
    let format = '';
    let properties = {};
    switch (pergunta === null || pergunta === void 0 ? void 0 : pergunta.tipoResposta) {
        case TipoRespostaPerguntaAvaliacao.descritiva:
            format = 'Descritiva';
            properties = {
                descricao: (_a = pergunta === null || pergunta === void 0 ? void 0 : pergunta.descritiva) === null || _a === void 0 ? void 0 : _a.descricao
            };
            break;
        case TipoRespostaPerguntaAvaliacao.multiplaEscolha:
            format = 'MultiplaEscolha';
            properties = {
                oneOf: (_b = pergunta === null || pergunta === void 0 ? void 0 : pergunta.multiplaEscolha) === null || _b === void 0 ? void 0 : _b.map(_ref => {
                    let { alternativa, id } = _ref;
                    return {
                        const: id,
                        title: alternativa
                    };
                })
            };
            break;
        case TipoRespostaPerguntaAvaliacao.nota:
            format = 'Nota';
            properties = {
                descricao: (_c = pergunta === null || pergunta === void 0 ? void 0 : pergunta.nota) === null || _c === void 0 ? void 0 : _c.descricao,
                deveTerJustificativa: pergunta === null || pergunta === void 0 ? void 0 : pergunta.nota.deveTerJustificativa,
                justificativa: pergunta === null || pergunta === void 0 ? void 0 : pergunta.nota.justificativa,
                minimo: (_d = pergunta === null || pergunta === void 0 ? void 0 : pergunta.nota) === null || _d === void 0 ? void 0 : _d.notaMinima,
                maximo: (_e = pergunta === null || pergunta === void 0 ? void 0 : pergunta.nota) === null || _e === void 0 ? void 0 : _e.notaMaxima
            };
            break;
    }
    return {
        type: _type,
        format,
        properties
    };
};
export const buildFormularioUiSchema = (isCategorizado, categorias) => {
    if (isCategorizado) {
        return {
            type: 'VerticalLayout',
            elements: categorias === null || categorias === void 0 ? void 0 : categorias.map(categoria => {
                return {
                    type: 'Control',
                    scope: `#/properties/form-${categoria.id}`
                };
            })
        };
    }
    else {
        return {
            type: 'VerticalLayout',
            elements: [{
                    type: 'Control',
                    scope: '#/properties/form'
                }]
        };
    }
};
export const buildSchemaFormularioConsultoria = (formularioConsultoria, isCategorizado) => {
    var _a, _b, _c, _d;
    if (isCategorizado) {
        return {
            type: 'object',
            properties: Object.fromEntries((_b = (_a = formularioConsultoria === null || formularioConsultoria === void 0 ? void 0 : formularioConsultoria.formularioConsultoriaCategorizado) === null || _a === void 0 ? void 0 : _a.map((categoria, indexCategory) => {
                var _a, _b, _c;
                const posicao = (_a = categoria.posicao) !== null && _a !== void 0 ? _a : indexCategory + 1;
                return [`form-${categoria.id}`, {
                        type: 'object',
                        title: `${posicao}) ${categoria.tituloCategoria}`,
                        properties: Object.fromEntries((_c = (_b = categoria === null || categoria === void 0 ? void 0 : categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao) === null || _b === void 0 ? void 0 : _b.map((pergunta, index) => {
                            var _a;
                            const { type, format, properties } = getProperties(pergunta);
                            return [`pergunta-${pergunta.id}`, Object.assign(Object.assign({ type,
                                    format }, properties), { title: `${posicao}.${(_a = pergunta.posicao) !== null && _a !== void 0 ? _a : index + 1}) ${pergunta.pergunta}` })];
                        })) !== null && _c !== void 0 ? _c : [])
                    }];
            })) !== null && _b !== void 0 ? _b : [])
        };
    }
    else {
        return {
            type: 'object',
            properties: {
                form: {
                    type: 'object',
                    properties: Object.fromEntries((_d = (_c = formularioConsultoria === null || formularioConsultoria === void 0 ? void 0 : formularioConsultoria.formularioConsultoriaPergunta) === null || _c === void 0 ? void 0 : _c.map((pergunta, index) => {
                        var _a;
                        const { type, format, properties } = getProperties(pergunta);
                        return [`pergunta-${pergunta.id}`, Object.assign(Object.assign({ type,
                                format }, properties), { title: `${(_a = pergunta.posicao) !== null && _a !== void 0 ? _a : index + 1}) ${pergunta.pergunta}` })];
                    })) !== null && _d !== void 0 ? _d : [])
                }
            }
        };
    }
};
export const buildSchemaFormularioEnquadramento = (formularioEnquadramento, isCategorizado) => {
    var _a, _b, _c, _d;
    if (isCategorizado) {
        return {
            type: 'object',
            properties: Object.fromEntries((_b = (_a = formularioEnquadramento === null || formularioEnquadramento === void 0 ? void 0 : formularioEnquadramento.formularioEnquadramentoCategorizado) === null || _a === void 0 ? void 0 : _a.map((categoria, indexCategory) => {
                var _a, _b, _c;
                const posicao = (_a = categoria.posicao) !== null && _a !== void 0 ? _a : indexCategory + 1;
                return [`form-${categoria.id}`, {
                        type: 'object',
                        title: `${posicao}) ${categoria.tituloCategoria}`,
                        properties: Object.fromEntries((_c = (_b = categoria === null || categoria === void 0 ? void 0 : categoria.formularioEnquadramentoCategorizadoPerguntaAvaliacao) === null || _b === void 0 ? void 0 : _b.map((pergunta, index) => {
                            var _a;
                            const { type, format, properties } = getProperties(pergunta);
                            return [`pergunta-${pergunta.id}`, Object.assign(Object.assign({ type,
                                    format }, properties), { title: `${posicao}.${(_a = pergunta.posicao) !== null && _a !== void 0 ? _a : index + 1}) ${pergunta.pergunta}` })];
                        })) !== null && _c !== void 0 ? _c : [])
                    }];
            })) !== null && _b !== void 0 ? _b : [])
        };
    }
    else {
        return {
            type: 'object',
            properties: {
                form: {
                    type: 'object',
                    properties: Object.fromEntries((_d = (_c = formularioEnquadramento === null || formularioEnquadramento === void 0 ? void 0 : formularioEnquadramento.formularioEnquadramentoPergunta) === null || _c === void 0 ? void 0 : _c.map((pergunta, index) => {
                        var _a;
                        const { type, format, properties } = getProperties(pergunta);
                        return [`pergunta-${pergunta.id}`, Object.assign(Object.assign({ type,
                                format }, properties), { title: `${(_a = pergunta.posicao) !== null && _a !== void 0 ? _a : index + 1}) ${pergunta.pergunta}` })];
                    })) !== null && _d !== void 0 ? _d : [])
                }
            }
        };
    }
};
