export const moduleName = 'ORCAMENTO.TESOURO.TESOURO';
export const displayProperties = ['ano'];
export const moduleLabel = 'Tesouro';
export const headCells = [{
        id: 'ano',
        numeric: false,
        disablePadding: false,
        label: 'Ano'
    }, {
        id: 'valorPrevisto',
        numeric: true,
        disablePadding: false,
        label: 'Valor Inicial Estimado',
        mask: 'money'
    }, {
        id: 'valorPrometido',
        numeric: true,
        disablePadding: false,
        label: 'Valor do Gasto Estimado',
        mask: 'money'
    }, {
        id: 'valorAlocado',
        numeric: true,
        disablePadding: false,
        label: 'Valor Alocado',
        mask: 'money'
    }, {
        id: 'valorAtual',
        numeric: true,
        disablePadding: false,
        label: 'Valor Atual',
        mask: 'money'
    }];
