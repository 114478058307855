import { useEffect, useState } from 'react';
import { FuncaoMembroApi } from './funcao-membro-api';
export function useFuncoesMembros() {
    const [funcoesMembros, setFuncoesMembros] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const apiInstance = FuncaoMembroApi.getInstance();
        if (!funcoesMembros.length) {
            apiInstance.getAll().then(data => {
                setFuncoesMembros(data);
                setLoading(false);
            }).catch(err => {
                console.error(err);
                setError(err);
                setLoading(false);
            });
        }
        else
            setLoading(false);
    }, []);
    return {
        funcoesMembros,
        loading,
        error
    };
}
