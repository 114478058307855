import { TipoRespostaPerguntaAvaliacao } from '../models/pergunta-avaliacao';
export const buildSingleDefaultFormula = perguntas => {
    if (!perguntas || perguntas.length === 0)
        return;
    const defaultFormula = perguntas.map((pergunta, index) => pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota ? `P${index + 1}` : null).filter(item => item !== null);
    return `(${defaultFormula.join(' + ')}) / ${defaultFormula.length}`;
};
export const buildCategorizedDefaultFormula = perguntas => {
    if (!perguntas || perguntas.length === 0)
        return;
    const formulaParts = [];
    perguntas.forEach((categoria, categoriaIndex) => {
        const categoriaPerguntas = 'formularioConsultoriaCategorizadoPerguntaAvaliacao' in categoria ? categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao : categoria.formularioEnquadramentoCategorizadoPerguntaAvaliacao;
        if (!categoriaPerguntas)
            return;
        categoriaPerguntas.forEach((pergunta, perguntaIndex) => {
            if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                formulaParts.push(`P${categoriaIndex + 1}.${perguntaIndex + 1}`);
            }
        });
    });
    if (formulaParts.length === 0)
        return;
    return `(${formulaParts.join(' + ')}) / ${formulaParts.length}`;
};
