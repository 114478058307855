import { tipoSituacaoI18N } from '../../../../models/proposta';
export const displayProperties = ['usuario.nome'];
export const moduleLabel = 'Convite de Consultores';
export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.FORMULARIO_CONSULTORIA';
export const headCells = [{
        id: 'usuario.nome',
        numeric: false,
        disablePadding: false,
        label: 'Nome'
    }, {
        id: 'situacao',
        numeric: false,
        disablePadding: false,
        label: 'Situação',
        computedRowLabel: row => {
            return tipoSituacaoI18N[row.situacao];
        }
    }];
