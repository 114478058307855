import dayjs from '../components/dayjs/sig-dayjs';
const hasFormat = obj => obj && typeof obj === 'object' && 'format' in obj;
// Arruma o formato das datas ao carregar uma row
export const fixDate = (data, schema) => {
    var _a, _b, _c, _d;
    for (const [key, value] of Object.entries((_a = schema === null || schema === void 0 ? void 0 : schema.properties) !== null && _a !== void 0 ? _a : [])) {
        if (hasFormat(value) && (value === null || value === void 0 ? void 0 : value.format) === 'date') {
            if (data === null || data === void 0 ? void 0 : data[key]) {
                data[key] = (_d = (_c = (_b = dayjs === null || dayjs === void 0 ? void 0 : dayjs(data === null || data === void 0 ? void 0 : data[key], 'YYYY-MM-DD', 'UTC')) === null || _b === void 0 ? void 0 : _b.format) === null || _c === void 0 ? void 0 : _c.call(_b, 'YYYY-MM-DD')) !== null && _d !== void 0 ? _d : data === null || data === void 0 ? void 0 : data[key];
            }
        }
    }
};
