var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pascalCase } from 'change-case';
import { makeApi } from '../api/generic-api';
export const createResource = function (ctx, apiUrl) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    return __awaiter(this, arguments, void 0, function* () {
        let fieldsConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
        let customPaths = arguments.length > 3 ? arguments[3] : undefined;
        const { setFormData, crudStates, parameterFilters, setParameterFilters, showError } = ctx;
        const rawData = ctx.formData;
        const formId = (_a = ctx.formData) === null || _a === void 0 ? void 0 : _a.id;
        const api = makeApi(apiUrl, {
            customPaths: customPaths
        });
        const urlVar = `step${pascalCase((_b = apiUrl === null || apiUrl === void 0 ? void 0 : apiUrl.replace) === null || _b === void 0 ? void 0 : _b.call(apiUrl, '/', ''))}Id`;
        const formData = fieldsConfig ? Object.fromEntries(Object.entries(rawData).filter(_ref => {
            let [path] = _ref;
            const field = path.split('.').at(0);
            return fieldsConfig.paths.some(configPath => configPath === null || configPath === void 0 ? void 0 : configPath.startsWith(field));
        })) : rawData;
        const isCreated = !!(formId && (parameterFilters === null || parameterFilters === void 0 ? void 0 : parameterFilters[urlVar]) && Number(parameterFilters === null || parameterFilters === void 0 ? void 0 : parameterFilters[urlVar]) === formId);
        if (crudStates.add && !isCreated) {
            try {
                const postResponse = yield ((_c = api === null || api === void 0 ? void 0 : api.post) === null || _c === void 0 ? void 0 : _c.call(api, formData));
                if (postResponse.status === 201) {
                    const newId = (_d = postResponse === null || postResponse === void 0 ? void 0 : postResponse.data) === null || _d === void 0 ? void 0 : _d.id;
                    setParameterFilters(Object.assign(Object.assign({}, parameterFilters), { [urlVar]: newId }));
                    setFormData(Object.assign(Object.assign({}, rawData), postResponse.data));
                    return newId;
                }
            }
            catch (error) {
                let errorMessage = ((_g = (_f = (_e = error === null || error === void 0 ? void 0 : error.cause) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.message) || ((_j = (_h = error === null || error === void 0 ? void 0 : error.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.message);
                if (Array.isArray(errorMessage))
                    errorMessage = errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage[0];
                showError(errorMessage || 'Ocorreu um erro.');
                return false;
            }
        }
        if (crudStates.edit || formId) {
            try {
                const putResponse = yield ((_k = api === null || api === void 0 ? void 0 : api.put) === null || _k === void 0 ? void 0 : _k.call(api, formId, formData));
                if (putResponse.status === 200) {
                    return true;
                }
                else {
                    console.error(putResponse);
                    return false;
                }
            }
            catch (error) {
                let errorMessage = ((_o = (_m = (_l = error === null || error === void 0 ? void 0 : error.cause) === null || _l === void 0 ? void 0 : _l.response) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.message) || ((_q = (_p = error === null || error === void 0 ? void 0 : error.response) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.message);
                showError(errorMessage || 'Ocorreu um erro.');
                console.error(errorMessage);
                return false;
            }
        }
        return true;
    });
};
