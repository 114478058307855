import { areaDeConhecimentoHandleChange } from '../../editar-perfil/handlers/area-de-conhecimento.handler';
import { makeAreaDeConhecimentoApi } from '../../fundacao/area-de-conhecimento/area-de-conhecimento-api';
const disableTituloDoEdital = {
    effect: 'DISABLE',
    condition: {}
};
const visibleIfHasFilter = (_ctx, _apiListData, queryFilterValues) => {
    var _a;
    const filterValue = (_a = queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0]) === null || _a === void 0 ? void 0 : _a.value;
    if (filterValue !== null && filterValue !== undefined)
        return true;
    return false;
};
const disableIfNoOptions = (ctx, apiListData) => {
    if (apiListData.length === 0)
        return true;
    return false;
};
const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();
export const informacoesIniciaisUiSchema = () => [{
        type: 'Void',
        scope: '#/properties/editalId'
    }, {
        type: 'Control',
        scope: '#/properties/edital/properties/nome',
        label: 'Título do Edital',
        rule: disableTituloDoEdital
    }, {
        type: 'Control',
        scope: '#/properties/tituloDoProjeto',
        label: 'Título do Projeto',
        options: {
            required: true
        }
    }, {
        type: 'Control',
        scope: '#/properties/duracao',
        label: 'Duração do Projeto em Meses',
        options: {
            required: true,
            isPositiveNumber: true
        }
    }, {
        type: 'Select',
        scope: '#/properties/instituicaoExecutoraId',
        label: 'Instituição Executora',
        options: {
            formatOptionLabel: option => `${option === null || option === void 0 ? void 0 : option.sigla}/${option === null || option === void 0 ? void 0 : option.nome}`
        }
    }, {
        type: 'Select',
        scope: '#/properties/unidadeExecutoraId',
        label: 'Unidade Executora',
        options: {
            formatOptionLabel: option => `${option === null || option === void 0 ? void 0 : option.sigla}/${option === null || option === void 0 ? void 0 : option.nome}`,
            filter: {
                formFields: ['instituicaoExecutoraId'],
                handler: (formData, listData, api) => {
                    if (!formData.instituicaoExecutoraId)
                        return Promise.resolve(listData);
                    return api.getAll({
                        instituicaoId: formData === null || formData === void 0 ? void 0 : formData.instituicaoExecutoraId
                    });
                }
            }
        }
    }, {
        type: 'Control',
        scope: '#/properties/areaDeConhecimento',
        label: 'Áreas de Conhecimento da Proposta*',
        options: {
            label: 'Área de Conhecimento',
            detail: {
                type: 'VerticalLayout',
                elements: [{
                        type: 'Select',
                        scope: '#/properties/grandeAreaId',
                        label: 'Grande Área',
                        options: {
                            apiInstance: apiInstanceAreaDeConhecimento,
                            handleChange: areaDeConhecimentoHandleChange,
                            displayProperties: ['grandeAreaNome'],
                            filter: {
                                handler: (_formData, _listData, api) => {
                                    return api.buscarGrandesAreas();
                                }
                            },
                            disableHandler: disableIfNoOptions
                        }
                    }, {
                        type: 'Select',
                        scope: '#/properties/areaId',
                        label: 'Área',
                        options: {
                            apiInstance: apiInstanceAreaDeConhecimento,
                            handleChange: areaDeConhecimentoHandleChange,
                            displayProperties: ['areaNome'],
                            filter: {
                                formFields: ['grandeAreaId'],
                                handler: (_, __, api, queryFilterValues) => {
                                    if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                        return Promise.resolve([]);
                                    return api.buscarAreaPorGrandeAreaId(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value);
                                }
                            },
                            disableHandler: disableIfNoOptions,
                            visibleHandler: visibleIfHasFilter
                        }
                    }, {
                        type: 'Select',
                        scope: '#/properties/subAreaId',
                        label: 'Sub-área',
                        options: {
                            apiInstance: apiInstanceAreaDeConhecimento,
                            displayProperties: ['subAreaNome'],
                            handleChange: areaDeConhecimentoHandleChange,
                            filter: {
                                formFields: ['areaId'],
                                handler: (_, __, api, queryFilterValues) => {
                                    if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                        return Promise.resolve([]);
                                    return api.buscarSubAreaPorAreaId(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value);
                                }
                            },
                            disableHandler: disableIfNoOptions,
                            visibleHandler: visibleIfHasFilter
                        }
                    }, {
                        type: 'Select',
                        scope: '#/properties/especialidadeId',
                        label: 'Especialidade',
                        options: {
                            apiInstance: apiInstanceAreaDeConhecimento,
                            displayProperties: ['especialidadeNome'],
                            handleChange: areaDeConhecimentoHandleChange,
                            filter: {
                                formFields: ['subAreaId'],
                                handler: (_, __, api, queryFilterValues) => {
                                    if (!(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value))
                                        return Promise.resolve([]);
                                    return api.buscarEspecialidadePorSubAreaId(queryFilterValues === null || queryFilterValues === void 0 ? void 0 : queryFilterValues[0].value);
                                }
                            },
                            disableHandler: disableIfNoOptions,
                            visibleHandler: visibleIfHasFilter
                        }
                    }]
            }
        }
    }];
