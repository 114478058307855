export const displayProperties = ['logradouro', 'numero', 'bairro', 'municipio', 'estado'];
export const moduleLabel = 'Endereço';
export const headCells = [{
        id: 'logradouro',
        numeric: false,
        disablePadding: false,
        label: 'Logradouro'
    }, {
        id: 'numero',
        numeric: false,
        disablePadding: false,
        label: 'Número'
    }, {
        id: 'bairro',
        numeric: false,
        disablePadding: false,
        label: 'Bairro'
    }, {
        id: 'municipio',
        numeric: false,
        disablePadding: false,
        label: 'Municipio'
    }, {
        id: 'estado',
        numeric: false,
        disablePadding: false,
        label: 'Estado'
    }];
