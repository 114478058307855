const cronogramaDesembolsoUiSchemaFactory = _ref => {
    let { tesouro } = _ref;
    const elements = [{
            type: 'MonetaryInput',
            scope: '#/properties/valor',
            label: 'Valor',
            options: {
                required: true,
                onlyCreate: true
            }
        }, {
            type: 'Date',
            scope: '#/properties/dataPrevista',
            label: 'Data Prevista',
            options: {
                required: true,
                onlyCreate: true
            }
        }, {
            type: 'Date',
            scope: '#/properties/dataEfetiva',
            label: 'Data Efetiva'
        }];
    if (tesouro) {
        elements.unshift({
            type: 'Select',
            scope: '#/properties/tesouroId',
            label: 'Tesouro',
            options: {
                onlyCreate: true,
                filter: {
                    handler: (formData, listData, api) => {
                        return api.getAll({
                            withDeleted: 'false',
                            orderBy: 'ano',
                            orderDirection: 'DESC'
                        });
                    }
                }
            }
        });
    }
    return {
        type: 'VerticalLayout',
        elements
    };
};
export default cronogramaDesembolsoUiSchemaFactory;
