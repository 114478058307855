import * as bootstrap from 'styled-icons/bootstrap';
import * as boxiconsRegular from 'styled-icons/boxicons-regular';
import * as boxiconsSolid from 'styled-icons/boxicons-solid';
import * as entypo from 'styled-icons/entypo';
import * as evaiconsOutline from 'styled-icons/evaicons-outline';
import * as evaiconsSolid from 'styled-icons/evaicons-solid';
import * as evil from 'styled-icons/evil';
import * as fluentuiSystemFilled from 'styled-icons/fluentui-system-filled';
import * as fluentuiSystemRegular from 'styled-icons/fluentui-system-regular';
import * as foundation from 'styled-icons/foundation';
import * as heroiconsSolid from 'styled-icons/heroicons-solid';
import * as icomoon from 'styled-icons/icomoon';
import * as ioniconsOutline from 'styled-icons/ionicons-outline';
import * as ioniconsSharp from 'styled-icons/ionicons-sharp';
import * as material from 'styled-icons/material';
import * as materialOutlined from 'styled-icons/material-outlined';
import * as remixLine from 'styled-icons/remix-line';
import * as simpleIcons from 'styled-icons/simple-icons';
import * as typicons from 'styled-icons/typicons';
import * as zondicons from 'styled-icons/zondicons';
import * as feather from 'styled-icons/feather';
import * as faSolid from 'styled-icons/fa-solid';
export const { CardChecklist, Check2, Clipboard2Check, CloudUpload, EyeFill, FiletypeDoc, FiletypeJpg, FiletypePdf, FiletypeXls, Gear, BriefcaseFill, BagPlusFill, Calendar2Week, ListCheck, Receipt, Trash3, MegaphoneFill, QuestionCircleFill, PersonXFill, ChevronDown, ChevronUp } = bootstrap;
export const { Money, Calendar } = boxiconsRegular;
export const { Report, User, CheckSquare, UserDetail, PlaneTakeOff, DownArrow } = boxiconsSolid;
export const { ClipboardPencil, Paperclip } = foundation;
export const { MoneyCalculator, Home, BuildingMultiple, Person, PeopleCommunityAdd, Add, MailOpenPerson, MailArrowUp } = fluentuiSystemFilled;
export const { BuildingBankToolbox, MoneyHand, AttachText, Production, NoteEdit } = fluentuiSystemRegular;
export const { Business, NoteAlt } = materialOutlined;
export const { Attachment } = typicons;
export const { Star, Info, Settings } = evaiconsSolid;
export const { Profile, Survey } = remixLine;
export const { Blocked } = icomoon;
export const { AcademicCap } = heroiconsSolid;
export const { DocumentText, Close } = ioniconsOutline;
export const { DocumentAttach } = ioniconsSharp;
export const { AddLocationAlt, StarRate, Reviews, PriorityHigh, Checklist } = material;
export const { Justeat } = simpleIcons;
export const { Eye } = entypo;
export const { Search } = evil;
export const { EyeOffOutline, EyeOutline } = evaiconsOutline;
export const { Download } = feather;
export const { ViewShow } = zondicons;
export const { FileSignature } = faSolid;
