export const displayProperties = ['grandeAreaNome'];
export const moduleLabel = 'Áreas de Conhecimento';
export const moduleName = 'CONFIGURACOES_INICIAIS.AREA_DE_CONHECIMENTO';
export const rowsLength = 12;
export const headCells = [{
        id: 'grandeAreaNome',
        numeric: false,
        disablePadding: false,
        label: 'Nome da Grande Área'
    }];
