export const moduleName = 'ORCAMENTO.TESOURO.HISTORICO_AJUSTE_TESOURO.HISTORICO_AJUSTE_TESOURO';
export const displayProperties = ['tesouroId'];
export const moduleLabel = 'Ajustar Tesouro';
export const headCells = [{
        id: 'tesouroId',
        numeric: true,
        disablePadding: false,
        label: 'Ano',
        resource: {
            name: 'tesouro',
            target: 'ano'
        }
    }, {
        id: 'valorInicial',
        numeric: true,
        disablePadding: false,
        label: 'Valor Inicial',
        mask: 'money',
        computedRowLabel: row => {
            let valorAjustado;
            const tipoCodigo = row.tipo;
            const valorAjuste = row.valorAjuste;
            const valorFinal = row.valorFinal;
            if (tipoCodigo === 'CORTE') {
                valorAjustado = valorFinal + valorAjuste;
            }
            else if (tipoCodigo === 'ADITIVO') {
                valorAjustado = valorFinal - valorAjuste;
            }
            valorAjustado = parseInt(valorAjustado) / 100;
            valorAjustado = 'R$ ' + valorAjustado.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return valorAjustado;
        }
    }, {
        id: 'valorAjuste',
        numeric: true,
        disablePadding: false,
        label: 'Valor do Ajuste',
        mask: 'money',
        computedRowLabel: row => {
            const tipoCodigo = row.tipo;
            let valorAjuste = row.valorAjuste;
            valorAjuste = parseInt(valorAjuste) / 100;
            valorAjuste = 'R$ ' + valorAjuste.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            if (tipoCodigo === 'CORTE')
                valorAjuste = '-' + valorAjuste;
            if (tipoCodigo === 'ADITIVO')
                valorAjuste = '+' + valorAjuste;
            return valorAjuste;
        }
    }, {
        id: 'valorAjustado',
        numeric: true,
        disablePadding: false,
        label: 'Valor Ajustado',
        mask: 'money',
        computedRowLabel: row => {
            let valorFinal = row.valorFinal;
            valorFinal = parseInt(valorFinal) / 100;
            valorFinal = 'R$ ' + valorFinal.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return valorFinal;
        }
    }, {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Data do Ajuste',
        dateFormat: 'DD/MM/YYYY'
    }];
