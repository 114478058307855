var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeApi } from '../../../api/generic-api';
export class FuncaoMembroApi {
    constructor() {
        this.lastFetchTime = null;
        this.cachedData = null;
        this.fetchPromise = null;
        this.CACHE_TIME = 5000;
        this.funcaoMembroApi = makeApi('/funcao-membro');
    }
    static getInstance() {
        if (!FuncaoMembroApi.instance) {
            FuncaoMembroApi.instance = new FuncaoMembroApi();
        }
        return FuncaoMembroApi.instance;
    }
    getApi() {
        return this.funcaoMembroApi;
    }
    getAll() {
        return __awaiter(this, void 0, void 0, function* () {
            const now = Date.now();
            if (this.lastFetchTime && now - this.lastFetchTime < this.CACHE_TIME && this.cachedData) {
                return this.cachedData;
            }
            if (this.fetchPromise) {
                return this.fetchPromise;
            }
            this.fetchPromise = this.funcaoMembroApi.getAll();
            try {
                this.cachedData = yield this.fetchPromise;
                this.lastFetchTime = now;
                return this.cachedData;
            }
            finally {
                this.fetchPromise = null;
            }
        });
    }
}
