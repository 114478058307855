import { RuleEffect } from '@jsonforms/core';
import { TipoRevisaoTecnica } from '../../../models/revisao-tecnica';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
import { dadosAcademicosUiSchema } from '../../editar-perfil/uischemas/dados-academicos.uischema';
import { dadosPessoaisUiSchema } from '../../editar-perfil/uischemas/dados-pessoais.uischema';
import { dadosProfissionaisUiSchema } from '../../editar-perfil/uischemas/dados-profissionais.uischema';
import { enderecoUiSchema } from '../../editar-perfil/uischemas/endereco.uischema';
import { stepHandlerAbrangencia } from '../handlers/step-handler-abrangencia';
import { stepHandlerDadosCoordenador } from '../handlers/step-handler-dados-coordenador';
import { stepHandlerDescricao } from '../handlers/step-handler-descricao';
import { stepHandlerIndicadores } from '../handlers/step-handler-indicadores';
import { getOrcamentoPropostaUiSchema } from '../orcamento-proposta.uischema';
import { abrangenciaUiSchema } from '../uischemas/abrangencia.uischema';
import { atividadesUiSchema } from '../uischemas/atividades.uischema';
import { descricaoUiSchema } from '../uischemas/descricao.uischema';
import { documentosDaPropostaUiSchema } from '../uischemas/documentos-da-proposta.uischema';
import { indicadoresDeProducaoUiSchema } from '../uischemas/indicadores-de-producao.uischema';
import { informacoesComplementaresUiSchema } from '../uischemas/informacoes-complementares.uischema';
import { informacoesIniciaisUiSchema } from '../uischemas/informacoes-iniciais.uischema';
import { membrosUiSchema } from '../uischemas/membros.uischema';
import { termoDeAceiteUiSchema } from '../uischemas/termo-de-aceite.uischema';
const revisaoTecnicaUiSchema = {
    type: 'CategorizationMenu',
    elements: [{
            type: 'Category',
            label: 'Caracterização da Proposta',
            elements: [{
                    type: 'Category',
                    label: 'Informações Iniciais',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...informacoesIniciaisUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.informacoesIniciais
                            }
                        }]
                }, {
                    type: 'Category',
                    label: 'Informações Complementares',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...informacoesComplementaresUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.informacoesComplementares
                            }
                        }],
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'AND',
                            conditions: [{
                                    scope: '#/properties/edital/properties/informacaoComplementarPergunta',
                                    schema: {
                                        type: 'array',
                                        minItems: 1
                                    }
                                }, {}]
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Abrangência',
                    options: {
                        stepHandler: {
                            handler: stepHandlerAbrangencia
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...abrangenciaUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.abrangencia
                            }
                        }]
                }]
        }, {
            type: 'Category',
            label: 'Dados do Coordenador',
            elements: [{
                    type: 'Category',
                    label: 'Dados Pessoais',
                    elements: [{
                            type: 'RevisaoLayout',
                            revisaoScope: '#/properties/revisaoTecnica',
                            elements: [...dadosPessoaisUiSchema('/properties/criadoPor').elements],
                            options: {
                                revisaoType: TipoRevisaoTecnica.dadosPessoais
                            }
                        }],
                    options: {
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Endereço',
                    elements: [{
                            type: 'RevisaoLayout',
                            revisaoScope: '#/properties/revisaoTecnica',
                            elements: [...enderecoUiSchema('/properties/criadoPor').elements],
                            options: {
                                revisaoType: TipoRevisaoTecnica.endereco
                            }
                        }],
                    options: {
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Dados Acadêmicos',
                    elements: [{
                            type: 'RevisaoLayout',
                            revisaoScope: '#/properties/revisaoTecnica',
                            elements: [...dadosAcademicosUiSchema('/properties/criadoPor').elements],
                            options: {
                                revisaoType: TipoRevisaoTecnica.dadosAcademicos
                            }
                        }],
                    options: {
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Dados Profissionais',
                    elements: [{
                            type: 'RevisaoLayout',
                            revisaoScope: '#/properties/revisaoTecnica',
                            elements: [...dadosProfissionaisUiSchema('/properties/criadoPor').elements],
                            options: {
                                revisaoType: TipoRevisaoTecnica.dadosProfissionais
                            }
                        }],
                    options: {
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    }
                }]
        }, {
            type: 'Category',
            label: 'Anexos',
            elements: [{
                    type: 'Category',
                    label: 'Documentos Pessoais',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    label: 'Documentos Pessoais',
                                    type: 'File',
                                    options: {
                                        categoriesRoute: 'proposta/documento-pessoal-proposta-anexo/categories',
                                        route: 'usuario/usuario-anexo',
                                        fileApiOptions: formData => {
                                            return {
                                                editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                                            };
                                        },
                                        withoutFk: true,
                                        filterByCategories: true
                                    }
                                }]
                        }],
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/edital/properties/documentoPessoalEdital',
                            schema: {
                                type: 'array',
                                minItems: 1
                            }
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Documentos da Proposta',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...documentosDaPropostaUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.documentosProposta
                            }
                        }],
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/edital/properties/documentoPropostaEdital',
                            schema: {
                                type: 'array',
                                minItems: 1
                            }
                        }
                    }
                }]
        }, {
            type: 'Category',
            label: 'Plano de Trabalho',
            elements: [{
                    type: 'Category',
                    label: 'Descrição',
                    options: {
                        stepHandler: {
                            handler: stepHandlerDescricao
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...descricaoUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.descricao
                            }
                        }],
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'AND',
                            conditions: [{
                                    scope: '#/properties/edital/properties/pergunta',
                                    schema: {
                                        type: 'array',
                                        minItems: 1
                                    }
                                }, {}]
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Indicadores de Produção',
                    options: {
                        stepHandler: {
                            handler: stepHandlerIndicadores
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...indicadoresDeProducaoUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.indicadoresProducao
                            }
                        }],
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'AND',
                            conditions: [{
                                    scope: '#/properties/edital/properties/indicadorProducao',
                                    schema: {
                                        type: 'array',
                                        minItems: 1
                                    }
                                }, {}]
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Membros',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...membrosUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.membros
                            }
                        }]
                }, {
                    type: 'Category',
                    label: 'Atividades',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: [{
                            type: 'RevisaoLayout',
                            elements: [...atividadesUiSchema()],
                            revisaoScope: '#/properties/revisaoTecnica',
                            options: {
                                revisaoType: TipoRevisaoTecnica.atividades
                            }
                        }]
                }, getOrcamentoPropostaUiSchema()]
        }, {
            type: 'Category',
            label: 'Termos',
            elements: termoDeAceiteUiSchema()
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        showProgress: true,
        route: '/proposta'
    }
};
export default revisaoTecnicaUiSchema;
