export const moduleName = 'AVALIACAO.FORMULARIO_ENQUADRAMENTO.FORMULARIO_ENQUADRAMENTO';
export const moduleLabel = 'Avaliações';
export const headCells = [{
        id: 'criadoPorId',
        numeric: false,
        disablePadding: false,
        label: 'Nome do Avaliador',
        computedRowLabel: row => { var _a; return (_a = row === null || row === void 0 ? void 0 : row.criadoPor) === null || _a === void 0 ? void 0 : _a.nome; }
    }, {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Data da Avaliação',
        dateFormat: 'DD/MM/YYYY'
    }];
