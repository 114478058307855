export const createShowRuleForEditalRubrica = tipoEditalRubricaValue => {
    return {
        effect: 'SHOW',
        condition: {
            scope: '#/properties/edital',
            schema: {
                properties: {
                    editalRubrica: {
                        type: 'array',
                        contains: {
                            properties: {
                                tipoEditalRubrica: {
                                    enum: [tipoEditalRubricaValue]
                                }
                            }
                        }
                    }
                }
            }
        }
    };
};
