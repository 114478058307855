var _a;
import axios from 'axios';
const fileClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_ADDR}${(_a = process.env.REACT_APP_API_PATH_PREFIX) !== null && _a !== void 0 ? _a : ''}/`,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
fileClient.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, error => {
    return Promise.reject(error);
});
export default fileClient;
