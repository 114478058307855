var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RuleEffect, setValidationMode } from '@jsonforms/core';
import { createResource } from '../../utils/create-resource';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { dadosAcademicosUiSchema } from '../editar-perfil/uischemas/dados-academicos.uischema';
import { dadosPessoaisUiSchema } from '../editar-perfil/uischemas/dados-pessoais.uischema';
import { dadosProfissionaisUiSchema } from '../editar-perfil/uischemas/dados-profissionais.uischema';
import { enderecoUiSchema } from '../editar-perfil/uischemas/endereco.uischema';
import { stepHandlerAbrangencia } from './handlers/step-handler-abrangencia';
import { stepHandlerDadosCoordenador } from './handlers/step-handler-dados-coordenador';
import { stepHandlerDescricao } from './handlers/step-handler-descricao';
import { stepHandlerIndicadores } from './handlers/step-handler-indicadores';
import { getOrcamentoPropostaUiSchema } from './orcamento-proposta.uischema';
import { abrangenciaUiSchema } from './uischemas/abrangencia.uischema';
import { atividadesUiSchema } from './uischemas/atividades.uischema';
import { documentosDaPropostaUiSchema } from './uischemas/documentos-da-proposta.uischema';
import { informacoesIniciaisUiSchema } from './uischemas/informacoes-iniciais.uischema';
import { membrosUiSchema } from './uischemas/membros.uischema';
import { termoDeAceiteUiSchema } from './uischemas/termo-de-aceite.uischema';
import { informacoesComplementaresUiSchema } from './uischemas/informacoes-complementares.uischema';
import { descricaoUiSchema } from './uischemas/descricao.uischema';
import { indicadoresDeProducaoUiSchema } from './uischemas/indicadores-de-producao.uischema';
const handleFinish = (_ref) => __awaiter(void 0, void 0, void 0, function* () {
    let { ctx, handleStep, categories } = _ref;
    const id = yield createResource(ctx, '/proposta');
    if (!id) {
        setValidationMode('ValidateAndShow');
        return false;
    }
    const stepPendencyIndex = categories.findIndex(category => category === null || category === void 0 ? void 0 : category.options.hiddenCategory);
    handleStep(stepPendencyIndex);
});
const propostaUiSchema = {
    type: 'CategorizationMenu',
    elements: [{
            type: 'Category',
            label: 'Caracterização da Proposta',
            elements: [{
                    type: 'Category',
                    label: 'Informações Iniciais',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: informacoesIniciaisUiSchema()
                }, {
                    type: 'Category',
                    label: 'Informações Complementares',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: informacoesComplementaresUiSchema(),
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'AND',
                            conditions: [{
                                    scope: '#/properties/edital/properties/informacaoComplementarPergunta',
                                    schema: {
                                        type: 'array',
                                        minItems: 1
                                    }
                                }, {}]
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Abrangência',
                    options: {
                        stepHandler: {
                            handler: stepHandlerAbrangencia
                        }
                    },
                    elements: abrangenciaUiSchema()
                }]
        }, {
            type: 'Category',
            label: 'Dados do Coordenador',
            elements: [Object.assign(Object.assign({}, dadosPessoaisUiSchema('/properties/criadoPor')), { options: {
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    } }), Object.assign(Object.assign({}, enderecoUiSchema('/properties/criadoPor')), { options: {
                        additionalPaths: ['paisId'],
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    } }), Object.assign(Object.assign({}, dadosAcademicosUiSchema('/properties/criadoPor')), { options: {
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    } }), Object.assign(Object.assign({}, dadosProfissionaisUiSchema('/properties/criadoPor')), { options: {
                        stepHandler: {
                            handler: stepHandlerDadosCoordenador
                        }
                    } })]
        }, {
            type: 'Category',
            label: 'Anexos',
            elements: [{
                    type: 'Category',
                    label: 'Documentos Pessoais',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: documentosDaPropostaUiSchema(),
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/edital/properties/documentoPessoalEdital',
                            schema: {
                                type: 'array',
                                minItems: 1
                            }
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Documentos da Proposta',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors
                        }
                    },
                    elements: [{
                            type: 'VerticalLayout',
                            elements: [{
                                    label: 'Documentos da Proposta',
                                    type: 'File',
                                    scope: '#/properties/documentoPropostaAnexo',
                                    options: {
                                        fileApiOptions: formData => {
                                            return {
                                                editalId: formData === null || formData === void 0 ? void 0 : formData.editalId
                                            };
                                        }
                                    }
                                }]
                        }],
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/edital/properties/documentoPropostaEdital',
                            schema: {
                                type: 'array',
                                minItems: 1
                            }
                        }
                    }
                }]
        }, {
            type: 'Category',
            label: 'Plano de Trabalho',
            elements: [{
                    type: 'Category',
                    label: 'Descrição',
                    options: {
                        stepHandler: {
                            handler: stepHandlerDescricao
                        }
                    },
                    elements: descricaoUiSchema(),
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'AND',
                            conditions: [{
                                    scope: '#/properties/edital/properties/pergunta',
                                    schema: {
                                        type: 'array',
                                        minItems: 1
                                    }
                                }, {}]
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Indicadores de Produção',
                    options: {
                        stepHandler: {
                            handler: stepHandlerIndicadores
                        }
                    },
                    elements: indicadoresDeProducaoUiSchema(),
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'AND',
                            conditions: [{
                                    scope: '#/properties/edital/properties/indicadorProducao',
                                    schema: {
                                        type: 'array',
                                        minItems: 1
                                    }
                                }, {}]
                        }
                    }
                }, {
                    type: 'Category',
                    label: 'Membros',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: membrosUiSchema()
                }, {
                    type: 'Category',
                    label: 'Atividades',
                    options: {
                        stepHandler: {
                            handler: stepHandlerErrors,
                            callback: (ctx, fieldsConfig) => createResource(ctx, '/proposta', fieldsConfig)
                        }
                    },
                    elements: atividadesUiSchema()
                }, getOrcamentoPropostaUiSchema()]
        }, {
            type: 'Category',
            label: 'Termos',
            elements: termoDeAceiteUiSchema()
        }, {
            type: 'Category',
            label: 'Pendências',
            elements: [{
                    type: 'Pendencies',
                    label: 'Pendências',
                    options: {
                        route: '/proposta'
                    }
                }],
            options: {
                hiddenCategory: true,
                finishButtonTitle: 'Submeter Proposta'
            }
        }],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        showProgress: true,
        route: '/proposta',
        finishButtonTitle: 'Verificar Pendências',
        onFinish: _ref2 => {
            let { ctx, handleStep, categories } = _ref2;
            return handleFinish({
                ctx,
                handleStep,
                categories
            });
        }
    }
};
export default propostaUiSchema;
