export const makeCustomRequiredIfDefineMonth = field => {
    var _a;
    return ({
        field,
        hasError: (value, formData) => (formData === null || formData === void 0 ? void 0 : formData['definirDuracaoProjetoEmMeses']) === true ? !value : false,
        error: {
            keyword: 'customRequired',
            message: 'Campo obrigatório',
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            schemaPath: '#/required',
            params: {
                missingProperty: field
            }
        }
    });
};
export const makeRangeValidationMonth = (field, maxValue) => {
    var _a;
    return ({
        field,
        hasError: (value, formData) => (formData === null || formData === void 0 ? void 0 : formData['duracaoProjetoEmMeses']) ? Number.isNaN(Number(value)) || Number(value) > maxValue : false,
        error: {
            keyword: 'outOfRange',
            message: `A quantidade de meses definida é maior que o permitido (1 a ${maxValue}).`,
            propertyName: field,
            instancePath: `/${(_a = field === null || field === void 0 ? void 0 : field.replace) === null || _a === void 0 ? void 0 : _a.call(field, '.', '/')}`,
            dataPath: `/${field}`,
            params: {}
        }
    });
};
