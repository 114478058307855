export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.FORMULARIO_CONSULTORIA';
export const displayProperties = ['titulo'];
export const moduleLabel = 'Revisão de Avaliação';
export const headCells = [{
        id: 'consultorId',
        numeric: true,
        disablePadding: false,
        label: 'Consultor',
        computedRowLabel: row => { var _a, _b; return (_b = (_a = row === null || row === void 0 ? void 0 : row.convite) === null || _a === void 0 ? void 0 : _a.usuario) === null || _b === void 0 ? void 0 : _b.nome; }
    }, {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Data de Envio',
        dateFormat: 'DD/MM/YYYY'
    }];
