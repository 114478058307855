import { TipoResposta } from '../models/pergunta';
export const getPerguntaSchema = pergunta => {
    var _a;
    const limiarDeAlternativas = 7;
    let type = '';
    switch (pergunta === null || pergunta === void 0 ? void 0 : pergunta.tipoResposta) {
        case TipoResposta.descritiva:
            type = 'Descritiva';
            break;
        case TipoResposta.multiplaEscolha:
            type = 'MultiplaEscolha';
            break;
        case TipoResposta.multiplaSelecao:
            if (((_a = pergunta === null || pergunta === void 0 ? void 0 : pergunta.multiplaEscolha) === null || _a === void 0 ? void 0 : _a.length) > limiarDeAlternativas)
                type = 'MultiplaSelecaoGrande';
            else
                type = 'MultiplaSelecaoPequena';
            break;
        case TipoResposta.data:
            type = 'Date';
            break;
        case TipoResposta.tabela:
            type = 'Tabela';
            break;
        default:
            type = 'Control';
    }
    const properties = (pergunta => {
        var _a, _b, _c, _d, _e, _f, _g;
        let properties = {};
        if ((pergunta === null || pergunta === void 0 ? void 0 : pergunta.tipoResposta) === TipoResposta.descritiva) {
            properties = {
                type: 'string',
                title: pergunta === null || pergunta === void 0 ? void 0 : pergunta.pergunta,
                min: (_a = pergunta === null || pergunta === void 0 ? void 0 : pergunta.descritiva) === null || _a === void 0 ? void 0 : _a.minimo,
                max: (_b = pergunta === null || pergunta === void 0 ? void 0 : pergunta.descritiva) === null || _b === void 0 ? void 0 : _b.maximo,
                tipoRestricao: (_c = pergunta === null || pergunta === void 0 ? void 0 : pergunta.descritiva) === null || _c === void 0 ? void 0 : _c.tipoRestricao
            };
        }
        else if ((pergunta === null || pergunta === void 0 ? void 0 : pergunta.tipoResposta) === TipoResposta.multiplaEscolha) {
            properties = {
                type: 'number',
                title: pergunta === null || pergunta === void 0 ? void 0 : pergunta.pergunta,
                oneOf: (_d = pergunta === null || pergunta === void 0 ? void 0 : pergunta.multiplaEscolha) === null || _d === void 0 ? void 0 : _d.map(_ref => {
                    let { alternativa, id } = _ref;
                    return {
                        const: id,
                        title: alternativa
                    };
                })
            };
        }
        else if ((pergunta === null || pergunta === void 0 ? void 0 : pergunta.tipoResposta) === TipoResposta.multiplaSelecao) {
            properties = {
                type: 'array',
                title: pergunta === null || pergunta === void 0 ? void 0 : pergunta.pergunta,
                items: {
                    oneOf: (_e = pergunta === null || pergunta === void 0 ? void 0 : pergunta.multiplaEscolha) === null || _e === void 0 ? void 0 : _e.map(_ref2 => {
                        let { alternativa, id } = _ref2;
                        return {
                            const: id,
                            title: alternativa
                        };
                    })
                }
            };
        }
        else if ((pergunta === null || pergunta === void 0 ? void 0 : pergunta.tipoResposta) === TipoResposta.data) {
            properties = {
                type: 'string',
                title: pergunta === null || pergunta === void 0 ? void 0 : pergunta.pergunta
            };
        }
        else if ((pergunta === null || pergunta === void 0 ? void 0 : pergunta.tipoResposta) === TipoResposta.tabela) {
            properties = {
                type: 'array',
                title: pergunta === null || pergunta === void 0 ? void 0 : pergunta.pergunta,
                linha: (_f = pergunta === null || pergunta === void 0 ? void 0 : pergunta.tabela) === null || _f === void 0 ? void 0 : _f.linha,
                coluna: (_g = pergunta === null || pergunta === void 0 ? void 0 : pergunta.tabela) === null || _g === void 0 ? void 0 : _g.coluna
            };
        }
        return properties;
    })(pergunta);
    const uischema = {
        type: 'VerticalLayout',
        elements: [{
                type,
                scope: '#/properties/field',
                options: {
                    required: pergunta === null || pergunta === void 0 ? void 0 : pergunta.obrigatoria
                }
            }]
    };
    const schema = {
        type: 'object',
        properties: {
            field: Object.assign({}, properties)
        }
    };
    return {
        schema,
        uischema
    };
};
