export const displayProperties = ['linhaDeAcao.titulo', 'ano'];
export const moduleName = 'ORCAMENTO.PROGRAMA.EDICAO_PROGRAMA.EDICAO_RECURSO_PROGRAMA.EDICAO_RECURSO_PROGRAMA';
export const headCells = [{
        id: 'edicaoProgramaId',
        numeric: false,
        disablePadding: false,
        label: 'Edição do Programa',
        resource: {
            name: 'edicaoPrograma',
            target: 'ano'
        }
    }, {
        id: 'linhaDeAcaoId',
        numeric: false,
        disablePadding: false,
        label: 'Linha de Ação',
        resource: {
            target: data => { var _a, _b; return (_b = (_a = data.edicaoLinhaDeAcao) === null || _a === void 0 ? void 0 : _a.linhaDeAcao) === null || _b === void 0 ? void 0 : _b.titulo; }
        }
    }, {
        id: 'funcionalProgramaticaId',
        numeric: false,
        disablePadding: false,
        label: 'Funcional Programática',
        resource: {
            name: 'funcionalProgramatica',
            target: 'descricao'
        }
    }, {
        id: 'edicaoRecursoProgramaNaturezaDespesa',
        numeric: false,
        disablePadding: false,
        label: 'Valor',
        resource: {
            target: data => {
                var _a;
                let newValue = (_a = data.edicaoRecursoProgramaNaturezaDespesa) === null || _a === void 0 ? void 0 : _a.reduce((acc, curr) => acc + curr.valor, 0);
                newValue = parseInt(`${newValue}`) / 100;
                if (Number.isNaN(newValue))
                    newValue = 0;
                let newValueStr = 'R$ ' + newValue.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
                return newValueStr;
            }
        },
        mask: 'money'
    }];
