var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CrudStatesOptions } from '../jsonforms/uischema/uischema.type';
export const stepHandlerBack = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    const { crudStates, updateCrudStates, showSuccess, clearForm } = ctx;
    clearForm();
    updateCrudStates(CrudStatesOptions.LIST);
    if (crudStates.add) {
        showSuccess('Adicionado com sucesso!');
    }
    if (crudStates.edit) {
        showSuccess('Editado com sucesso!');
    }
    return true;
});
