import { ConstNode } from './const-node';
export const makeOperator = (op, leftChild, rightChild) => {
    if (leftChild instanceof ConstNode && rightChild instanceof ConstNode) {
        return new ConstNode(new OperatorNode(op, leftChild, rightChild).solve({}));
    }
    if (op === '/' && rightChild.listVars().length > 0)
        throw new Error('Division by variable is not supported');
    return new OperatorNode(op, leftChild, rightChild);
};
export class OperatorNode {
    constructor(op, leftChild, rightChild) {
        this.op = op;
        this.leftChild = leftChild;
        this.rightChild = rightChild;
    }
    listVars() {
        const left = this.leftChild.listVars();
        const right = this.rightChild.listVars();
        return [...new Set([...left, ...right])];
    }
    solve(vars) {
        const left = this.leftChild.solve(vars);
        const right = this.rightChild.solve(vars);
        switch (this.op) {
            case '+':
                return left + right;
            case '-':
                return left - right;
            case '*':
                return left * right;
            case '/':
                return left / right;
        }
    }
}
