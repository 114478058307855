var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dayjs from 'dayjs';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';
export const stepHandler = (fieldsConfig, ctx, data, url, validator, errorMessage, callback) => __awaiter(void 0, void 0, void 0, function* () {
    const { crudStates, formData, showError } = ctx;
    const errorsResult = yield stepHandlerErrors(fieldsConfig, ctx, data, url);
    if (errorsResult) {
        const isValid = crudStates.edit || crudStates.add ? validator(formData) : true;
        if (callback && isValid) {
            return callback(ctx, fieldsConfig);
        }
        if (!isValid) {
            showError(errorMessage);
        }
        return isValid;
    }
    return false;
});
export const formatDates = function () {
    for (var _len = arguments.length, dates = new Array(_len), _key = 0; _key < _len; _key++) {
        dates[_key] = arguments[_key];
    }
    return dates.map(date => dayjs.utc(date).format('YYYY-MM-DD'));
};
export const stepHandlerTermoContratacao = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    return stepHandler(fieldsConfig, ctx, data, url, validarExecucao, 'Datas de execução devem estar no intervalo de vigência e Nº Fundação deve iniciar com um número.', callback);
});
export const stepHandlerPrestacaoDeConta = (fieldsConfig, ctx, data, url, callback) => __awaiter(void 0, void 0, void 0, function* () {
    return stepHandler(fieldsConfig, ctx, data, url, validarPrestacoes, 'Datas de prestação de contas devem estar no intervalo de vigência.', callback);
});
const isDateInRange = (date, inicioVigencia, terminoVigencia) => {
    const [dateFormatted, inicioFormatted, terminoFormatted] = formatDates(date, inicioVigencia, terminoVigencia);
    return !dateFormatted || dateFormatted >= inicioFormatted && dateFormatted <= terminoFormatted;
};
const validarExecucao = termocontratacao => {
    const { inicioVigencia, terminoVigencia, numeroFundacao } = termocontratacao;
    const validarNumeroFundacao = !numeroFundacao || /^[0-9]/.test(numeroFundacao);
    if (!inicioVigencia && !terminoVigencia) {
        return true;
    }
    if (!inicioVigencia || !terminoVigencia) {
        return false;
    }
    return isDateInRange(inicioVigencia, inicioVigencia, terminoVigencia) && validarNumeroFundacao;
};
const validarPrestacoes = termocontratacao => {
    var _a;
    const { inicioVigencia, terminoVigencia, prestacaoDeConta } = termocontratacao;
    return (_a = prestacaoDeConta === null || prestacaoDeConta === void 0 ? void 0 : prestacaoDeConta.every(p => isDateInRange(p.data, inicioVigencia, terminoVigencia))) !== null && _a !== void 0 ? _a : true;
};
