export const dataTableSx = {
    styledPaperSx: {
        margin: '0px !important',
        padding: '0px !important'
    },
    tableContainerSx: {
        margin: '0px !important',
        padding: '0px !important'
    },
    tableHeadSx: {
        fontSize: '0.7rem',
        backgroundColor: '#1351b4'
    },
    tableCellSx: {
        fontSize: '0.75rem'
    },
    tablePaginationSx: {
        fontSize: '0.75rem',
        '*': {
            fontSize: '0.75rem'
        }
    }
};
