import { TipoEditalRubrica } from '../../../models/edital-rubrica';
import { getBolsaAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaBolsa.uischema';
import { getDiariasAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaDiaria.uischema';
import { getEncargoAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaEncargo.uischema';
import { getHospedagemAlimentacaoAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaHospedagemAlimentacao.uischema';
import { getMaterialDeConsumoAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaMaterialConsumo.uischema';
import { getMaterialPermanenteAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaMaterialPermanente.uischema';
import { getPassagemAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaPassagem.uischema';
import { getPessoalAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaPessoal.uischema';
import { getServicoTerceiroAnswerForm } from '../../../jsonforms/uischema/rubrica/rubricaServicoTerceiro.uischema';
const generateRevisaoRubricaUiSchema = (tipoRubrica, proposta, customHeader, customFooter) => {
    const baseUISchema = {
        type: 'VerticalLayout',
        elements: [{
                type: 'VerticalLayout',
                elements: []
            }, {
                type: 'VerticalLayout',
                elements: [{
                        type: 'LongText',
                        scope: '#/properties/revisao',
                        options: {
                            required: true,
                            maxLength: 256,
                            customHeader: customHeader
                        }
                    }]
            }]
    };
    switch (tipoRubrica) {
        case TipoEditalRubrica.diarias:
            baseUISchema.elements[0].elements = getDiariasAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.pessoal:
            baseUISchema.elements[0].elements = getPessoalAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.bolsa:
            baseUISchema.elements[0].elements = getBolsaAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.encargos:
            baseUISchema.elements[0].elements = getEncargoAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.servicosDeTerceiros:
            baseUISchema.elements[0].elements = getServicoTerceiroAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.materialDeConsumo:
            baseUISchema.elements[0].elements = getMaterialDeConsumoAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.passagens:
            baseUISchema.elements[0].elements = getPassagemAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.hospedagemAlimentacao:
            baseUISchema.elements[0].elements = getHospedagemAlimentacaoAnswerForm({
                proposta,
                readonly: true
            });
            break;
        case TipoEditalRubrica.materialPermanente:
            baseUISchema.elements[0].elements = getMaterialPermanenteAnswerForm({
                proposta,
                readonly: true
            });
            break;
        default:
            break;
    }
    return baseUISchema;
};
export default generateRevisaoRubricaUiSchema;
