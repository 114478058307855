import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { createResource } from '../../../utils/create-resource';
import { getOrcamentoPropostaUiSchema } from '../orcamento-proposta.uischema';
const ajusteOrcamentarioUiSchema = {
    type: 'CategorizationMenu',
    elements: [getOrcamentoPropostaUiSchema({
            route: '/proposta',
            customPaths: {
                put: '/ajuste-orcamentario'
            }
            // scopePrefix: '/properties/orcamentoEdicao',
            // formPrefix: 'orcamentoEdicao',
        })],
    options: {
        variant: 'stepper',
        showNavButtons: true,
        showEditButton: true,
        showProgress: false,
        hideSave: true,
        route: '/proposta',
        onFinish: _ref => {
            let { ctx, navigate } = _ref;
            const { updateCrudStates, setFormData } = ctx;
            if (createResource(ctx, '/proposta', null, {
                put: '/finalizar-ajuste-orcamento'
            })) {
                setFormData({});
                updateCrudStates(CrudStatesOptions.LIST);
                navigate('/proposta');
            }
        }
    }
};
export default ajusteOrcamentarioUiSchema;
